

export interface Menu {
  name: string;
  icon: string;
  activeIcon: string;
  childs: Array<MenuItem>;
  action: string;
  active: boolean;
  path: string;
}

export interface MenuItem {
  name: string;
  action: string;
  active: boolean;
}

export interface Action {
  name: string;
  image: string;
  action: string;
  isBefore: boolean;
}


export const actions : Array<Action>= [
  {
    name: "adminAnnaNagar",
    image: "/images/action/account.png",
    action: "",
    isBefore: true
  },
  {
    name: "Logout",
    image: "/images/action/logout.png",
    action: "logout",
    isBefore: true
  }
]

export const menus: Array<Menu> = [
  {
    name: "DASHBOARD",
    icon: "/images/menu/dashboard.png",
    activeIcon: "/images/menu/dashboard_active.png",
    childs: [],
    action: "/dashboard",
    path: "dashboard",
    active: true
  },
  {
    name: "LAB COLLECTION",
    icon: "/images/menu/lab_collection.png",
    activeIcon: "/images/menu/lab_collection_active.png",
    childs: [
      {
        name: "Manage Routes",
        active: false,
        action: "/lab_collection/manage_routes"
      },
      {
        name: "Manage Trips",
        active: false,
        action: "/lab_collection/manage_trips"
      },
      {
        name: "Manage Labs",
        active: false,
        action: "/lab_collection/manage_labs"
      }
    ],
    action: "/lab_collection/manage_routes",
    path: "lab_collection",
    active: false
  },
  {
    name: "HOME COLLECTION",
    icon: "/images/menu/home_collection.png",
    activeIcon: "/images/menu/home_collection_active.png",
    childs: [
      {
        name: "Manage Trips",
        active: false,
        action: "/home_collection/manage_trips"
      },
      {
        name: "Manage Patient Data",
        active: false,
        action: "/home_collection/manage_patient_data"
      },
      {
        name: "Manage Test Data",
        active: false,
        action: "/home_collection/manage_test_data"
      }
    ],
    action: "/home_collection/manage_trips",
    path: "home_collection",
    active: false
  },
  {
    name: "HOSPITAL COLLECTION",
    icon: "/images/menu/lab_collection.png",
    activeIcon: "/images/menu/hospital_collection_active.png",
    childs: [
      {
        name: "Manage Trips",
        active: false,
        action: "/hospital_collection/manage_trips"
      },
      {
        name: "Manage Patient Data",
        active: false,
        action: "/hospital_collection/manage_patient_data"
      },
      {
        name: "Manage Test Data",
        active: false,
        action: "/hospital_collection/manage_test_data"
      },
      {
        name: "Manage Hospital",
        active: false,
        action: "/hospital_collection/manage_hospital"
      }
    ],
    action: "/hospital_collection/manage_trips",
    path: "hospital_collection",
    active: false
  },
  {
    name: "BAGS AND CARRIERS",
    icon: "/images/menu/bags_carriers.png",
    activeIcon: "/images/menu/bags_carriers_active.png",
    childs: [
      {
        name: "Manage Bags",
        active: false,
        action: "/bags_carriers/manage_bags"
      },
      {
        name: "Manage Carriers",
        active: false,
        action: "/bags_carriers/manage_carriers"
      }
    ],
    action: "/bags_carriers/manage_bags",
    path: "bags_carriers",
    active: false 
  },
  {
    name: "SHIPMENT HISTORY",
    icon: "/images/menu/shipment_history.png",
    activeIcon: "/images/menu/shipment_history_active.png",
    childs: [],
    action: "/shipment_history",
    path: "shipment_history",
    active: false 
  }
]

/*
,
  {
    name: "DOCUMENTS",
    icon: "/images/menu/document.svg",
    activeIcon: "/images/menu/document_active.svg",
    childs: [],
    action: "/documents",
    path: "documents",
    active: false
  }*/