import React from 'react';
import {Router} from '@reach/router';

import SidePanel from '../../components/menu/Menu';

import {PowerDashboardView} from '../power_dashboard/PowerDashboard';

import {SuperDashboardView} from '../super_dashboard/SuperDashboard';
import {MainCenter} from '../main_center/MainCenter';
import {EmployeeView} from '../employee/EmployeeView';
import {PermissionView} from '../permission/PermissionView';

import {DashboardView} from '../dashboard/Dashboard';
import {CovidDashboardView} from '../dashboard/CovidDashboard';
import {HomeCollection} from '../home_collection/HomeCollection';
import { HospitalCollection } from '../hospital_collection/HospitalCollection';
import {CovidOnsiteCollection} from '../covid_onsite/CovidOnsiteCollection';
import { CovidOffsiteCollection } from '../covid_offsite/CovidOffsiteCollection';
import {LabCollection} from '../lab_collection/LabCollection';
import {BagsCarriers} from '../bag_carrier/BagsCarriers';
import {ShipmentHistory} from '../shipment/ShipmentHistory';
import './BaseView.css';
import { DocumentView } from '../document/DocumentView';
import { PowerAdmins } from '../power_user/PowerAdmins';
import { PowerPermission } from '../power_permission/PowerPermission';
import { ManageOrg } from '../organisation/ManageOrg';

export class BaseView extends React.Component {

  render() {
    return (
      <div className="flex-container">
        <div className="flex-menu" >
          <SidePanel />
        </div>
        <div className="flex-workspace">
          <Router basepath="/">
            <DashboardView path="/" />
            <CovidDashboardView path="covid_dashboard" />
            <DashboardView path="dashboard" />
            <LabCollection path="lab_collection/*" />
            <HomeCollection path="home_collection/*" />
            <HospitalCollection path="hospital_collection/*" />
            <CovidOnsiteCollection path="covid_onsite_collection/*" />
            <CovidOffsiteCollection path="covid_offsite_collection/*" />
            <BagsCarriers path="bags_carriers/*" />
            <ShipmentHistory path="shipment_history" />
            <DocumentView path="documents" />
            <SuperDashboardView path="super_dashboard" />
            <MainCenter path="main_center" />
            <EmployeeView path="employees/*" />
            <PermissionView path="permissions/*" />
            <PowerDashboardView path="power_dashboard" />
            <PowerAdmins path="power_users" />
            <PowerPermission path="power_permission" />
            <ManageOrg path="power_organisation/*" />
          </Router>
        </div>
      </div>
    );
  }

}