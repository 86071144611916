import React from 'react';
import GoogleMapReact from 'google-map-react';

import { Location, DefaultApi } from '../../apis';
import './Map.css';

export interface MapProps {
  selectedPin?(ids: Array<string>, type: MapType): void;
  selected? (mapData: MapData, type: MapType): void;
  removed? (mapData: MapData, waypoint: WayPoint, type: MapType) : void;
  data: Array<MapData>;
  markers: Array<MarkerData>;
  center: MarkerData;
  bike?: MarkerData;
  type: MapType;
  isFull: boolean;
  isUpdated: boolean;
  selectedRoute: string;
  selectedIds: Array<string>;
}

export enum MapType {
  Route = 'Route',
  HomeTrip = 'HomeTrip',
  HospitalTrip = 'HospitalTrip',
  CovidOnsiteTrip = 'CovidOnsiteTrip',
  CovidOffsiteTrip = 'CovidOffsiteTrip',
  LabTrip = 'LabTrip',
  BagDetail = 'BagDetail'
}

export interface MarkerData {
  id: string;
  title: string;
  address: string;
  location: Location;
  assign: string;
  state: string;
}

export interface MapData {
  id: string;
  startPlace: string;
  endPlace: string;
  waypoints: Array<WayPoint>;
  poliLine?: any;
  state: string;
}

export interface WayPoint {
  id: string;
  location: Location;
  stopover: boolean;
}

interface MapState {
  selectedIds: Array<string>;
  mapObj: any;
}

interface MarkProps {
  title: string;
  address: string;
  lat: number;
  lng: number; 
  icon: string;
  selected: boolean;
  bike?: boolean;
  click(count: number):void;
}

export default class MapView extends React.Component<MapProps, MapState> {

  constructor(props: MapProps) {
    super(props);
    this.state = {
      selectedIds: this.props.selectedIds || [],
      mapObj: {
        selected: this.props.selectedRoute || ""
      }
    };
    this.apiIsLoaded = this.apiIsLoaded.bind(this);
    this.addRoutes = this.addRoutes.bind(this);
    this.markerClicked = this.markerClicked.bind(this);
    this.getMapIcon = this.getMapIcon.bind(this);
    this.getIconByState = this.getIconByState.bind(this);
    this.getWayPoints = this.getWayPoints.bind(this);
  }

  markerClicked(marker: MarkerData, count: number) {
    const self = this;
    const selected = this.props.selectedRoute || "";
    if( marker.assign !== "" && marker.assign === selected && count === 1) {
      if( this.props.removed !== undefined && this.props.removed !== null ) {
        const mapData = this.props.data.find( mapData1 => (mapData1.id === selected))
        const wayPoint = mapData?.waypoints.find( (way) => (way.id === marker.id))
        if( wayPoint !== undefined && wayPoint !== null && mapData !== undefined && mapData !== null ) {
          self.props.removed!(mapData, wayPoint, this.props.type);
        }        
      }
    }
    if( marker.assign !== "" ) {
      const state = this.state;
      let data = this.props.data.find((item => (item.id === marker.assign)))
      if( data !== undefined && data !== null ) {
        if( this.props.selected !== undefined && this.props.selected !== null ) {
          this.props.selected(data, this.props.type);
        }
      }
      state.mapObj.selected = marker.assign;  
      this.setState({
        selectedIds: [],
        mapObj: state.mapObj
      });
    }else {
      const state = this.state;
      let ids = state.selectedIds;
      let index = ids.indexOf(marker.id);
      if( index > -1 ) {
        ids.splice(index, 1);
      }else {
        ids.push(marker.id)
      }
      if( this.props.selectedPin !== undefined && this.props.selectedPin !== null ) {
        this.props.selectedPin(ids, this.props.type);
      }
      this.setState({
        selectedIds: ids,
        mapObj: state.mapObj
      });
    }
  }

  apiIsLoaded = (map :any, maps: any) => {
    const state = this.state;
    this.setState({
      selectedIds: state.selectedIds,
      mapObj: {
        map: map,
        maps: maps
      }
    });
  };

  getWayPoints(items: Array<WayPoint>) : Array<Object> {
    let waypoints = [];
    for( let item of items ) {
      waypoints.push(
        {
          location: {
            lat: item.location.lat || 0.0,
            lng: item.location.lng || 0.0
          },
          stopover: item.stopover  
        }
      );
    }
    return waypoints;
  }

  //'14, Crescent Rd Nagar, Bharathi Puram, Annanagar East Chennai, Tamil Nadu 600102'
  //'2732, Block Y, Jawahar Colony, Anna Nagar, Chennai, Tamil Nadu 600040'
  addRoutes(route: MapData) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const maps = this.state.mapObj.maps;
    const map = this.state.mapObj.map;
    if( maps === undefined || maps === null ) {
      return;
    }
    const defaultApi = new DefaultApi();
    defaultApi.getMap("","",token, centerId, route.id).then((mapPath) => {
      const path = mapPath.data.success?.path || [];
      const routePolyline = new maps.Polyline({
        path: path,
        geodesic: true,
        strokeColor: '#558dff',
        strokeOpacity: 1.0,
        strokeWeight: 5
      });
      routePolyline.setMap(map);
      route.poliLine = routePolyline;      
      routePolyline.addListener('click', function() {
        //const state = self.state;
        self.setState({
          //data: state.data,
          mapObj: {
            map: map,
            maps: maps,
            selected: route.id
          }
        });
        if( self.props.selected !== undefined || self.props.selected !== null ) {
          self.props.selected!(route, self.props.type);
        }          
      });
      const state = self.state;
      self.props.data.forEach((mapData,index) =>{
        if( mapData.id === route.id ) {
          self.props.data[index] = route;
        }
        return route;
      })
      self.setState(state);
    });
    /*const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer();
    directionsService.route({
      origin: route.startPlace,
      destination: route.endPlace,
      waypoints: this.getWayPoints(route.waypoints),
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    }, (response : any, status: any) => {
      if (status === 'OK') {
        directionsDisplay.setDirections(response);
        const routePolyline = new maps.Polyline({
          path: response.routes[0].overview_path,
          geodesic: true,
          strokeColor: '#558dff',
          strokeOpacity: 1.0,
          strokeWeight: 5
        });
        routePolyline.setMap(map);
        route.poliLine = routePolyline;      
        routePolyline.addListener('click', function() {
          //const state = self.state;
          self.setState({
            //data: state.data,
            mapObj: {
              map: map,
              maps: maps,
              selected: route.id
            }
          });
          if( self.props.selected !== undefined || self.props.selected !== null ) {
            self.props.selected!(route, self.props.type);
          }          
        });
        const state = self.state;
        self.props.data.forEach((mapData,index) =>{
          if( mapData.id === route.id ) {
            self.props.data[index] = route;
          }
          return route;
        })
        self.setState(state);
      } 
    });*/
  }

  getIconByState(state: string) : string {
    if( this.props.type === MapType.HomeTrip ) {
      if( state === 'InActive' ) {
        return "/images/maps/home_inactive.png"
      }else if( state === 'Active' ) {
        return "/images/maps/home_active.png"
      }else if( state === 'Completed' ) {
        return "/images/maps/home_checked.png"
      }
      return "/images/maps/home_unselected.png"
    }if( this.props.type === MapType.CovidOnsiteTrip ) {
      if( state === 'InActive' ) {
        return "/images/maps/home_inactive.png"
      }else if( state === 'Active' ) {
        return "/images/maps/home_active.png"
      }else if( state === 'Completed' ) {
        return "/images/maps/home_checked.png"
      }
      return "/images/maps/home_unselected.png"
    }else if( this.props.type === MapType.LabTrip ) {
      if( state === 'InActive' ) {
        return "/images/maps/lab_inactive.png"
      }else if( state === 'Active' ) {
        return "/images/maps/lab_active.png"
      }else if( state === 'Completed' ) {
        return "/images/maps/lab_checked.png"
      }
      return "/images/maps/lab_unselected.png"
    } else if( this.props.type === MapType.HospitalTrip ) {
      if( state === 'InActive' ) {
        return "/images/maps/lab_inactive.png"
      }else if( state === 'Active' ) {
        return "/images/maps/lab_active.png"
      }else if( state === 'Completed' ) {
        return "/images/maps/lab_checked.png"
      }
      return "/images/maps/lab_unselected.png"
    }else if( this.props.type === MapType.CovidOffsiteTrip ) {
      if( state === 'InActive' ) {
        return "/images/maps/lab_inactive.png"
      }else if( state === 'Active' ) {
        return "/images/maps/lab_active.png"
      }else if( state === 'Completed' ) {
        return "/images/maps/lab_checked.png"
      }
      return "/images/maps/lab_unselected.png"
    }
    return "/images/maps/main_center.png";
  }

  getMapIcon(marker: MarkerData, isCenter: boolean = false) : string {
    if( isCenter ) {
      return "/images/maps/main_center.png";
    }
    if( this.props.type === MapType.Route ) {
      if( (marker.assign !== "" && marker.assign === this.props.selectedRoute) || (this.state.selectedIds.includes(marker.id)) ) {
        return "/images/maps/lab_selected.png";
      } else { 
        return ( marker.assign === "" ) ? "/images/maps/lab_unassigned.png" : "/images/maps/lab_unselected.png";
      } 
    }else  if( this.props.type === MapType.HomeTrip ) {
      if((marker.assign !== "" && marker.assign === this.props.selectedRoute) || (this.state.selectedIds.includes(marker.id))) {
        return "/images/maps/home_selected.png";
      } else { 
        return ( marker.assign === "" ) ? "/images/maps/home_unassigned.png" : this.getIconByState(marker.state);
      } 
    }else if( this.props.type === MapType.LabTrip ) {
      if( (marker.assign !== "" && marker.assign === this.props.selectedRoute) || (this.state.selectedIds.includes(marker.id)) ) {
        return "/images/maps/lab_selected.png";
      } else { 
        return ( marker.assign === "" ) ? "/images/maps/lab_unassigned.png" : this.getIconByState(marker.state);
      } 
    }else  if( this.props.type === MapType.CovidOnsiteTrip ) {
      if((marker.assign !== "" && marker.assign === this.props.selectedRoute) || (this.state.selectedIds.includes(marker.id))) {
        return "/images/maps/home_selected.png";
      } else { 
        return ( marker.assign === "" ) ? "/images/maps/home_unassigned.png" : this.getIconByState(marker.state);
      } 
    }else if( this.props.type === MapType.CovidOffsiteTrip ) {
      if( (marker.assign !== "" && marker.assign === this.props.selectedRoute) || (this.state.selectedIds.includes(marker.id)) ) {
        return "/images/maps/lab_selected.png";
      } else { 
        return ( marker.assign === "" ) ? "/images/maps/lab_unassigned.png" : this.getIconByState(marker.state);
      } 
    }
    return "/images/maps/main_center.png";
  }

  static getDerivedStateFromProps(nextProps: MapProps, prevState: MapState): MapState {
    if( nextProps.isUpdated ) {
      return {
        selectedIds: nextProps.selectedIds || [],
        mapObj: prevState.mapObj
      };
    }
    return prevState;
  } 
 
  getPathColor(selected: boolean, route: MapData): string {
    if( selected ) {
      return '#3a419a';
    }else if( this.props.type === MapType.Route ) {
      return '#aac6ff';
    }else if( route.state === 'InActive' ) {
      return '#e1484e'
    }else if( route.state === 'Active' ) {
      return '#1c9477'
    }else if( route.state === 'Completed' ) {
      return '#1c9477'
    }
    return '#aac6ff'
  }

  render() {
    const s = this.props.selectedRoute || "";
    this.props.data.forEach((route) => {
      if( route.poliLine === undefined || route.poliLine === null ) {
        this.addRoutes(route);
      }
      const color = this.getPathColor(( route.id === s ), route);
      route.poliLine?.setOptions({strokeColor: color});
    });
    const className = (this.props.isFull) ? "full-map" : "half-map";
    const centerMarker = {
      lat: this.props.center.location.lat || 0.0,
      lng: this.props.center.location.lng || 0.0,
      title: this.props.center.title,
      address: this.props.center.address,
      icon: this.getMapIcon(this.props.center, true),
      selected: false,
      click: ((count: number)=>{this.markerClicked(this.props.center, count)})
    }
    const bikeMarker = {
      lat: this.props.bike?.location.lat || 0.0,
      lng: this.props.bike?.location.lng || 0.0,
      title: "",
      address: "",
      icon: "/images/maps/bike.png",
      selected: false,
      bike: true,
      click: ((count: number)=>{/** do nothing for now. */})
    }
    return (
      <div className={className}>
         <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyAmTXfy-q2ri0seVsVduRP7K1fkIcmSSdk"}} 
            center={{lat: this.props.center.location.lat || 0.0, lng: this.props.center.location.lng || 0.0}}
            zoom={13} 
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}>
            <Marker {...centerMarker} />  
            <Marker {...bikeMarker} />
            {              
              this.props.markers.map((marker, index) =>{
                const item = {
                  lat: marker.location.lat || 0.0,
                  lng: marker.location.lng || 0.0,
                  title: marker.title,
                  address: marker.address,
                  icon: this.getMapIcon(marker),
                  selected: (marker.assign !== "" && marker.assign === s),
                  click: ((count: number)=>{this.markerClicked(marker,count)})
                }
                return(<Marker {...item} key={index} />);
              })
            }  
          </GoogleMapReact> 
      </div>
    );
  }
}

export class Marker extends React.Component<MarkProps> {

  render() {
    let result = (this.props.selected) ? <img src="/images/maps/close.png" className="marker-close" alt="" onClick={()=>{this.props.click(1);}}/> : null;
    if( (this.props.lat === this.props.lng) && (this.props.lat === 0.0) ) {
      return null;
    }
    if( this.props.bike ) {
      return(
        <div className="base-marker">
          <img src={this.props.icon} alt="" onClick={()=>{this.props.click(0);}} className="base-marker-bike"/>
        </div>
      );
    }else {
      return(
        <div className="base-marker">
          <img src={this.props.icon} alt="" onClick={()=>{this.props.click(0);}} className="base-marker-img"/>
          {result}
          <div className="talk-bubble tri-right left-top">
            <div className="talktext">
              <p>{this.props.title}<br/>
              {this.props.address}</p>
            </div>
          </div>
        </div>
      );
    }
  }

}
