import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { 
  RTestCollection, RHomeTrip, RHospitalTrip,
  RSample, DefaultApi, RModelMessageCodeEnum, 
  RPatient, RModelListRPatient, RModelListRPatientCodeEnum,
  RModelListRSample, RModelListRSampleCodeEnum, RModelListRTestCollection,
  RModelListRTestCollectionCodeEnum,
  TestTubes,
  RModelRTestCollectionCodeEnum
} from '../../apis';
import { AxiosResponse } from 'axios';
import { Modal } from 'react-bootstrap';
import './CollectionView.css';

interface CollectionViewProps extends RouteComponentProps {
  trip: RHomeTrip|RHospitalTrip;
  type: string;
  show: boolean;
  hide(): void;
}

interface CollectionViewState {
  patients: Array<RPatient>;
  samples: Array<RSample>;
  sampleGroups: Array<RSample>;
  testCollection: Array<RTestCollection>;
}

export class CollectionView extends React.Component<CollectionViewProps, CollectionViewState> {
  
  constructor(props: CollectionViewProps) {
    super(props)
    this.state = {
      testCollection: [],
      samples: [],
      sampleGroups: [],
      patients: []
    }
    this.update = this.update.bind(this);
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    obj.tripId = this.props.trip.id || "";
    obj.tripType = this.props.type || "OP";

    const tripStyle = (this.props.trip.name?.startsWith("HT") ? "Home" : "Hospital" ) || "Home";

    const defaultApi = new DefaultApi()
    if( tripStyle === "Home" ) {
      defaultApi.updateTestInBooking("","",token,centerId, obj as RTestCollection).then((item) =>{
        const data = item.data;
        if( data.code === RModelRTestCollectionCodeEnum.Success ) {
          //window.alert("Created.");
          obj.testCollection = data.success?.testCollection || [] ;
          self.props.hide(); 
        }else {
          callback();
        }
      });
    }else {
      if( obj.id !== undefined ) {
        obj.group = obj.testCollection;
        defaultApi.updateTestCollection("","",token,centerId, obj as RTestCollection).then((item)=>{
          const data = item.data;
          if( data.code === RModelMessageCodeEnum.Success ) {
            //window.alert("Created.");
            self.props.hide(); 
          }else {
            callback();
          }
        });
      }else {
        obj.group = obj.testCollection;
        defaultApi.createTestCollection("","",token,centerId, obj as RTestCollection).then((item)=>{
          const data = item.data;
          if( data.code === RModelMessageCodeEnum.Success ) {
            //window.alert("Created."); 
            self.props.hide();
          }else {
            callback();
          }
        });
      }
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getAllPatients("","",token,centerId, this.props.type).then((itemR: AxiosResponse<RModelListRPatient>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRPatientCodeEnum.Success ) {
        self.setState({
          patients: dataR.success || [] 
        });
      }
    });
    defaultApi.getTestGroups("","", token, centerId, "nonpro").then((itemR: AxiosResponse<RModelListRSample>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRSampleCodeEnum.Success ) {
        self.setState({
          sampleGroups: dataR.success || [] 
        });
      }
    });
    defaultApi.getAllSamples("","",token,centerId).then((itemR: AxiosResponse<RModelListRSample>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRSampleCodeEnum.Success ) {
        self.setState({
          samples: dataR.success || [] 
        });
      }
    });
    defaultApi.getTripTestCollections("","",token,centerId, this.props.type, this.props.trip.id || "").then((itemR: AxiosResponse<RModelListRTestCollection>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRTestCollectionCodeEnum.Success ) {
        self.setState({
          testCollection: dataR.success || [] 
        });
      }
    });
  }

  render() {
    const trip = this.props.trip
    const home = this.props.trip.name?.startsWith("HT") || false;
    return(
      <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl" >
        <div className="window" style={{overflow: "scroll"}}>
          {
            trip.patientIds?.map((patientId, index) => {
              const test = this.state.testCollection.find( item => (item.patient === patientId));
              const patient = this.state.patients.find( item => (item.id === patientId) ) || {}; 
              const testProps: TestCollectionProps = {
                home: home,
                testCollection: test,
                samples: this.state.samples,
                sampleGroups: this.state.sampleGroups,
                patient: patient,
                update: this.update
              }
              return(<TestCollectionView {...testProps} key={index}/>);
            })
          }
        </div>
      </Modal>
    )
  }

}

interface TestCollectionProps {
  home: boolean;
  testCollection?: RTestCollection;
  samples: Array<RSample>;
  sampleGroups: Array<RSample>;
  patient: RPatient;
  update(obj: any, callback: Function): void;
}

interface TestCollectionState {
  show: boolean;
}

class TestCollectionView extends React.Component<TestCollectionProps, TestCollectionState> {

  constructor(props: TestCollectionProps) {
    super(props);
    this.state = {
      show: false
    };
    this.hide = this.hide.bind(this);
    this.selected = this.selected.bind(this);
  }

  selected(ids: Array<string>, callback: Function) {
    let obj = this.props.testCollection || {};
    obj.patient = this.props.patient.id;
    obj.number = "";
    if( this.props.home ) {
      obj.group = ids.unique();
    }else {
      obj.testCollection = ids.unique();
    }
    this.props.update(obj, callback);
    this.hide();
  }

  hide() {
    this.setState({show: false});
  }

  render() {
    const item  = ( this.props.testCollection?.status || false ) ? (<img src="/images/maps/lab_checked.png" alt="" width="20px" height="20px"/>) : (<button type="button" className="btn" onClick={()=>{this.setState({show: true})}}>Add Test</button>)
    const groups = (this.props.home) ? (this.props.sampleGroups || []) : ((this.props.sampleGroups.length === 0) ? this.props.samples : this.props.sampleGroups) || [];
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div>Patient ID #{this.props.patient.id?.substr(this.props.patient.id?.length-6).toUpperCase() || ""} ({this.props.testCollection?.noOfTests || 0})
          <span style={{paddingLeft: 10}}>{item}</span>
        </div>
        <div style={{padding: 10}}>
          <div className="row"><strong>Test Group</strong></div>
          <div className="row">
            {
              this.props.testCollection?.group?.map((testId, index) => {
                const sample = groups.find(item1 => (item1.id === testId));
                if( sample !== undefined ) {
                  return(
                    <div className="col-md-4" key={index}>
                      <SampleGroupView sample={sample}/>
                    </div>
                  )
                }else {
                  return(
                    <div className="col-md-4" key={index}>
                    </div>
                  )
                }
              })
            }
          </div>
          <div className="row"><strong>Test Collection</strong></div>
          <div className="row">
            {
              this.props.testCollection?.testCollection?.map((testId, index) => {
                const sample = this.props.samples.find(item1 => (item1.id === testId));
                if( sample !== undefined ) {
                  const testTubes = this.props.testCollection?.testTubes || {};
                  //const sampleType =  this.props.testCollection?.sampleType || [];
                  //const barScans = this.props.testCollection?.barCollection || [];
                  //const barId = sampleType.indexOf((sample.type || "") as string);
                 // console.log( this.props.testCollection, sampleType, barId, sample.type);
                  //const bar = (barId >= 0 && barScans.length > barId) ? barScans[barId] : "";
                  return(
                    <div className="col-md-4" key={index}>
                      <SampleView sample={sample} bar={testTubes}/>
                    </div>
                  )
                }else {
                  return(
                    <div className="col-md-4" key={index}>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
        <AddCollectionView home={this.props.home} samples={this.props.samples} show={this.state.show} ids={this.props.testCollection?.testCollection || []}
          selected={this.selected} hide={this.hide} group={this.props.testCollection?.group || []} sampleGroups={this.props.sampleGroups}/>
      </div>
    )
  }

}

interface SampleGroupProps {
  sample: RSample;
}

class SampleGroupView extends React.Component<SampleGroupProps> {
 
  render() {
    const name = this.props.sample.name;
    const count = this.props.sample.ids?.length || 1;
    const image = "/images/maps/lab_selected.png";
    return(
      <div style={{padding: 10}}>
        <div className="test-tube">
          <div>
            <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name+ " ("+count+")"}</span>  
          </div>
        </div>
      </div>
    )
  }
}


interface SampleProps {
  sample: RSample;
  bar: TestTubes;
}

class SampleView extends React.Component<SampleProps> {
  constructor(props: SampleProps) {
    super(props);
    this.getBars = this.getBars.bind(this);
  }

  getBars(color: string): Array<string> {
    let barCodes: Array<string> = [];
    let barString: string = "";
    switch (color) {
      case 'red':
        barString = this.props.bar.red || ""
        break;
      case 'blue':
        barString = this.props.bar.blue || ""
        break;
      case 'green':
        barString = this.props.bar.green || ""
        break;
      case 'yellow':
        barString = this.props.bar.yellow || ""
        break;
      case 'purple':
        barString = this.props.bar.purple || ""
        break;
      case 'grey':
        barString = this.props.bar.grey || ""
        break;
      default:
        barString = ""  
        break;
    }  
    barString.split(";").forEach((bar) => {
      let code = bar.trim()
      if( code !== "" ) {
        barCodes.push(code)
      }
    });
    return barCodes;
  }

  render() {
    const color = this.props.sample.type?.toLowerCase() || "blue";
    const image = "/images/tubes/"+color+".png";
    const name = this.props.sample.name;
    const bars = this.getBars(color);
    if( bars.length === 0 ) {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
            <div>
              { 
                bars.map((bar, i) => {
                  return(<div className={"bar-code "+color}>{bar || ""}</div>)
                })
              }
            </div> 
          </div>
        </div>
      )
    }
  }
}

interface SampleSelectProps {
  sample: RSample;
  selected: boolean;
}

class SampleSelectView extends React.Component<SampleSelectProps> {

  render() {
    const image = "/images/tubes/"+this.props.sample.type?.toLowerCase()+".png"
    const checked = "/images/maps/"+((this.props.selected)? "lab_checked" : "lab_selected")+".png"
    const name = this.props.sample.name
    return(
      <div style={{padding: 10}}>
        <div className="test-tube">
          <div className="flex-row">
            <div><img src={image} alt="" style={{width:25, height:25}}/><span style={{paddingLeft: 10}}>{name}</span></div>
            <div><img src={checked} alt="" style={{width:25, height:25}}/></div>
          </div>             
        </div>
      </div>
    )
  }

}

interface AddCollectionViewProps extends RouteComponentProps {
  home: boolean;
  samples: Array<RSample>;
  sampleGroups: Array<RSample>;
  show: boolean;
  ids: Array<string>;
  group: Array<string>;
  selected(ids: Array<string>, callback: Function): void;
  hide(): void;
}

interface AddCollectionViewState {
  confirm: boolean;
}

export class AddCollectionView extends React.Component<AddCollectionViewProps, AddCollectionViewState> {

  constructor(props: AddCollectionViewProps) {
    super(props);
    this.state = {
      confirm: true
    }
    this.update = this.update.bind(this);
    this.updateServer = this.updateServer.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.disableConfirm = this.disableConfirm.bind(this);
  }

  updateServer(ids: Array<string>) {
    this.disableConfirm();
    this.props.selected(ids, this.showConfirm);
  }

  showConfirm() {
    this.setState({confirm: true});
  }

  disableConfirm() {
    this.setState({confirm: false});
  }

  update(id: string) {
    if( this.props.home ) {
      if( this.props.group.includes(id) ) {
        const index = this.props.group.indexOf(id);
        if( index > -1 ) {
          this.props.group.splice(index, 1);
        } 
      }else {
        this.props.group.push(id);
      }
    }else {
      if( this.props.ids.includes(id) ) {
        const index = this.props.ids.indexOf(id);
        if( index > -1 ) {
          this.props.ids.splice(index, 1);
        }      
      }else {
        this.props.ids.push(id);
      }
    }
    this.setState({});
  }

  render() {
    if( this.props.home ) {
      return(
        <Modal show={this.props.show} dialogClassName="modal-70w overflow-auto" onHide={()=>{this.props.hide()}} size="xl">
          <div className="window overflow-auto">
            <div className="row">
              {
                this.props.sampleGroups.map((sample, index) => {
                  return(
                    <div className="col-md-4" key={index} onClick={()=>{this.update(sample.id || "")}}>
                      <SampleSelectView sample={sample} selected={this.props.group.includes(sample.id || "")} />
                    </div>
                  );
                })
              }
            </div>
            <div className="flex-action">
              <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Close</button></div>
              <div><button className="btn btn-confirm" onClick={()=>{this.updateServer(this.props.group)}} disabled={!this.state.confirm}>Confirm</button></div>
            </div>
          </div>
        </Modal>
      )
    } else {
      return(
        <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl">
          <div className="window">
            <div className="row">
              {
                this.props.samples.map((sample, index) => {
                  return(
                    <div className="col-md-4" key={index} onClick={()=>{this.update(sample.id || "")}}>
                      <SampleSelectView sample={sample} selected={this.props.ids.includes(sample.id || "")} />
                    </div>
                  );
                })
              }
            </div>
            <div className="flex-action">
              <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Close</button></div>
              <div><button className="btn btn-confirm" onClick={()=>{this.updateServer(this.props.ids)}} disabled={!this.state.confirm}>Confirm</button></div>
            </div>
          </div>
        </Modal>
      )
    }
  }

}