import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { AxiosResponse } from 'axios';

import { 
  RLab, 
  RRoute, 
  DefaultApi, 
  RModelListRLab, 
  RModelListRLabCodeEnum, 
  RModelListRRoute, 
  RModelListRRouteCodeEnum, 
  RModelMessage, 
  RModelMessageCodeEnum,
  RModelFormCodeEnum,
  RModelRMainCenter,
  RModelRMainCenterCodeEnum,
  DynamicItem,
  Form
} from '../../../apis';
import MapView, { MapType, MapProps, MapData, WayPoint, MarkerData } from '../../../components/map/Map';
import './ManageRoutes.css';
//import { Dropdown } from 'react-bootstrap';
import {Dropdown} from '../../../components/dropdown/Dropdown';
import { FormModel } from '../../../components/form/Form';
import { MapLegend } from '../../../components/map/MapLegend';

interface ManageRoutesProps extends RouteComponentProps {

}

interface ManageRoutesState {
  labs: Array<RLab>;
  routes: Array<RRoute>;
  isUpdate: boolean;
  selectedRoute: string;
  form: Form,
  showForm: boolean,
  selected: any,
  edit: boolean,
  center: MarkerData
  selectedLab: Array<string>;
}

export class ManageRoutes extends React.Component<ManageRoutesProps, ManageRoutesState> {

  constructor(props: ManageRoutesProps) {
    super(props);
    this.state = {
      labs: [],
      routes: [],
      isUpdate: false,
      selectedRoute: "",
      form : {},
      showForm: false,
      selected: {},
      edit: false,
      center: {
        id: "main-center",
        title: "Main Center",
        address: "",
        assign: "",
        state: "",
        location: {lat: 13.081739, lng: 80.202409}
      },
      selectedLab: []
    };
    this.removeLabs = this.removeLabs.bind(this);
    this.selectedLabItem = this.selectedLabItem.bind(this);
    this.selectedRoute = this.selectedRoute.bind(this);
    this.getMapRoute = this.getMapRoute.bind(this);
    this.getMarkers = this.getMarkers.bind(this);
    this.getWayPoints = this.getWayPoints.bind(this);
    this.isAssigned = this.isAssigned.bind(this);
    this.selectedRouteDrop = this.selectedRouteDrop.bind(this);
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    this.setState(
      {
        labs: state.labs,
        routes: state.routes,
        isUpdate: state.isUpdate,
        selectedRoute: state.selectedRoute,
        form : state.form,
        showForm: true,
        selected: obj || {},
        edit: true,
        center: state.center,
        selectedLab: state.selectedLab
      }
    );
  }

  show() {
    const state = this.state;
    this.setState(
      {
        labs: state.labs,
        routes: state.routes,
        isUpdate: true,
        selectedRoute: state.selectedRoute,
        form : state.form,
        showForm: true,
        selected: state.selected,
        edit: state.edit,
        center: state.center,
        selectedLab: state.selectedLab
      }
    );
  }

  hide() {
    const state = this.state;
    this.setState(
      {
        labs: state.labs,
        routes: state.routes,
        isUpdate: true,
        selectedRoute: state.selectedRoute,
        form : state.form,
        showForm: false,
        selected: {},
        edit: false,
        center: state.center,
        selectedLab: []
      }
    );
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updateRoute("","",token,centerId, obj as RRoute).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Routes", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createRoute("","",token,centerId, obj as RRoute).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Routes", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }
  }


  selectedRouteDrop(id: string) {
    const state = this.state;
    const selected = this.state.routes.find((item => (item.id === id))) || {};
    this.setState({
        labs: state.labs,
        routes: state.routes,
        isUpdate: true,
        selectedRoute: id,
        form : state.form,
        showForm: state.showForm,
        selected: selected,
        edit: true,
        center: state.center,
        selectedLab: []
      }
    );
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getAllLabs("","",token,centerId).then((item : AxiosResponse<RModelListRLab>) => {
      const data = item.data;
      if( data.code! === RModelListRLabCodeEnum.Success ) {
        const state = self.state;
        data.success?.map((lab, _) =>{
          state.labs.push(lab); 
        });
        self.setState(state);        
      } 
    });
    defaultApi.getMainCenterById("","",token,centerId, centerId).then((item: AxiosResponse<RModelRMainCenter>) =>{
      const dataR= item.data;
      if( dataR.code! === RModelRMainCenterCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          labs: state.labs,
          routes: state.routes,
          isUpdate: state.isUpdate,
          selectedRoute: state.selectedRoute,
          form : state.form || {},
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          center: {
            id: centerId,
            title: dataR.success?.name || "Main Center",
            address: dataR.success?.address?.area || "Anna Nagar",
            assign: "",
            state: "",
            location: dataR.success?.address?.location || {lat: 13.081739, lng: 80.202409}
          },
          selectedLab: state.selectedLab
        });
      }
    })
    defaultApi.getAllRoutes("","",token,centerId).then((itemR: AxiosResponse<RModelListRRoute>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRRouteCodeEnum.Success ) {
        const state = self.state;
        dataR.success?.map((route, _) =>{
          state.routes.push(route);
        });
        self.setState(state);
      }
    });  

    defaultApi.getFormType("","",token,centerId,"Route").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          labs: state.labs,
          routes: state.routes,
          isUpdate: state.isUpdate,
          selectedRoute: state.selectedRoute,
          form : data.success || {},
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          center: state.center,
          selectedLab: state.selectedLab
        });
      }
    });
  }

  getWayPoints(labs: Array<RLab>, ids?: Array<string>) : Array<WayPoint> {
    let data : Array<WayPoint> = [];
    ids?.map((id) =>{
      const lab = labs.find(rlab => (id === rlab.id))
      if(lab !== undefined || lab !== null ) {
        const waypoint : WayPoint = {
          id: lab?.id || "",
          stopover: true,
          location: lab?.address?.location || {}
        };
        data.push(waypoint);
      }
    });
    return data;
  }

  getMapRoute(routes: Array<RRoute>, labs: Array<RLab>) : Array<MapData> {
    let data : Array<MapData> = [];
    routes.map((route) => {
      const firstId =  route.labIds![0];
      const lastId = route.labIds![route.labIds!.length-1];
      let mapData : MapData = {
        id: route.id || "",
        startPlace: labs.find(value => value.id === firstId)?.address?.full || "",
        endPlace: labs.find(value =>  value.id === lastId)?.address?.full || "",
        waypoints: this.getWayPoints(labs, route.labIds),
        state: ""
      }
      data.push(mapData);
      return route;
    });
    return data;
  }

  isAssigned(routes: Array<RRoute>, id: string) : string {
    for( const route of routes ) {
      const match = route.labIds?.find(labId => (id === labId))
      if( match !== undefined && match !== null ) {
        return route.id || "";
      }
    }
    return "";
  }

  getMarkers(routes: Array<RRoute>, labs: Array<RLab>) : Array<MarkerData>{
    let data : Array<MarkerData> = [];
    labs.map((lab) =>{
      let markerData: MarkerData = {
        id: lab.id || "",
        title: lab.name || "",
        location: lab.address?.location || {},
        address: lab.address?.area || "",
        assign: this.isAssigned(routes, lab.id!) || "",
        state: ""
      }
      data.push(markerData);
      return lab;
    })
    return data;
  }

  removeLabs(route: MapData, waypoint: WayPoint, type: MapType) {
    const self = this;
    const routes = this.state.routes;
    const rroute = routes.find(item => (item.id === route.id))
    if( rroute !== undefined && rroute !== null ) {
      const index = rroute.labIds!.findIndex(item => (waypoint.id === item))
      if( index > -1 ) {
        rroute.labIds!.splice(index, 1);
      }
      const token = localStorage.getItem("token") || "";
      const centerId = localStorage.getItem("centerId") || "";
      const defaultAPI = new DefaultApi();
      defaultAPI.updateRoute("","",token,centerId, rroute).then((item : AxiosResponse<RModelMessage>) => {
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          self.hide();
        }        
      })
    }   
  }

  selectedLabItem(ids: Array<string>, type: MapType) {
    const state = {
      labs: this.state.labs,
      routes: this.state.routes,
      isUpdate: true,
      selectedRoute: this.state.selectedRoute,
      form : this.state.form,
      showForm: this.state.showForm,
      selected: {
        labIds: ids
      },
      edit: false,
      center: this.state.center,
      selectedLab: ids
    }
    this.setState(state);
  }

  selectedRoute(route: MapData, type: MapType) {
    const selected = this.state.routes.find((item => (item.id === route.id))) || {};
    const state = {
      isUpdate: true,
      selectedRoute: route.id,
      routes: this.state.routes,
      labs: this.state.labs,
      form : this.state.form,
      showForm: this.state.showForm,
      selected: selected,
      edit: true,
      center: this.state.center,
      selectedLab: []
    };
    this.setState(state);
  }

  render() {
    const routes = this.state.routes;
    const labs = this.state.labs;
    const selectedRouteName = routes.find(route => (route.id === this.state.selectedRoute))?.name || "Select Route";
    const mapProps : MapProps = {
      type: MapType.Route,
      isFull: true,
      center: this.state.center,
      data: this.getMapRoute(routes, labs),
      markers: this.getMarkers(routes, labs),
      isUpdated: this.state.isUpdate,
      selectedRoute: this.state.selectedRoute,
      selectedIds: this.state.selectedLab,
      removed: this.removeLabs,
      selected: this.selectedRoute,
      selectedPin: this.selectedLabItem
    }
    const items : Array<DynamicItem>= [];
    for( let route of routes ) {
      items.push(
        { 
          id: route.id,
          name: route.name,
          ids: []
        }
      );
    }
    return(
      <div>
        <div className="action-holder flex-row">
          <div className="row">
            <div style={{minWidth: "240px"}}>
              <Dropdown id="map-route-dropdown" selected={selectedRouteName}
                placeholder="Select Route" items={items}
                invert={true}
                onSelected={(item)=>{this.selectedRouteDrop(item.id || "")}}/>  
            </div>
            <div style={{marginLeft: 35, marginTop: 10}}><MapLegend type={MapType.Route} /></div>
          </div>
          <div><button type="button" className="btn" onClick={this.show}>{(this.state.edit) ? "Update" : "Add"} Route</button></div>
        </div>
        <div>
          <MapView {...mapProps} />
        </div>
        <FormModel form={this.state.form} edit={this.state.edit} 
          obj={this.state.selected} update={this.update} hide={this.hide} 
          show={this.state.showForm}/> 
      </div>
    );  
  }
  
}
/*
<Dropdown> 
              <Dropdown.Toggle variant="primary" id="map-route-dropdown" className="dropdown-class">
                {selectedRouteName}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  routes.map((route, index) => {
                    return(
                      <Dropdown.Item href="#" key={index} onClick={()=>{this.selectedRouteDrop(route.id || "")}} >
                        {route.name}
                      </Dropdown.Item>
                    );
                  })
                }
              </Dropdown.Menu>
            </Dropdown>


<MapView  type={MapType.Route} 
            selectedRoute={(route)=>{}} selectedHomeTrip={(trip) =>{}} 
            selectedLabTrip={(trip)=>{}} removeHomeFromTrip={(trip, home)=>{}}
            removeLabFromTrip={(trip, lab) =>{}} routes={routers} labs={labs}
            patients={[]}
          />
          */