import React from 'react';
import { Modal } from 'react-bootstrap';
import {PouchBag} from '../../apis';

import './CriticalBags.css';
import { Link } from '@reach/router';
import { Badge } from '../badges/Bagde';

interface PouchBagsProps {
  pouchBags: PouchBag;
  showAll: boolean;
}

interface PouchBagsState {
  showDialog: boolean;
}

export class PouchView extends React.Component<PouchBagsProps, PouchBagsState> {
  
  constructor(props: PouchBagsProps) {
    super(props);
    this.state = {
      showDialog: false
    };
  }

  render() {
    const pouchBags = this.props.pouchBags;
    const showAll = this.props.showAll;
    const showViewAll = showAll ? false : ( (pouchBags.data?.length || 0) > 3 );
    const style = (showViewAll) ? {} : {display: "none"};
    let total = 0;
    return(
      <div>
        <table className="critial-bags">
          <thead style={{backgroundColor: "var(--light-red-color)"}}>
            <tr>
              <th className="critial-bags-border" style={{width: "10%"}}>
                S.No
              </th>  
              <th className="critial-bags-border" style={{width: "30%"}}>
                Pouch ID
              </th>
              <th className="critial-bags-border" style={{width: "30%"}}>
                Used
              </th>
              <th className="critial-bags-border" style={{width: "30%"}}>
                Remaining
              </th>
            </tr>
          </thead>
          <tbody>
            {
              pouchBags.data?.map((bag, index) =>{
                if( showAll || index < 3 )  {
                  const used = (bag.used || 0 );
                  const remaining = (bag.remaining || 0 );
                  return(
                    <tr key={index}>
                      <td className="critial-bags-border" style={{width: "10%"}}>{index}</td>
                      <td className="critial-bags-border" style={{width: "30%"}}>{bag.bagId || "L0001"}</td>
                      <td className="critial-bags-border" style={{width: "30%"}}>{used}</td>
                      <td className="critial-bags-border" style={{width: "30%"}}>{remaining}</td>
                    </tr>
                  );
                }
              })
            }
          </tbody>
        </table>
        <Badge size={40} color="#fff" count={total} bgColor="var(--dark-red-color)" badgeClass={"table-badge"} />
        <div className="align-right" style={style}>
          <span>
            <Link to="#" onClick={()=>{this.setState({showDialog: true})}}>
              view all
            </Link>
          </span>
        </div>
        <Modal show={this.state.showDialog} dialogClassName="modal-70w" onHide={()=>{this.setState({showDialog: false})}} size="xl" >
          <div className="window" style={{overflow: "scroll"}}>
            <PouchView pouchBags={pouchBags} showAll={true} />
          </div>  
        </Modal>
      </div>
    );  
  }

}