import {DashboardTypeEnum} from '../../apis';

export const data = {
  labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  datasets: [
    {
      label: "",
      data: [10, 48, 20, 35, 0, 10, 64],
      backgroundColor: [
        '#3a419a',
        '#e1484e',
        '#3a419a',
        '#e1484e',
        '#3a419a',
        '#3a419a',
        '#e1484e'
      ]
    }
  ]
};

export const options = {
  scales: {
    xAxes: [{
      ticks: {
        beginAtZero: true,
        fontSize: 16,
        fontStyle: 'Bold'
      },
      gridLines: {
        drawOnChartArea: false
      }
    }],
    yAxes: [{
      ticks: {
        beginAtZero: true,
        fontSize: 16
      },
      gridLines: {
        drawOnChartArea: false
      } 
    }]
  },
  title: {
    display: true,
    position: "top",
    fontColor: '#000',
    fontSize: '16',
    fontFamily: 'Roboto',
    fontStyle: '300',
    text: 'COLLECTION REPORT'
  },
  legend: {
    display: true,
    position: "top"
  }
};

export let dashData = {
  state: "Week",
  selected: "all",
  actions: [ "Day","Week","Month","Year" ],
  list: [
    {
      id: "All",
      name: "All"
    }
  ],
  filter: "all",
  dashboard: {
    type: DashboardTypeEnum.Week,
    total: 432,
    data: {
      labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      datasets: [
        {
          label: "",
          data: [10, 48, 20, 35, 0, 10, 64],
          backgroundColor: [
            '#3a419a',
            '#e1484e',
            '#3a419a',
            '#e1484e',
            '#3a419a',
            '#3a419a',
            '#e1484e'
          ]
        }
      ]
    },
    criticalBag: {
      data: [
        {
          bagId: "L00001",
          noOfCriticalSampleC1: 35,
          noOfCriticalSampleC2: 0,
          noOfCriticalSampleC3: 10
        },
        {
          bagId: "L00002",
          noOfCriticalSampleC1: 15,
          noOfCriticalSampleC2: 5,
          noOfCriticalSampleC3: 14
        },
        {
          bagId: "H00001",
          noOfCriticalSampleC1: 27,
          noOfCriticalSampleC2: 0,
          noOfCriticalSampleC3: 0
        }
      ]
    },
    carrier: {
      title: "Carrier Leaderboard",
      data: [
        {
          order: 1,
          name: "Karthik Kishore",
          count: 50
        },
        {
          order: 2,
          name: "Kaushik Ram",
          count: 35
        },
        {
          order: 3,
          name: "Jacob Koshy",
          count: 23
        },
        {
          order: 4,
          name: "Siddhanth Mathew",
          count: 20
        },
        {
          order: 5,
          name: "Arun Mohan",
          count: 18
        },
        {
          order: 6,
          name: "Ashwin Thiru",
          count: 10
        }
      ]
    },
    pouch: {
      data: []
    },
    lab: {
      title: "Lab Leaderboard",
      data: [
        {
          order: 1,
          name: "Medall Lab, Anna Nagar",
          count: 50
        },
        {
          order: 2,
          name: "Lister Lab, Kilpauk",
          count: 35
        },
        {
          order: 3,
          name: "Trinity Labs",
          count: 23
        },
        {
          order: 4,
          name: "Diagonistic Labs, Kilpauk",
          count: 20
        },
        {
          order: 5,
          name: "Raju Labs, Chetpet",
          count: 18
        },
        {
          order: 6,
          name: "Dell Labs, Anna Nagar",
          count: 10
        }
      ]
    },
    numberOfReSample: 0,
    temperature: 32
  }
};