import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { DynamicItem } from '../../apis';
import './Dropdown.css';

interface DropdownProps {
  id: string;
  placeholder: string;
  selected?: string;
  items: Array<DynamicItem>;
  invert?: boolean;
  onSelected(item: DynamicItem): void;
  //onBlur?(item?: DynamicItem): void;
}

interface DropdownState {
  show: boolean;
}

export class Dropdown extends React.Component<DropdownProps, DropdownState> {

  constructor(props: DropdownProps) {
    super(props);
    this.state = {
      show: false
    };
    this.changeShow = this.changeShow.bind(this);
  }

  changeShow(item?: DynamicItem) {
    const state = this.state;
    this.setState({
      show: !state.show
    });
    if( item !== undefined && item !== null ) {
      this.props.onSelected(item);
    }
    //this.props.onBlur?.(item);
  }
//<img src="/images/form/arrow.png" alt="" width="16px"/>
  render() {
    const selected = this.props.selected
    const item = selected ? this.props.items.find((item1 => (item1.id === selected|| item1.name === selected))) : undefined;
    const show = this.state.show ? "show": "";
    const invert = this.props.invert ? "invert" : ""; 
    return(
      <div className={"dropdown "+show}>
        <div className={"dropdown-class "+invert} id={this.props.id} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={()=>{this.changeShow(item);}}>
          <div className="flex-row">
            <div>{ item?.name || this.props.placeholder}</div>
            <div><FontAwesomeIcon icon={faChevronDown}/></div>
          </div>
        </div>
        <div className={"dropdown-menu item-scroll "+show} aria-labelledby={this.props.id}>
          {
            this.props.items.map((item2, index) => {
              return(
                <div key={index} onClick={()=>{this.changeShow(item2);}} className="dropdown-menu-item">
                  {item2.name}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}