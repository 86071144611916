import React from 'react';
import { Modal } from 'react-bootstrap';
//import {Link} from '@reach/router';

import {LeaderBoard} from '../../apis';

import './LeaderBoard.css';
import '../../screens/base_view/BaseView.css';

interface LeaderBoardProps {
  leaderBoard: LeaderBoard;
  isLab: boolean;
  showAll: boolean;
}

interface LeaderBoardState {
  showDialog: boolean;
}

export default class LeaderBoardView extends React.Component<LeaderBoardProps, LeaderBoardState> {

  constructor(props: LeaderBoardProps) {
    super(props);
    this.state = {
      showDialog: false
    };
  }

  render() {
    const leaderBoard = this.props.leaderBoard;
    const isLab = this.props.isLab;
    const showAll = this.props.showAll;
    const showViewAll = showAll ? false : ( (leaderBoard.data?.length || 0) > 6 );
    const style = (showViewAll) ? {} : {display: "none"};
    return (
      <div className="container-fluid leader-board">
        <div className="row">
          <div className="col-md-12 leader-title">
            <span style={{textTransform: "uppercase"}}>{leaderBoard.title}</span>
            <div className="view-right" style={style} onClick={()=>{this.setState({showDialog: true})}}>
              <span style={{textDecoration: 'underline'}}>view all</span>
            </div>
          </div>
        </div>
        <div className="row">
          {
            leaderBoard.data?.map((board, index) =>{
              if( showAll || index < 6 ) {
                const greenStyle = {backgroundColor: "var(--dark-green-color)"};
                const blueStyle = {backgroundColor: "var(--dark-blue-color)"};
                const boardStyle = (isLab)? blueStyle : greenStyle;
                const order = board.order || 1;
                const name = board.name || "";
                const count = board.count || 0;
                const item = (index % 2 === 0) ? "flex-row-odd" : "flex-row-even";
                return(
                  <div className={"col-md-6 "+item} key={index}>
                    <table style={{width: '100%', marginBottom: 3}}>
                      <tbody>
                        <tr>
                          <td style={{width: 60}}>
                            <div className="order-style" style={boardStyle}>
                              <span>{order}</span>
                            </div>
                          </td>
                          <td>
                            <div className="order-bg">
                              <div className="title-item">{name}</div>
                              <div>{count} collections</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                );
              }
            })
          }
        </div> 
        <Modal show={this.state.showDialog} dialogClassName="modal-70w" onHide={()=>{this.setState({showDialog: false})}} size="xl" >
          <div className="window" style={{overflow: "scroll"}}>
            <LeaderBoardView leaderBoard={leaderBoard} isLab={isLab} showAll={true} />
          </div>  
        </Modal>       
      </div>
    );
  }

}