import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';

import {MapType} from './Map';
import './Map.css';

interface MapLegendProps {
  type: MapType
} 

const data = {
  route: [
    {
      color: "#222426",
      name: "Unassigned Lab"
    },
    {
      color: "#aac6ff",
      name: "Routes"
    },
    {
      color: "#3a419a",
      name: "Selected Route"
    }
  ],
  homeTrip: [
    {
      color: "#1c9477",
      name: "Active"
    },
    {
      color: "#e1484e",
      name: "Inactive"
    }
  ],
  labTrip: [
    {
      color: "#1c9477",
      name: "Active"
    },
    {
      color: "#e1484e",
      name: "Inactive"
    }
  ]
}

export class MapLegend extends React.Component<MapLegendProps> {
   render() {
     const items = (this.props.type === MapType.Route) ? data.route : (this.props.type === MapType.LabTrip) ? data.labTrip : data.homeTrip
     return (
       <div className="row">
         {
           items.map((item, key) => {
              return (
                <div key={key} className="legend-holder"> 
                  <FontAwesomeIcon icon={faDotCircle} color={item.color} size="1x" />
                  <span className="map-legend">{item.name}</span>
                </div>);
           })
         }
       </div>
     )
   }
}