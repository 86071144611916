import React from 'react';

import './Badge.css';

interface BadgeProps {
  size: number;
  count: number;
  color: string;
  bgColor: string;
  badgeClass?: string;
}

export class Badge extends React.Component<BadgeProps> {

  render() {
    let style = {
      width: this.props.size,
      height: this.props.size,
      display: 'grid',
      verticalAlign: 'middle',
      borderRadius: (this.props.size/2),
      backgroundColor: this.props.bgColor,
      color: this.props.color,
      fontWeight: 700,
      fontSize: this.props.size/3
    };
    return(
      <div className={this.props.badgeClass} style={style}>
        <span style={{margin: 'auto'}}>{this.props.count}</span>
      </div>
    );
  }

}