import React from 'react';
import {RouteComponentProps} from '@reach/router';

import './MainCenter.css';
import { DefaultApi, TableModel, RModelFormCodeEnum, RModelMessageCodeEnum, RMainCenter, Form, RModelRTableRMainCenterCodeEnum } from '../../apis';
import {FormModel} from '../../components/form/Form';
import {SearchText} from '../../components/search/SearchText';
import Table from '../../components/table/Table';

interface MainCenterProps extends RouteComponentProps {

}

interface MainCenterState {
  form?: Form;
  showForm: boolean;
  selected: any;
  edit: boolean;
  search?: string;
  table: TableModel;
}

export class MainCenter extends React.Component<MainCenterProps, MainCenterState> {

  constructor(props: MainCenterProps) {
    super(props);
    this.state = { 
      showForm: false,
      selected: {},
      edit: false,
      table: {}
    };
  
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: true,
      selected: obj || {},
      edit: true,
      table: state.table
    });
  }

  show() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: true,
      selected: state.selected,
      edit: state.edit,
      table: state.table
    });
  }

  hide() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: false,
      selected: {},
      edit: false,
      table: state.table
    });
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = obj.centerId || "all";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updateMainCenter("","",token,centerId, obj as RMainCenter).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert("Main center updated successfully.","Manage Main Center", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createMainCenter("","",token,centerId, obj as RMainCenter).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert("Main center created successfully.","Manage Main Center", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const defaultApi = new DefaultApi()
    defaultApi.getFormType("","",token,centerId,"MainCenter").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        self.setState({ 
          form: data.success,
        });
      }
    });
    defaultApi.getTableMainCenters("","",token, centerId).then((item) =>{
      const data = item.data;
      if( data.code === RModelRTableRMainCenterCodeEnum.Success ) {
        self.setState({ 
          table: data.success as TableModel
        });
      }
    });
  }

  onChange(text: string) {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: text,
      table: state.table
    });
  }

  onClear() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: undefined,
      table: state.table
    });
  }

  render() {
    const form = this.state.form || {};
    return(
      <div>
        <div style={{minHeight: "20px"}}></div>
        <div className="action-holder flex-row">
          <div><SearchText onChange={this.onChange} onClear={this.onClear}/></div>
          <div><button type="button" className="btn" onClick={this.show}>Add Main Center</button></div>
        </div>
        <Table table={this.state.table} openForm={this.openForm} search={this.state.search} />
        <FormModel form={form} edit={this.state.edit} 
            obj={this.state.selected} update={this.update} hide={this.hide} 
            show={this.state.showForm}/> 
      </div>
    );
  }
}