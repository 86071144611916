import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { 
  RLabCollection, DefaultApi, RModelListRLabCollectionCodeEnum, RModelListRLabCollection, FileItem
} from '../../apis';
import { AxiosResponse } from 'axios';
import {ThumbDocumentView} from '../../components/labcollection/LabCollectionView';
import './DocumentView.css';

export interface DocumentViewProps extends RouteComponentProps {
  
}

export interface DocumentViewState {
  labCollections: Array<RLabCollection>;
}

export class DocumentView extends React.Component<DocumentViewProps, DocumentViewState> {
  
  constructor(props: DocumentViewProps) {
    super(props)
    this.state = {
      labCollections: []
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getAllLabCollections("","",token,centerId).then((itemR: AxiosResponse<RModelListRLabCollection>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRLabCollectionCodeEnum.Success ) {
        self.setState({
          labCollections: dataR.success || []  
        });
      }
    });
  }

  render() {
    return(
      <div className="row">
        <div className="flex">
          {
            this.state.labCollections.map((item, i) => {
              const files = item.files || [];
              return(<FileList files={files} key={i} />);
            })            
          }
        </div>
      </div>
    )
  }
}

interface FileListProps extends RouteComponentProps {
  files: Array<FileItem>;
}

class FileList extends React.Component<FileListProps,FileListProps> {
  render() {
    const files = this.props.files
    return(
      <div> 
        {
          files?.map((file, index) => {
            return(
              <div key={index}>
                <ThumbDocumentView file={file} />
              </div>
            )
          })
        }        
      </div>
    )
  }
}