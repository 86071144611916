import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, navigate } from '@reach/router';

import {
  DefaultApi,
  RModelListMenuCodeEnum,
} from '../../apis';
import { BASE_PATH } from '../../apis/base';
import { actions, Menu, Action } from './MenuData';
import './Menu.css';


export interface MenuItemProps {
  menu: Menu
}

export interface MenuItemState {
  menu: Menu
}

export interface ActionBarProps {
  actions: Array<Action>;
}

export interface ActionBarState {
  actions: Array<Action>;
}

export interface MenuBarProps {
  menus: Array<Menu>;
}

export interface MenuBarState {
  menus: Array<Menu>;
}

export interface SidePanelProps {

}

export interface SidePanelState {
  logo: string;
  menus: Array<Menu>;
  actions: Array<Action>;
}

export class ActionBar extends React.Component<ActionBarProps/*, ActionBarState*/> {
  constructor(props: ActionBarProps) {
    super(props);
    //this.state = { actions: props.actions};
    this.perform = this.perform.bind(this);
  }

  perform(action: string) {
    if (action === "logout") {
      localStorage.removeItem("token");
      const org = localStorage.getItem("org");
      localStorage.removeItem("org");
      localStorage.removeItem('name');
      navigate("/" + org);
      window.location.reload();
    } else if (action === "kriyamed") {
      window.open("https://kriyamed.com/");
    }
  }

  render() {
    const actionItems = this.props.actions;
    return (
      <div className="bottom-panel">
        <ul className="nav">
          {
            actionItems.map((action, index) => {
              const icon = (action.action.length === 0) ? "admin-profile" : "";
              const imageIcon = (action.action.length === 0) ? "admin-icon" : "logout-icon";
              const name = (action.name === "adminAnnaNagar") ? localStorage.getItem("name") : action.name;
              return (
                <li className={"bottom-nav " + icon} key={index}>
                  <div onClick={(e) => { this.perform(action.action) }} >
                    <img className={imageIcon + " nav-icon"} src={action.image} alt="" />
                    <span className="nav-name">{name}</span>
                  </div>
                </li>
              );
            }
            )
          }
          <div className="kriya-logo" onClick={(e) => { this.perform("kriyamed") }}>
            <span className="kriya-logo-text">powered by <em className='kriya-text'>Kriya</em></span>

          </div>
        </ul>
      </div>
    );
  }
}

/*
<img className="krizac-logo-icon" src="/images/action/kriya.png" alt="" />

*/

export class MenuBar extends React.Component<MenuBarProps/*, MenuBarState*/> {
  /*constructor(props: MenuBarProps) {
    super(props);
    this.state = { menus: props.menus };
  }*/

  render() {
    const menus = this.props.menus;
    return (
      <ul className="tabs nav">
        {
          menus.map((menu, index) => {
            return (<MenuItemView menu={menu} key={index} />);
          })
        }
      </ul>
    );
  }
}

export class MenuItemView extends React.Component<MenuItemProps/*, MenuItemState*/> {

  /*constructor(props: MenuItemProps) {
    super(props);
    this.state = { menu: props.menu };
  }*/

  render() {
    const menu = this.props.menu;
    const menuClass = menu.active ? "active main-li" : "main-li";
    const icon = menu.active ? menu.activeIcon : menu.icon
    if (menu.childs.length === 0 || !menu.active) {
      return (
        <li className={menuClass}>
          <Link to={menu.action}>
            <img className="nav-icon" src={icon} alt="" />
            <span className="nav-name">{menu.name}</span>
          </Link>
        </li>
      );
    } else {
      return (
        <li className={menuClass}>
          <span className="active-li">
            <img className="nav-icon" src={icon} alt="" />
            <span className="nav-name">{menu.name}</span>
          </span>
          <ul className="nav-sub-name list-unstyled">
            {
              menu.childs.map((menuItem, index) => {
                const activeSubName = menuItem.active ? "active-sub-name " : "";
                const color = menu.active ? "sub-menu-color" : "";
                return (
                  <li key={index}>
                    <Link to={menuItem.action} className={activeSubName + color} >
                      {menuItem.name}
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </li>
      );
    }
  }
}

export default class SidePanel extends React.Component<SidePanelProps, SidePanelState> {
  constructor(props: SidePanelProps) {
    super(props);
    const logo = localStorage.getItem("logo") || "/images/client_logo.png";
    this.state = { logo: logo, menus: [], actions: actions };
    this.getActiveMenu = this.getActiveMenu.bind(this);
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getMenus("", "", token, centerId).then((item) => {
      if (item.data.code === RModelListMenuCodeEnum.Success) {
        let sMenus = (item.data.success || []) as Array<Menu>
        self.setState({ menus: sMenus });
      }
    })
  }

  getActiveMenu(menus: Array<Menu>) {
    const pathname = window.location.pathname;
    const path = (pathname === undefined || pathname === null) ? "" : pathname;
    /*let i = 0;
    for( i = 0; i < menus.length; i++ ) {
      const menu = menus[i];
      menu.active = false;
      if( path.includes(menu.path || "") ) {
        menu.active = true;
        let j = 0;
        let childs = menu.childs || [];
        for( j = 0; j < childs.length; i++ ) {
          let menuItem = childs[j] || {};
          menuItem.active = ( path === menuItem.action );
        }
      }
    }*/
    menus.forEach((menu, _) => {
      menu.active = false;
      if (path.includes(menu.path || "/")) {
        menu.active = true;
        menu.childs.forEach((menuItem) => {
          menuItem.active = (path === menuItem.action);
          //return menuItem;
        });
      }
      //return menu;
    });
    if (path === "" || path === "/") {
      menus[0].active = true;
    }
    return menus;
  }

  render() {
    const logo = this.state.logo;
    const org = localStorage.getItem("org") || "org";
    const logoURL = (logo === "" || logo === "/images/client_logo.png" || logo === "/images/profile.png") ? "/images/client_logo.png" : BASE_PATH + logo + "&download="
    const menus = this.getActiveMenu(this.state.menus);
    const actionItems = this.state.actions;
    return (
      <div className="menu-wrapper">
        <Navbar className="sidebar-wrapper">
          <div className="logo-client">
            <img src={logoURL} alt={org} />
          </div>
          <MenuBar menus={menus} />
          <ActionBar actions={actionItems} />
        </Navbar>
      </div>
    );
  }
}
