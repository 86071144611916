import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { AxiosResponse } from 'axios';

import './ManageAdmins.css';
import { DefaultApi, TableModel, RAccess, RModule,
  RModelRTableRModule, RModelRTableRModuleCodeEnum,
   RModelMessageCodeEnum, RModelRTableRAccess, RModelRTableRAccessCodeEnum, DynamicItem } from '../../../apis';
import Table from '../../../components/table/Table';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import { Modal } from 'react-bootstrap';

interface ManageAdminsProps extends RouteComponentProps {

}

interface ManageAdminsState {
  filter: string;
  filters: Array<DynamicItem>;
  adminTable: TableModel;
  moduleTable: TableModel;
  adminTableChange: TableModel;
  moduleTableChange: TableModel;
  showConfirmation: boolean;
  selected?: any;
  type: string;
}

export class ManageAdmins extends React.Component<ManageAdminsProps,ManageAdminsState> {

  constructor(props: ManageAdminsProps) {
    super(props);
    this.state = {
      filter: "all",
      filters: [],
      adminTable: {},
      moduleTable: {},
      adminTableChange: {},
      moduleTableChange: {},
      showConfirmation: false,
      type: ""
    };
    this.update = this.update.bind(this);
    this.openForm = this.openForm.bind(this);
    this.hide = this.hide.bind(this);
    this.selectList = this.selectList.bind(this);
    this.showForFilter = this.showForFilter.bind(this); 
  }

  openForm(form: string, id: string, obj?: any) {
    this.setState({showConfirmation: true, selected: obj, type: form});
  }

  hide() {
    this.setState({showConfirmation: false, selected: {}, type: ""});
    window.location.reload();
  }

  update(obj: any, type: string) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = obj.centerId;
    if( obj.access === "Enabled" ) {
      obj.access = "Disabled";
    }else {
      obj.access = "Enabled";
    }
    const defaultApi = new DefaultApi()
    if( type === "Access" ) {
      defaultApi.updateAccess("","",token,centerId, obj as RAccess).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Admins", false, (confirmed) => {
            self.hide();  
          });
        }
      });
    }else {
      defaultApi.updateModule("","",token,centerId, obj as RModule).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Admins", false, (confirmed) => {
            self.hide();  
          });
        }
      });
    }
  }

  showForFilter(filter?: string) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = filter || this.state.filter;
    const defaultApi = new DefaultApi();
    defaultApi.getAdminAccess("","",token,centerId, centerId).then((itemR: AxiosResponse<RModelRTableRAccess>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRAccessCodeEnum.Success ) {
        let table = dataR.success as TableModel
        //table.headerColor = "#C4D9E2";        
        self.setState({
          adminTable: table
        });
      }
    });
    defaultApi.getModule("","",token,centerId, centerId).then((itemR: AxiosResponse<RModelRTableRModule>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRModuleCodeEnum.Success ) {
        let table = dataR.success as TableModel
        //table.headerColor = "#C4D9E2"; 
        self.setState({
          moduleTable: table
        });
      }
    });
    defaultApi.getAdminAccess("","",token,centerId, "change").then((itemR: AxiosResponse<RModelRTableRAccess>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRAccessCodeEnum.Success ) {
        const table = dataR.success as TableModel
        if( table.title === undefined || table.title === null || table.title === "" ) {
          table.title = "Center based custom changes";
        }
        //table.headerColor = "#C4D9E2"; 
        self.setState({
          adminTableChange: table
        });
      }
    });
    defaultApi.getModule("","",token,centerId, "change").then((itemR: AxiosResponse<RModelRTableRModule>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRModuleCodeEnum.Success ) {
        const table = dataR.success as TableModel
        if( table.title === undefined || table.title === null || table.title === "" ) {
          table.title = "Center based custom changes";
        }
        //table.headerColor = "#C4D9E2"; 
        self.setState({
          moduleTableChange: table
        });
      }
    });
  }

  componentDidMount() {
    this.showForFilter();
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = this.state.filter;
    const defaultApi = new DefaultApi();
    defaultApi.getDynamicList("","",token,centerId,"all",{
      name: "MainCenter",
      properties: ["id", "name"]
    }).then((item) => {
      let filters = item.data;
      filters.unshift({id: "all", name: "All"});
      self.setState({filters: filters});
    })
  }

  selectList(list: DynamicItem) {
    this.setState({filter: list.id || "all"});
    this.showForFilter(list.id || "all");
  }

  render() {
    const filter = this.state.filter
    const adminChangeTable = (filter === "all") ? 
      ( <Table table={this.state.adminTableChange} openForm={(name, id, obj?: any) => { this.openForm("Access",id, obj)}} /> ) : 
      ( <div></div> );
    const moduleChangeTable = (filter === "all") ? 
      ( <Table table={this.state.moduleTableChange} openForm={(name, id, obj?: any) => { this.openForm("Module",id, obj)}} /> ) : 
      ( <div></div> );
    return(
      <div>
        <div className="action-holder flex-row">
          <div className="col-md-3"><Dropdown id="dashboard-lab" selected={filter} 
              placeholder="All" 
              items={this.state.filters}
              invert={true}
              onSelected={(item) => {this.selectList(item)}}/></div>
        </div>
        <div style={{minHeight: 20}}></div>
        {moduleChangeTable}
        <div style={{minHeight: 20}}></div>
        <Table table={this.state.moduleTable} openForm={(name, id, obj?: any) => { this.openForm("Module",id, obj)}}/> 
        <div style={{minHeight: 60}}></div>
        {adminChangeTable}
        <div style={{minHeight: 20}}></div>
        <Table table={this.state.adminTable} openForm={(name, id, obj?: any) => { this.openForm("Access",id, obj)}} /> 
        <Modal show={this.state.showConfirmation} hide={this.hide} >
          <Modal.Body>
            <div className="mx-auto">
              <div style={{minHeight: 20}}></div>
              <div className="text-center">Are you sure you want to perform this action?</div>
              <div style={{minHeight: 20}}></div>
              <div className="flex-action">
                <div><button className="btn btn-close" onClick={()=>{this.hide()}}>Cancel</button></div>
                <div><button className="btn btn-confirm" onClick={()=>{this.update(this.state.selected, this.state.type)}}>Confirm</button></div>
              </div>
              <div style={{minHeight: 20}}></div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );  
  }
  
}