import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { 
  RVaccineInjectData, RCovidOnsiteTrip, RCovidOffsiteTrip,
  RVaccine, DefaultApi, RModelMessageCodeEnum, 
  RCovidPatient, RModelListRCovidPatient, RModelListRCovidPatientCodeEnum,
  RModelListRVaccine, RModelListRVaccineCodeEnum, RModelListRVaccineInjectData,
  RModelListRVaccineInjectDataCodeEnum,
  FileItem
} from '../../apis';
import { AxiosResponse } from 'axios';
import { Modal } from 'react-bootstrap';
import { BASE_PATH } from '../../apis/base';
import './CollectionView.css';

interface CollectionViewProps extends RouteComponentProps {
  trip: RCovidOnsiteTrip|RCovidOffsiteTrip;
  type: string;
  show: boolean;
  hide(): void;
}

interface CollectionViewState {
  patients: Array<RCovidPatient>;
  samples: Array<RVaccine>;
  testCollection: Array<RVaccineInjectData>;
}

export class CovidCollectionView extends React.Component<CollectionViewProps, CollectionViewState> {
  
  constructor(props: CollectionViewProps) {
    super(props)
    this.state = {
      testCollection: [],
      samples: [],
      patients: []
    }
    this.update = this.update.bind(this);
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    obj.tripId = this.props.trip.id || "";
    obj.tripType = this.props.type || "OP";

    //const tripStyle = (this.props.trip.name?.startsWith("CT") ? "CovidOnsite" : "CovidOffsite" ) || "CovidOnsite";

    const defaultApi = new DefaultApi()
    if( obj.id !== undefined ) {
      defaultApi.updateVaccineInjectData("","",token,centerId, obj as RVaccineInjectData).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          //window.alert("Created.");
          self.props.hide(); 
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createVaccineInjectData("","",token,centerId, obj as RVaccineInjectData).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          //window.alert("Created."); 
          self.props.hide();
        }else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getAllCovidPatients("","",token,centerId, this.props.type).then((itemR: AxiosResponse<RModelListRCovidPatient>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRCovidPatientCodeEnum.Success ) {
        self.setState({
          patients: dataR.success || [] 
        });
      }
    });
    defaultApi.getAllVaccines("","",token,centerId).then((itemR: AxiosResponse<RModelListRVaccine>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRVaccineCodeEnum.Success ) {
        self.setState({
          samples: dataR.success || [] 
        });
      }
    });
    defaultApi.getTripVaccineInjectDatas("","",token,centerId, this.props.type, this.props.trip.id || "").then((itemR: AxiosResponse<RModelListRVaccineInjectData>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRVaccineInjectDataCodeEnum.Success ) {
        self.setState({
          testCollection: dataR.success || [] 
        });
      }
    });
  }

  render() {
    const trip = this.props.trip
    const home = this.props.trip.name?.startsWith("HT") || false;
    return(
      <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl" >
        <div className="window" style={{overflow: "scroll"}}>
          {
            trip.patientIds?.map((patientId, index) => {
              const test = this.state.testCollection.find( item => (item.patient === patientId));
              const patient = this.state.patients.find( item => (item.id === patientId) ) || {}; 
              const testProps: VaccineInjectDataProps = {
                home: home,
                testCollection: test,
                samples: this.state.samples,
                patient: patient,
                update: this.update
              }
              return(<VaccineInjectDataView {...testProps} key={index}/>);
            })
          }
        </div>
      </Modal>
    )
  }

}

interface VaccineInjectDataProps {
  home: boolean;
  testCollection?: RVaccineInjectData;
  samples: Array<RVaccine>;
  patient: RCovidPatient;
  update(obj: any, callback: Function): void;
}

interface VaccineInjectDataState {
  show: boolean;
}

class VaccineInjectDataView extends React.Component<VaccineInjectDataProps, VaccineInjectDataState> {

  constructor(props: VaccineInjectDataProps) {
    super(props);
    this.state = {
      show: false
    };
    this.hide = this.hide.bind(this);
    this.selected = this.selected.bind(this);
  }

  selected(id: string, callback: Function) {
    let obj = this.props.testCollection || {};
    obj.patient = this.props.patient.id;
    obj.number = "";
    obj.vaccineId = id;
    this.props.update(obj, callback);
    this.hide();
  }

  hide() {
    this.setState({show: false});
  }

  render() {
    const vaccineInjectData = this.props.testCollection;
    const item  = ( vaccineInjectData?.status || false ) ? (<img src="/images/maps/lab_checked.png" alt="" width="20px" height="20px"/>) : (<button type="button" className="btn" onClick={()=>{this.setState({show: true})}}>Change Vaccine</button>)
    const vaccineId = vaccineInjectData?.vaccineId || "";
    const sample = this.props.samples.find(it => (it.id === vaccineId));
    const bar = vaccineInjectData?.batchNumber || vaccineInjectData?.barcode || "";
    const view = (sample !== undefined) ? <VaccineView sample={sample} bar={bar}/> : "";
    const date = (vaccineInjectData?.status || false) ? new Date(vaccineInjectData?.updatedAt ||0).toLocaleString("en-US") : "";
    
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div>Patient ID #{this.props.patient.id?.substr(this.props.patient.id?.length-6).toUpperCase() || ""}
          <span style={{paddingLeft: 10}}>{item}</span>
        </div>
        <div style={{padding: 10}}>
          <div className="row"><strong>Vaccine</strong>&nbsp;&nbsp;({date})</div>
          <div className="row">
            <div className="col-md-4" >
              {view}
            </div>
          </div>
          <div className="row">
            {
              vaccineInjectData?.files?.map((file, index) => {
                return(
                  <div key={index}>
                    <ThumbDocumentView file={file} />
                  </div>
                )
              })
            }
          </div>
        </div>
        <AddCollectionView home={this.props.home} samples={this.props.samples} show={this.state.show} id={vaccineId}
          selected={this.selected} hide={this.hide} />
      </div>
    )
  }

}

export interface DocumentProps {
  file: FileItem;
}

export class ThumbDocumentView extends React.Component<DocumentProps> {
  render() {
    const file = this.props.file
    const name = file.name || "file.jpeg"
    const url = file.url || ""
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div style={{padding: 10}}>
          <div className="row">
            <div>
              <img src={BASE_PATH+url+"&download=false"} alt={name} width="200" height="auto" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}


interface VaccineProps {
  sample: RVaccine;
  bar: string;
}

class VaccineView extends React.Component<VaccineProps> {
  
  render() {
    const color = "urine";
    const image = "/images/tubes/"+color+".png";
    const name = this.props.sample.name +" ("+this.props.sample.created+")";
    const bars = this.props.bar;
    if( bars === "" ) {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
            <div>
              <div className={"bar-code blue"}>{bars || ""}</div>
            </div> 
          </div>
        </div>
      )
    }
  }
}

interface VaccineSelectProps {
  sample: RVaccine;
  selected: boolean;
}

class VaccineSelectView extends React.Component<VaccineSelectProps> {

  render() {
    const image = "/images/tubes/urine.png"
    const checked = "/images/maps/"+((this.props.selected)? "lab_checked" : "lab_selected")+".png"
    const name = this.props.sample.name
    return(
      <div style={{padding: 10}}>
        <div className="test-tube">
          <div className="flex-row">
            <div><img src={image} alt="" style={{width:25, height:25}}/><span style={{paddingLeft: 10}}>{name}</span></div>
            <div><img src={checked} alt="" style={{width:25, height:25}}/></div>
          </div>             
        </div>
      </div>
    )
  }

}

interface AddCollectionViewProps extends RouteComponentProps {
  home: boolean;
  samples: Array<RVaccine>;
  show: boolean;
  id: string;
  selected(id: string, callback: Function): void;
  hide(): void;
}

interface AddCollectionViewState {
  confirm: boolean;
}

export class AddCollectionView extends React.Component<AddCollectionViewProps, AddCollectionViewState> {

  constructor(props: AddCollectionViewProps) {
    super(props);
    this.state = {
      confirm: true
    }
    this.update = this.update.bind(this);
    this.updateServer = this.updateServer.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.disableConfirm = this.disableConfirm.bind(this);
  }

  updateServer(id: string) {
    this.disableConfirm();
    this.props.selected(id, this.showConfirm);
  }

  showConfirm() {
    this.setState({confirm: true});
  }

  disableConfirm() {
    this.setState({confirm: false});
  }

  update(id: string) {
    this.props.selected(id, this.showConfirm);  
    this.setState({});
  }

  render() {
    return(
      <Modal show={this.props.show} dialogClassName="modal-70w overflow-auto" onHide={()=>{this.props.hide()}} size="xl">
        <div className="window overflow-auto">
          <div className="row">
            {
              this.props.samples.map((sample, index) => {
                return(
                  <div className="col-md-4" key={index} onClick={()=>{this.update(sample.id || "")}}>
                    <VaccineSelectView sample={sample} selected={(this.props.id === sample.id)} />
                  </div>
                );
              })
            }
          </div>
          <div className="flex-action">
            <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Close</button></div>
            <div><button className="btn btn-confirm" onClick={()=>{this.updateServer(this.props.id)}} disabled={!this.state.confirm}>Confirm</button></div>
          </div>
        </div>
      </Modal>
    )
  }

}