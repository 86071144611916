// tslint:disable
/**
 * 
 * 
 *
 * 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


 import * as globalImportUrl from 'url';
 import { Configuration } from './configuration';
 import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
 // Some imports not used depending on template conditions
 // @ts-ignore
 import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';
 
 /**
  * 
  * @export
  * @interface Address
  */
 export interface Address {
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     home?: string;
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     street?: string;
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     area?: string;
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     city?: string;
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     state?: string;
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     landmark?: string;
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     pincode?: string;
     /**
      * 
      * @type {Location}
      * @memberof Address
      */
     location?: Location;
     /**
      * 
      * @type {string}
      * @memberof Address
      */
     full?: string;
 }
 /**
  * 
  * @export
  * @interface BoardData
  */
 export interface BoardData {
     /**
      * 
      * @type {number}
      * @memberof BoardData
      */
     order?: number;
     /**
      * 
      * @type {string}
      * @memberof BoardData
      */
     name?: string;
     /**
      * 
      * @type {number}
      * @memberof BoardData
      */
     count?: number;
 }
 /**
  * 
  * @export
  * @interface BodyPart
  */
 export interface BodyPart {
     /**
      * 
      * @type {ContentDisposition}
      * @memberof BodyPart
      */
     contentDisposition?: ContentDisposition;
     /**
      * 
      * @type {object}
      * @memberof BodyPart
      */
     entity?: object;
     /**
      * 
      * @type {{ [key: string]: Array<string>; }}
      * @memberof BodyPart
      */
     headers?: { [key: string]: Array<string>; };
     /**
      * 
      * @type {BodyPartMediaType}
      * @memberof BodyPart
      */
     mediaType?: BodyPartMediaType;
     /**
      * 
      * @type {object}
      * @memberof BodyPart
      */
     messageBodyWorkers?: object;
     /**
      * 
      * @type {MultiPart}
      * @memberof BodyPart
      */
     parent?: MultiPart;
     /**
      * 
      * @type {object}
      * @memberof BodyPart
      */
     providers?: object;
     /**
      * 
      * @type {{ [key: string]: Array<ParameterizedHeader>; }}
      * @memberof BodyPart
      */
     parameterizedHeaders?: { [key: string]: Array<ParameterizedHeader>; };
 }
 /**
  * 
  * @export
  * @interface BodyPartMediaType
  */
 export interface BodyPartMediaType {
     /**
      * 
      * @type {string}
      * @memberof BodyPartMediaType
      */
     type?: string;
     /**
      * 
      * @type {string}
      * @memberof BodyPartMediaType
      */
     subtype?: string;
     /**
      * 
      * @type {{ [key: string]: string; }}
      * @memberof BodyPartMediaType
      */
     parameters?: { [key: string]: string; };
     /**
      * 
      * @type {boolean}
      * @memberof BodyPartMediaType
      */
     wildcardType?: boolean;
     /**
      * 
      * @type {boolean}
      * @memberof BodyPartMediaType
      */
     wildcardSubtype?: boolean;
 }
 /**
  * 
  * @export
  * @interface Button
  */
 export interface Button {
     /**
      * 
      * @type {string}
      * @memberof Button
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof Button
      */
     action?: string;
 }
 /**
  * 
  * @export
  * @interface CallContent
  */
 export interface CallContent {
     /**
      * 
      * @type {{ [key: string]: string; }}
      * @memberof CallContent
      */
     headers?: { [key: string]: string; };
     /**
      * 
      * @type {string}
      * @memberof CallContent
      */
     body?: string;
 }
 /**
  * 
  * @export
  * @interface CallHistory
  */
 export interface CallHistory {
     /**
      * 
      * @type {string}
      * @memberof CallHistory
      */
     url?: string;
     /**
      * 
      * @type {string}
      * @memberof CallHistory
      */
     method?: CallHistoryMethodEnum;
     /**
      * 
      * @type {CallContent}
      * @memberof CallHistory
      */
     request?: CallContent;
     /**
      * 
      * @type {CallContent}
      * @memberof CallHistory
      */
     response?: CallContent;
     /**
      * 
      * @type {ObjectId}
      * @memberof CallHistory
      */
     centerId?: ObjectId;
     /**
      * 
      * @type {number}
      * @memberof CallHistory
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof CallHistory
      */
     createdAt?: number;
     /**
      * 
      * @type {ObjectId}
      * @memberof CallHistory
      */
     id?: ObjectId;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum CallHistoryMethodEnum {
     GET = 'GET',
     POST = 'POST',
     PUT = 'PUT',
     PATCH = 'PATCH',
     DELETE = 'DELETE'
 }
 
 /**
  * 
  * @export
  * @interface ContentDisposition
  */
 export interface ContentDisposition {
     /**
      * 
      * @type {string}
      * @memberof ContentDisposition
      */
     type?: string;
     /**
      * 
      * @type {{ [key: string]: string; }}
      * @memberof ContentDisposition
      */
     parameters?: { [key: string]: string; };
     /**
      * 
      * @type {string}
      * @memberof ContentDisposition
      */
     fileName?: string;
     /**
      * 
      * @type {string}
      * @memberof ContentDisposition
      */
     creationDate?: string;
     /**
      * 
      * @type {string}
      * @memberof ContentDisposition
      */
     modificationDate?: string;
     /**
      * 
      * @type {string}
      * @memberof ContentDisposition
      */
     readDate?: string;
     /**
      * 
      * @type {number}
      * @memberof ContentDisposition
      */
     size?: number;
 }
 /**
  * 
  * @export
  * @interface CriticalBag
  */
 export interface CriticalBag {
     /**
      * 
      * @type {Array<CriticalBagItem>}
      * @memberof CriticalBag
      */
     data?: Array<CriticalBagItem>;
 }
 /**
  * 
  * @export
  * @interface CriticalBagItem
  */
 export interface CriticalBagItem {
     /**
      * 
      * @type {string}
      * @memberof CriticalBagItem
      */
     bagId?: string;
     /**
      * 
      * @type {number}
      * @memberof CriticalBagItem
      */
     noOfCriticalSampleC1?: number;
     /**
      * 
      * @type {number}
      * @memberof CriticalBagItem
      */
     noOfCriticalSampleC2?: number;
     /**
      * 
      * @type {number}
      * @memberof CriticalBagItem
      */
     noOfCriticalSampleC3?: number;
 }
 /**
  * 
  * @export
  * @interface Dashboard
  */
 export interface Dashboard {
     /**
      * 
      * @type {string}
      * @memberof Dashboard
      */
     type?: DashboardTypeEnum;
     /**
      * 
      * @type {number}
      * @memberof Dashboard
      */
     total?: number;
     /**
      * 
      * @type {string}
      * @memberof Dashboard
      */
     data?: string;
     /**
      * 
      * @type {CriticalBag}
      * @memberof Dashboard
      */
     criticalBag?: CriticalBag;
     /**
      * 
      * @type {LeaderBoard}
      * @memberof Dashboard
      */
     carrier?: LeaderBoard;
     /**
      * 
      * @type {LeaderBoard}
      * @memberof Dashboard
      */
     lab?: LeaderBoard;
     /**
      * 
      * @type {PouchBag}
      * @memberof Dashboard
      */
     pouch?: PouchBag;
     /**
      * 
      * @type {number}
      * @memberof Dashboard
      */
     numberOfReSample?: number;
     /**
      * 
      * @type {number}
      * @memberof Dashboard
      */
     temperature?: number;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum DashboardTypeEnum {
     Day = 'Day',
     Week = 'Week',
     Month = 'Month',
     Year = 'Year'
 }
 
 /**
  * 
  * @export
  * @interface DynamicItem
  */
 export interface DynamicItem {
     /**
      * 
      * @type {string}
      * @memberof DynamicItem
      */
     id?: string;
     /**
      * 
      * @type {string}
      * @memberof DynamicItem
      */
     name?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof DynamicItem
      */
     ids?: Array<string>;
 }
 /**
  * 
  * @export
  * @interface DynamicList
  */
 export interface DynamicList {
     /**
      * 
      * @type {string}
      * @memberof DynamicList
      */
     name?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof DynamicList
      */
     properties?: Array<string>;
 }
 /**
  * 
  * @export
  * @interface Field
  */
 export interface Field {
     /**
      * 
      * @type {string}
      * @memberof Field
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof Field
      */
     placeholder?: string;
     /**
      * 
      * @type {string}
      * @memberof Field
      */
     type?: FieldTypeEnum;
     /**
      * 
      * @type {boolean}
      * @memberof Field
      */
     editable?: boolean;
     /**
      * 
      * @type {string}
      * @memberof Field
      */
     linkTo?: string;
     /**
      * 
      * @type {boolean}
      * @memberof Field
      */
     required?: boolean;
     /**
      * 
      * @type {boolean}
      * @memberof Field
      */
     validate?: boolean;
     /**
      * 
      * @type {string}
      * @memberof Field
      */
     regex?: string;
     /**
      * 
      * @type {string}
      * @memberof Field
      */
     errorMessage?: string;
     /**
      * 
      * @type {string}
      * @memberof Field
      */
     defaultValue?: string;
     /**
      * 
      * @type {DynamicList}
      * @memberof Field
      */
     dynamicList?: DynamicList;
     /**
      * 
      * @type {Array<Item>}
      * @memberof Field
      */
     list?: Array<Item>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum FieldTypeEnum {
     Text = 'Text',
     Password = 'Password',
     Profile = 'Profile',
     Hidden = 'Hidden',
     MultipleList = 'MultipleList',
     Attachment = 'Attachment',
     StaticList = 'StaticList',
     DynamicList = 'DynamicList'
 }
 
 /**
  * 
  * @export
  * @interface FileItem
  */
 export interface FileItem {
     /**
      * 
      * @type {string}
      * @memberof FileItem
      */
     name?: string;
     /**
      * 
      * @type {number}
      * @memberof FileItem
      */
     fileSize?: number;
     /**
      * 
      * @type {string}
      * @memberof FileItem
      */
     url?: string;
 }
 /**
  * 
  * @export
  * @interface Filter
  */
 export interface Filter {
     /**
      * 
      * @type {string}
      * @memberof Filter
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof Filter
      */
     type?: FilterTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof Filter
      */
     property?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof Filter
      */
     list?: Array<string>;
     /**
      * 
      * @type {DynamicList}
      * @memberof Filter
      */
     dynamicList?: DynamicList;
     /**
      * 
      * @type {Button}
      * @memberof Filter
      */
     action?: Button;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum FilterTypeEnum {
     Search = 'Search',
     StaticList = 'StaticList',
     DynamicList = 'DynamicList',
     Text = 'Text',
     Button = 'Button'
 }
 
 /**
  * 
  * @export
  * @interface Form
  */
 export interface Form {
     /**
      * 
      * @type {string}
      * @memberof Form
      */
     name?: string;
     /**
      * 
      * @type {Array<Group>}
      * @memberof Form
      */
     groups?: Array<Group>;
 }
 /**
  * 
  * @export
  * @interface FormDataBodyPart
  */
 export interface FormDataBodyPart {
     /**
      * 
      * @type {ContentDisposition}
      * @memberof FormDataBodyPart
      */
     contentDisposition?: ContentDisposition;
     /**
      * 
      * @type {object}
      * @memberof FormDataBodyPart
      */
     entity?: object;
     /**
      * 
      * @type {{ [key: string]: Array<string>; }}
      * @memberof FormDataBodyPart
      */
     headers?: { [key: string]: Array<string>; };
     /**
      * 
      * @type {BodyPartMediaType}
      * @memberof FormDataBodyPart
      */
     mediaType?: BodyPartMediaType;
     /**
      * 
      * @type {object}
      * @memberof FormDataBodyPart
      */
     messageBodyWorkers?: object;
     /**
      * 
      * @type {MultiPart}
      * @memberof FormDataBodyPart
      */
     parent?: MultiPart;
     /**
      * 
      * @type {object}
      * @memberof FormDataBodyPart
      */
     providers?: object;
     /**
      * 
      * @type {string}
      * @memberof FormDataBodyPart
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof FormDataBodyPart
      */
     value?: string;
     /**
      * 
      * @type {boolean}
      * @memberof FormDataBodyPart
      */
     simple?: boolean;
     /**
      * 
      * @type {FormDataContentDisposition}
      * @memberof FormDataBodyPart
      */
     formDataContentDisposition?: FormDataContentDisposition;
     /**
      * 
      * @type {{ [key: string]: Array<ParameterizedHeader>; }}
      * @memberof FormDataBodyPart
      */
     parameterizedHeaders?: { [key: string]: Array<ParameterizedHeader>; };
 }
 /**
  * 
  * @export
  * @interface FormDataContentDisposition
  */
 export interface FormDataContentDisposition {
     /**
      * 
      * @type {string}
      * @memberof FormDataContentDisposition
      */
     type?: string;
     /**
      * 
      * @type {{ [key: string]: string; }}
      * @memberof FormDataContentDisposition
      */
     parameters?: { [key: string]: string; };
     /**
      * 
      * @type {string}
      * @memberof FormDataContentDisposition
      */
     fileName?: string;
     /**
      * 
      * @type {string}
      * @memberof FormDataContentDisposition
      */
     creationDate?: string;
     /**
      * 
      * @type {string}
      * @memberof FormDataContentDisposition
      */
     modificationDate?: string;
     /**
      * 
      * @type {string}
      * @memberof FormDataContentDisposition
      */
     readDate?: string;
     /**
      * 
      * @type {number}
      * @memberof FormDataContentDisposition
      */
     size?: number;
     /**
      * 
      * @type {string}
      * @memberof FormDataContentDisposition
      */
     name?: string;
 }
 /**
  * 
  * @export
  * @interface FormDataMultiPart
  */
 export interface FormDataMultiPart {
     /**
      * 
      * @type {ContentDisposition}
      * @memberof FormDataMultiPart
      */
     contentDisposition?: ContentDisposition;
     /**
      * 
      * @type {object}
      * @memberof FormDataMultiPart
      */
     entity?: object;
     /**
      * 
      * @type {{ [key: string]: Array<string>; }}
      * @memberof FormDataMultiPart
      */
     headers?: { [key: string]: Array<string>; };
     /**
      * 
      * @type {BodyPartMediaType}
      * @memberof FormDataMultiPart
      */
     mediaType?: BodyPartMediaType;
     /**
      * 
      * @type {object}
      * @memberof FormDataMultiPart
      */
     messageBodyWorkers?: object;
     /**
      * 
      * @type {MultiPart}
      * @memberof FormDataMultiPart
      */
     parent?: MultiPart;
     /**
      * 
      * @type {object}
      * @memberof FormDataMultiPart
      */
     providers?: object;
     /**
      * 
      * @type {Array<BodyPart>}
      * @memberof FormDataMultiPart
      */
     bodyParts?: Array<BodyPart>;
     /**
      * 
      * @type {{ [key: string]: Array<FormDataBodyPart>; }}
      * @memberof FormDataMultiPart
      */
     fields?: { [key: string]: Array<FormDataBodyPart>; };
     /**
      * 
      * @type {{ [key: string]: Array<ParameterizedHeader>; }}
      * @memberof FormDataMultiPart
      */
     parameterizedHeaders?: { [key: string]: Array<ParameterizedHeader>; };
 }
 /**
  * 
  * @export
  * @interface Group
  */
 export interface Group {
     /**
      * 
      * @type {string}
      * @memberof Group
      */
     name?: string;
     /**
      * 
      * @type {Array<Field>}
      * @memberof Group
      */
     fields?: Array<Field>;
 }
 /**
  * 
  * @export
  * @interface Header
  */
 export interface Header {
     /**
      * 
      * @type {string}
      * @memberof Header
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof Header
      */
     type?: HeaderTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof Header
      */
     property?: string;
     /**
      * 
      * @type {Button}
      * @memberof Header
      */
     button?: Button;
     /**
      * 
      * @type {Link}
      * @memberof Header
      */
     link?: Link;
     /**
      * 
      * @type {{ [key: string]: string; }}
      * @memberof Header
      */
     images?: { [key: string]: string; };
     /**
      * 
      * @type {{ [key: string]: string; }}
      * @memberof Header
      */
     colors?: { [key: string]: string; };
     /**
      * 
      * @type {string}
      * @memberof Header
      */
     unit?: string;
     /**
      * 
      * @type {number}
      * @memberof Header
      */
     min?: number;
     /**
      * 
      * @type {number}
      * @memberof Header
      */
     max?: number;
     /**
      * 
      * @type {number}
      * @memberof Header
      */
     width?: number;
     /**
      * 
      * @type {string}
      * @memberof Header
      */
     style?: HeaderStyleEnum;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum HeaderTypeEnum {
     Text = 'Text',
     Number = 'Number',
     Link = 'Link',
     Date = 'Date',
     Time = 'Time',
     DateTime = 'DateTime',
     Edit = 'Edit',
     Button = 'Button',
     Image = 'Image',
     ImageLink = 'ImageLink',
     CustomAction = 'CustomAction'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum HeaderStyleEnum {
     None = 'None',
     Bold = 'Bold',
     Italic = 'Italic',
     Caps = 'Caps',
     Center = 'Center',
     CapsBold = 'CapsBold',
     CenterCapsBold = 'CenterCapsBold'
 }
 
 /**
  * 
  * @export
  * @interface InlineObject
  */
 export interface InlineObject {
     /**
      * 
      * @type {string}
      * @memberof InlineObject
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject1
  */
 export interface InlineObject1 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject1
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject10
  */
 export interface InlineObject10 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject10
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject11
  */
 export interface InlineObject11 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject11
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject12
  */
 export interface InlineObject12 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject12
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject13
  */
 export interface InlineObject13 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject13
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject2
  */
 export interface InlineObject2 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject2
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject3
  */
 export interface InlineObject3 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject3
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject4
  */
 export interface InlineObject4 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject4
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject5
  */
 export interface InlineObject5 {
     /**
      * 
      * @type {FormDataContentDisposition}
      * @memberof InlineObject5
      */
     file?: FormDataContentDisposition;
 }
 /**
  * 
  * @export
  * @interface InlineObject6
  */
 export interface InlineObject6 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject6
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject7
  */
 export interface InlineObject7 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject7
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject8
  */
 export interface InlineObject8 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject8
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface InlineObject9
  */
 export interface InlineObject9 {
     /**
      * 
      * @type {string}
      * @memberof InlineObject9
      */
     search: string;
 }
 /**
  * 
  * @export
  * @interface Item
  */
 export interface Item {
     /**
      * 
      * @type {string}
      * @memberof Item
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof Item
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface LeaderBoard
  */
 export interface LeaderBoard {
     /**
      * 
      * @type {string}
      * @memberof LeaderBoard
      */
     title?: string;
     /**
      * 
      * @type {Array<BoardData>}
      * @memberof LeaderBoard
      */
     data?: Array<BoardData>;
 }
 /**
  * 
  * @export
  * @interface Link
  */
 export interface Link {
     /**
      * 
      * @type {string}
      * @memberof Link
      */
     link?: string;
     /**
      * 
      * @type {string}
      * @memberof Link
      */
     name?: string;
     /**
      * 
      * @type {boolean}
      * @memberof Link
      */
     variable?: boolean;
 }
 /**
  * 
  * @export
  * @interface Location
  */
 export interface Location {
     /**
      * 
      * @type {number}
      * @memberof Location
      */
     lat?: number;
     /**
      * 
      * @type {number}
      * @memberof Location
      */
     lng?: number;
 }
 /**
  * 
  * @export
  * @interface Login
  */
 export interface Login {
     /**
      * 
      * @type {string}
      * @memberof Login
      */
     org?: string;
     /**
      * 
      * @type {string}
      * @memberof Login
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof Login
      */
     password?: string;
     /**
      * 
      * @type {string}
      * @memberof Login
      */
     oldPassword?: string;
 }
 /**
  * 
  * @export
  * @interface MapMatrix
  */
 export interface MapMatrix {
     /**
      * 
      * @type {number}
      * @memberof MapMatrix
      */
     distanceInMeter?: number;
     /**
      * 
      * @type {number}
      * @memberof MapMatrix
      */
     durationInSeconds?: number;
     /**
      * 
      * @type {number}
      * @memberof MapMatrix
      */
     displayDurationInSeconds?: number;
     /**
      * 
      * @type {Location}
      * @memberof MapMatrix
      */
     location?: Location;
     /**
      * 
      * @type {string}
      * @memberof MapMatrix
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface MapPath
  */
 export interface MapPath {
     /**
      * 
      * @type {Array<Location>}
      * @memberof MapPath
      */
     fullPath?: Array<Location>;
     /**
      * 
      * @type {Array<Location>}
      * @memberof MapPath
      */
     path?: Array<Location>;
     /**
      * 
      * @type {Array<MapMatrix>}
      * @memberof MapPath
      */
     stops?: Array<MapMatrix>;
     /**
      * 
      * @type {number}
      * @memberof MapPath
      */
     distanceInMeter?: number;
     /**
      * 
      * @type {number}
      * @memberof MapPath
      */
     durationInSeconds?: number;
     /**
      * 
      * @type {string}
      * @memberof MapPath
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof MapPath
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof MapPath
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof MapPath
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface Menu
  */
 export interface Menu {
     /**
      * 
      * @type {string}
      * @memberof Menu
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof Menu
      */
     icon?: string;
     /**
      * 
      * @type {string}
      * @memberof Menu
      */
     activeIcon?: string;
     /**
      * 
      * @type {Array<MenuItem>}
      * @memberof Menu
      */
     childs?: Array<MenuItem>;
     /**
      * 
      * @type {string}
      * @memberof Menu
      */
     action?: string;
     /**
      * 
      * @type {string}
      * @memberof Menu
      */
     path?: string;
     /**
      * 
      * @type {boolean}
      * @memberof Menu
      */
     active?: boolean;
 }
 /**
  * 
  * @export
  * @interface MenuItem
  */
 export interface MenuItem {
     /**
      * 
      * @type {string}
      * @memberof MenuItem
      */
     name?: string;
     /**
      * 
      * @type {boolean}
      * @memberof MenuItem
      */
     active?: boolean;
     /**
      * 
      * @type {string}
      * @memberof MenuItem
      */
     action?: string;
 }
 /**
  * 
  * @export
  * @interface Message
  */
 export interface Message {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof Message
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof Message
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof Message
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof Message
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageDashboard
  */
 export interface MessageDashboard {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageDashboard
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageDashboard
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageDashboard
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageDashboard
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageForm
  */
 export interface MessageForm {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageForm
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageForm
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageForm
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageForm
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListMenu
  */
 export interface MessageListMenu {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListMenu
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListMenu
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListMenu
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListMenu
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRBag
  */
 export interface MessageListRBag {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRBag
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRBag
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRBag
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRBag
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRCarrier
  */
 export interface MessageListRCarrier {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRCarrier
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRCarrier
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRCarrier
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRCarrier
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRCovidOffsite
  */
 export interface MessageListRCovidOffsite {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRCovidOffsite
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRCovidOffsite
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRCovidOffsite
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRCovidOffsite
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRCovidOffsiteTrip
  */
 export interface MessageListRCovidOffsiteTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRCovidOffsiteTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRCovidOffsiteTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRCovidOffsiteTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRCovidOffsiteTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRCovidOnsiteTrip
  */
 export interface MessageListRCovidOnsiteTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRCovidOnsiteTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRCovidOnsiteTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRCovidOnsiteTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRCovidOnsiteTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRCovidPatient
  */
 export interface MessageListRCovidPatient {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRCovidPatient
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRCovidPatient
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRCovidPatient
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRCovidPatient
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRCovidTrip
  */
 export interface MessageListRCovidTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRCovidTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRCovidTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRCovidTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRCovidTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRHomeTrip
  */
 export interface MessageListRHomeTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRHomeTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRHomeTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRHomeTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRHomeTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRHospital
  */
 export interface MessageListRHospital {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRHospital
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRHospital
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRHospital
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRHospital
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRHospitalTrip
  */
 export interface MessageListRHospitalTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRHospitalTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRHospitalTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRHospitalTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRHospitalTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRLab
  */
 export interface MessageListRLab {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRLab
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRLab
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRLab
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRLab
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRLabCollection
  */
 export interface MessageListRLabCollection {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRLabCollection
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRLabCollection
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRLabCollection
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRLabCollection
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRLabTrip
  */
 export interface MessageListRLabTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRLabTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRLabTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRLabTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRLabTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRMainCenter
  */
 export interface MessageListRMainCenter {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRMainCenter
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRMainCenter
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRMainCenter
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRMainCenter
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListROrganisation
  */
 export interface MessageListROrganisation {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListROrganisation
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListROrganisation
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListROrganisation
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListROrganisation
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRPatient
  */
 export interface MessageListRPatient {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRPatient
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRPatient
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRPatient
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRPatient
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRPouch
  */
 export interface MessageListRPouch {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRPouch
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRPouch
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRPouch
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRPouch
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRRoute
  */
 export interface MessageListRRoute {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRRoute
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRRoute
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRRoute
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRRoute
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRSample
  */
 export interface MessageListRSample {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRSample
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRSample
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRSample
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRSample
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRShipment
  */
 export interface MessageListRShipment {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRShipment
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRShipment
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRShipment
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRShipment
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRTestCollection
  */
 export interface MessageListRTestCollection {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRTestCollection
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRTestCollection
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRTestCollection
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRTestCollection
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRTrip
  */
 export interface MessageListRTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRUser
  */
 export interface MessageListRUser {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRUser
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRUser
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRUser
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRUser
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRVaccine
  */
 export interface MessageListRVaccine {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRVaccine
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRVaccine
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRVaccine
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRVaccine
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListRVaccineInjectData
  */
 export interface MessageListRVaccineInjectData {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListRVaccineInjectData
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListRVaccineInjectData
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListRVaccineInjectData
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListRVaccineInjectData
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageListString
  */
 export interface MessageListString {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageListString
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageListString
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageListString
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageListString
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageMapPath
  */
 export interface MessageMapPath {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageMapPath
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageMapPath
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageMapPath
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageMapPath
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageMessage
  */
 export interface MessageMessage {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageMessage
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageMessage
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageMessage
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageMessage
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRBag
  */
 export interface MessageRBag {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRBag
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRBag
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRBag
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRBag
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRCarrier
  */
 export interface MessageRCarrier {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRCarrier
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRCarrier
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRCarrier
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRCarrier
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRCovidOffsite
  */
 export interface MessageRCovidOffsite {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRCovidOffsite
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRCovidOffsite
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRCovidOffsite
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRCovidOffsite
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRCovidOffsiteTrip
  */
 export interface MessageRCovidOffsiteTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRCovidOffsiteTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRCovidOffsiteTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRCovidOffsiteTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRCovidOffsiteTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRCovidOnsiteTrip
  */
 export interface MessageRCovidOnsiteTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRCovidOnsiteTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRCovidOnsiteTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRCovidOnsiteTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRCovidOnsiteTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRCovidPatient
  */
 export interface MessageRCovidPatient {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRCovidPatient
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRCovidPatient
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRCovidPatient
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRCovidPatient
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRHomeTrip
  */
 export interface MessageRHomeTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRHomeTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRHomeTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRHomeTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRHomeTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRHospital
  */
 export interface MessageRHospital {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRHospital
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRHospital
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRHospital
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRHospital
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRHospitalTrip
  */
 export interface MessageRHospitalTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRHospitalTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRHospitalTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRHospitalTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRHospitalTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRLab
  */
 export interface MessageRLab {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRLab
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRLab
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRLab
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRLab
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRLabTrip
  */
 export interface MessageRLabTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRLabTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRLabTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRLabTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRLabTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRMainCenter
  */
 export interface MessageRMainCenter {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRMainCenter
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRMainCenter
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRMainCenter
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRMainCenter
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageROrganisation
  */
 export interface MessageROrganisation {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageROrganisation
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageROrganisation
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageROrganisation
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageROrganisation
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRPatient
  */
 export interface MessageRPatient {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRPatient
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRPatient
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRPatient
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRPatient
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRPouch
  */
 export interface MessageRPouch {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRPouch
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRPouch
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRPouch
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRPouch
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRPowerDashboard
  */
 export interface MessageRPowerDashboard {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRPowerDashboard
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRPowerDashboard
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRPowerDashboard
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRPowerDashboard
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRRoute
  */
 export interface MessageRRoute {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRRoute
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRRoute
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRRoute
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRRoute
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRSample
  */
 export interface MessageRSample {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRSample
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRSample
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRSample
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRSample
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRShipment
  */
 export interface MessageRShipment {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRShipment
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRShipment
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRShipment
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRShipment
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRAccess
  */
 export interface MessageRTableRAccess {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRAccess
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRAccess
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRAccess
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRAccess
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRBag
  */
 export interface MessageRTableRBag {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRBag
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRBag
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRBag
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRBag
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRCarrier
  */
 export interface MessageRTableRCarrier {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRCarrier
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRCarrier
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRCarrier
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRCarrier
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRCovidOffsite
  */
 export interface MessageRTableRCovidOffsite {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRCovidOffsite
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRCovidOffsite
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRCovidOffsite
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRCovidOffsite
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRCovidOffsiteTrip
  */
 export interface MessageRTableRCovidOffsiteTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRCovidOffsiteTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRCovidOffsiteTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRCovidOffsiteTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRCovidOffsiteTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRCovidOnsiteTrip
  */
 export interface MessageRTableRCovidOnsiteTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRCovidOnsiteTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRCovidOnsiteTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRCovidOnsiteTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRCovidOnsiteTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRCovidPatient
  */
 export interface MessageRTableRCovidPatient {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRCovidPatient
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRCovidPatient
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRCovidPatient
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRCovidPatient
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRHomeTrip
  */
 export interface MessageRTableRHomeTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRHomeTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRHomeTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRHomeTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRHomeTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRHospital
  */
 export interface MessageRTableRHospital {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRHospital
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRHospital
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRHospital
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRHospital
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRHospitalTrip
  */
 export interface MessageRTableRHospitalTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRHospitalTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRHospitalTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRHospitalTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRHospitalTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRLab
  */
 export interface MessageRTableRLab {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRLab
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRLab
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRLab
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRLab
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRLabTrip
  */
 export interface MessageRTableRLabTrip {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRLabTrip
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRLabTrip
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRLabTrip
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRLabTrip
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRMainCenter
  */
 export interface MessageRTableRMainCenter {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRMainCenter
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRMainCenter
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRMainCenter
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRMainCenter
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRModule
  */
 export interface MessageRTableRModule {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRModule
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRModule
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRModule
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRModule
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableROrganisation
  */
 export interface MessageRTableROrganisation {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableROrganisation
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableROrganisation
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableROrganisation
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableROrganisation
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRPatient
  */
 export interface MessageRTableRPatient {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRPatient
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRPatient
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRPatient
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRPatient
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRRoute
  */
 export interface MessageRTableRRoute {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRRoute
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRRoute
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRRoute
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRRoute
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRSample
  */
 export interface MessageRTableRSample {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRSample
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRSample
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRSample
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRSample
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRShipment
  */
 export interface MessageRTableRShipment {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRShipment
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRShipment
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRShipment
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRShipment
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRTestCollection
  */
 export interface MessageRTableRTestCollection {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRTestCollection
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRTestCollection
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRTestCollection
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRTestCollection
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRUser
  */
 export interface MessageRTableRUser {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRUser
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRUser
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRUser
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRUser
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRVaccine
  */
 export interface MessageRTableRVaccine {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRVaccine
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRVaccine
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRVaccine
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRVaccine
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTableRVaccineInjectData
  */
 export interface MessageRTableRVaccineInjectData {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTableRVaccineInjectData
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTableRVaccineInjectData
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTableRVaccineInjectData
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTableRVaccineInjectData
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTestCollection
  */
 export interface MessageRTestCollection {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTestCollection
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTestCollection
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTestCollection
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTestCollection
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRTracker
  */
 export interface MessageRTracker {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRTracker
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRTracker
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRTracker
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRTracker
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRUser
  */
 export interface MessageRUser {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRUser
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRUser
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRUser
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRUser
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRVaccine
  */
 export interface MessageRVaccine {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRVaccine
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRVaccine
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRVaccine
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRVaccine
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageRVaccineInjectData
  */
 export interface MessageRVaccineInjectData {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageRVaccineInjectData
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageRVaccineInjectData
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageRVaccineInjectData
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageRVaccineInjectData
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageString
  */
 export interface MessageString {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageString
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageString
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageString
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageString
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MessageUserCollection
  */
 export interface MessageUserCollection {
     /**
      * Code to identify the use case scenario.
      * @type {string}
      * @memberof MessageUserCollection
      */
     code?: string;
     /**
      * User readable message for displaying to consumer.
      * @type {string}
      * @memberof MessageUserCollection
      */
     message?: string;
     /**
      * Identify is the operation success or failure
      * @type {boolean}
      * @memberof MessageUserCollection
      */
     error?: boolean;
     /**
      * Identify is the use case scenario is handled.
      * @type {boolean}
      * @memberof MessageUserCollection
      */
     unknown?: boolean;
 }
 /**
  * 
  * @export
  * @interface MultiPart
  */
 export interface MultiPart {
     /**
      * 
      * @type {ContentDisposition}
      * @memberof MultiPart
      */
     contentDisposition?: ContentDisposition;
     /**
      * 
      * @type {object}
      * @memberof MultiPart
      */
     entity?: object;
     /**
      * 
      * @type {{ [key: string]: Array<string>; }}
      * @memberof MultiPart
      */
     headers?: { [key: string]: Array<string>; };
     /**
      * 
      * @type {BodyPartMediaType}
      * @memberof MultiPart
      */
     mediaType?: BodyPartMediaType;
     /**
      * 
      * @type {object}
      * @memberof MultiPart
      */
     messageBodyWorkers?: object;
     /**
      * 
      * @type {MultiPart}
      * @memberof MultiPart
      */
     parent?: MultiPart;
     /**
      * 
      * @type {object}
      * @memberof MultiPart
      */
     providers?: object;
     /**
      * 
      * @type {Array<BodyPart>}
      * @memberof MultiPart
      */
     bodyParts?: Array<BodyPart>;
     /**
      * 
      * @type {{ [key: string]: Array<ParameterizedHeader>; }}
      * @memberof MultiPart
      */
     parameterizedHeaders?: { [key: string]: Array<ParameterizedHeader>; };
 }
 /**
  * 
  * @export
  * @interface ObjectId
  */
 export interface ObjectId {
     /**
      * 
      * @type {number}
      * @memberof ObjectId
      */
     timestamp?: number;
     /**
      * 
      * @type {number}
      * @memberof ObjectId
      */
     counter?: number;
     /**
      * 
      * @type {string}
      * @memberof ObjectId
      */
     date?: string;
     /**
      * 
      * @type {number}
      * @memberof ObjectId
      */
     time?: number;
     /**
      * 
      * @type {number}
      * @memberof ObjectId
      */
     machineIdentifier?: number;
     /**
      * 
      * @type {number}
      * @memberof ObjectId
      */
     processIdentifier?: number;
     /**
      * 
      * @type {number}
      * @memberof ObjectId
      */
     timeSecond?: number;
 }
 /**
  * 
  * @export
  * @interface ParameterizedHeader
  */
 export interface ParameterizedHeader {
     /**
      * 
      * @type {string}
      * @memberof ParameterizedHeader
      */
     value?: string;
     /**
      * 
      * @type {{ [key: string]: string; }}
      * @memberof ParameterizedHeader
      */
     parameters?: { [key: string]: string; };
 }
 /**
  * 
  * @export
  * @interface PouchBag
  */
 export interface PouchBag {
     /**
      * 
      * @type {Array<PouchBagItem>}
      * @memberof PouchBag
      */
     data?: Array<PouchBagItem>;
 }
 /**
  * 
  * @export
  * @interface PouchBagItem
  */
 export interface PouchBagItem {
     /**
      * 
      * @type {string}
      * @memberof PouchBagItem
      */
     bagId?: string;
     /**
      * 
      * @type {number}
      * @memberof PouchBagItem
      */
     used?: number;
     /**
      * 
      * @type {number}
      * @memberof PouchBagItem
      */
     remaining?: number;
 }
 /**
  * 
  * @export
  * @interface RAccess
  */
 export interface RAccess {
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     module?: string;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     code?: string;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     role?: RAccessRoleEnum;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     access?: string;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     accessButton?: string;
     /**
      * 
      * @type {number}
      * @memberof RAccess
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RAccess
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     id?: string;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     centerId?: string;
     /**
      * 
      * @type {string}
      * @memberof RAccess
      */
     centerName?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RAccessRoleEnum {
     PowerAdmin = 'PowerAdmin',
     SuperAdmin = 'SuperAdmin',
     Admin = 'Admin',
     Phlebotomist = 'Phlebotomist',
     FieldExecutive = 'FieldExecutive'
 }
 
 /**
  * 
  * @export
  * @interface RBag
  */
 export interface RBag {
     /**
      * 
      * @type {string}
      * @memberof RBag
      */
     type?: RBagTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof RBag
      */
     status?: RBagStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RBag
      */
     carrierId?: string;
     /**
      * 
      * @type {string}
      * @memberof RBag
      */
     carrierName?: string;
     /**
      * 
      * @type {number}
      * @memberof RBag
      */
     chamber1?: number;
     /**
      * 
      * @type {number}
      * @memberof RBag
      */
     chamber2?: number;
     /**
      * 
      * @type {number}
      * @memberof RBag
      */
     chamber3?: number;
     /**
      * 
      * @type {string}
      * @memberof RBag
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RBag
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RBag
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RBag
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RBagTypeEnum {
     Hospital = 'Hospital',
     Home = 'Home',
     Lab = 'Lab',
     CovidOnsite = 'CovidOnsite',
     CovidOffsite = 'CovidOffsite'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RBagStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Suspended = 'Suspended'
 }
 
 /**
  * 
  * @export
  * @interface RCarrier
  */
 export interface RCarrier {
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     firstName?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     lastName?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     profile?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     password?: string;
     /**
      * 
      * @type {number}
      * @memberof RCarrier
      */
     userNo?: number;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     gender?: RCarrierGenderEnum;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     role?: RCarrierRoleEnum;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     email?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     phone?: string;
     /**
      * 
      * @type {Address}
      * @memberof RCarrier
      */
     address?: Address;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     flow?: RCarrierFlowEnum;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     centerId?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     centerName?: string;
     /**
      * 
      * @type {number}
      * @memberof RCarrier
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RCarrier
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     id?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     idType?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     idDoc?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     qualification?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     resume?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     brand?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     licensePlate?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     registration?: string;
     /**
      * 
      * @type {string}
      * @memberof RCarrier
      */
     licenseDoc?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RCarrierGenderEnum {
     Male = 'Male',
     Female = 'Female',
     Other = 'Other'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RCarrierRoleEnum {
     PowerAdmin = 'PowerAdmin',
     SuperAdmin = 'SuperAdmin',
     Admin = 'Admin',
     Phlebotomist = 'Phlebotomist',
     FieldExecutive = 'FieldExecutive'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RCarrierFlowEnum {
     Hospital = 'Hospital',
     Home = 'Home',
     Lab = 'Lab',
     CovidOnsite = 'CovidOnsite',
     CovidOffsite = 'CovidOffsite'
 }
 
 /**
  * 
  * @export
  * @interface RCovidOffsite
  */
 export interface RCovidOffsite {
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsite
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsite
      */
     phone?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsite
      */
     centerId?: string;
     /**
      * 
      * @type {Address}
      * @memberof RCovidOffsite
      */
     address?: Address;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsite
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsite
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsite
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface RCovidOffsiteTrip
  */
 export interface RCovidOffsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     covidOffsiteId?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RCovidOffsiteTrip
      */
     pouchIds?: Array<string>;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     bagId?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RCovidOffsiteTrip
      */
     patientIds?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RCovidOffsiteTrip
      */
     completedIds?: Array<string>;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     noOfSamples?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     distanceCovered?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     travelTime?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     status?: RCovidOffsiteTripStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     carrierId?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     carrierName?: string;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     noOfPatients?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     startTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     endTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOffsiteTrip
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidOffsiteTrip
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RCovidOffsiteTripStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Paused = 'Paused',
     Completed = 'Completed'
 }
 
 /**
  * 
  * @export
  * @interface RCovidOnsiteTrip
  */
 export interface RCovidOnsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RCovidOnsiteTrip
      */
     bagId?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RCovidOnsiteTrip
      */
     pouchIds?: Array<string>;
     /**
      * 
      * @type {string}
      * @memberof RCovidOnsiteTrip
      */
     name?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RCovidOnsiteTrip
      */
     patientIds?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RCovidOnsiteTrip
      */
     completedIds?: Array<string>;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     noOfSamples?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     distanceCovered?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     travelTime?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidOnsiteTrip
      */
     status?: RCovidOnsiteTripStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RCovidOnsiteTrip
      */
     carrierId?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidOnsiteTrip
      */
     carrierName?: string;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     noOfPatients?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidOnsiteTrip
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     startTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     endTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidOnsiteTrip
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidOnsiteTrip
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RCovidOnsiteTripStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Paused = 'Paused',
     Completed = 'Completed'
 }
 
 /**
  * 
  * @export
  * @interface RCovidPatient
  */
 export interface RCovidPatient {
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     covidOffsiteId?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     age?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     gender?: RCovidPatientGenderEnum;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     phone?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
      idProof?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     idDoc?: string;
     /**
      * 
      * @type {boolean}
      * @memberof RCovidPatient
      */
     vaccinated?: boolean;
     /**
      * 
      * @type {number}
      * @memberof RCovidPatient
      */
     dose?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidPatient
      */
     date1?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidPatient
      */
     date2?: number;
     /**
      * 
      * @type {Address}
      * @memberof RCovidPatient
      */
     address?: Address;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     centerId?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     type?: RCovidPatientTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     covidPatientType?: RCovidPatientCovidPatientTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     status?: RCovidPatientStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     tripId?: string;
     /**
      * 
      * @type {number}
      * @memberof RCovidPatient
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidPatient
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RCovidPatient
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RCovidPatientGenderEnum {
     Male = 'Male',
     Female = 'Female',
     Other = 'Other'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RCovidPatientTypeEnum {
     IP = 'IP',
     OP = 'OP'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RCovidPatientCovidPatientTypeEnum {
     IP = 'IP',
     OP = 'OP'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RCovidPatientStatusEnum {
     Upcoming = 'Upcoming',
     Completed = 'Completed',
     ND = 'ND'
 }
 
 /**
  * 
  * @export
  * @interface RCovidTrip
  */
 export interface RCovidTrip {
     /**
      * 
      * @type {string}
      * @memberof RCovidTrip
      */
     id?: string;
     /**
      * 
      * @type {string}
      * @memberof RCovidTrip
      */
     name?: string;
     /**
      * 
      * @type {number}
      * @memberof RCovidTrip
      */
     startTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RCovidTrip
      */
     count?: number;
     /**
      * 
      * @type {RVaccineInjectData}
      * @memberof RCovidTrip
      */
     vaccineInjectData?: RVaccineInjectData;
 }
 /**
  * 
  * @export
  * @interface RHomeTrip
  */
 export interface RHomeTrip {
     /**
      * 
      * @type {string}
      * @memberof RHomeTrip
      */
     bagId?: string;
     /**
      * 
      * @type {string}
      * @memberof RHomeTrip
      */
     name?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RHomeTrip
      */
     patientIds?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RHomeTrip
      */
     completedIds?: Array<string>;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     noOfSamples?: number;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     distanceCovered?: number;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     travelTime?: number;
     /**
      * 
      * @type {string}
      * @memberof RHomeTrip
      */
     status?: RHomeTripStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RHomeTrip
      */
     carrierId?: string;
     /**
      * 
      * @type {string}
      * @memberof RHomeTrip
      */
     carrierName?: string;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     noOfPatients?: number;
     /**
      * 
      * @type {string}
      * @memberof RHomeTrip
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     startTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     endTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RHomeTrip
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RHomeTrip
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RHomeTripStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Paused = 'Paused',
     Completed = 'Completed'
 }
 
 /**
  * 
  * @export
  * @interface RHospital
  */
 export interface RHospital {
     /**
      * 
      * @type {string}
      * @memberof RHospital
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RHospital
      */
     phone?: string;
     /**
      * 
      * @type {string}
      * @memberof RHospital
      */
     centerId?: string;
     /**
      * 
      * @type {Address}
      * @memberof RHospital
      */
     address?: Address;
     /**
      * 
      * @type {number}
      * @memberof RHospital
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RHospital
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RHospital
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface RHospitalTrip
  */
 export interface RHospitalTrip {
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     hospitalId?: string;
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     bagId?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RHospitalTrip
      */
     patientIds?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RHospitalTrip
      */
     completedIds?: Array<string>;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     noOfSamples?: number;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     distanceCovered?: number;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     travelTime?: number;
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     status?: RHospitalTripStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     carrierId?: string;
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     carrierName?: string;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     noOfPatients?: number;
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     startTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     endTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RHospitalTrip
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RHospitalTrip
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RHospitalTripStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Paused = 'Paused',
     Completed = 'Completed'
 }
 
 /**
  * 
  * @export
  * @interface RLRecord
  */
 export interface RLRecord {
     /**
      * 
      * @type {string}
      * @memberof RLRecord
      */
     tripId?: string;
     /**
      * 
      * @type {string}
      * @memberof RLRecord
      */
     type?: RLRecordTypeEnum;
     /**
      * 
      * @type {Location}
      * @memberof RLRecord
      */
     location?: Location;
     /**
      * 
      * @type {string}
      * @memberof RLRecord
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RLRecord
      */
     time?: number;
     /**
      * 
      * @type {number}
      * @memberof RLRecord
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RLRecord
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RLRecord
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RLRecordTypeEnum {
     Hospital = 'Hospital',
     Home = 'Home',
     Lab = 'Lab',
     CovidOnsite = 'CovidOnsite',
     CovidOffsite = 'CovidOffsite'
 }
 
 /**
  * 
  * @export
  * @interface RLab
  */
 export interface RLab {
     /**
      * 
      * @type {string}
      * @memberof RLab
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RLab
      */
     pointOfContact?: string;
     /**
      * 
      * @type {string}
      * @memberof RLab
      */
     contact?: string;
     /**
      * 
      * @type {Address}
      * @memberof RLab
      */
     address?: Address;
     /**
      * 
      * @type {string}
      * @memberof RLab
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RLab
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RLab
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RLab
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface RLabCollection
  */
 export interface RLabCollection {
     /**
      * 
      * @type {string}
      * @memberof RLabCollection
      */
     tripId?: string;
     /**
      * 
      * @type {string}
      * @memberof RLabCollection
      */
     labId?: string;
     /**
      * 
      * @type {number}
      * @memberof RLabCollection
      */
     chamber1?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabCollection
      */
     chamber2?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabCollection
      */
     chamber3?: number;
     /**
      * 
      * @type {string}
      * @memberof RLabCollection
      */
     chamberBar1?: string;
     /**
      * 
      * @type {string}
      * @memberof RLabCollection
      */
     chamberBar2?: string;
     /**
      * 
      * @type {string}
      * @memberof RLabCollection
      */
     chamberBar3?: string;
     /**
      * 
      * @type {Array<FileItem>}
      * @memberof RLabCollection
      */
     files?: Array<FileItem>;
     /**
      * 
      * @type {string}
      * @memberof RLabCollection
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RLabCollection
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabCollection
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RLabCollection
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface RLabTrip
  */
 export interface RLabTrip {
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     bagId?: string;
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     routeId?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RLabTrip
      */
     labIds?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RLabTrip
      */
     completedIds?: Array<string>;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     noOfSamples?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     distanceCovered?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     travelTime?: number;
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     status?: RLabTripStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     carrierId?: string;
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     carrierName?: string;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     noOfLabs?: number;
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     startTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     endTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RLabTrip
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RLabTrip
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RLabTripStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Paused = 'Paused',
     Completed = 'Completed'
 }
 
 /**
  * 
  * @export
  * @interface RMainCenter
  */
 export interface RMainCenter {
     /**
      * 
      * @type {string}
      * @memberof RMainCenter
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RMainCenter
      */
     pointOfContact?: string;
     /**
      * 
      * @type {string}
      * @memberof RMainCenter
      */
     contact?: string;
     /**
      * 
      * @type {Address}
      * @memberof RMainCenter
      */
     address?: Address;
     /**
      * 
      * @type {number}
      * @memberof RMainCenter
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RMainCenter
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RMainCenter
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface RModelDashboard
  */
 export interface RModelDashboard {
     /**
      * 
      * @type {string}
      * @memberof RModelDashboard
      */
     code?: RModelDashboardCodeEnum;
     /**
      * 
      * @type {MessageDashboard}
      * @memberof RModelDashboard
      */
     failure?: MessageDashboard;
     /**
      * 
      * @type {Dashboard}
      * @memberof RModelDashboard
      */
     success?: Dashboard;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelDashboardCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelForm
  */
 export interface RModelForm {
     /**
      * 
      * @type {string}
      * @memberof RModelForm
      */
     code?: RModelFormCodeEnum;
     /**
      * 
      * @type {MessageForm}
      * @memberof RModelForm
      */
     failure?: MessageForm;
     /**
      * 
      * @type {Form}
      * @memberof RModelForm
      */
     success?: Form;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelFormCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListMenu
  */
 export interface RModelListMenu {
     /**
      * 
      * @type {string}
      * @memberof RModelListMenu
      */
     code?: RModelListMenuCodeEnum;
     /**
      * 
      * @type {MessageListMenu}
      * @memberof RModelListMenu
      */
     failure?: MessageListMenu;
     /**
      * 
      * @type {Array<Menu>}
      * @memberof RModelListMenu
      */
     success?: Array<Menu>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListMenuCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRBag
  */
 export interface RModelListRBag {
     /**
      * 
      * @type {string}
      * @memberof RModelListRBag
      */
     code?: RModelListRBagCodeEnum;
     /**
      * 
      * @type {MessageListRBag}
      * @memberof RModelListRBag
      */
     failure?: MessageListRBag;
     /**
      * 
      * @type {Array<RBag>}
      * @memberof RModelListRBag
      */
     success?: Array<RBag>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRBagCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRCarrier
  */
 export interface RModelListRCarrier {
     /**
      * 
      * @type {string}
      * @memberof RModelListRCarrier
      */
     code?: RModelListRCarrierCodeEnum;
     /**
      * 
      * @type {MessageListRCarrier}
      * @memberof RModelListRCarrier
      */
     failure?: MessageListRCarrier;
     /**
      * 
      * @type {Array<RCarrier>}
      * @memberof RModelListRCarrier
      */
     success?: Array<RCarrier>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRCarrierCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRCovidOffsite
  */
 export interface RModelListRCovidOffsite {
     /**
      * 
      * @type {string}
      * @memberof RModelListRCovidOffsite
      */
     code?: RModelListRCovidOffsiteCodeEnum;
     /**
      * 
      * @type {MessageListRCovidOffsite}
      * @memberof RModelListRCovidOffsite
      */
     failure?: MessageListRCovidOffsite;
     /**
      * 
      * @type {Array<RCovidOffsite>}
      * @memberof RModelListRCovidOffsite
      */
     success?: Array<RCovidOffsite>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRCovidOffsiteCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRCovidOffsiteTrip
  */
 export interface RModelListRCovidOffsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelListRCovidOffsiteTrip
      */
     code?: RModelListRCovidOffsiteTripCodeEnum;
     /**
      * 
      * @type {MessageListRCovidOffsiteTrip}
      * @memberof RModelListRCovidOffsiteTrip
      */
     failure?: MessageListRCovidOffsiteTrip;
     /**
      * 
      * @type {Array<RCovidOffsiteTrip>}
      * @memberof RModelListRCovidOffsiteTrip
      */
     success?: Array<RCovidOffsiteTrip>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRCovidOffsiteTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRCovidOnsiteTrip
  */
 export interface RModelListRCovidOnsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelListRCovidOnsiteTrip
      */
     code?: RModelListRCovidOnsiteTripCodeEnum;
     /**
      * 
      * @type {MessageListRCovidOnsiteTrip}
      * @memberof RModelListRCovidOnsiteTrip
      */
     failure?: MessageListRCovidOnsiteTrip;
     /**
      * 
      * @type {Array<RCovidOnsiteTrip>}
      * @memberof RModelListRCovidOnsiteTrip
      */
     success?: Array<RCovidOnsiteTrip>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRCovidOnsiteTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRCovidPatient
  */
 export interface RModelListRCovidPatient {
     /**
      * 
      * @type {string}
      * @memberof RModelListRCovidPatient
      */
     code?: RModelListRCovidPatientCodeEnum;
     /**
      * 
      * @type {MessageListRCovidPatient}
      * @memberof RModelListRCovidPatient
      */
     failure?: MessageListRCovidPatient;
     /**
      * 
      * @type {Array<RCovidPatient>}
      * @memberof RModelListRCovidPatient
      */
     success?: Array<RCovidPatient>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRCovidPatientCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRCovidTrip
  */
 export interface RModelListRCovidTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelListRCovidTrip
      */
     code?: RModelListRCovidTripCodeEnum;
     /**
      * 
      * @type {MessageListRCovidTrip}
      * @memberof RModelListRCovidTrip
      */
     failure?: MessageListRCovidTrip;
     /**
      * 
      * @type {Array<RCovidTrip>}
      * @memberof RModelListRCovidTrip
      */
     success?: Array<RCovidTrip>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRCovidTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRHomeTrip
  */
 export interface RModelListRHomeTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelListRHomeTrip
      */
     code?: RModelListRHomeTripCodeEnum;
     /**
      * 
      * @type {MessageListRHomeTrip}
      * @memberof RModelListRHomeTrip
      */
     failure?: MessageListRHomeTrip;
     /**
      * 
      * @type {Array<RHomeTrip>}
      * @memberof RModelListRHomeTrip
      */
     success?: Array<RHomeTrip>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRHomeTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRHospital
  */
 export interface RModelListRHospital {
     /**
      * 
      * @type {string}
      * @memberof RModelListRHospital
      */
     code?: RModelListRHospitalCodeEnum;
     /**
      * 
      * @type {MessageListRHospital}
      * @memberof RModelListRHospital
      */
     failure?: MessageListRHospital;
     /**
      * 
      * @type {Array<RHospital>}
      * @memberof RModelListRHospital
      */
     success?: Array<RHospital>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRHospitalCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRHospitalTrip
  */
 export interface RModelListRHospitalTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelListRHospitalTrip
      */
     code?: RModelListRHospitalTripCodeEnum;
     /**
      * 
      * @type {MessageListRHospitalTrip}
      * @memberof RModelListRHospitalTrip
      */
     failure?: MessageListRHospitalTrip;
     /**
      * 
      * @type {Array<RHospitalTrip>}
      * @memberof RModelListRHospitalTrip
      */
     success?: Array<RHospitalTrip>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRHospitalTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRLab
  */
 export interface RModelListRLab {
     /**
      * 
      * @type {string}
      * @memberof RModelListRLab
      */
     code?: RModelListRLabCodeEnum;
     /**
      * 
      * @type {MessageListRLab}
      * @memberof RModelListRLab
      */
     failure?: MessageListRLab;
     /**
      * 
      * @type {Array<RLab>}
      * @memberof RModelListRLab
      */
     success?: Array<RLab>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRLabCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRLabCollection
  */
 export interface RModelListRLabCollection {
     /**
      * 
      * @type {string}
      * @memberof RModelListRLabCollection
      */
     code?: RModelListRLabCollectionCodeEnum;
     /**
      * 
      * @type {MessageListRLabCollection}
      * @memberof RModelListRLabCollection
      */
     failure?: MessageListRLabCollection;
     /**
      * 
      * @type {Array<RLabCollection>}
      * @memberof RModelListRLabCollection
      */
     success?: Array<RLabCollection>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRLabCollectionCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRLabTrip
  */
 export interface RModelListRLabTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelListRLabTrip
      */
     code?: RModelListRLabTripCodeEnum;
     /**
      * 
      * @type {MessageListRLabTrip}
      * @memberof RModelListRLabTrip
      */
     failure?: MessageListRLabTrip;
     /**
      * 
      * @type {Array<RLabTrip>}
      * @memberof RModelListRLabTrip
      */
     success?: Array<RLabTrip>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRLabTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRMainCenter
  */
 export interface RModelListRMainCenter {
     /**
      * 
      * @type {string}
      * @memberof RModelListRMainCenter
      */
     code?: RModelListRMainCenterCodeEnum;
     /**
      * 
      * @type {MessageListRMainCenter}
      * @memberof RModelListRMainCenter
      */
     failure?: MessageListRMainCenter;
     /**
      * 
      * @type {Array<RMainCenter>}
      * @memberof RModelListRMainCenter
      */
     success?: Array<RMainCenter>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRMainCenterCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListROrganisation
  */
 export interface RModelListROrganisation {
     /**
      * 
      * @type {string}
      * @memberof RModelListROrganisation
      */
     code?: RModelListROrganisationCodeEnum;
     /**
      * 
      * @type {MessageListROrganisation}
      * @memberof RModelListROrganisation
      */
     failure?: MessageListROrganisation;
     /**
      * 
      * @type {Array<ROrganisation>}
      * @memberof RModelListROrganisation
      */
     success?: Array<ROrganisation>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListROrganisationCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRPatient
  */
 export interface RModelListRPatient {
     /**
      * 
      * @type {string}
      * @memberof RModelListRPatient
      */
     code?: RModelListRPatientCodeEnum;
     /**
      * 
      * @type {MessageListRPatient}
      * @memberof RModelListRPatient
      */
     failure?: MessageListRPatient;
     /**
      * 
      * @type {Array<RPatient>}
      * @memberof RModelListRPatient
      */
     success?: Array<RPatient>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRPatientCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRPouch
  */
 export interface RModelListRPouch {
     /**
      * 
      * @type {string}
      * @memberof RModelListRPouch
      */
     code?: RModelListRPouchCodeEnum;
     /**
      * 
      * @type {MessageListRPouch}
      * @memberof RModelListRPouch
      */
     failure?: MessageListRPouch;
     /**
      * 
      * @type {Array<RPouch>}
      * @memberof RModelListRPouch
      */
     success?: Array<RPouch>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRPouchCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRRoute
  */
 export interface RModelListRRoute {
     /**
      * 
      * @type {string}
      * @memberof RModelListRRoute
      */
     code?: RModelListRRouteCodeEnum;
     /**
      * 
      * @type {MessageListRRoute}
      * @memberof RModelListRRoute
      */
     failure?: MessageListRRoute;
     /**
      * 
      * @type {Array<RRoute>}
      * @memberof RModelListRRoute
      */
     success?: Array<RRoute>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRRouteCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRSample
  */
 export interface RModelListRSample {
     /**
      * 
      * @type {string}
      * @memberof RModelListRSample
      */
     code?: RModelListRSampleCodeEnum;
     /**
      * 
      * @type {MessageListRSample}
      * @memberof RModelListRSample
      */
     failure?: MessageListRSample;
     /**
      * 
      * @type {Array<RSample>}
      * @memberof RModelListRSample
      */
     success?: Array<RSample>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRSampleCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRShipment
  */
 export interface RModelListRShipment {
     /**
      * 
      * @type {string}
      * @memberof RModelListRShipment
      */
     code?: RModelListRShipmentCodeEnum;
     /**
      * 
      * @type {MessageListRShipment}
      * @memberof RModelListRShipment
      */
     failure?: MessageListRShipment;
     /**
      * 
      * @type {Array<RShipment>}
      * @memberof RModelListRShipment
      */
     success?: Array<RShipment>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRShipmentCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRTestCollection
  */
 export interface RModelListRTestCollection {
     /**
      * 
      * @type {string}
      * @memberof RModelListRTestCollection
      */
     code?: RModelListRTestCollectionCodeEnum;
     /**
      * 
      * @type {MessageListRTestCollection}
      * @memberof RModelListRTestCollection
      */
     failure?: MessageListRTestCollection;
     /**
      * 
      * @type {Array<RTestCollection>}
      * @memberof RModelListRTestCollection
      */
     success?: Array<RTestCollection>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRTestCollectionCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRTrip
  */
 export interface RModelListRTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelListRTrip
      */
     code?: RModelListRTripCodeEnum;
     /**
      * 
      * @type {MessageListRTrip}
      * @memberof RModelListRTrip
      */
     failure?: MessageListRTrip;
     /**
      * 
      * @type {Array<RTrip>}
      * @memberof RModelListRTrip
      */
     success?: Array<RTrip>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRUser
  */
 export interface RModelListRUser {
     /**
      * 
      * @type {string}
      * @memberof RModelListRUser
      */
     code?: RModelListRUserCodeEnum;
     /**
      * 
      * @type {MessageListRUser}
      * @memberof RModelListRUser
      */
     failure?: MessageListRUser;
     /**
      * 
      * @type {Array<RUser>}
      * @memberof RModelListRUser
      */
     success?: Array<RUser>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRUserCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRVaccine
  */
 export interface RModelListRVaccine {
     /**
      * 
      * @type {string}
      * @memberof RModelListRVaccine
      */
     code?: RModelListRVaccineCodeEnum;
     /**
      * 
      * @type {MessageListRVaccine}
      * @memberof RModelListRVaccine
      */
     failure?: MessageListRVaccine;
     /**
      * 
      * @type {Array<RVaccine>}
      * @memberof RModelListRVaccine
      */
     success?: Array<RVaccine>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRVaccineCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListRVaccineInjectData
  */
 export interface RModelListRVaccineInjectData {
     /**
      * 
      * @type {string}
      * @memberof RModelListRVaccineInjectData
      */
     code?: RModelListRVaccineInjectDataCodeEnum;
     /**
      * 
      * @type {MessageListRVaccineInjectData}
      * @memberof RModelListRVaccineInjectData
      */
     failure?: MessageListRVaccineInjectData;
     /**
      * 
      * @type {Array<RVaccineInjectData>}
      * @memberof RModelListRVaccineInjectData
      */
     success?: Array<RVaccineInjectData>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListRVaccineInjectDataCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelListString
  */
 export interface RModelListString {
     /**
      * 
      * @type {string}
      * @memberof RModelListString
      */
     code?: RModelListStringCodeEnum;
     /**
      * 
      * @type {MessageListString}
      * @memberof RModelListString
      */
     failure?: MessageListString;
     /**
      * 
      * @type {Array<string>}
      * @memberof RModelListString
      */
     success?: Array<string>;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelListStringCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelMapPath
  */
 export interface RModelMapPath {
     /**
      * 
      * @type {string}
      * @memberof RModelMapPath
      */
     code?: RModelMapPathCodeEnum;
     /**
      * 
      * @type {MessageMapPath}
      * @memberof RModelMapPath
      */
     failure?: MessageMapPath;
     /**
      * 
      * @type {MapPath}
      * @memberof RModelMapPath
      */
     success?: MapPath;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelMapPathCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelMessage
  */
 export interface RModelMessage {
     /**
      * 
      * @type {string}
      * @memberof RModelMessage
      */
     code?: RModelMessageCodeEnum;
     /**
      * 
      * @type {MessageMessage}
      * @memberof RModelMessage
      */
     failure?: MessageMessage;
     /**
      * 
      * @type {Message}
      * @memberof RModelMessage
      */
     success?: Message;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelMessageCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRBag
  */
 export interface RModelRBag {
     /**
      * 
      * @type {string}
      * @memberof RModelRBag
      */
     code?: RModelRBagCodeEnum;
     /**
      * 
      * @type {MessageRBag}
      * @memberof RModelRBag
      */
     failure?: MessageRBag;
     /**
      * 
      * @type {RBag}
      * @memberof RModelRBag
      */
     success?: RBag;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRBagCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRCarrier
  */
 export interface RModelRCarrier {
     /**
      * 
      * @type {string}
      * @memberof RModelRCarrier
      */
     code?: RModelRCarrierCodeEnum;
     /**
      * 
      * @type {MessageRCarrier}
      * @memberof RModelRCarrier
      */
     failure?: MessageRCarrier;
     /**
      * 
      * @type {RCarrier}
      * @memberof RModelRCarrier
      */
     success?: RCarrier;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRCarrierCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRCovidOffsite
  */
 export interface RModelRCovidOffsite {
     /**
      * 
      * @type {string}
      * @memberof RModelRCovidOffsite
      */
     code?: RModelRCovidOffsiteCodeEnum;
     /**
      * 
      * @type {MessageRCovidOffsite}
      * @memberof RModelRCovidOffsite
      */
     failure?: MessageRCovidOffsite;
     /**
      * 
      * @type {RCovidOffsite}
      * @memberof RModelRCovidOffsite
      */
     success?: RCovidOffsite;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRCovidOffsiteCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRCovidOffsiteTrip
  */
 export interface RModelRCovidOffsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRCovidOffsiteTrip
      */
     code?: RModelRCovidOffsiteTripCodeEnum;
     /**
      * 
      * @type {MessageRCovidOffsiteTrip}
      * @memberof RModelRCovidOffsiteTrip
      */
     failure?: MessageRCovidOffsiteTrip;
     /**
      * 
      * @type {RCovidOffsiteTrip}
      * @memberof RModelRCovidOffsiteTrip
      */
     success?: RCovidOffsiteTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRCovidOffsiteTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRCovidOnsiteTrip
  */
 export interface RModelRCovidOnsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRCovidOnsiteTrip
      */
     code?: RModelRCovidOnsiteTripCodeEnum;
     /**
      * 
      * @type {MessageRCovidOnsiteTrip}
      * @memberof RModelRCovidOnsiteTrip
      */
     failure?: MessageRCovidOnsiteTrip;
     /**
      * 
      * @type {RCovidOnsiteTrip}
      * @memberof RModelRCovidOnsiteTrip
      */
     success?: RCovidOnsiteTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRCovidOnsiteTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRCovidPatient
  */
 export interface RModelRCovidPatient {
     /**
      * 
      * @type {string}
      * @memberof RModelRCovidPatient
      */
     code?: RModelRCovidPatientCodeEnum;
     /**
      * 
      * @type {MessageRCovidPatient}
      * @memberof RModelRCovidPatient
      */
     failure?: MessageRCovidPatient;
     /**
      * 
      * @type {RCovidPatient}
      * @memberof RModelRCovidPatient
      */
     success?: RCovidPatient;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRCovidPatientCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRHomeTrip
  */
 export interface RModelRHomeTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRHomeTrip
      */
     code?: RModelRHomeTripCodeEnum;
     /**
      * 
      * @type {MessageRHomeTrip}
      * @memberof RModelRHomeTrip
      */
     failure?: MessageRHomeTrip;
     /**
      * 
      * @type {RHomeTrip}
      * @memberof RModelRHomeTrip
      */
     success?: RHomeTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRHomeTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRHospital
  */
 export interface RModelRHospital {
     /**
      * 
      * @type {string}
      * @memberof RModelRHospital
      */
     code?: RModelRHospitalCodeEnum;
     /**
      * 
      * @type {MessageRHospital}
      * @memberof RModelRHospital
      */
     failure?: MessageRHospital;
     /**
      * 
      * @type {RHospital}
      * @memberof RModelRHospital
      */
     success?: RHospital;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRHospitalCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRHospitalTrip
  */
 export interface RModelRHospitalTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRHospitalTrip
      */
     code?: RModelRHospitalTripCodeEnum;
     /**
      * 
      * @type {MessageRHospitalTrip}
      * @memberof RModelRHospitalTrip
      */
     failure?: MessageRHospitalTrip;
     /**
      * 
      * @type {RHospitalTrip}
      * @memberof RModelRHospitalTrip
      */
     success?: RHospitalTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRHospitalTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRLab
  */
 export interface RModelRLab {
     /**
      * 
      * @type {string}
      * @memberof RModelRLab
      */
     code?: RModelRLabCodeEnum;
     /**
      * 
      * @type {MessageRLab}
      * @memberof RModelRLab
      */
     failure?: MessageRLab;
     /**
      * 
      * @type {RLab}
      * @memberof RModelRLab
      */
     success?: RLab;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRLabCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRLabTrip
  */
 export interface RModelRLabTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRLabTrip
      */
     code?: RModelRLabTripCodeEnum;
     /**
      * 
      * @type {MessageRLabTrip}
      * @memberof RModelRLabTrip
      */
     failure?: MessageRLabTrip;
     /**
      * 
      * @type {RLabTrip}
      * @memberof RModelRLabTrip
      */
     success?: RLabTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRLabTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRMainCenter
  */
 export interface RModelRMainCenter {
     /**
      * 
      * @type {string}
      * @memberof RModelRMainCenter
      */
     code?: RModelRMainCenterCodeEnum;
     /**
      * 
      * @type {MessageRMainCenter}
      * @memberof RModelRMainCenter
      */
     failure?: MessageRMainCenter;
     /**
      * 
      * @type {RMainCenter}
      * @memberof RModelRMainCenter
      */
     success?: RMainCenter;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRMainCenterCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelROrganisation
  */
 export interface RModelROrganisation {
     /**
      * 
      * @type {string}
      * @memberof RModelROrganisation
      */
     code?: RModelROrganisationCodeEnum;
     /**
      * 
      * @type {MessageROrganisation}
      * @memberof RModelROrganisation
      */
     failure?: MessageROrganisation;
     /**
      * 
      * @type {ROrganisation}
      * @memberof RModelROrganisation
      */
     success?: ROrganisation;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelROrganisationCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRPatient
  */
 export interface RModelRPatient {
     /**
      * 
      * @type {string}
      * @memberof RModelRPatient
      */
     code?: RModelRPatientCodeEnum;
     /**
      * 
      * @type {MessageRPatient}
      * @memberof RModelRPatient
      */
     failure?: MessageRPatient;
     /**
      * 
      * @type {RPatient}
      * @memberof RModelRPatient
      */
     success?: RPatient;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRPatientCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRPouch
  */
 export interface RModelRPouch {
     /**
      * 
      * @type {string}
      * @memberof RModelRPouch
      */
     code?: RModelRPouchCodeEnum;
     /**
      * 
      * @type {MessageRPouch}
      * @memberof RModelRPouch
      */
     failure?: MessageRPouch;
     /**
      * 
      * @type {RPouch}
      * @memberof RModelRPouch
      */
     success?: RPouch;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRPouchCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRPowerDashboard
  */
 export interface RModelRPowerDashboard {
     /**
      * 
      * @type {string}
      * @memberof RModelRPowerDashboard
      */
     code?: RModelRPowerDashboardCodeEnum;
     /**
      * 
      * @type {MessageRPowerDashboard}
      * @memberof RModelRPowerDashboard
      */
     failure?: MessageRPowerDashboard;
     /**
      * 
      * @type {RPowerDashboard}
      * @memberof RModelRPowerDashboard
      */
     success?: RPowerDashboard;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRPowerDashboardCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRRoute
  */
 export interface RModelRRoute {
     /**
      * 
      * @type {string}
      * @memberof RModelRRoute
      */
     code?: RModelRRouteCodeEnum;
     /**
      * 
      * @type {MessageRRoute}
      * @memberof RModelRRoute
      */
     failure?: MessageRRoute;
     /**
      * 
      * @type {RRoute}
      * @memberof RModelRRoute
      */
     success?: RRoute;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRRouteCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRSample
  */
 export interface RModelRSample {
     /**
      * 
      * @type {string}
      * @memberof RModelRSample
      */
     code?: RModelRSampleCodeEnum;
     /**
      * 
      * @type {MessageRSample}
      * @memberof RModelRSample
      */
     failure?: MessageRSample;
     /**
      * 
      * @type {RSample}
      * @memberof RModelRSample
      */
     success?: RSample;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRSampleCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRShipment
  */
 export interface RModelRShipment {
     /**
      * 
      * @type {string}
      * @memberof RModelRShipment
      */
     code?: RModelRShipmentCodeEnum;
     /**
      * 
      * @type {MessageRShipment}
      * @memberof RModelRShipment
      */
     failure?: MessageRShipment;
     /**
      * 
      * @type {RShipment}
      * @memberof RModelRShipment
      */
     success?: RShipment;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRShipmentCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRAccess
  */
 export interface RModelRTableRAccess {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRAccess
      */
     code?: RModelRTableRAccessCodeEnum;
     /**
      * 
      * @type {MessageRTableRAccess}
      * @memberof RModelRTableRAccess
      */
     failure?: MessageRTableRAccess;
     /**
      * 
      * @type {RTableRAccess}
      * @memberof RModelRTableRAccess
      */
     success?: RTableRAccess;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRAccessCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRBag
  */
 export interface RModelRTableRBag {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRBag
      */
     code?: RModelRTableRBagCodeEnum;
     /**
      * 
      * @type {MessageRTableRBag}
      * @memberof RModelRTableRBag
      */
     failure?: MessageRTableRBag;
     /**
      * 
      * @type {RTableRBag}
      * @memberof RModelRTableRBag
      */
     success?: RTableRBag;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRBagCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRCarrier
  */
 export interface RModelRTableRCarrier {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRCarrier
      */
     code?: RModelRTableRCarrierCodeEnum;
     /**
      * 
      * @type {MessageRTableRCarrier}
      * @memberof RModelRTableRCarrier
      */
     failure?: MessageRTableRCarrier;
     /**
      * 
      * @type {RTableRCarrier}
      * @memberof RModelRTableRCarrier
      */
     success?: RTableRCarrier;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRCarrierCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRCovidOffsite
  */
 export interface RModelRTableRCovidOffsite {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRCovidOffsite
      */
     code?: RModelRTableRCovidOffsiteCodeEnum;
     /**
      * 
      * @type {MessageRTableRCovidOffsite}
      * @memberof RModelRTableRCovidOffsite
      */
     failure?: MessageRTableRCovidOffsite;
     /**
      * 
      * @type {RTableRCovidOffsite}
      * @memberof RModelRTableRCovidOffsite
      */
     success?: RTableRCovidOffsite;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRCovidOffsiteCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRCovidOffsiteTrip
  */
 export interface RModelRTableRCovidOffsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRCovidOffsiteTrip
      */
     code?: RModelRTableRCovidOffsiteTripCodeEnum;
     /**
      * 
      * @type {MessageRTableRCovidOffsiteTrip}
      * @memberof RModelRTableRCovidOffsiteTrip
      */
     failure?: MessageRTableRCovidOffsiteTrip;
     /**
      * 
      * @type {RTableRCovidOffsiteTrip}
      * @memberof RModelRTableRCovidOffsiteTrip
      */
     success?: RTableRCovidOffsiteTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRCovidOffsiteTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRCovidOnsiteTrip
  */
 export interface RModelRTableRCovidOnsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRCovidOnsiteTrip
      */
     code?: RModelRTableRCovidOnsiteTripCodeEnum;
     /**
      * 
      * @type {MessageRTableRCovidOnsiteTrip}
      * @memberof RModelRTableRCovidOnsiteTrip
      */
     failure?: MessageRTableRCovidOnsiteTrip;
     /**
      * 
      * @type {RTableRCovidOnsiteTrip}
      * @memberof RModelRTableRCovidOnsiteTrip
      */
     success?: RTableRCovidOnsiteTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRCovidOnsiteTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRCovidPatient
  */
 export interface RModelRTableRCovidPatient {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRCovidPatient
      */
     code?: RModelRTableRCovidPatientCodeEnum;
     /**
      * 
      * @type {MessageRTableRCovidPatient}
      * @memberof RModelRTableRCovidPatient
      */
     failure?: MessageRTableRCovidPatient;
     /**
      * 
      * @type {RTableRCovidPatient}
      * @memberof RModelRTableRCovidPatient
      */
     success?: RTableRCovidPatient;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRCovidPatientCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRHomeTrip
  */
 export interface RModelRTableRHomeTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRHomeTrip
      */
     code?: RModelRTableRHomeTripCodeEnum;
     /**
      * 
      * @type {MessageRTableRHomeTrip}
      * @memberof RModelRTableRHomeTrip
      */
     failure?: MessageRTableRHomeTrip;
     /**
      * 
      * @type {RTableRHomeTrip}
      * @memberof RModelRTableRHomeTrip
      */
     success?: RTableRHomeTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRHomeTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRHospital
  */
 export interface RModelRTableRHospital {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRHospital
      */
     code?: RModelRTableRHospitalCodeEnum;
     /**
      * 
      * @type {MessageRTableRHospital}
      * @memberof RModelRTableRHospital
      */
     failure?: MessageRTableRHospital;
     /**
      * 
      * @type {RTableRHospital}
      * @memberof RModelRTableRHospital
      */
     success?: RTableRHospital;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRHospitalCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRHospitalTrip
  */
 export interface RModelRTableRHospitalTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRHospitalTrip
      */
     code?: RModelRTableRHospitalTripCodeEnum;
     /**
      * 
      * @type {MessageRTableRHospitalTrip}
      * @memberof RModelRTableRHospitalTrip
      */
     failure?: MessageRTableRHospitalTrip;
     /**
      * 
      * @type {RTableRHospitalTrip}
      * @memberof RModelRTableRHospitalTrip
      */
     success?: RTableRHospitalTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRHospitalTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRLab
  */
 export interface RModelRTableRLab {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRLab
      */
     code?: RModelRTableRLabCodeEnum;
     /**
      * 
      * @type {MessageRTableRLab}
      * @memberof RModelRTableRLab
      */
     failure?: MessageRTableRLab;
     /**
      * 
      * @type {RTableRLab}
      * @memberof RModelRTableRLab
      */
     success?: RTableRLab;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRLabCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRLabTrip
  */
 export interface RModelRTableRLabTrip {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRLabTrip
      */
     code?: RModelRTableRLabTripCodeEnum;
     /**
      * 
      * @type {MessageRTableRLabTrip}
      * @memberof RModelRTableRLabTrip
      */
     failure?: MessageRTableRLabTrip;
     /**
      * 
      * @type {RTableRLabTrip}
      * @memberof RModelRTableRLabTrip
      */
     success?: RTableRLabTrip;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRLabTripCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRMainCenter
  */
 export interface RModelRTableRMainCenter {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRMainCenter
      */
     code?: RModelRTableRMainCenterCodeEnum;
     /**
      * 
      * @type {MessageRTableRMainCenter}
      * @memberof RModelRTableRMainCenter
      */
     failure?: MessageRTableRMainCenter;
     /**
      * 
      * @type {RTableRMainCenter}
      * @memberof RModelRTableRMainCenter
      */
     success?: RTableRMainCenter;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRMainCenterCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRModule
  */
 export interface RModelRTableRModule {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRModule
      */
     code?: RModelRTableRModuleCodeEnum;
     /**
      * 
      * @type {MessageRTableRModule}
      * @memberof RModelRTableRModule
      */
     failure?: MessageRTableRModule;
     /**
      * 
      * @type {RTableRModule}
      * @memberof RModelRTableRModule
      */
     success?: RTableRModule;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRModuleCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableROrganisation
  */
 export interface RModelRTableROrganisation {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableROrganisation
      */
     code?: RModelRTableROrganisationCodeEnum;
     /**
      * 
      * @type {MessageRTableROrganisation}
      * @memberof RModelRTableROrganisation
      */
     failure?: MessageRTableROrganisation;
     /**
      * 
      * @type {RTableROrganisation}
      * @memberof RModelRTableROrganisation
      */
     success?: RTableROrganisation;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableROrganisationCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRPatient
  */
 export interface RModelRTableRPatient {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRPatient
      */
     code?: RModelRTableRPatientCodeEnum;
     /**
      * 
      * @type {MessageRTableRPatient}
      * @memberof RModelRTableRPatient
      */
     failure?: MessageRTableRPatient;
     /**
      * 
      * @type {RTableRPatient}
      * @memberof RModelRTableRPatient
      */
     success?: RTableRPatient;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRPatientCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRRoute
  */
 export interface RModelRTableRRoute {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRRoute
      */
     code?: RModelRTableRRouteCodeEnum;
     /**
      * 
      * @type {MessageRTableRRoute}
      * @memberof RModelRTableRRoute
      */
     failure?: MessageRTableRRoute;
     /**
      * 
      * @type {RTableRRoute}
      * @memberof RModelRTableRRoute
      */
     success?: RTableRRoute;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRRouteCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRSample
  */
 export interface RModelRTableRSample {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRSample
      */
     code?: RModelRTableRSampleCodeEnum;
     /**
      * 
      * @type {MessageRTableRSample}
      * @memberof RModelRTableRSample
      */
     failure?: MessageRTableRSample;
     /**
      * 
      * @type {RTableRSample}
      * @memberof RModelRTableRSample
      */
     success?: RTableRSample;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRSampleCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRShipment
  */
 export interface RModelRTableRShipment {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRShipment
      */
     code?: RModelRTableRShipmentCodeEnum;
     /**
      * 
      * @type {MessageRTableRShipment}
      * @memberof RModelRTableRShipment
      */
     failure?: MessageRTableRShipment;
     /**
      * 
      * @type {RTableRShipment}
      * @memberof RModelRTableRShipment
      */
     success?: RTableRShipment;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRShipmentCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRTestCollection
  */
 export interface RModelRTableRTestCollection {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRTestCollection
      */
     code?: RModelRTableRTestCollectionCodeEnum;
     /**
      * 
      * @type {MessageRTableRTestCollection}
      * @memberof RModelRTableRTestCollection
      */
     failure?: MessageRTableRTestCollection;
     /**
      * 
      * @type {RTableRTestCollection}
      * @memberof RModelRTableRTestCollection
      */
     success?: RTableRTestCollection;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRTestCollectionCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRUser
  */
 export interface RModelRTableRUser {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRUser
      */
     code?: RModelRTableRUserCodeEnum;
     /**
      * 
      * @type {MessageRTableRUser}
      * @memberof RModelRTableRUser
      */
     failure?: MessageRTableRUser;
     /**
      * 
      * @type {RTableRUser}
      * @memberof RModelRTableRUser
      */
     success?: RTableRUser;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRUserCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRVaccine
  */
 export interface RModelRTableRVaccine {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRVaccine
      */
     code?: RModelRTableRVaccineCodeEnum;
     /**
      * 
      * @type {MessageRTableRVaccine}
      * @memberof RModelRTableRVaccine
      */
     failure?: MessageRTableRVaccine;
     /**
      * 
      * @type {RTableRVaccine}
      * @memberof RModelRTableRVaccine
      */
     success?: RTableRVaccine;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRVaccineCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTableRVaccineInjectData
  */
 export interface RModelRTableRVaccineInjectData {
     /**
      * 
      * @type {string}
      * @memberof RModelRTableRVaccineInjectData
      */
     code?: RModelRTableRVaccineInjectDataCodeEnum;
     /**
      * 
      * @type {MessageRTableRVaccineInjectData}
      * @memberof RModelRTableRVaccineInjectData
      */
     failure?: MessageRTableRVaccineInjectData;
     /**
      * 
      * @type {RTableRVaccineInjectData}
      * @memberof RModelRTableRVaccineInjectData
      */
     success?: RTableRVaccineInjectData;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTableRVaccineInjectDataCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTestCollection
  */
 export interface RModelRTestCollection {
     /**
      * 
      * @type {string}
      * @memberof RModelRTestCollection
      */
     code?: RModelRTestCollectionCodeEnum;
     /**
      * 
      * @type {MessageRTestCollection}
      * @memberof RModelRTestCollection
      */
     failure?: MessageRTestCollection;
     /**
      * 
      * @type {RTestCollection}
      * @memberof RModelRTestCollection
      */
     success?: RTestCollection;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTestCollectionCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRTracker
  */
 export interface RModelRTracker {
     /**
      * 
      * @type {string}
      * @memberof RModelRTracker
      */
     code?: RModelRTrackerCodeEnum;
     /**
      * 
      * @type {MessageRTracker}
      * @memberof RModelRTracker
      */
     failure?: MessageRTracker;
     /**
      * 
      * @type {RTracker}
      * @memberof RModelRTracker
      */
     success?: RTracker;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRTrackerCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRUser
  */
 export interface RModelRUser {
     /**
      * 
      * @type {string}
      * @memberof RModelRUser
      */
     code?: RModelRUserCodeEnum;
     /**
      * 
      * @type {MessageRUser}
      * @memberof RModelRUser
      */
     failure?: MessageRUser;
     /**
      * 
      * @type {RUser}
      * @memberof RModelRUser
      */
     success?: RUser;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRUserCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRVaccine
  */
 export interface RModelRVaccine {
     /**
      * 
      * @type {string}
      * @memberof RModelRVaccine
      */
     code?: RModelRVaccineCodeEnum;
     /**
      * 
      * @type {MessageRVaccine}
      * @memberof RModelRVaccine
      */
     failure?: MessageRVaccine;
     /**
      * 
      * @type {RVaccine}
      * @memberof RModelRVaccine
      */
     success?: RVaccine;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRVaccineCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelRVaccineInjectData
  */
 export interface RModelRVaccineInjectData {
     /**
      * 
      * @type {string}
      * @memberof RModelRVaccineInjectData
      */
     code?: RModelRVaccineInjectDataCodeEnum;
     /**
      * 
      * @type {MessageRVaccineInjectData}
      * @memberof RModelRVaccineInjectData
      */
     failure?: MessageRVaccineInjectData;
     /**
      * 
      * @type {RVaccineInjectData}
      * @memberof RModelRVaccineInjectData
      */
     success?: RVaccineInjectData;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelRVaccineInjectDataCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelString
  */
 export interface RModelString {
     /**
      * 
      * @type {string}
      * @memberof RModelString
      */
     code?: RModelStringCodeEnum;
     /**
      * 
      * @type {MessageString}
      * @memberof RModelString
      */
     failure?: MessageString;
     /**
      * 
      * @type {string}
      * @memberof RModelString
      */
     success?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelStringCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModelUserCollection
  */
 export interface RModelUserCollection {
     /**
      * 
      * @type {string}
      * @memberof RModelUserCollection
      */
     code?: RModelUserCollectionCodeEnum;
     /**
      * 
      * @type {MessageUserCollection}
      * @memberof RModelUserCollection
      */
     failure?: MessageUserCollection;
     /**
      * 
      * @type {UserCollection}
      * @memberof RModelUserCollection
      */
     success?: UserCollection;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RModelUserCollectionCodeEnum {
     Success = 'Success',
     Failure = 'Failure'
 }
 
 /**
  * 
  * @export
  * @interface RModule
  */
 export interface RModule {
     /**
      * 
      * @type {string}
      * @memberof RModule
      */
     access?: string;
     /**
      * 
      * @type {string}
      * @memberof RModule
      */
     accessButton?: string;
     /**
      * 
      * @type {string}
      * @memberof RModule
      */
     centerId?: string;
     /**
      * 
      * @type {string}
      * @memberof RModule
      */
     name?: string;
     /**
      * 
      * @type {number}
      * @memberof RModule
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RModule
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RModule
      */
     id?: string;
     /**
      * 
      * @type {string}
      * @memberof RModule
      */
     centerName?: string;
 }
 /**
  * 
  * @export
  * @interface ROrganisation
  */
 export interface ROrganisation {
     /**
      * 
      * @type {string}
      * @memberof ROrganisation
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof ROrganisation
      */
     icon?: string;
     /**
      * 
      * @type {string}
      * @memberof ROrganisation
      */
     pointOfContact?: string;
     /**
      * 
      * @type {string}
      * @memberof ROrganisation
      */
     contact?: string;
     /**
      * 
      * @type {number}
      * @memberof ROrganisation
      */
     storage?: number;
     /**
      * 
      * @type {string}
      * @memberof ROrganisation
      */
     slug?: string;
     /**
      * 
      * @type {string}
      * @memberof ROrganisation
      */
     lisSupport?: string;
     /**
      * 
      * @type {number}
      * @memberof ROrganisation
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof ROrganisation
      */
     createdAt?: number;
     /**
      * 
      * @type {Array<string>}
      * @memberof ROrganisation
      */
     services?: Array<string>;
     /**
      * 
      * @type {string}
      * @memberof ROrganisation
      */
     id?: string;
     /**
      * 
      * @type {RUser}
      * @memberof ROrganisation
      */
     user?: RUser;
 }
 /**
  * 
  * @export
  * @interface RPatient
  */
 export interface RPatient {
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     hospitalId?: string;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     age?: string;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     gender?: RPatientGenderEnum;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     phone?: string;
     /**
      * 
      * @type {Address}
      * @memberof RPatient
      */
     address?: Address;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     centerId?: string;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     type?: RPatientTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     patientType?: RPatientPatientTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     status?: RPatientStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     tripId?: string;
     /**
      * 
      * @type {number}
      * @memberof RPatient
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RPatient
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RPatient
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RPatientGenderEnum {
     Male = 'Male',
     Female = 'Female',
     Other = 'Other'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RPatientTypeEnum {
     IP = 'IP',
     OP = 'OP'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RPatientPatientTypeEnum {
     IP = 'IP',
     OP = 'OP'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RPatientStatusEnum {
     Upcoming = 'Upcoming',
     Completed = 'Completed',
     ND = 'ND'
 }
 
 /**
  * 
  * @export
  * @interface RPouch
  */
 export interface RPouch {
     /**
      * 
      * @type {Array<FileItem>}
      * @memberof RPouch
      */
     files?: Array<FileItem>;
     /**
      * 
      * @type {string}
      * @memberof RPouch
      */
     batchNumber?: string;
     /**
      * 
      * @type {string}
      * @memberof RPouch
      */
     barcode?: string;
     /**
      * 
      * @type {string}
      * @memberof RPouch
      */
     vaccineId?: string;
     /**
      * 
      * @type {number}
      * @memberof RPouch
      */
     used?: number;
     /**
      * 
      * @type {number}
      * @memberof RPouch
      */
     remaining?: number;
     /**
      * 
      * @type {string}
      * @memberof RPouch
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RPouch
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RPouch
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RPouch
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface RPowerDashboard
  */
 export interface RPowerDashboard {
     /**
      * 
      * @type {string}
      * @memberof RPowerDashboard
      */
     samples?: string;
     /**
      * 
      * @type {number}
      * @memberof RPowerDashboard
      */
     sampleCounts?: number;
     /**
      * 
      * @type {string}
      * @memberof RPowerDashboard
      */
     storage?: string;
     /**
      * 
      * @type {number}
      * @memberof RPowerDashboard
      */
     fileCounts?: number;
 }
 /**
  * 
  * @export
  * @interface RRecord
  */
 export interface RRecord {
     /**
      * 
      * @type {string}
      * @memberof RRecord
      */
     tripId?: string;
     /**
      * 
      * @type {string}
      * @memberof RRecord
      */
     type?: RRecordTypeEnum;
     /**
      * 
      * @type {number}
      * @memberof RRecord
      */
     chamber1?: number;
     /**
      * 
      * @type {number}
      * @memberof RRecord
      */
     chamber2?: number;
     /**
      * 
      * @type {number}
      * @memberof RRecord
      */
     chamber3?: number;
     /**
      * 
      * @type {number}
      * @memberof RRecord
      */
     time?: number;
     /**
      * 
      * @type {string}
      * @memberof RRecord
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RRecord
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RRecord
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RRecord
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RRecordTypeEnum {
     Hospital = 'Hospital',
     Home = 'Home',
     Lab = 'Lab',
     CovidOnsite = 'CovidOnsite',
     CovidOffsite = 'CovidOffsite'
 }
 
 /**
  * 
  * @export
  * @interface RRoute
  */
 export interface RRoute {
     /**
      * 
      * @type {string}
      * @memberof RRoute
      */
     name?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RRoute
      */
     labIds?: Array<string>;
     /**
      * 
      * @type {number}
      * @memberof RRoute
      */
     noOfSamples?: number;
     /**
      * 
      * @type {number}
      * @memberof RRoute
      */
     distanceCovered?: number;
     /**
      * 
      * @type {number}
      * @memberof RRoute
      */
     travelTime?: number;
     /**
      * 
      * @type {string}
      * @memberof RRoute
      */
     status?: RRouteStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RRoute
      */
     carrierId?: string;
     /**
      * 
      * @type {string}
      * @memberof RRoute
      */
     carrierName?: string;
     /**
      * 
      * @type {string}
      * @memberof RRoute
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RRoute
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RRoute
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RRoute
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RRouteStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Paused = 'Paused',
     Completed = 'Completed'
 }
 
 /**
  * 
  * @export
  * @interface RSample
  */
 export interface RSample {
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     type?: RSampleTypeEnum;
     /**
      * 
      * @type {number}
      * @memberof RSample
      */
     order?: number;
     /**
      * 
      * @type {number}
      * @memberof RSample
      */
     tripOrder?: number;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     sampleType?: string;
     /**
      * 
      * @type {boolean}
      * @memberof RSample
      */
     testTube?: boolean;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     serviceCode?: string;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     serviceNo?: string;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     serviceType?: string;
     /**
      * 
      * @type {boolean}
      * @memberof RSample
      */
     fasting?: boolean;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     price?: string;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     duration?: string;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     maxTemp?: string;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     minTemp?: string;
     /**
      * 
      * @type {boolean}
      * @memberof RSample
      */
     group?: boolean;
     /**
      * 
      * @type {Array<string>}
      * @memberof RSample
      */
     ids?: Array<string>;
     /**
      * 
      * @type {number}
      * @memberof RSample
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RSample
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RSample
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RSampleTypeEnum {
     Red = 'Red',
     Yellow = 'Yellow',
     Purple = 'Purple',
     Green = 'Green',
     Blue = 'Blue',
     Grey = 'Grey',
     Urine = 'Urine'
 }
 
 /**
  * 
  * @export
  * @interface RShipment
  */
 export interface RShipment {
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     tripId?: string;
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     type?: RShipmentTypeEnum;
     /**
      * 
      * @type {number}
      * @memberof RShipment
      */
     tripDate?: number;
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     startTime?: string;
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     endTime?: string;
     /**
      * 
      * @type {number}
      * @memberof RShipment
      */
     numberOfSamples?: number;
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     pdfURL?: string;
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RShipment
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RShipment
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RShipment
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RShipmentTypeEnum {
     Hospital = 'Hospital',
     Home = 'Home',
     Lab = 'Lab',
     CovidOnsite = 'CovidOnsite',
     CovidOffsite = 'CovidOffsite'
 }
 
 /**
  * 
  * @export
  * @interface RTableRAccess
  */
 export interface RTableRAccess {
     /**
      * 
      * @type {string}
      * @memberof RTableRAccess
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRAccess
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRAccess
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRAccess
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRAccess
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRAccess
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RAccess>}
      * @memberof RTableRAccess
      */
     data?: Array<RAccess>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRAccess
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRBag
  */
 export interface RTableRBag {
     /**
      * 
      * @type {string}
      * @memberof RTableRBag
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRBag
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRBag
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRBag
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRBag
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRBag
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RBag>}
      * @memberof RTableRBag
      */
     data?: Array<RBag>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRBag
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRCarrier
  */
 export interface RTableRCarrier {
     /**
      * 
      * @type {string}
      * @memberof RTableRCarrier
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCarrier
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCarrier
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCarrier
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRCarrier
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRCarrier
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RCarrier>}
      * @memberof RTableRCarrier
      */
     data?: Array<RCarrier>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRCarrier
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRCovidOffsite
  */
 export interface RTableRCovidOffsite {
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsite
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsite
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsite
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsite
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRCovidOffsite
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRCovidOffsite
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RCovidOffsite>}
      * @memberof RTableRCovidOffsite
      */
     data?: Array<RCovidOffsite>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRCovidOffsite
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRCovidOffsiteTrip
  */
 export interface RTableRCovidOffsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsiteTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsiteTrip
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsiteTrip
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOffsiteTrip
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRCovidOffsiteTrip
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRCovidOffsiteTrip
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RCovidOffsiteTrip>}
      * @memberof RTableRCovidOffsiteTrip
      */
     data?: Array<RCovidOffsiteTrip>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRCovidOffsiteTrip
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRCovidOnsiteTrip
  */
 export interface RTableRCovidOnsiteTrip {
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOnsiteTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOnsiteTrip
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOnsiteTrip
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidOnsiteTrip
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRCovidOnsiteTrip
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRCovidOnsiteTrip
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RCovidOnsiteTrip>}
      * @memberof RTableRCovidOnsiteTrip
      */
     data?: Array<RCovidOnsiteTrip>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRCovidOnsiteTrip
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRCovidPatient
  */
 export interface RTableRCovidPatient {
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidPatient
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidPatient
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidPatient
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRCovidPatient
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRCovidPatient
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRCovidPatient
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RCovidPatient>}
      * @memberof RTableRCovidPatient
      */
     data?: Array<RCovidPatient>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRCovidPatient
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRHomeTrip
  */
 export interface RTableRHomeTrip {
     /**
      * 
      * @type {string}
      * @memberof RTableRHomeTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHomeTrip
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHomeTrip
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHomeTrip
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRHomeTrip
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRHomeTrip
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RHomeTrip>}
      * @memberof RTableRHomeTrip
      */
     data?: Array<RHomeTrip>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRHomeTrip
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRHospital
  */
 export interface RTableRHospital {
     /**
      * 
      * @type {string}
      * @memberof RTableRHospital
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHospital
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHospital
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHospital
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRHospital
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRHospital
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RHospital>}
      * @memberof RTableRHospital
      */
     data?: Array<RHospital>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRHospital
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRHospitalTrip
  */
 export interface RTableRHospitalTrip {
     /**
      * 
      * @type {string}
      * @memberof RTableRHospitalTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHospitalTrip
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHospitalTrip
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRHospitalTrip
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRHospitalTrip
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRHospitalTrip
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RHospitalTrip>}
      * @memberof RTableRHospitalTrip
      */
     data?: Array<RHospitalTrip>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRHospitalTrip
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRLab
  */
 export interface RTableRLab {
     /**
      * 
      * @type {string}
      * @memberof RTableRLab
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRLab
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRLab
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRLab
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRLab
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRLab
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RLab>}
      * @memberof RTableRLab
      */
     data?: Array<RLab>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRLab
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRLabTrip
  */
 export interface RTableRLabTrip {
     /**
      * 
      * @type {string}
      * @memberof RTableRLabTrip
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRLabTrip
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRLabTrip
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRLabTrip
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRLabTrip
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRLabTrip
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RLabTrip>}
      * @memberof RTableRLabTrip
      */
     data?: Array<RLabTrip>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRLabTrip
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRMainCenter
  */
 export interface RTableRMainCenter {
     /**
      * 
      * @type {string}
      * @memberof RTableRMainCenter
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRMainCenter
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRMainCenter
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRMainCenter
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRMainCenter
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRMainCenter
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RMainCenter>}
      * @memberof RTableRMainCenter
      */
     data?: Array<RMainCenter>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRMainCenter
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRModule
  */
 export interface RTableRModule {
     /**
      * 
      * @type {string}
      * @memberof RTableRModule
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRModule
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRModule
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRModule
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRModule
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRModule
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RModule>}
      * @memberof RTableRModule
      */
     data?: Array<RModule>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRModule
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableROrganisation
  */
 export interface RTableROrganisation {
     /**
      * 
      * @type {string}
      * @memberof RTableROrganisation
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableROrganisation
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableROrganisation
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableROrganisation
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableROrganisation
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableROrganisation
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<ROrganisation>}
      * @memberof RTableROrganisation
      */
     data?: Array<ROrganisation>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableROrganisation
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRPatient
  */
 export interface RTableRPatient {
     /**
      * 
      * @type {string}
      * @memberof RTableRPatient
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRPatient
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRPatient
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRPatient
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRPatient
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRPatient
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RPatient>}
      * @memberof RTableRPatient
      */
     data?: Array<RPatient>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRPatient
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRRoute
  */
 export interface RTableRRoute {
     /**
      * 
      * @type {string}
      * @memberof RTableRRoute
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRRoute
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRRoute
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRRoute
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRRoute
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRRoute
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RRoute>}
      * @memberof RTableRRoute
      */
     data?: Array<RRoute>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRRoute
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRSample
  */
 export interface RTableRSample {
     /**
      * 
      * @type {string}
      * @memberof RTableRSample
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRSample
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRSample
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRSample
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRSample
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRSample
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RSample>}
      * @memberof RTableRSample
      */
     data?: Array<RSample>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRSample
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRShipment
  */
 export interface RTableRShipment {
     /**
      * 
      * @type {string}
      * @memberof RTableRShipment
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRShipment
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRShipment
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRShipment
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRShipment
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRShipment
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RShipment>}
      * @memberof RTableRShipment
      */
     data?: Array<RShipment>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRShipment
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRTestCollection
  */
 export interface RTableRTestCollection {
     /**
      * 
      * @type {string}
      * @memberof RTableRTestCollection
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRTestCollection
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRTestCollection
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRTestCollection
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRTestCollection
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRTestCollection
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RTestCollection>}
      * @memberof RTableRTestCollection
      */
     data?: Array<RTestCollection>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRTestCollection
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRUser
  */
 export interface RTableRUser {
     /**
      * 
      * @type {string}
      * @memberof RTableRUser
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRUser
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRUser
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRUser
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRUser
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRUser
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RUser>}
      * @memberof RTableRUser
      */
     data?: Array<RUser>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRUser
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRVaccine
  */
 export interface RTableRVaccine {
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccine
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccine
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccine
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccine
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRVaccine
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRVaccine
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RVaccine>}
      * @memberof RTableRVaccine
      */
     data?: Array<RVaccine>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRVaccine
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTableRVaccineInjectData
  */
 export interface RTableRVaccineInjectData {
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccineInjectData
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccineInjectData
      */
     title?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccineInjectData
      */
     titleBackground?: string;
     /**
      * 
      * @type {string}
      * @memberof RTableRVaccineInjectData
      */
     headerColor?: string;
     /**
      * 
      * @type {Array<Filter>}
      * @memberof RTableRVaccineInjectData
      */
     filters?: Array<Filter>;
     /**
      * 
      * @type {Array<Header>}
      * @memberof RTableRVaccineInjectData
      */
     headers?: Array<Header>;
     /**
      * 
      * @type {Array<RVaccineInjectData>}
      * @memberof RTableRVaccineInjectData
      */
     data?: Array<RVaccineInjectData>;
     /**
      * 
      * @type {boolean}
      * @memberof RTableRVaccineInjectData
      */
     access?: boolean;
 }
 /**
  * 
  * @export
  * @interface RTestCollection
  */
 export interface RTestCollection {
     /**
      * 
      * @type {string}
      * @memberof RTestCollection
      */
     bookingId?: string;
     /**
      * 
      * @type {number}
      * @memberof RTestCollection
      */
     price?: number;
     /**
      * 
      * @type {number}
      * @memberof RTestCollection
      */
     userId?: number;
     /**
      * 
      * @type {string}
      * @memberof RTestCollection
      */
     tripId?: string;
     /**
      * 
      * @type {string}
      * @memberof RTestCollection
      */
     tripType?: RTestCollectionTripTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof RTestCollection
      */
     patient?: string;
     /**
      * 
      * @type {string}
      * @memberof RTestCollection
      */
     number?: string;
     /**
      * 
      * @type {Array<string>}
      * @memberof RTestCollection
      */
     group?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RTestCollection
      */
     testCollection?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RTestCollection
      */
     sampleType?: Array<string>;
     /**
      * 
      * @type {Array<string>}
      * @memberof RTestCollection
      */
     barCollection?: Array<string>;
     /**
      * 
      * @type {TestTubes}
      * @memberof RTestCollection
      */
     testTubes?: TestTubes;
     /**
      * 
      * @type {number}
      * @memberof RTestCollection
      */
     noOfTests?: number;
     /**
      * 
      * @type {boolean}
      * @memberof RTestCollection
      */
     status?: boolean;
     /**
      * 
      * @type {boolean}
      * @memberof RTestCollection
      */
     allocated?: boolean;
     /**
      * 
      * @type {string}
      * @memberof RTestCollection
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RTestCollection
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RTestCollection
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RTestCollection
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RTestCollectionTripTypeEnum {
     IP = 'IP',
     OP = 'OP'
 }
 
 /**
  * 
  * @export
  * @interface RTracker
  */
 export interface RTracker {
     /**
      * 
      * @type {Array<RLRecord>}
      * @memberof RTracker
      */
     locations?: Array<RLRecord>;
     /**
      * 
      * @type {Array<RRecord>}
      * @memberof RTracker
      */
     temperatures?: Array<RRecord>;
 }
 /**
  * 
  * @export
  * @interface RTrip
  */
 export interface RTrip {
     /**
      * 
      * @type {string}
      * @memberof RTrip
      */
     id?: string;
     /**
      * 
      * @type {string}
      * @memberof RTrip
      */
     name?: string;
     /**
      * 
      * @type {number}
      * @memberof RTrip
      */
     startTime?: number;
     /**
      * 
      * @type {number}
      * @memberof RTrip
      */
     count?: number;
     /**
      * 
      * @type {RTestCollection}
      * @memberof RTrip
      */
     testCollection?: RTestCollection;
 }
 /**
  * 
  * @export
  * @interface RUser
  */
 export interface RUser {
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     firstName?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     lastName?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     profile?: string;
     /**
      * 
      * @type {number}
      * @memberof RUser
      */
     userNo?: number;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     gender?: RUserGenderEnum;
     /**
      * 
      * @type {Address}
      * @memberof RUser
      */
     address?: Address;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     email?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     phone?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     password?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     role?: RUserRoleEnum;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     status?: RUserStatusEnum;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     flow?: RUserFlowEnum;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     centerId?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     centerName?: string;
     /**
      * 
      * @type {number}
      * @memberof RUser
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RUser
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     token?: string;
     /**
      * 
      * @type {string}
      * @memberof RUser
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RUserGenderEnum {
     Male = 'Male',
     Female = 'Female',
     Other = 'Other'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RUserRoleEnum {
     PowerAdmin = 'PowerAdmin',
     SuperAdmin = 'SuperAdmin',
     Admin = 'Admin',
     Phlebotomist = 'Phlebotomist',
     FieldExecutive = 'FieldExecutive'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RUserStatusEnum {
     Active = 'Active',
     InActive = 'InActive',
     Suspended = 'Suspended'
 }
 /**
     * @export
     * @enum {string}
     */
 export enum RUserFlowEnum {
     Hospital = 'Hospital',
     Home = 'Home',
     Lab = 'Lab',
     CovidOnsite = 'CovidOnsite',
     CovidOffsite = 'CovidOffsite'
 }
 
 /**
  * 
  * @export
  * @interface RVaccine
  */
 export interface RVaccine {
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     name?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     type?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     created?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     price?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     duration?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     maxTemp?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     minTemp?: string;
     /**
      * 
      * @type {number}
      * @memberof RVaccine
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RVaccine
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RVaccine
      */
     id?: string;
 }
 /**
  * 
  * @export
  * @interface RVaccineInjectData
  */
 export interface RVaccineInjectData {
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     bookingId?: string;
     /**
      * 
      * @type {number}
      * @memberof RVaccineInjectData
      */
     price?: number;
     /**
      * 
      * @type {number}
      * @memberof RVaccineInjectData
      */
     userId?: number;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     tripId?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     tripType?: RVaccineInjectDataTripTypeEnum;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     patient?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     number?: string;
     /**
      * 
      * @type {Array<FileItem>}
      * @memberof RVaccineInjectData
      */
     files?: Array<FileItem>;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     batchNumber?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     barcode?: string;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     vaccineId?: string;
     /**
      * 
      * @type {boolean}
      * @memberof RVaccineInjectData
      */
     status?: boolean;
     /**
      * 
      * @type {boolean}
      * @memberof RVaccineInjectData
      */
     allocated?: boolean;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     centerId?: string;
     /**
      * 
      * @type {number}
      * @memberof RVaccineInjectData
      */
     updatedAt?: number;
     /**
      * 
      * @type {number}
      * @memberof RVaccineInjectData
      */
     createdAt?: number;
     /**
      * 
      * @type {string}
      * @memberof RVaccineInjectData
      */
     id?: string;
 }
 
 /**
     * @export
     * @enum {string}
     */
 export enum RVaccineInjectDataTripTypeEnum {
     IP = 'IP',
     OP = 'OP'
 }
 
 /**
  * 
  * @export
  * @interface TestTubes
  */
 export interface TestTubes {
     /**
      * 
      * @type {string}
      * @memberof TestTubes
      */
     red?: string;
     /**
      * 
      * @type {string}
      * @memberof TestTubes
      */
     blue?: string;
     /**
      * 
      * @type {string}
      * @memberof TestTubes
      */
     green?: string;
     /**
      * 
      * @type {string}
      * @memberof TestTubes
      */
     yellow?: string;
     /**
      * 
      * @type {string}
      * @memberof TestTubes
      */
     purple?: string;
     /**
      * 
      * @type {string}
      * @memberof TestTubes
      */
     grey?: string;
     /**
      * 
      * @type {string}
      * @memberof TestTubes
      */
     urine?: string;
 }
 /**
  * 
  * @export
  * @interface UserCollection
  */
 export interface UserCollection {
     /**
      * 
      * @type {Array<RUser>}
      * @memberof UserCollection
      */
     admins?: Array<RUser>;
     /**
      * 
      * @type {Array<RUser>}
      * @memberof UserCollection
      */
     carriers?: Array<RUser>;
 }
 
 /**
  * DefaultApi - axios parameter creator
  * @export
  */
 export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Call Logs API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [org] 
          * @param {number} [time] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         callLogs: async (apiKey: string, secretKey: string, authToken: string, centerID: string, org?: string, time?: number, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling callLogs.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling callLogs.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling callLogs.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling callLogs.');
             }
             const localVarPath = `/org/data/logs/call/history`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (org !== undefined) {
                 localVarQueryParameter['org'] = org;
             }
 
             if (time !== undefined) {
                 localVarQueryParameter['time'] = time;
             }
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Change Password of the User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {Login} login Account used to identify the user.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         changePassword: async (apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling changePassword.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling changePassword.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling changePassword.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling changePassword.');
             }
             // verify required parameter 'login' is not null or undefined
             if (login === null || login === undefined) {
                 throw new RequiredError('login','Required parameter login was null or undefined when calling changePassword.');
             }
             const localVarPath = `/org/user/change_password`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof login !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(login !== undefined ? login : {}) : (login || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RBag} rBag Bag Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createBag: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createBag.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createBag.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createBag.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createBag.');
             }
             // verify required parameter 'rBag' is not null or undefined
             if (rBag === null || rBag === undefined) {
                 throw new RequiredError('rBag','Required parameter rBag was null or undefined when calling createBag.');
             }
             const localVarPath = `/org/bag/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rBag !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rBag !== undefined ? rBag : {}) : (rBag || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCarrier} rCarrier Carrier Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCarrier: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createCarrier.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createCarrier.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createCarrier.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createCarrier.');
             }
             // verify required parameter 'rCarrier' is not null or undefined
             if (rCarrier === null || rCarrier === undefined) {
                 throw new RequiredError('rCarrier','Required parameter rCarrier was null or undefined when calling createCarrier.');
             }
             const localVarPath = `/org/carrier/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCarrier !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCarrier !== undefined ? rCarrier : {}) : (rCarrier || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidOffsite: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createCovidOffsite.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createCovidOffsite.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createCovidOffsite.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createCovidOffsite.');
             }
             // verify required parameter 'rCovidOffsite' is not null or undefined
             if (rCovidOffsite === null || rCovidOffsite === undefined) {
                 throw new RequiredError('rCovidOffsite','Required parameter rCovidOffsite was null or undefined when calling createCovidOffsite.');
             }
             const localVarPath = `/org/covid_offsite/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidOffsite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidOffsite !== undefined ? rCovidOffsite : {}) : (rCovidOffsite || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidOffsiteTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createCovidOffsiteTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createCovidOffsiteTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createCovidOffsiteTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createCovidOffsiteTrip.');
             }
             // verify required parameter 'rCovidOffsiteTrip' is not null or undefined
             if (rCovidOffsiteTrip === null || rCovidOffsiteTrip === undefined) {
                 throw new RequiredError('rCovidOffsiteTrip','Required parameter rCovidOffsiteTrip was null or undefined when calling createCovidOffsiteTrip.');
             }
             const localVarPath = `/org/covid_offsite_trip/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidOffsiteTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidOffsiteTrip !== undefined ? rCovidOffsiteTrip : {}) : (rCovidOffsiteTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidOnsiteTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createCovidOnsiteTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createCovidOnsiteTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createCovidOnsiteTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createCovidOnsiteTrip.');
             }
             // verify required parameter 'rCovidOnsiteTrip' is not null or undefined
             if (rCovidOnsiteTrip === null || rCovidOnsiteTrip === undefined) {
                 throw new RequiredError('rCovidOnsiteTrip','Required parameter rCovidOnsiteTrip was null or undefined when calling createCovidOnsiteTrip.');
             }
             const localVarPath = `/org/covid_onsite_trip/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidOnsiteTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidOnsiteTrip !== undefined ? rCovidOnsiteTrip : {}) : (rCovidOnsiteTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidPatient} rCovidPatient CovidPatient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidPatient: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createCovidPatient.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createCovidPatient.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createCovidPatient.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createCovidPatient.');
             }
             // verify required parameter 'rCovidPatient' is not null or undefined
             if (rCovidPatient === null || rCovidPatient === undefined) {
                 throw new RequiredError('rCovidPatient','Required parameter rCovidPatient was null or undefined when calling createCovidPatient.');
             }
             const localVarPath = `/org/covid_patient/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidPatient !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidPatient !== undefined ? rCovidPatient : {}) : (rCovidPatient || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [slug] 
          * @param {FormDataContentDisposition} [file] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createFile: async (apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, file?: FormDataContentDisposition, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createFile.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createFile.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createFile.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createFile.');
             }
             const localVarPath = `/org/file/create/single`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
             const localVarFormParams = new FormData();
 
             if (slug !== undefined) {
                 localVarQueryParameter['slug'] = slug;
             }
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
             if (file !== undefined) { 
                 localVarFormParams.append('file', file as any);
             }
     
     
             localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             localVarRequestOptions.data = localVarFormParams;
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [slug] 
          * @param {ContentDisposition} [contentDisposition] 
          * @param {object} [entity] 
          * @param {BodyPartMediaType} [mediaType] 
          * @param {object} [messageBodyWorkers] 
          * @param {MultiPart} [parent] 
          * @param {object} [providers] 
          * @param {Array<BodyPart>} [bodyParts] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createFiles: async (apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, contentDisposition?: ContentDisposition, entity?: object, mediaType?: BodyPartMediaType, messageBodyWorkers?: object, parent?: MultiPart, providers?: object, bodyParts?: Array<BodyPart>, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createFiles.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createFiles.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createFiles.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createFiles.');
             }
             const localVarPath = `/org/file/create/multiple`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
             const localVarFormParams = new FormData();
 
             if (slug !== undefined) {
                 localVarQueryParameter['slug'] = slug;
             }
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
             if (contentDisposition !== undefined) { 
                 localVarFormParams.append('contentDisposition', contentDisposition as any);
             }
     
             if (entity !== undefined) { 
                 localVarFormParams.append('entity', entity as any);
             }
     
             if (mediaType !== undefined) { 
                 localVarFormParams.append('mediaType', mediaType as any);
             }
     
             if (messageBodyWorkers !== undefined) { 
                 localVarFormParams.append('messageBodyWorkers', messageBodyWorkers as any);
             }
     
             if (parent !== undefined) { 
                 localVarFormParams.append('parent', parent as any);
             }
     
             if (providers !== undefined) { 
                 localVarFormParams.append('providers', providers as any);
             }
                 if (bodyParts) {
             
                 localVarFormParams.append('bodyParts', bodyParts.join(COLLECTION_FORMATS.csv));
             }
     
     
             localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             localVarRequestOptions.data = localVarFormParams;
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHomeTrip} rHomeTrip HomeTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createHomeTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createHomeTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createHomeTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createHomeTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createHomeTrip.');
             }
             // verify required parameter 'rHomeTrip' is not null or undefined
             if (rHomeTrip === null || rHomeTrip === undefined) {
                 throw new RequiredError('rHomeTrip','Required parameter rHomeTrip was null or undefined when calling createHomeTrip.');
             }
             const localVarPath = `/org/home_trip/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rHomeTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rHomeTrip !== undefined ? rHomeTrip : {}) : (rHomeTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospital} rHospital Hospital Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createHospital: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createHospital.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createHospital.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createHospital.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createHospital.');
             }
             // verify required parameter 'rHospital' is not null or undefined
             if (rHospital === null || rHospital === undefined) {
                 throw new RequiredError('rHospital','Required parameter rHospital was null or undefined when calling createHospital.');
             }
             const localVarPath = `/org/hospital/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rHospital !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rHospital !== undefined ? rHospital : {}) : (rHospital || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createHospitalTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createHospitalTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createHospitalTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createHospitalTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createHospitalTrip.');
             }
             // verify required parameter 'rHospitalTrip' is not null or undefined
             if (rHospitalTrip === null || rHospitalTrip === undefined) {
                 throw new RequiredError('rHospitalTrip','Required parameter rHospitalTrip was null or undefined when calling createHospitalTrip.');
             }
             const localVarPath = `/org/hospital_trip/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rHospitalTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rHospitalTrip !== undefined ? rHospitalTrip : {}) : (rHospitalTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLab} rLab Lab Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createLab: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createLab.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createLab.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createLab.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createLab.');
             }
             // verify required parameter 'rLab' is not null or undefined
             if (rLab === null || rLab === undefined) {
                 throw new RequiredError('rLab','Required parameter rLab was null or undefined when calling createLab.');
             }
             const localVarPath = `/org/lab/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rLab !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rLab !== undefined ? rLab : {}) : (rLab || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabCollection} rLabCollection LabCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createLabCollection: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createLabCollection.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createLabCollection.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createLabCollection.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createLabCollection.');
             }
             // verify required parameter 'rLabCollection' is not null or undefined
             if (rLabCollection === null || rLabCollection === undefined) {
                 throw new RequiredError('rLabCollection','Required parameter rLabCollection was null or undefined when calling createLabCollection.');
             }
             const localVarPath = `/org/lab_collection/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rLabCollection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rLabCollection !== undefined ? rLabCollection : {}) : (rLabCollection || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabTrip} rLabTrip LabTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createLabTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createLabTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createLabTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createLabTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createLabTrip.');
             }
             // verify required parameter 'rLabTrip' is not null or undefined
             if (rLabTrip === null || rLabTrip === undefined) {
                 throw new RequiredError('rLabTrip','Required parameter rLabTrip was null or undefined when calling createLabTrip.');
             }
             const localVarPath = `/org/lab_trip/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rLabTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rLabTrip !== undefined ? rLabTrip : {}) : (rLabTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createMainCenter: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createMainCenter.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createMainCenter.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createMainCenter.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createMainCenter.');
             }
             // verify required parameter 'rMainCenter' is not null or undefined
             if (rMainCenter === null || rMainCenter === undefined) {
                 throw new RequiredError('rMainCenter','Required parameter rMainCenter was null or undefined when calling createMainCenter.');
             }
             const localVarPath = `/org/main_center/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rMainCenter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rMainCenter !== undefined ? rMainCenter : {}) : (rMainCenter || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPatient} rPatient Patient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPatient: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createPatient.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createPatient.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createPatient.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createPatient.');
             }
             // verify required parameter 'rPatient' is not null or undefined
             if (rPatient === null || rPatient === undefined) {
                 throw new RequiredError('rPatient','Required parameter rPatient was null or undefined when calling createPatient.');
             }
             const localVarPath = `/org/patient/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rPatient !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rPatient !== undefined ? rPatient : {}) : (rPatient || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPouch} rPouch Pouch Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPouch: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createPouch.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createPouch.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createPouch.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createPouch.');
             }
             // verify required parameter 'rPouch' is not null or undefined
             if (rPouch === null || rPouch === undefined) {
                 throw new RequiredError('rPouch','Required parameter rPouch was null or undefined when calling createPouch.');
             }
             const localVarPath = `/org/pouch/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rPouch !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rPouch !== undefined ? rPouch : {}) : (rPouch || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPowerMainCenter: async (apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createPowerMainCenter.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createPowerMainCenter.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createPowerMainCenter.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createPowerMainCenter.');
             }
             // verify required parameter 'slug' is not null or undefined
             if (slug === null || slug === undefined) {
                 throw new RequiredError('slug','Required parameter slug was null or undefined when calling createPowerMainCenter.');
             }
             // verify required parameter 'rMainCenter' is not null or undefined
             if (rMainCenter === null || rMainCenter === undefined) {
                 throw new RequiredError('rMainCenter','Required parameter rMainCenter was null or undefined when calling createPowerMainCenter.');
             }
             const localVarPath = `/org/power/maincenter/create/{slug}`
                 .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rMainCenter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rMainCenter !== undefined ? rMainCenter : {}) : (rMainCenter || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPowerUser: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createPowerUser.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createPowerUser.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createPowerUser.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createPowerUser.');
             }
             // verify required parameter 'rUser' is not null or undefined
             if (rUser === null || rUser === undefined) {
                 throw new RequiredError('rUser','Required parameter rUser was null or undefined when calling createPowerUser.');
             }
             const localVarPath = `/org/power/user/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rUser !== undefined ? rUser : {}) : (rUser || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Record
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTracker} rTracker Bag temperature logs
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createRecords: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rTracker: RTracker, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createRecords.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createRecords.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createRecords.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createRecords.');
             }
             // verify required parameter 'rTracker' is not null or undefined
             if (rTracker === null || rTracker === undefined) {
                 throw new RequiredError('rTracker','Required parameter rTracker was null or undefined when calling createRecords.');
             }
             const localVarPath = `/org/record/create/multiple`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rTracker !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rTracker !== undefined ? rTracker : {}) : (rTracker || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RRoute} rRoute Route Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createRoute: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createRoute.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createRoute.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createRoute.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createRoute.');
             }
             // verify required parameter 'rRoute' is not null or undefined
             if (rRoute === null || rRoute === undefined) {
                 throw new RequiredError('rRoute','Required parameter rRoute was null or undefined when calling createRoute.');
             }
             const localVarPath = `/org/route/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rRoute !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rRoute !== undefined ? rRoute : {}) : (rRoute || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RSample} rSample Sample Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createSample: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createSample.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createSample.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createSample.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createSample.');
             }
             // verify required parameter 'rSample' is not null or undefined
             if (rSample === null || rSample === undefined) {
                 throw new RequiredError('rSample','Required parameter rSample was null or undefined when calling createSample.');
             }
             const localVarPath = `/org/sample/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rSample !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rSample !== undefined ? rSample : {}) : (rSample || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {string} tripType 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createShipment: async (apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, version: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createShipment.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createShipment.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createShipment.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createShipment.');
             }
             // verify required parameter 'tripId' is not null or undefined
             if (tripId === null || tripId === undefined) {
                 throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling createShipment.');
             }
             // verify required parameter 'tripType' is not null or undefined
             if (tripType === null || tripType === undefined) {
                 throw new RequiredError('tripType','Required parameter tripType was null or undefined when calling createShipment.');
             }
             // verify required parameter 'version' is not null or undefined
             if (version === null || version === undefined) {
                 throw new RequiredError('version','Required parameter version was null or undefined when calling createShipment.');
             }
             const localVarPath = `/org/shipment/create/{tripId}/{tripType}/{version}`
                 .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)))
                 .replace(`{${"tripType"}}`, encodeURIComponent(String(tripType)))
                 .replace(`{${"version"}}`, encodeURIComponent(String(version)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RShipment} rShipment Shipment Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createShipment1: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createShipment1.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createShipment1.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createShipment1.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createShipment1.');
             }
             // verify required parameter 'rShipment' is not null or undefined
             if (rShipment === null || rShipment === undefined) {
                 throw new RequiredError('rShipment','Required parameter rShipment was null or undefined when calling createShipment1.');
             }
             const localVarPath = `/org/shipment/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rShipment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rShipment !== undefined ? rShipment : {}) : (rShipment || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {string} tripType 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createShipment2: async (apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createShipment2.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createShipment2.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createShipment2.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createShipment2.');
             }
             // verify required parameter 'tripId' is not null or undefined
             if (tripId === null || tripId === undefined) {
                 throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling createShipment2.');
             }
             // verify required parameter 'tripType' is not null or undefined
             if (tripType === null || tripType === undefined) {
                 throw new RequiredError('tripType','Required parameter tripType was null or undefined when calling createShipment2.');
             }
             const localVarPath = `/org/shipment/create/{tripId}/{tripType}`
                 .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)))
                 .replace(`{${"tripType"}}`, encodeURIComponent(String(tripType)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createTestCollection: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createTestCollection.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createTestCollection.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createTestCollection.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createTestCollection.');
             }
             // verify required parameter 'rTestCollection' is not null or undefined
             if (rTestCollection === null || rTestCollection === undefined) {
                 throw new RequiredError('rTestCollection','Required parameter rTestCollection was null or undefined when calling createTestCollection.');
             }
             const localVarPath = `/org/test_collection/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rTestCollection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rTestCollection !== undefined ? rTestCollection : {}) : (rTestCollection || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createUser: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createUser.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createUser.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createUser.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createUser.');
             }
             // verify required parameter 'rUser' is not null or undefined
             if (rUser === null || rUser === undefined) {
                 throw new RequiredError('rUser','Required parameter rUser was null or undefined when calling createUser.');
             }
             const localVarPath = `/org/user/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rUser !== undefined ? rUser : {}) : (rUser || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccine} rVaccine Vaccine Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createVaccine: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createVaccine.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createVaccine.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createVaccine.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createVaccine.');
             }
             // verify required parameter 'rVaccine' is not null or undefined
             if (rVaccine === null || rVaccine === undefined) {
                 throw new RequiredError('rVaccine','Required parameter rVaccine was null or undefined when calling createVaccine.');
             }
             const localVarPath = `/org/vaccine/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rVaccine !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rVaccine !== undefined ? rVaccine : {}) : (rVaccine || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createVaccineInjectData: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling createVaccineInjectData.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling createVaccineInjectData.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling createVaccineInjectData.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling createVaccineInjectData.');
             }
             // verify required parameter 'rVaccineInjectData' is not null or undefined
             if (rVaccineInjectData === null || rVaccineInjectData === undefined) {
                 throw new RequiredError('rVaccineInjectData','Required parameter rVaccineInjectData was null or undefined when calling createVaccineInjectData.');
             }
             const localVarPath = `/org/vaccine_inject_data/create`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rVaccineInjectData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rVaccineInjectData !== undefined ? rVaccineInjectData : {}) : (rVaccineInjectData || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteBagById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteBagById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteBagById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteBagById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteBagById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBagById.');
             }
             const localVarPath = `/org/bag/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCarrierById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteCarrierById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteCarrierById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteCarrierById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteCarrierById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCarrierById.');
             }
             const localVarPath = `/org/carrier/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidOffsiteById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteCovidOffsiteById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteCovidOffsiteById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteCovidOffsiteById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteCovidOffsiteById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCovidOffsiteById.');
             }
             const localVarPath = `/org/covid_offsite/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidOffsiteTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteCovidOffsiteTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteCovidOffsiteTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteCovidOffsiteTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteCovidOffsiteTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCovidOffsiteTripById.');
             }
             const localVarPath = `/org/covid_offsite_trip/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidOnsiteTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteCovidOnsiteTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteCovidOnsiteTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteCovidOnsiteTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteCovidOnsiteTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCovidOnsiteTripById.');
             }
             const localVarPath = `/org/covid_onsite_trip/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidPatientById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteCovidPatientById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteCovidPatientById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteCovidPatientById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteCovidPatientById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCovidPatientById.');
             }
             const localVarPath = `/org/covid_patient/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteHomeTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteHomeTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteHomeTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteHomeTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteHomeTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteHomeTripById.');
             }
             const localVarPath = `/org/home_trip/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteHospitalById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteHospitalById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteHospitalById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteHospitalById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteHospitalById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteHospitalById.');
             }
             const localVarPath = `/org/hospital/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteHospitalTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteHospitalTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteHospitalTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteHospitalTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteHospitalTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteHospitalTripById.');
             }
             const localVarPath = `/org/hospital_trip/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteLabByd: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteLabByd.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteLabByd.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteLabByd.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteLabByd.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLabByd.');
             }
             const localVarPath = `/org/lab/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteLabTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteLabTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteLabTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteLabTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteLabTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteLabTripById.');
             }
             const localVarPath = `/org/lab_trip/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteMainCenterByd: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteMainCenterByd.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteMainCenterByd.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteMainCenterByd.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteMainCenterByd.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMainCenterByd.');
             }
             const localVarPath = `/org/main_center/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteOrganisation: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteOrganisation.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteOrganisation.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteOrganisation.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteOrganisation.');
             }
             const localVarPath = `/org/organisation/delete`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deletePatientById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deletePatientById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deletePatientById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deletePatientById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deletePatientById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deletePatientById.');
             }
             const localVarPath = `/org/patient/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deletePouchById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deletePouchById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deletePouchById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deletePouchById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deletePouchById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deletePouchById.');
             }
             const localVarPath = `/org/pouch/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteRouteById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteRouteById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteRouteById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteRouteById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteRouteById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteRouteById.');
             }
             const localVarPath = `/org/route/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteSampleById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteSampleById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteSampleById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteSampleById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteSampleById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSampleById.');
             }
             const localVarPath = `/org/sample/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteShipmentById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteShipmentById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteShipmentById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteShipmentById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteShipmentById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteShipmentById.');
             }
             const localVarPath = `/org/shipment/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteTestCollectionById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteTestCollectionById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteTestCollectionById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteTestCollectionById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteTestCollectionById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTestCollectionById.');
             }
             const localVarPath = `/org/test_collection/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Identification of the user for delete.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteUserById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteUserById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteUserById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteUserById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteUserById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserById.');
             }
             const localVarPath = `/org/user/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteVaccineById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteVaccineById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteVaccineById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteVaccineById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteVaccineById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVaccineById.');
             }
             const localVarPath = `/org/vaccine/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Delete VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteVaccineInjectDataById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling deleteVaccineInjectDataById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling deleteVaccineInjectDataById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling deleteVaccineInjectDataById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling deleteVaccineInjectDataById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVaccineInjectDataById.');
             }
             const localVarPath = `/org/vaccine_inject_data/delete/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Data Exchange API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} _function 
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         exchangeData: async (apiKey: string, secretKey: string, authToken: string, centerID: string, _function: string, type: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling exchangeData.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling exchangeData.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling exchangeData.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling exchangeData.');
             }
             // verify required parameter '_function' is not null or undefined
             if (_function === null || _function === undefined) {
                 throw new RequiredError('_function','Required parameter _function was null or undefined when calling exchangeData.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling exchangeData.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling exchangeData.');
             }
             const localVarPath = `/org/data/exchange/{function}/{type}/{id}/notification`
                 .replace(`{${"function"}}`, encodeURIComponent(String(_function)))
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Admin Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAdminAccess: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAdminAccess.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAdminAccess.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAdminAccess.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAdminAccess.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getAdminAccess.');
             }
             const localVarPath = `/org/access/admin/feature/get/table/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllBags: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllBags.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllBags.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllBags.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllBags.');
             }
             const localVarPath = `/org/bag/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCarriers: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllCarriers.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllCarriers.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllCarriers.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllCarriers.');
             }
             const localVarPath = `/org/carrier/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidOffsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllCovidOffsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllCovidOffsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllCovidOffsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllCovidOffsiteTrips.');
             }
             const localVarPath = `/org/covid_offsite_trip/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidOffsites: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllCovidOffsites.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllCovidOffsites.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllCovidOffsites.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllCovidOffsites.');
             }
             const localVarPath = `/org/covid_offsite/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidOnsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllCovidOnsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllCovidOnsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllCovidOnsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllCovidOnsiteTrips.');
             }
             const localVarPath = `/org/covid_onsite_trip/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidPatients: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllCovidPatients.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllCovidPatients.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllCovidPatients.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllCovidPatients.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getAllCovidPatients.');
             }
             const localVarPath = `/org/covid_patient/{type}/all`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllHomeTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllHomeTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllHomeTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllHomeTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllHomeTrips.');
             }
             const localVarPath = `/org/home_trip/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllHospitalTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllHospitalTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllHospitalTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllHospitalTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllHospitalTrips.');
             }
             const localVarPath = `/org/hospital_trip/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllHospitals: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllHospitals.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllHospitals.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllHospitals.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllHospitals.');
             }
             const localVarPath = `/org/hospital/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllLabCollections: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllLabCollections.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllLabCollections.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllLabCollections.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllLabCollections.');
             }
             const localVarPath = `/org/lab_collection/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllLabTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllLabTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllLabTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllLabTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllLabTrips.');
             }
             const localVarPath = `/org/lab_trip/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllLabs: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllLabs.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllLabs.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllLabs.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllLabs.');
             }
             const localVarPath = `/org/lab/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllMainCenters: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllMainCenters.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllMainCenters.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllMainCenters.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllMainCenters.');
             }
             const localVarPath = `/org/main_center/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllOrganisations: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllOrganisations.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllOrganisations.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllOrganisations.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllOrganisations.');
             }
             const localVarPath = `/org/organisation/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllPatients: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllPatients.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllPatients.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllPatients.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllPatients.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getAllPatients.');
             }
             const localVarPath = `/org/patient/{type}/all`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllPouchs: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllPouchs.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllPouchs.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllPouchs.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllPouchs.');
             }
             const localVarPath = `/org/pouch/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllRoutes: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllRoutes.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllRoutes.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllRoutes.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllRoutes.');
             }
             const localVarPath = `/org/route/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllSamples: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllSamples.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllSamples.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllSamples.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllSamples.');
             }
             const localVarPath = `/org/sample/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllShipments: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllShipments.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllShipments.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllShipments.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllShipments.');
             }
             const localVarPath = `/org/shipment/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllTestCollections: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllTestCollections.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllTestCollections.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllTestCollections.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllTestCollections.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getAllTestCollections.');
             }
             const localVarPath = `/org/test_collection/{type}/all`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Fetch All User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllUsers: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllUsers.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllUsers.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllUsers.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllUsers.');
             }
             const localVarPath = `/org/user/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllVaccineInjectDatas: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllVaccineInjectDatas.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllVaccineInjectDatas.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllVaccineInjectDatas.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllVaccineInjectDatas.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getAllVaccineInjectDatas.');
             }
             const localVarPath = `/org/vaccine_inject_data/{type}/all`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllVaccines: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getAllVaccines.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getAllVaccines.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getAllVaccines.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getAllVaccines.');
             }
             const localVarPath = `/org/vaccine/all`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getBagById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getBagById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getBagById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getBagById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getBagById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getBagById.');
             }
             const localVarPath = `/org/bag/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCarrierById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCarrierById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCarrierById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCarrierById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCarrierById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getCarrierById.');
             }
             const localVarPath = `/org/carrier/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Fetch All Carrier Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCarriers: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCarriers.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCarriers.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCarriers.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCarriers.');
             }
             const localVarPath = `/org/user/carriers`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Completed Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableCovidOffsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCompletedTableCovidOffsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCompletedTableCovidOffsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCompletedTableCovidOffsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCompletedTableCovidOffsiteTrips.');
             }
             const localVarPath = `/org/covid_offsite_trip/table/completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Completed Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableCovidOnsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCompletedTableCovidOnsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCompletedTableCovidOnsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCompletedTableCovidOnsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCompletedTableCovidOnsiteTrips.');
             }
             const localVarPath = `/org/covid_onsite_trip/table/completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Completed Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableHomeTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCompletedTableHomeTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCompletedTableHomeTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCompletedTableHomeTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCompletedTableHomeTrips.');
             }
             const localVarPath = `/org/home_trip/table/completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Completed Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableHospitalTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCompletedTableHospitalTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCompletedTableHospitalTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCompletedTableHospitalTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCompletedTableHospitalTrips.');
             }
             const localVarPath = `/org/hospital_trip/table/completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Completed Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableLabTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCompletedTableLabTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCompletedTableLabTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCompletedTableLabTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCompletedTableLabTrips.');
             }
             const localVarPath = `/org/lab_trip/table/completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Dashboard Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} option 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidDashboard: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, option: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCovidDashboard.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCovidDashboard.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCovidDashboard.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCovidDashboard.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getCovidDashboard.');
             }
             // verify required parameter 'option' is not null or undefined
             if (option === null || option === undefined) {
                 throw new RequiredError('option','Required parameter option was null or undefined when calling getCovidDashboard.');
             }
             const localVarPath = `/org/dashboard/{option}/covid/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                 .replace(`{${"option"}}`, encodeURIComponent(String(option)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get CovidPatient History
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidHistoryById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCovidHistoryById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCovidHistoryById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCovidHistoryById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCovidHistoryById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getCovidHistoryById.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getCovidHistoryById.');
             }
             const localVarPath = `/org/covid_patient/history/{id}/{type}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidOffsiteById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCovidOffsiteById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCovidOffsiteById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCovidOffsiteById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCovidOffsiteById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getCovidOffsiteById.');
             }
             const localVarPath = `/org/covid_offsite/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidOffsiteTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCovidOffsiteTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCovidOffsiteTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCovidOffsiteTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCovidOffsiteTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getCovidOffsiteTripById.');
             }
             const localVarPath = `/org/covid_offsite_trip/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidOnsiteTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCovidOnsiteTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCovidOnsiteTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCovidOnsiteTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCovidOnsiteTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getCovidOnsiteTripById.');
             }
             const localVarPath = `/org/covid_onsite_trip/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidPatientById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getCovidPatientById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getCovidPatientById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getCovidPatientById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getCovidPatientById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getCovidPatientById.');
             }
             const localVarPath = `/org/covid_patient/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Dashboard Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getDashboard: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getDashboard.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getDashboard.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getDashboard.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getDashboard.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getDashboard.');
             }
             const localVarPath = `/org/dashboard/all/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Dynamic List
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id id of the item on edit
          * @param {DynamicList} dynamicList Dynamic List from the form
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getDynamicList: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, dynamicList: DynamicList, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getDynamicList.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getDynamicList.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getDynamicList.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getDynamicList.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getDynamicList.');
             }
             // verify required parameter 'dynamicList' is not null or undefined
             if (dynamicList === null || dynamicList === undefined) {
                 throw new RequiredError('dynamicList','Required parameter dynamicList was null or undefined when calling getDynamicList.');
             }
             const localVarPath = `/org/organisation/dynamic/list/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof dynamicList !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dynamicList !== undefined ? dynamicList : {}) : (dynamicList || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Field Executive Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getFieldAccess: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getFieldAccess.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getFieldAccess.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getFieldAccess.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getFieldAccess.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getFieldAccess.');
             }
             const localVarPath = `/org/access/field/feature/get/table/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} fileName 
          * @param {string} centerId 
          * @param {string} [org] 
          * @param {string} [download] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getFile: async (apiKey: string, secretKey: string, authToken: string, centerID: string, fileName: string, centerId: string, org?: string, download?: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getFile.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getFile.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getFile.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getFile.');
             }
             // verify required parameter 'fileName' is not null or undefined
             if (fileName === null || fileName === undefined) {
                 throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getFile.');
             }
             // verify required parameter 'centerId' is not null or undefined
             if (centerId === null || centerId === undefined) {
                 throw new RequiredError('centerId','Required parameter centerId was null or undefined when calling getFile.');
             }
             const localVarPath = `/org/file/get/{centerId}/{fileName}`
                 .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)))
                 .replace(`{${"centerId"}}`, encodeURIComponent(String(centerId)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (org !== undefined) {
                 localVarQueryParameter['org'] = org;
             }
 
             if (download !== undefined) {
                 localVarQueryParameter['download'] = download;
             }
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Form
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type Form Name
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getFormType: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getFormType.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getFormType.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getFormType.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getFormType.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getFormType.');
             }
             const localVarPath = `/org/organisation/form/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHealth: async (options: any = {}): Promise<RequestArgs> => {
             const localVarPath = `/health/check`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Patient History
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHistoryById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getHistoryById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getHistoryById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getHistoryById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getHistoryById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getHistoryById.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getHistoryById.');
             }
             const localVarPath = `/org/patient/history/{id}/{type}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHomeTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getHomeTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getHomeTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getHomeTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getHomeTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getHomeTripById.');
             }
             const localVarPath = `/org/home_trip/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHospitalById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getHospitalById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getHospitalById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getHospitalById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getHospitalById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getHospitalById.');
             }
             const localVarPath = `/org/hospital/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHospitalTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getHospitalTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getHospitalTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getHospitalTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getHospitalTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getHospitalTripById.');
             }
             const localVarPath = `/org/hospital_trip/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getLabById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getLabById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getLabById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getLabById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getLabById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getLabById.');
             }
             const localVarPath = `/org/lab/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getLabCollections: async (apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getLabCollections.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getLabCollections.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getLabCollections.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getLabCollections.');
             }
             // verify required parameter 'tripId' is not null or undefined
             if (tripId === null || tripId === undefined) {
                 throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling getLabCollections.');
             }
             const localVarPath = `/org/lab_collection/trip/{tripId}`
                 .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getLabTripById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getLabTripById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getLabTripById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getLabTripById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getLabTripById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getLabTripById.');
             }
             const localVarPath = `/org/lab_trip/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getMainCenterById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getMainCenterById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getMainCenterById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getMainCenterById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getMainCenterById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getMainCenterById.');
             }
             const localVarPath = `/org/main_center/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Map
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Map Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getMap: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getMap.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getMap.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getMap.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getMap.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getMap.');
             }
             const localVarPath = `/org/organisation/map/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Menus
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getMenus: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getMenus.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getMenus.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getMenus.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getMenus.');
             }
             const localVarPath = `/org/organisation/get/menu`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getModule: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getModule.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getModule.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getModule.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getModule.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getModule.');
             }
             const localVarPath = `/org/access/module/get/table/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Not Completed Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedCovidOffsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getNotCompletedCovidOffsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getNotCompletedCovidOffsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getNotCompletedCovidOffsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getNotCompletedCovidOffsiteTrips.');
             }
             const localVarPath = `/org/covid_offsite_trip/table/not_completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Not Completed Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedCovidOnsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getNotCompletedCovidOnsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getNotCompletedCovidOnsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getNotCompletedCovidOnsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getNotCompletedCovidOnsiteTrips.');
             }
             const localVarPath = `/org/covid_onsite_trip/table/not_completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Not Completed Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedHomeTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getNotCompletedHomeTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getNotCompletedHomeTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getNotCompletedHomeTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getNotCompletedHomeTrips.');
             }
             const localVarPath = `/org/home_trip/table/not_completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Not Completed Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedHospitalTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getNotCompletedHospitalTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getNotCompletedHospitalTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getNotCompletedHospitalTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getNotCompletedHospitalTrips.');
             }
             const localVarPath = `/org/hospital_trip/table/not_completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Not Completed Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedLabTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getNotCompletedLabTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getNotCompletedLabTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getNotCompletedLabTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getNotCompletedLabTrips.');
             }
             const localVarPath = `/org/lab_trip/table/not_completed`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getOrganisation: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getOrganisation.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getOrganisation.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getOrganisation.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getOrganisation.');
             }
             const localVarPath = `/org/organisation/profile`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPatientById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getPatientById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getPatientById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getPatientById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getPatientById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getPatientById.');
             }
             const localVarPath = `/org/patient/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Phlebotomist Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPhlebotomistAccess: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getPhlebotomistAccess.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getPhlebotomistAccess.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getPhlebotomistAccess.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getPhlebotomistAccess.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getPhlebotomistAccess.');
             }
             const localVarPath = `/org/access/phlebotomist/feature/get/table/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPouchById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getPouchById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getPouchById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getPouchById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getPouchById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getPouchById.');
             }
             const localVarPath = `/org/pouch/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Power Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPowerDashboard: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getPowerDashboard.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getPowerDashboard.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getPowerDashboard.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getPowerDashboard.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getPowerDashboard.');
             }
             const localVarPath = `/org/dashboard/power/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getRouteById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getRouteById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getRouteById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getRouteById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getRouteById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getRouteById.');
             }
             const localVarPath = `/org/route/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getSampleById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getSampleById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getSampleById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getSampleById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getSampleById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getSampleById.');
             }
             const localVarPath = `/org/sample/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getShipmentById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getShipmentById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getShipmentById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getShipmentById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getShipmentById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getShipmentById.');
             }
             const localVarPath = `/org/shipment/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableAdmins: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableAdmins.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableAdmins.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableAdmins.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableAdmins.');
             }
             const localVarPath = `/org/user/admin/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableBags: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableBags.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableBags.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableBags.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableBags.');
             }
             const localVarPath = `/org/bag/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableBagsByType: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableBagsByType.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableBagsByType.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableBagsByType.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableBagsByType.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTableBagsByType.');
             }
             const localVarPath = `/org/bag/table/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCarriers: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableCarriers.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableCarriers.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableCarriers.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableCarriers.');
             }
             const localVarPath = `/org/carrier/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type Module Type
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCarriersByType: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableCarriersByType.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableCarriersByType.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableCarriersByType.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableCarriersByType.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTableCarriersByType.');
             }
             const localVarPath = `/org/carrier/table/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidOffsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableCovidOffsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableCovidOffsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableCovidOffsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableCovidOffsiteTrips.');
             }
             const localVarPath = `/org/covid_offsite_trip/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidOffsites: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableCovidOffsites.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableCovidOffsites.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableCovidOffsites.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableCovidOffsites.');
             }
             const localVarPath = `/org/covid_offsite/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidOnsiteTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableCovidOnsiteTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableCovidOnsiteTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableCovidOnsiteTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableCovidOnsiteTrips.');
             }
             const localVarPath = `/org/covid_onsite_trip/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidPatient: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableCovidPatient.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableCovidPatient.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableCovidPatient.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableCovidPatient.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTableCovidPatient.');
             }
             const localVarPath = `/org/covid_patient/{type}/table`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Critical Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCriticalBags: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableCriticalBags.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableCriticalBags.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableCriticalBags.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableCriticalBags.');
             }
             const localVarPath = `/org/bag/table/critical_bag`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableHomeTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableHomeTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableHomeTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableHomeTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableHomeTrips.');
             }
             const localVarPath = `/org/home_trip/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableHospitalTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableHospitalTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableHospitalTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableHospitalTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableHospitalTrips.');
             }
             const localVarPath = `/org/hospital_trip/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableHospitals: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableHospitals.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableHospitals.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableHospitals.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableHospitals.');
             }
             const localVarPath = `/org/hospital/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableLabTrips: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableLabTrips.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableLabTrips.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableLabTrips.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableLabTrips.');
             }
             const localVarPath = `/org/lab_trip/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableLabs: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableLabs.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableLabs.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableLabs.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableLabs.');
             }
             const localVarPath = `/org/lab/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableMainCenters: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableMainCenters.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableMainCenters.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableMainCenters.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableMainCenters.');
             }
             const localVarPath = `/org/main_center/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Non Critical Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableNonCriticalBags: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableNonCriticalBags.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableNonCriticalBags.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableNonCriticalBags.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableNonCriticalBags.');
             }
             const localVarPath = `/org/bag/table/non_critical_bag`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableOrganisations: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableOrganisations.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableOrganisations.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableOrganisations.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableOrganisations.');
             }
             const localVarPath = `/org/organisation/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePatient: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTablePatient.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTablePatient.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTablePatient.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTablePatient.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTablePatient.');
             }
             const localVarPath = `/org/patient/{type}/table`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePowerAdmins: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTablePowerAdmins.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTablePowerAdmins.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTablePowerAdmins.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTablePowerAdmins.');
             }
             const localVarPath = `/org/power/admin/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePowerMainCenters: async (apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTablePowerMainCenters.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTablePowerMainCenters.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTablePowerMainCenters.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTablePowerMainCenters.');
             }
             // verify required parameter 'slug' is not null or undefined
             if (slug === null || slug === undefined) {
                 throw new RequiredError('slug','Required parameter slug was null or undefined when calling getTablePowerMainCenters.');
             }
             const localVarPath = `/org/power/maincenter/table/{slug}`
                 .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePowerModules: async (apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTablePowerModules.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTablePowerModules.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTablePowerModules.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTablePowerModules.');
             }
             // verify required parameter 'slug' is not null or undefined
             if (slug === null || slug === undefined) {
                 throw new RequiredError('slug','Required parameter slug was null or undefined when calling getTablePowerModules.');
             }
             const localVarPath = `/org/power/module/table/{slug}`
                 .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableRoutes: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableRoutes.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableRoutes.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableRoutes.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableRoutes.');
             }
             const localVarPath = `/org/route/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableSamples: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableSamples.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableSamples.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableSamples.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableSamples.');
             }
             const localVarPath = `/org/sample/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableShipments: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableShipments.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableShipments.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableShipments.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableShipments.');
             }
             const localVarPath = `/org/shipment/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableSuperCarriers: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableSuperCarriers.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableSuperCarriers.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableSuperCarriers.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableSuperCarriers.');
             }
             const localVarPath = `/org/user/carrier/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableTestCollection: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableTestCollection.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableTestCollection.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableTestCollection.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableTestCollection.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTableTestCollection.');
             }
             const localVarPath = `/org/test_collection/table`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableVaccineInjectData: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableVaccineInjectData.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableVaccineInjectData.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableVaccineInjectData.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableVaccineInjectData.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTableVaccineInjectData.');
             }
             const localVarPath = `/org/vaccine_inject_data/table`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Table Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableVaccines: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTableVaccines.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTableVaccines.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTableVaccines.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTableVaccines.');
             }
             const localVarPath = `/org/vaccine/table`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTestCollectionById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTestCollectionById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTestCollectionById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTestCollectionById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTestCollectionById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getTestCollectionById.');
             }
             const localVarPath = `/org/test_collection/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Sample Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTestGroups: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTestGroups.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTestGroups.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTestGroups.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTestGroups.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTestGroups.');
             }
             const localVarPath = `/org/sample/test/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Sample Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} module 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTestGroupsWithModule: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, module: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTestGroupsWithModule.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTestGroupsWithModule.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTestGroupsWithModule.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTestGroupsWithModule.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTestGroupsWithModule.');
             }
             // verify required parameter 'module' is not null or undefined
             if (module === null || module === undefined) {
                 throw new RequiredError('module','Required parameter module was null or undefined when calling getTestGroupsWithModule.');
             }
             const localVarPath = `/org/sample/{module}/test/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                 .replace(`{${"module"}}`, encodeURIComponent(String(module)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Trip TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTripTestCollections: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTripTestCollections.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTripTestCollections.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTripTestCollections.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTripTestCollections.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTripTestCollections.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getTripTestCollections.');
             }
             const localVarPath = `/org/test_collection/{type}/trip/{id}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Trip VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTripVaccineInjectDatas: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getTripVaccineInjectDatas.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getTripVaccineInjectDatas.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getTripVaccineInjectDatas.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getTripVaccineInjectDatas.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getTripVaccineInjectDatas.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getTripVaccineInjectDatas.');
             }
             const localVarPath = `/org/vaccine_inject_data/{type}/trip/{id}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Fetch All Carrier and Admin Accounts
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getUserCollection: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getUserCollection.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getUserCollection.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getUserCollection.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getUserCollection.');
             }
             const localVarPath = `/org/user/users/collection`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getVaccineById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getVaccineById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getVaccineById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getVaccineById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getVaccineById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getVaccineById.');
             }
             const localVarPath = `/org/vaccine/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get All Vaccine Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getVaccineGroups: async (apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getVaccineGroups.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getVaccineGroups.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getVaccineGroups.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getVaccineGroups.');
             }
             // verify required parameter 'type' is not null or undefined
             if (type === null || type === undefined) {
                 throw new RequiredError('type','Required parameter type was null or undefined when calling getVaccineGroups.');
             }
             const localVarPath = `/org/vaccine/test/{type}`
                 .replace(`{${"type"}}`, encodeURIComponent(String(type)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Get VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getVaccineInjectDataById: async (apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling getVaccineInjectDataById.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling getVaccineInjectDataById.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling getVaccineInjectDataById.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling getVaccineInjectDataById.');
             }
             // verify required parameter 'id' is not null or undefined
             if (id === null || id === undefined) {
                 throw new RequiredError('id','Required parameter id was null or undefined when calling getVaccineInjectDataById.');
             }
             const localVarPath = `/org/vaccine_inject_data/profile/{id}`
                 .replace(`{${"id"}}`, encodeURIComponent(String(id)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Login to service
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {Login} login Account used to identify the user.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         login: async (apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling login.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling login.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling login.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling login.');
             }
             // verify required parameter 'login' is not null or undefined
             if (login === null || login === undefined) {
                 throw new RequiredError('login','Required parameter login was null or undefined when calling login.');
             }
             const localVarPath = `/org/user/login`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof login !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(login !== undefined ? login : {}) : (login || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Read Records
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         readRecords: async (apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling readRecords.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling readRecords.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling readRecords.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling readRecords.');
             }
             // verify required parameter 'tripId' is not null or undefined
             if (tripId === null || tripId === undefined) {
                 throw new RequiredError('tripId','Required parameter tripId was null or undefined when calling readRecords.');
             }
             const localVarPath = `/org/record/read/{tripId}`
                 .replace(`{${"tripId"}}`, encodeURIComponent(String(tripId)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject2} [inlineObject2] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchCovidOffsiteTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject2?: InlineObject2, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchCovidOffsiteTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchCovidOffsiteTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchCovidOffsiteTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchCovidOffsiteTrip.');
             }
             const localVarPath = `/org/covid_offsite_trip/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject2 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject2 !== undefined ? inlineObject2 : {}) : (inlineObject2 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the covidOnsite trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject3} [inlineObject3] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchCovidOnsiteTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject3?: InlineObject3, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchCovidOnsiteTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchCovidOnsiteTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchCovidOnsiteTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchCovidOnsiteTrip.');
             }
             const localVarPath = `/org/covid_onsite_trip/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject3 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject3 !== undefined ? inlineObject3 : {}) : (inlineObject3 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the CovidPatient
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject4} [inlineObject4] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchCovidPatientTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject4?: InlineObject4, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchCovidPatientTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchCovidPatientTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchCovidPatientTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchCovidPatientTrip.');
             }
             const localVarPath = `/org/covid_patient/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject4 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject4 !== undefined ? inlineObject4 : {}) : (inlineObject4 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject} [inlineObject] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip.');
             }
             const localVarPath = `/org/bag/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject !== undefined ? inlineObject : {}) : (inlineObject || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject1} [inlineObject1] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip1: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject1?: InlineObject1, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip1.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip1.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip1.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip1.');
             }
             const localVarPath = `/org/carrier/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject1 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject1 !== undefined ? inlineObject1 : {}) : (inlineObject1 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject6} [inlineObject6] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip2: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject6?: InlineObject6, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip2.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip2.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip2.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip2.');
             }
             const localVarPath = `/org/home_trip/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject6 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject6 !== undefined ? inlineObject6 : {}) : (inlineObject6 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the Lab
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject8} [inlineObject8] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip3: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject8?: InlineObject8, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip3.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip3.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip3.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip3.');
             }
             const localVarPath = `/org/lab/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject8 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject8 !== undefined ? inlineObject8 : {}) : (inlineObject8 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the LabTrip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject9} [inlineObject9] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip4: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject9?: InlineObject9, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip4.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip4.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip4.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip4.');
             }
             const localVarPath = `/org/lab_trip/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject9 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject9 !== undefined ? inlineObject9 : {}) : (inlineObject9 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the Patient
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject10} [inlineObject10] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip5: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject10?: InlineObject10, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip5.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip5.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip5.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip5.');
             }
             const localVarPath = `/org/patient/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject10 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject10 !== undefined ? inlineObject10 : {}) : (inlineObject10 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject11} [inlineObject11] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip6: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject11?: InlineObject11, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip6.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip6.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip6.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip6.');
             }
             const localVarPath = `/org/shipment/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject11 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject11 !== undefined ? inlineObject11 : {}) : (inlineObject11 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the TestCollection
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject12} [inlineObject12] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip7: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject12?: InlineObject12, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHomeTrip7.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHomeTrip7.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHomeTrip7.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHomeTrip7.');
             }
             const localVarPath = `/org/test_collection/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject12 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject12 !== undefined ? inlineObject12 : {}) : (inlineObject12 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject7} [inlineObject7] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHospitalTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject7?: InlineObject7, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchHospitalTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchHospitalTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchHospitalTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchHospitalTrip.');
             }
             const localVarPath = `/org/hospital_trip/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject7 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject7 !== undefined ? inlineObject7 : {}) : (inlineObject7 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Search the VaccineInjectData
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject13} [inlineObject13] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchVaccineInjectDataTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject13?: InlineObject13, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling searchVaccineInjectDataTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling searchVaccineInjectDataTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling searchVaccineInjectDataTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling searchVaccineInjectDataTrip.');
             }
             const localVarPath = `/org/vaccine_inject_data/search`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof inlineObject13 !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(inlineObject13 !== undefined ? inlineObject13 : {}) : (inlineObject13 || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Create Organisation Profile and Super Admin Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {ROrganisation} rOrganisation Organisation Profile with Super Admin Account.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         signUp: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling signUp.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling signUp.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling signUp.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling signUp.');
             }
             // verify required parameter 'rOrganisation' is not null or undefined
             if (rOrganisation === null || rOrganisation === undefined) {
                 throw new RequiredError('rOrganisation','Required parameter rOrganisation was null or undefined when calling signUp.');
             }
             const localVarPath = `/org/organisation/sign_up`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rOrganisation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rOrganisation !== undefined ? rOrganisation : {}) : (rOrganisation || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Access
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RAccess} rAccess Access that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateAccess: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rAccess: RAccess, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateAccess.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateAccess.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateAccess.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateAccess.');
             }
             // verify required parameter 'rAccess' is not null or undefined
             if (rAccess === null || rAccess === undefined) {
                 throw new RequiredError('rAccess','Required parameter rAccess was null or undefined when calling updateAccess.');
             }
             const localVarPath = `/org/access/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rAccess !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rAccess !== undefined ? rAccess : {}) : (rAccess || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RBag} rBag Bag Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateBag: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateBag.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateBag.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateBag.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateBag.');
             }
             // verify required parameter 'rBag' is not null or undefined
             if (rBag === null || rBag === undefined) {
                 throw new RequiredError('rBag','Required parameter rBag was null or undefined when calling updateBag.');
             }
             const localVarPath = `/org/bag/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rBag !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rBag !== undefined ? rBag : {}) : (rBag || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCarrier} rCarrier Carrier Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCarrier: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateCarrier.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateCarrier.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateCarrier.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateCarrier.');
             }
             // verify required parameter 'rCarrier' is not null or undefined
             if (rCarrier === null || rCarrier === undefined) {
                 throw new RequiredError('rCarrier','Required parameter rCarrier was null or undefined when calling updateCarrier.');
             }
             const localVarPath = `/org/carrier/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCarrier !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCarrier !== undefined ? rCarrier : {}) : (rCarrier || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidOffsite: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateCovidOffsite.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateCovidOffsite.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateCovidOffsite.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateCovidOffsite.');
             }
             // verify required parameter 'rCovidOffsite' is not null or undefined
             if (rCovidOffsite === null || rCovidOffsite === undefined) {
                 throw new RequiredError('rCovidOffsite','Required parameter rCovidOffsite was null or undefined when calling updateCovidOffsite.');
             }
             const localVarPath = `/org/covid_offsite/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidOffsite !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidOffsite !== undefined ? rCovidOffsite : {}) : (rCovidOffsite || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidOffsiteTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateCovidOffsiteTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateCovidOffsiteTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateCovidOffsiteTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateCovidOffsiteTrip.');
             }
             // verify required parameter 'rCovidOffsiteTrip' is not null or undefined
             if (rCovidOffsiteTrip === null || rCovidOffsiteTrip === undefined) {
                 throw new RequiredError('rCovidOffsiteTrip','Required parameter rCovidOffsiteTrip was null or undefined when calling updateCovidOffsiteTrip.');
             }
             const localVarPath = `/org/covid_offsite_trip/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidOffsiteTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidOffsiteTrip !== undefined ? rCovidOffsiteTrip : {}) : (rCovidOffsiteTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidOnsiteTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateCovidOnsiteTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateCovidOnsiteTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateCovidOnsiteTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateCovidOnsiteTrip.');
             }
             // verify required parameter 'rCovidOnsiteTrip' is not null or undefined
             if (rCovidOnsiteTrip === null || rCovidOnsiteTrip === undefined) {
                 throw new RequiredError('rCovidOnsiteTrip','Required parameter rCovidOnsiteTrip was null or undefined when calling updateCovidOnsiteTrip.');
             }
             const localVarPath = `/org/covid_onsite_trip/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidOnsiteTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidOnsiteTrip !== undefined ? rCovidOnsiteTrip : {}) : (rCovidOnsiteTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidPatient} rCovidPatient CovidPatient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidPatient: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateCovidPatient.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateCovidPatient.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateCovidPatient.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateCovidPatient.');
             }
             // verify required parameter 'rCovidPatient' is not null or undefined
             if (rCovidPatient === null || rCovidPatient === undefined) {
                 throw new RequiredError('rCovidPatient','Required parameter rCovidPatient was null or undefined when calling updateCovidPatient.');
             }
             const localVarPath = `/org/covid_patient/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rCovidPatient !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rCovidPatient !== undefined ? rCovidPatient : {}) : (rCovidPatient || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHomeTrip} rHomeTrip HomeTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateHomeTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateHomeTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateHomeTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateHomeTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateHomeTrip.');
             }
             // verify required parameter 'rHomeTrip' is not null or undefined
             if (rHomeTrip === null || rHomeTrip === undefined) {
                 throw new RequiredError('rHomeTrip','Required parameter rHomeTrip was null or undefined when calling updateHomeTrip.');
             }
             const localVarPath = `/org/home_trip/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rHomeTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rHomeTrip !== undefined ? rHomeTrip : {}) : (rHomeTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospital} rHospital Hospital Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateHospital: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateHospital.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateHospital.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateHospital.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateHospital.');
             }
             // verify required parameter 'rHospital' is not null or undefined
             if (rHospital === null || rHospital === undefined) {
                 throw new RequiredError('rHospital','Required parameter rHospital was null or undefined when calling updateHospital.');
             }
             const localVarPath = `/org/hospital/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rHospital !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rHospital !== undefined ? rHospital : {}) : (rHospital || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateHospitalTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateHospitalTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateHospitalTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateHospitalTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateHospitalTrip.');
             }
             // verify required parameter 'rHospitalTrip' is not null or undefined
             if (rHospitalTrip === null || rHospitalTrip === undefined) {
                 throw new RequiredError('rHospitalTrip','Required parameter rHospitalTrip was null or undefined when calling updateHospitalTrip.');
             }
             const localVarPath = `/org/hospital_trip/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rHospitalTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rHospitalTrip !== undefined ? rHospitalTrip : {}) : (rHospitalTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLab} rLab Lab Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateLab: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateLab.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateLab.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateLab.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateLab.');
             }
             // verify required parameter 'rLab' is not null or undefined
             if (rLab === null || rLab === undefined) {
                 throw new RequiredError('rLab','Required parameter rLab was null or undefined when calling updateLab.');
             }
             const localVarPath = `/org/lab/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rLab !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rLab !== undefined ? rLab : {}) : (rLab || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabCollection} rLabCollection LabCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateLabCollection: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateLabCollection.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateLabCollection.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateLabCollection.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateLabCollection.');
             }
             // verify required parameter 'rLabCollection' is not null or undefined
             if (rLabCollection === null || rLabCollection === undefined) {
                 throw new RequiredError('rLabCollection','Required parameter rLabCollection was null or undefined when calling updateLabCollection.');
             }
             const localVarPath = `/org/lab_collection/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rLabCollection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rLabCollection !== undefined ? rLabCollection : {}) : (rLabCollection || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabTrip} rLabTrip LabTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateLabTrip: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateLabTrip.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateLabTrip.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateLabTrip.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateLabTrip.');
             }
             // verify required parameter 'rLabTrip' is not null or undefined
             if (rLabTrip === null || rLabTrip === undefined) {
                 throw new RequiredError('rLabTrip','Required parameter rLabTrip was null or undefined when calling updateLabTrip.');
             }
             const localVarPath = `/org/lab_trip/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rLabTrip !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rLabTrip !== undefined ? rLabTrip : {}) : (rLabTrip || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateMainCenter: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateMainCenter.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateMainCenter.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateMainCenter.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateMainCenter.');
             }
             // verify required parameter 'rMainCenter' is not null or undefined
             if (rMainCenter === null || rMainCenter === undefined) {
                 throw new RequiredError('rMainCenter','Required parameter rMainCenter was null or undefined when calling updateMainCenter.');
             }
             const localVarPath = `/org/main_center/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rMainCenter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rMainCenter !== undefined ? rMainCenter : {}) : (rMainCenter || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RModule} rModule Module that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateModule: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rModule: RModule, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateModule.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateModule.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateModule.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateModule.');
             }
             // verify required parameter 'rModule' is not null or undefined
             if (rModule === null || rModule === undefined) {
                 throw new RequiredError('rModule','Required parameter rModule was null or undefined when calling updateModule.');
             }
             const localVarPath = `/org/access/module/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rModule !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rModule !== undefined ? rModule : {}) : (rModule || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {ROrganisation} rOrganisation Organisation Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateOrganisation: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateOrganisation.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateOrganisation.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateOrganisation.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateOrganisation.');
             }
             // verify required parameter 'rOrganisation' is not null or undefined
             if (rOrganisation === null || rOrganisation === undefined) {
                 throw new RequiredError('rOrganisation','Required parameter rOrganisation was null or undefined when calling updateOrganisation.');
             }
             const localVarPath = `/org/organisation/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rOrganisation !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rOrganisation !== undefined ? rOrganisation : {}) : (rOrganisation || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Carrier
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePatch: async (apiKey: string, secretKey: string, authToken: string, centerID: string, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updatePatch.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updatePatch.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updatePatch.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updatePatch.');
             }
             const localVarPath = `/org/user/patch`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPatient} rPatient Patient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePatient: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updatePatient.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updatePatient.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updatePatient.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updatePatient.');
             }
             // verify required parameter 'rPatient' is not null or undefined
             if (rPatient === null || rPatient === undefined) {
                 throw new RequiredError('rPatient','Required parameter rPatient was null or undefined when calling updatePatient.');
             }
             const localVarPath = `/org/patient/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rPatient !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rPatient !== undefined ? rPatient : {}) : (rPatient || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPouch} rPouch Pouch Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePouch: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updatePouch.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updatePouch.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updatePouch.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updatePouch.');
             }
             // verify required parameter 'rPouch' is not null or undefined
             if (rPouch === null || rPouch === undefined) {
                 throw new RequiredError('rPouch','Required parameter rPouch was null or undefined when calling updatePouch.');
             }
             const localVarPath = `/org/pouch/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rPouch !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rPouch !== undefined ? rPouch : {}) : (rPouch || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePowerMainCenter: async (apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updatePowerMainCenter.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updatePowerMainCenter.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updatePowerMainCenter.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updatePowerMainCenter.');
             }
             // verify required parameter 'slug' is not null or undefined
             if (slug === null || slug === undefined) {
                 throw new RequiredError('slug','Required parameter slug was null or undefined when calling updatePowerMainCenter.');
             }
             // verify required parameter 'rMainCenter' is not null or undefined
             if (rMainCenter === null || rMainCenter === undefined) {
                 throw new RequiredError('rMainCenter','Required parameter rMainCenter was null or undefined when calling updatePowerMainCenter.');
             }
             const localVarPath = `/org/power/maincenter/update/{slug}`
                 .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rMainCenter !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rMainCenter !== undefined ? rMainCenter : {}) : (rMainCenter || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RModule} rModule Module that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePowerModule: async (apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rModule: RModule, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updatePowerModule.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updatePowerModule.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updatePowerModule.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updatePowerModule.');
             }
             // verify required parameter 'slug' is not null or undefined
             if (slug === null || slug === undefined) {
                 throw new RequiredError('slug','Required parameter slug was null or undefined when calling updatePowerModule.');
             }
             // verify required parameter 'rModule' is not null or undefined
             if (rModule === null || rModule === undefined) {
                 throw new RequiredError('rModule','Required parameter rModule was null or undefined when calling updatePowerModule.');
             }
             const localVarPath = `/org/power/module/update/{slug}`
                 .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rModule !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rModule !== undefined ? rModule : {}) : (rModule || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePowerUser: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updatePowerUser.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updatePowerUser.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updatePowerUser.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updatePowerUser.');
             }
             // verify required parameter 'rUser' is not null or undefined
             if (rUser === null || rUser === undefined) {
                 throw new RequiredError('rUser','Required parameter rUser was null or undefined when calling updatePowerUser.');
             }
             const localVarPath = `/org/power/user/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rUser !== undefined ? rUser : {}) : (rUser || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RRoute} rRoute Route Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateRoute: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateRoute.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateRoute.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateRoute.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateRoute.');
             }
             // verify required parameter 'rRoute' is not null or undefined
             if (rRoute === null || rRoute === undefined) {
                 throw new RequiredError('rRoute','Required parameter rRoute was null or undefined when calling updateRoute.');
             }
             const localVarPath = `/org/route/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rRoute !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rRoute !== undefined ? rRoute : {}) : (rRoute || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RSample} rSample Sample Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateSample: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateSample.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateSample.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateSample.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateSample.');
             }
             // verify required parameter 'rSample' is not null or undefined
             if (rSample === null || rSample === undefined) {
                 throw new RequiredError('rSample','Required parameter rSample was null or undefined when calling updateSample.');
             }
             const localVarPath = `/org/sample/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rSample !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rSample !== undefined ? rSample : {}) : (rSample || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RShipment} rShipment Shipment Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateShipment: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateShipment.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateShipment.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateShipment.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateShipment.');
             }
             // verify required parameter 'rShipment' is not null or undefined
             if (rShipment === null || rShipment === undefined) {
                 throw new RequiredError('rShipment','Required parameter rShipment was null or undefined when calling updateShipment.');
             }
             const localVarPath = `/org/shipment/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rShipment !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rShipment !== undefined ? rShipment : {}) : (rShipment || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateTestCollection: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateTestCollection.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateTestCollection.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateTestCollection.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateTestCollection.');
             }
             // verify required parameter 'rTestCollection' is not null or undefined
             if (rTestCollection === null || rTestCollection === undefined) {
                 throw new RequiredError('rTestCollection','Required parameter rTestCollection was null or undefined when calling updateTestCollection.');
             }
             const localVarPath = `/org/test_collection/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rTestCollection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rTestCollection !== undefined ? rTestCollection : {}) : (rTestCollection || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Data Exchange API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateTestInBooking: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateTestInBooking.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateTestInBooking.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateTestInBooking.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateTestInBooking.');
             }
             // verify required parameter 'rTestCollection' is not null or undefined
             if (rTestCollection === null || rTestCollection === undefined) {
                 throw new RequiredError('rTestCollection','Required parameter rTestCollection was null or undefined when calling updateTestInBooking.');
             }
             const localVarPath = `/org/data/exchange/testupdate`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rTestCollection !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rTestCollection !== undefined ? rTestCollection : {}) : (rTestCollection || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateUser: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateUser.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateUser.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateUser.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateUser.');
             }
             // verify required parameter 'rUser' is not null or undefined
             if (rUser === null || rUser === undefined) {
                 throw new RequiredError('rUser','Required parameter rUser was null or undefined when calling updateUser.');
             }
             const localVarPath = `/org/user/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rUser !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rUser !== undefined ? rUser : {}) : (rUser || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccine} rVaccine Vaccine Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateVaccine: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateVaccine.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateVaccine.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateVaccine.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateVaccine.');
             }
             // verify required parameter 'rVaccine' is not null or undefined
             if (rVaccine === null || rVaccine === undefined) {
                 throw new RequiredError('rVaccine','Required parameter rVaccine was null or undefined when calling updateVaccine.');
             }
             const localVarPath = `/org/vaccine/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rVaccine !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rVaccine !== undefined ? rVaccine : {}) : (rVaccine || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @summary Update VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateVaccineInjectData: async (apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options: any = {}): Promise<RequestArgs> => {
             // verify required parameter 'apiKey' is not null or undefined
             if (apiKey === null || apiKey === undefined) {
                 throw new RequiredError('apiKey','Required parameter apiKey was null or undefined when calling updateVaccineInjectData.');
             }
             // verify required parameter 'secretKey' is not null or undefined
             if (secretKey === null || secretKey === undefined) {
                 throw new RequiredError('secretKey','Required parameter secretKey was null or undefined when calling updateVaccineInjectData.');
             }
             // verify required parameter 'authToken' is not null or undefined
             if (authToken === null || authToken === undefined) {
                 throw new RequiredError('authToken','Required parameter authToken was null or undefined when calling updateVaccineInjectData.');
             }
             // verify required parameter 'centerID' is not null or undefined
             if (centerID === null || centerID === undefined) {
                 throw new RequiredError('centerID','Required parameter centerID was null or undefined when calling updateVaccineInjectData.');
             }
             // verify required parameter 'rVaccineInjectData' is not null or undefined
             if (rVaccineInjectData === null || rVaccineInjectData === undefined) {
                 throw new RequiredError('rVaccineInjectData','Required parameter rVaccineInjectData was null or undefined when calling updateVaccineInjectData.');
             }
             const localVarPath = `/org/vaccine_inject_data/update`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
             if (apiKey !== undefined && apiKey !== null) {
                 localVarHeaderParameter['Api-Key'] = String(apiKey);
             }
 
             if (secretKey !== undefined && secretKey !== null) {
                 localVarHeaderParameter['Secret-Key'] = String(secretKey);
             }
 
             if (authToken !== undefined && authToken !== null) {
                 localVarHeaderParameter['Auth-Token'] = String(authToken);
             }
 
             if (centerID !== undefined && centerID !== null) {
                 localVarHeaderParameter['Center-ID'] = String(centerID);
             }
 
 
     
             localVarHeaderParameter['Content-Type'] = 'application/json';
 
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
             const needsSerialization = (typeof rVaccineInjectData !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
             localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rVaccineInjectData !== undefined ? rVaccineInjectData : {}) : (rVaccineInjectData || "");
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
         /**
          * 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadCertificate: async (options: any = {}): Promise<RequestArgs> => {
             const localVarPath = `/super/user`;
             const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
             const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
             // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
             localVarUrlObj.search = null;
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: globalImportUrl.format(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * DefaultApi - functional programming interface
  * @export
  */
 export const DefaultApiFp = function(configuration?: Configuration) {
     return {
         /**
          * 
          * @summary Call Logs API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [org] 
          * @param {number} [time] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async callLogs(apiKey: string, secretKey: string, authToken: string, centerID: string, org?: string, time?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CallHistory>>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).callLogs(apiKey, secretKey, authToken, centerID, org, time, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Change Password of the User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {Login} login Account used to identify the user.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async changePassword(apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).changePassword(apiKey, secretKey, authToken, centerID, login, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RBag} rBag Bag Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createBag(apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createBag(apiKey, secretKey, authToken, centerID, rBag, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCarrier} rCarrier Carrier Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createCarrier(apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createCarrier(apiKey, secretKey, authToken, centerID, rCarrier, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createCovidOffsite(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createCovidOffsite(apiKey, secretKey, authToken, centerID, rCovidOffsite, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOffsiteTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOnsiteTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidPatient} rCovidPatient CovidPatient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createCovidPatient(apiKey, secretKey, authToken, centerID, rCovidPatient, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [slug] 
          * @param {FormDataContentDisposition} [file] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createFile(apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, file?: FormDataContentDisposition, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelString>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createFile(apiKey, secretKey, authToken, centerID, slug, file, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [slug] 
          * @param {ContentDisposition} [contentDisposition] 
          * @param {object} [entity] 
          * @param {BodyPartMediaType} [mediaType] 
          * @param {object} [messageBodyWorkers] 
          * @param {MultiPart} [parent] 
          * @param {object} [providers] 
          * @param {Array<BodyPart>} [bodyParts] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createFiles(apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, contentDisposition?: ContentDisposition, entity?: object, mediaType?: BodyPartMediaType, messageBodyWorkers?: object, parent?: MultiPart, providers?: object, bodyParts?: Array<BodyPart>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListString>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createFiles(apiKey, secretKey, authToken, centerID, slug, contentDisposition, entity, mediaType, messageBodyWorkers, parent, providers, bodyParts, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHomeTrip} rHomeTrip HomeTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createHomeTrip(apiKey, secretKey, authToken, centerID, rHomeTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospital} rHospital Hospital Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createHospital(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createHospital(apiKey, secretKey, authToken, centerID, rHospital, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createHospitalTrip(apiKey, secretKey, authToken, centerID, rHospitalTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLab} rLab Lab Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createLab(apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createLab(apiKey, secretKey, authToken, centerID, rLab, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabCollection} rLabCollection LabCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createLabCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createLabCollection(apiKey, secretKey, authToken, centerID, rLabCollection, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabTrip} rLabTrip LabTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createLabTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createLabTrip(apiKey, secretKey, authToken, centerID, rLabTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createMainCenter(apiKey, secretKey, authToken, centerID, rMainCenter, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPatient} rPatient Patient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createPatient(apiKey, secretKey, authToken, centerID, rPatient, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPouch} rPouch Pouch Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createPouch(apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createPouch(apiKey, secretKey, authToken, centerID, rPouch, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createPowerMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createPowerMainCenter(apiKey, secretKey, authToken, centerID, slug, rMainCenter, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createPowerUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createPowerUser(apiKey, secretKey, authToken, centerID, rUser, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Record
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTracker} rTracker Bag temperature logs
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createRecords(apiKey: string, secretKey: string, authToken: string, centerID: string, rTracker: RTracker, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createRecords(apiKey, secretKey, authToken, centerID, rTracker, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RRoute} rRoute Route Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createRoute(apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createRoute(apiKey, secretKey, authToken, centerID, rRoute, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RSample} rSample Sample Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createSample(apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createSample(apiKey, secretKey, authToken, centerID, rSample, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {string} tripType 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createShipment(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, version: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createShipment(apiKey, secretKey, authToken, centerID, tripId, tripType, version, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RShipment} rShipment Shipment Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createShipment1(apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createShipment1(apiKey, secretKey, authToken, centerID, rShipment, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {string} tripType 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createShipment2(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createShipment2(apiKey, secretKey, authToken, centerID, tripId, tripType, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createTestCollection(apiKey, secretKey, authToken, centerID, rTestCollection, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createUser(apiKey, secretKey, authToken, centerID, rUser, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccine} rVaccine Vaccine Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createVaccine(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createVaccine(apiKey, secretKey, authToken, centerID, rVaccine, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async createVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createVaccineInjectData(apiKey, secretKey, authToken, centerID, rVaccineInjectData, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteBagById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteBagById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteCarrierById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteCarrierById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteCovidOffsiteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteCovidOffsiteById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteCovidOffsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteCovidOffsiteTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteCovidOnsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteCovidOnsiteTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteCovidPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteCovidPatientById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteHomeTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteHomeTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteHospitalById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteHospitalById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteHospitalTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteHospitalTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteLabByd(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteLabByd(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteLabTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteLabTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteMainCenterByd(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteMainCenterByd(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteOrganisation(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deletePatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deletePatientById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deletePouchById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deletePouchById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteRouteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteRouteById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteSampleById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteSampleById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteShipmentById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteShipmentById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteTestCollectionById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteTestCollectionById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Identification of the user for delete.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteUserById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteUserById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteVaccineById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteVaccineById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Delete VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async deleteVaccineInjectDataById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteVaccineInjectDataById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Data Exchange API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} _function 
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async exchangeData(apiKey: string, secretKey: string, authToken: string, centerID: string, _function: string, type: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).exchangeData(apiKey, secretKey, authToken, centerID, _function, type, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Admin Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAdminAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRAccess>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAdminAccess(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRBag>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllBags(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCarrier>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCarriers(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidOffsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllCovidOffsites(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidOffsite>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCovidOffsites(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidOnsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllCovidPatients(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllCovidPatients(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRHomeTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllHomeTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRHospitalTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllHospitalTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllHospitals(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRHospital>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllHospitals(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllLabCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRLabCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllLabCollections(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRLabTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllLabTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllLabs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRLab>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllLabs(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRMainCenter>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllMainCenters(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllOrganisations(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListROrganisation>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllOrganisations(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllPatients(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllPatients(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllPouchs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRPouch>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllPouchs(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllRoutes(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRRoute>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllRoutes(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllSamples(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRSample>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllSamples(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllShipments(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRShipment>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllShipments(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllTestCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRTestCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllTestCollections(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Fetch All User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllUsers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRUser>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllUsers(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllVaccineInjectDatas(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRVaccineInjectData>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllVaccineInjectDatas(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getAllVaccines(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRVaccine>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAllVaccines(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getBagById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRBag>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBagById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCarrierById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRCarrier>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCarrierById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Fetch All Carrier Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCarrier>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCarriers(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Completed Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCompletedTableCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidOffsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCompletedTableCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Completed Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCompletedTableCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidOnsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCompletedTableCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Completed Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCompletedTableHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRHomeTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCompletedTableHomeTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Completed Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCompletedTableHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRHospitalTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCompletedTableHospitalTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Completed Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCompletedTableLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRLabTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCompletedTableLabTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Dashboard Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} option 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCovidDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, option: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelDashboard>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCovidDashboard(apiKey, secretKey, authToken, centerID, type, option, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get CovidPatient History
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCovidHistoryById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCovidHistoryById(apiKey, secretKey, authToken, centerID, id, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCovidOffsiteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRCovidOffsite>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCovidOffsiteById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCovidOffsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRCovidOffsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCovidOffsiteTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCovidOnsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRCovidOnsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCovidOnsiteTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getCovidPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRCovidPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getCovidPatientById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Dashboard Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelDashboard>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDashboard(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Dynamic List
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id id of the item on edit
          * @param {DynamicList} dynamicList Dynamic List from the form
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getDynamicList(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, dynamicList: DynamicList, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DynamicItem>>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getDynamicList(apiKey, secretKey, authToken, centerID, id, dynamicList, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Field Executive Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getFieldAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRAccess>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFieldAccess(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} fileName 
          * @param {string} centerId 
          * @param {string} [org] 
          * @param {string} [download] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getFile(apiKey: string, secretKey: string, authToken: string, centerID: string, fileName: string, centerId: string, org?: string, download?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFile(apiKey, secretKey, authToken, centerID, fileName, centerId, org, download, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Form
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type Form Name
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getFormType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelForm>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getFormType(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getHealth(options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Patient History
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getHistoryById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getHistoryById(apiKey, secretKey, authToken, centerID, id, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getHomeTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRHomeTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getHomeTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getHospitalById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRHospital>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getHospitalById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getHospitalTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRHospitalTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getHospitalTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getLabById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRLab>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getLabById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getLabCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRLabCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getLabCollections(apiKey, secretKey, authToken, centerID, tripId, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getLabTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRLabTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getLabTripById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getMainCenterById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRMainCenter>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMainCenterById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Map
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Map Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getMap(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMapPath>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMap(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Menus
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getMenus(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListMenu>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getMenus(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getModule(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRModule>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getModule(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Not Completed Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getNotCompletedCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidOffsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotCompletedCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Not Completed Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getNotCompletedCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidOnsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotCompletedCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Not Completed Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getNotCompletedHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRHomeTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotCompletedHomeTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Not Completed Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getNotCompletedHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRHospitalTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotCompletedHospitalTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Not Completed Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getNotCompletedLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRLabTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getNotCompletedLabTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelROrganisation>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getOrganisation(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPatientById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Phlebotomist Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getPhlebotomistAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRAccess>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPhlebotomistAccess(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getPouchById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRPouch>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPouchById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Power Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getPowerDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRPowerDashboard>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPowerDashboard(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getRouteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRRoute>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getRouteById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getSampleById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRSample>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSampleById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getShipmentById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRShipment>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getShipmentById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableAdmins(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRUser>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableAdmins(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRBag>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableBags(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableBagsByType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRBag>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableBagsByType(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCarrier>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableCarriers(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type Module Type
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableCarriersByType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCarrier>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableCarriersByType(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidOffsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableCovidOffsites(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidOffsite>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableCovidOffsites(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidOnsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCovidPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableCovidPatient(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Critical Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableCriticalBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRBag>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableCriticalBags(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRHomeTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableHomeTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRHospitalTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableHospitalTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableHospitals(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRHospital>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableHospitals(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRLabTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableLabTrips(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableLabs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRLab>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableLabs(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRMainCenter>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableMainCenters(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Non Critical Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableNonCriticalBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRBag>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableNonCriticalBags(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableOrganisations(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableROrganisation>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableOrganisations(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTablePatient(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTablePatient(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTablePowerAdmins(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRUser>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTablePowerAdmins(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTablePowerMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRMainCenter>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTablePowerMainCenters(apiKey, secretKey, authToken, centerID, slug, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTablePowerModules(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRModule>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTablePowerModules(apiKey, secretKey, authToken, centerID, slug, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableRoutes(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRRoute>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableRoutes(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableSamples(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRSample>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableSamples(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableShipments(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRShipment>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableShipments(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableSuperCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRCarrier>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableSuperCarriers(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRTestCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableTestCollection(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRVaccineInjectData>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableVaccineInjectData(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Table Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTableVaccines(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTableRVaccine>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTableVaccines(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTestCollectionById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTestCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTestCollectionById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Sample Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTestGroups(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRSample>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTestGroups(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Sample Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} module 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTestGroupsWithModule(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, module: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRSample>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTestGroupsWithModule(apiKey, secretKey, authToken, centerID, type, module, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Trip TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTripTestCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRTestCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTripTestCollections(apiKey, secretKey, authToken, centerID, type, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Trip VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getTripVaccineInjectDatas(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRVaccineInjectData>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTripVaccineInjectDatas(apiKey, secretKey, authToken, centerID, type, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Fetch All Carrier and Admin Accounts
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getUserCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelUserCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUserCollection(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getVaccineById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRVaccine>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getVaccineById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get All Vaccine Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getVaccineGroups(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRVaccine>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getVaccineGroups(apiKey, secretKey, authToken, centerID, type, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Get VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async getVaccineInjectDataById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRVaccineInjectData>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getVaccineInjectDataById(apiKey, secretKey, authToken, centerID, id, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Login to service
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {Login} login Account used to identify the user.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async login(apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRUser>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).login(apiKey, secretKey, authToken, centerID, login, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Read Records
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async readRecords(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTracker>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).readRecords(apiKey, secretKey, authToken, centerID, tripId, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject2} [inlineObject2] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject2?: InlineObject2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidOffsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, inlineObject2, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the covidOnsite trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject3} [inlineObject3] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject3?: InlineObject3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidOnsiteTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, inlineObject3, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the CovidPatient
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject4} [inlineObject4] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchCovidPatientTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject4?: InlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCovidPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchCovidPatientTrip(apiKey, secretKey, authToken, centerID, inlineObject4, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject} [inlineObject] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRBag>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip(apiKey, secretKey, authToken, centerID, inlineObject, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject1} [inlineObject1] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip1(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject1?: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRCarrier>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip1(apiKey, secretKey, authToken, centerID, inlineObject1, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject6} [inlineObject6] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip2(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject6?: InlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRHomeTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip2(apiKey, secretKey, authToken, centerID, inlineObject6, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the Lab
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject8} [inlineObject8] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip3(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject8?: InlineObject8, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRLab>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip3(apiKey, secretKey, authToken, centerID, inlineObject8, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the LabTrip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject9} [inlineObject9] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip4(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject9?: InlineObject9, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRLabTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip4(apiKey, secretKey, authToken, centerID, inlineObject9, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the Patient
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject10} [inlineObject10] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip5(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject10?: InlineObject10, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRPatient>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip5(apiKey, secretKey, authToken, centerID, inlineObject10, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject11} [inlineObject11] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip6(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject11?: InlineObject11, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRShipment>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip6(apiKey, secretKey, authToken, centerID, inlineObject11, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the TestCollection
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject12} [inlineObject12] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHomeTrip7(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject12?: InlineObject12, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRTestCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHomeTrip7(apiKey, secretKey, authToken, centerID, inlineObject12, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject7} [inlineObject7] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject7?: InlineObject7, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRHospitalTrip>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchHospitalTrip(apiKey, secretKey, authToken, centerID, inlineObject7, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Search the VaccineInjectData
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject13} [inlineObject13] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async searchVaccineInjectDataTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject13?: InlineObject13, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRVaccineInjectData>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).searchVaccineInjectDataTrip(apiKey, secretKey, authToken, centerID, inlineObject13, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Create Organisation Profile and Super Admin Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {ROrganisation} rOrganisation Organisation Profile with Super Admin Account.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async signUp(apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).signUp(apiKey, secretKey, authToken, centerID, rOrganisation, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Access
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RAccess} rAccess Access that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, rAccess: RAccess, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateAccess(apiKey, secretKey, authToken, centerID, rAccess, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RBag} rBag Bag Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateBag(apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateBag(apiKey, secretKey, authToken, centerID, rBag, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCarrier} rCarrier Carrier Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateCarrier(apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateCarrier(apiKey, secretKey, authToken, centerID, rCarrier, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateCovidOffsite(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateCovidOffsite(apiKey, secretKey, authToken, centerID, rCovidOffsite, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOffsiteTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOnsiteTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidPatient} rCovidPatient CovidPatient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateCovidPatient(apiKey, secretKey, authToken, centerID, rCovidPatient, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHomeTrip} rHomeTrip HomeTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateHomeTrip(apiKey, secretKey, authToken, centerID, rHomeTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospital} rHospital Hospital Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateHospital(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateHospital(apiKey, secretKey, authToken, centerID, rHospital, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateHospitalTrip(apiKey, secretKey, authToken, centerID, rHospitalTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLab} rLab Lab Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateLab(apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateLab(apiKey, secretKey, authToken, centerID, rLab, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabCollection} rLabCollection LabCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateLabCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateLabCollection(apiKey, secretKey, authToken, centerID, rLabCollection, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabTrip} rLabTrip LabTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateLabTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateLabTrip(apiKey, secretKey, authToken, centerID, rLabTrip, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateMainCenter(apiKey, secretKey, authToken, centerID, rMainCenter, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RModule} rModule Module that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateModule(apiKey: string, secretKey: string, authToken: string, centerID: string, rModule: RModule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateModule(apiKey, secretKey, authToken, centerID, rModule, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {ROrganisation} rOrganisation Organisation Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateOrganisation(apiKey, secretKey, authToken, centerID, rOrganisation, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Carrier
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updatePatch(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelListRUser>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updatePatch(apiKey, secretKey, authToken, centerID, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPatient} rPatient Patient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updatePatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updatePatient(apiKey, secretKey, authToken, centerID, rPatient, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPouch} rPouch Pouch Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updatePouch(apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updatePouch(apiKey, secretKey, authToken, centerID, rPouch, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updatePowerMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updatePowerMainCenter(apiKey, secretKey, authToken, centerID, slug, rMainCenter, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RModule} rModule Module that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updatePowerModule(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rModule: RModule, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updatePowerModule(apiKey, secretKey, authToken, centerID, slug, rModule, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updatePowerUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updatePowerUser(apiKey, secretKey, authToken, centerID, rUser, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RRoute} rRoute Route Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateRoute(apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateRoute(apiKey, secretKey, authToken, centerID, rRoute, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RSample} rSample Sample Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateSample(apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateSample(apiKey, secretKey, authToken, centerID, rSample, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RShipment} rShipment Shipment Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateShipment(apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateShipment(apiKey, secretKey, authToken, centerID, rShipment, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateTestCollection(apiKey, secretKey, authToken, centerID, rTestCollection, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Data Exchange API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateTestInBooking(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelRTestCollection>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateTestInBooking(apiKey, secretKey, authToken, centerID, rTestCollection, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateUser(apiKey, secretKey, authToken, centerID, rUser, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccine} rVaccine Vaccine Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateVaccine(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateVaccine(apiKey, secretKey, authToken, centerID, rVaccine, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @summary Update VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async updateVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RModelMessage>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateVaccineInjectData(apiKey, secretKey, authToken, centerID, rVaccineInjectData, options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
         /**
          * 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async uploadCertificate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
             const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).uploadCertificate(options);
             return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                 const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                 return axios.request(axiosRequestArgs);
             };
         },
     }
 };
 
 /**
  * DefaultApi - factory interface
  * @export
  */
 export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     return {
         /**
          * 
          * @summary Call Logs API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [org] 
          * @param {number} [time] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         callLogs(apiKey: string, secretKey: string, authToken: string, centerID: string, org?: string, time?: number, options?: any): AxiosPromise<Array<CallHistory>> {
             return DefaultApiFp(configuration).callLogs(apiKey, secretKey, authToken, centerID, org, time, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Change Password of the User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {Login} login Account used to identify the user.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         changePassword(apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).changePassword(apiKey, secretKey, authToken, centerID, login, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RBag} rBag Bag Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createBag(apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createBag(apiKey, secretKey, authToken, centerID, rBag, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCarrier} rCarrier Carrier Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCarrier(apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createCarrier(apiKey, secretKey, authToken, centerID, rCarrier, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidOffsite(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createCovidOffsite(apiKey, secretKey, authToken, centerID, rCovidOffsite, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOffsiteTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOnsiteTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidPatient} rCovidPatient CovidPatient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createCovidPatient(apiKey, secretKey, authToken, centerID, rCovidPatient, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [slug] 
          * @param {FormDataContentDisposition} [file] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createFile(apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, file?: FormDataContentDisposition, options?: any): AxiosPromise<RModelString> {
             return DefaultApiFp(configuration).createFile(apiKey, secretKey, authToken, centerID, slug, file, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} [slug] 
          * @param {ContentDisposition} [contentDisposition] 
          * @param {object} [entity] 
          * @param {BodyPartMediaType} [mediaType] 
          * @param {object} [messageBodyWorkers] 
          * @param {MultiPart} [parent] 
          * @param {object} [providers] 
          * @param {Array<BodyPart>} [bodyParts] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createFiles(apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, contentDisposition?: ContentDisposition, entity?: object, mediaType?: BodyPartMediaType, messageBodyWorkers?: object, parent?: MultiPart, providers?: object, bodyParts?: Array<BodyPart>, options?: any): AxiosPromise<RModelListString> {
             return DefaultApiFp(configuration).createFiles(apiKey, secretKey, authToken, centerID, slug, contentDisposition, entity, mediaType, messageBodyWorkers, parent, providers, bodyParts, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHomeTrip} rHomeTrip HomeTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createHomeTrip(apiKey, secretKey, authToken, centerID, rHomeTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospital} rHospital Hospital Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createHospital(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createHospital(apiKey, secretKey, authToken, centerID, rHospital, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createHospitalTrip(apiKey, secretKey, authToken, centerID, rHospitalTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLab} rLab Lab Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createLab(apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createLab(apiKey, secretKey, authToken, centerID, rLab, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabCollection} rLabCollection LabCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createLabCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createLabCollection(apiKey, secretKey, authToken, centerID, rLabCollection, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabTrip} rLabTrip LabTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createLabTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createLabTrip(apiKey, secretKey, authToken, centerID, rLabTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createMainCenter(apiKey, secretKey, authToken, centerID, rMainCenter, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPatient} rPatient Patient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createPatient(apiKey, secretKey, authToken, centerID, rPatient, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPouch} rPouch Pouch Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPouch(apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createPouch(apiKey, secretKey, authToken, centerID, rPouch, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPowerMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createPowerMainCenter(apiKey, secretKey, authToken, centerID, slug, rMainCenter, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createPowerUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createPowerUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Record
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTracker} rTracker Bag temperature logs
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createRecords(apiKey: string, secretKey: string, authToken: string, centerID: string, rTracker: RTracker, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createRecords(apiKey, secretKey, authToken, centerID, rTracker, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RRoute} rRoute Route Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createRoute(apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createRoute(apiKey, secretKey, authToken, centerID, rRoute, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RSample} rSample Sample Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createSample(apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createSample(apiKey, secretKey, authToken, centerID, rSample, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {string} tripType 
          * @param {string} version 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createShipment(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, version: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createShipment(apiKey, secretKey, authToken, centerID, tripId, tripType, version, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RShipment} rShipment Shipment Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createShipment1(apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createShipment1(apiKey, secretKey, authToken, centerID, rShipment, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {string} tripType 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createShipment2(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createShipment2(apiKey, secretKey, authToken, centerID, tripId, tripType, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createTestCollection(apiKey, secretKey, authToken, centerID, rTestCollection, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccine} rVaccine Vaccine Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createVaccine(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createVaccine(apiKey, secretKey, authToken, centerID, rVaccine, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         createVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).createVaccineInjectData(apiKey, secretKey, authToken, centerID, rVaccineInjectData, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteBagById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteBagById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCarrierById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteCarrierById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidOffsiteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteCovidOffsiteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidOffsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteCovidOffsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidOnsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteCovidOnsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteCovidPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteCovidPatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteHomeTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteHomeTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteHospitalById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteHospitalById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteHospitalTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteHospitalTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteLabByd(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteLabByd(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteLabTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteLabTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteMainCenterByd(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteMainCenterByd(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteOrganisation(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deletePatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deletePatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deletePouchById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deletePouchById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteRouteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteRouteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteSampleById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteSampleById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteShipmentById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteShipmentById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteTestCollectionById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteTestCollectionById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Identification of the user for delete.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteUserById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteUserById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteVaccineById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteVaccineById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Delete VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         deleteVaccineInjectDataById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).deleteVaccineInjectDataById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Data Exchange API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} _function 
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         exchangeData(apiKey: string, secretKey: string, authToken: string, centerID: string, _function: string, type: string, id: string, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).exchangeData(apiKey, secretKey, authToken, centerID, _function, type, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Admin Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAdminAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRTableRAccess> {
             return DefaultApiFp(configuration).getAdminAccess(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRBag> {
             return DefaultApiFp(configuration).getAllBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRCarrier> {
             return DefaultApiFp(configuration).getAllCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRCovidOffsiteTrip> {
             return DefaultApiFp(configuration).getAllCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidOffsites(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRCovidOffsite> {
             return DefaultApiFp(configuration).getAllCovidOffsites(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRCovidOnsiteTrip> {
             return DefaultApiFp(configuration).getAllCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllCovidPatients(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelListRCovidPatient> {
             return DefaultApiFp(configuration).getAllCovidPatients(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRHomeTrip> {
             return DefaultApiFp(configuration).getAllHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRHospitalTrip> {
             return DefaultApiFp(configuration).getAllHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllHospitals(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRHospital> {
             return DefaultApiFp(configuration).getAllHospitals(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllLabCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRLabCollection> {
             return DefaultApiFp(configuration).getAllLabCollections(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRLabTrip> {
             return DefaultApiFp(configuration).getAllLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllLabs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRLab> {
             return DefaultApiFp(configuration).getAllLabs(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRMainCenter> {
             return DefaultApiFp(configuration).getAllMainCenters(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllOrganisations(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListROrganisation> {
             return DefaultApiFp(configuration).getAllOrganisations(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllPatients(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelListRPatient> {
             return DefaultApiFp(configuration).getAllPatients(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllPouchs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRPouch> {
             return DefaultApiFp(configuration).getAllPouchs(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllRoutes(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRRoute> {
             return DefaultApiFp(configuration).getAllRoutes(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllSamples(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRSample> {
             return DefaultApiFp(configuration).getAllSamples(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllShipments(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRShipment> {
             return DefaultApiFp(configuration).getAllShipments(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllTestCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelListRTestCollection> {
             return DefaultApiFp(configuration).getAllTestCollections(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Fetch All User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllUsers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRUser> {
             return DefaultApiFp(configuration).getAllUsers(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllVaccineInjectDatas(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelListRVaccineInjectData> {
             return DefaultApiFp(configuration).getAllVaccineInjectDatas(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getAllVaccines(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRVaccine> {
             return DefaultApiFp(configuration).getAllVaccines(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getBagById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRBag> {
             return DefaultApiFp(configuration).getBagById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCarrierById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRCarrier> {
             return DefaultApiFp(configuration).getCarrierById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Fetch All Carrier Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRCarrier> {
             return DefaultApiFp(configuration).getCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Completed Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCovidOffsiteTrip> {
             return DefaultApiFp(configuration).getCompletedTableCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Completed Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCovidOnsiteTrip> {
             return DefaultApiFp(configuration).getCompletedTableCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Completed Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRHomeTrip> {
             return DefaultApiFp(configuration).getCompletedTableHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Completed Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRHospitalTrip> {
             return DefaultApiFp(configuration).getCompletedTableHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Completed Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCompletedTableLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRLabTrip> {
             return DefaultApiFp(configuration).getCompletedTableLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Dashboard Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} option 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, option: string, options?: any): AxiosPromise<RModelDashboard> {
             return DefaultApiFp(configuration).getCovidDashboard(apiKey, secretKey, authToken, centerID, type, option, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get CovidPatient History
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidHistoryById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options?: any): AxiosPromise<RModelListRCovidTrip> {
             return DefaultApiFp(configuration).getCovidHistoryById(apiKey, secretKey, authToken, centerID, id, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidOffsiteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRCovidOffsite> {
             return DefaultApiFp(configuration).getCovidOffsiteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidOffsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRCovidOffsiteTrip> {
             return DefaultApiFp(configuration).getCovidOffsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidOnsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRCovidOnsiteTrip> {
             return DefaultApiFp(configuration).getCovidOnsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getCovidPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRCovidPatient> {
             return DefaultApiFp(configuration).getCovidPatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Dashboard Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelDashboard> {
             return DefaultApiFp(configuration).getDashboard(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Dynamic List
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id id of the item on edit
          * @param {DynamicList} dynamicList Dynamic List from the form
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getDynamicList(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, dynamicList: DynamicList, options?: any): AxiosPromise<Array<DynamicItem>> {
             return DefaultApiFp(configuration).getDynamicList(apiKey, secretKey, authToken, centerID, id, dynamicList, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Field Executive Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getFieldAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRTableRAccess> {
             return DefaultApiFp(configuration).getFieldAccess(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} fileName 
          * @param {string} centerId 
          * @param {string} [org] 
          * @param {string} [download] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getFile(apiKey: string, secretKey: string, authToken: string, centerID: string, fileName: string, centerId: string, org?: string, download?: string, options?: any): AxiosPromise<void> {
             return DefaultApiFp(configuration).getFile(apiKey, secretKey, authToken, centerID, fileName, centerId, org, download, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Form
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type Form Name
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getFormType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelForm> {
             return DefaultApiFp(configuration).getFormType(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHealth(options?: any): AxiosPromise<string> {
             return DefaultApiFp(configuration).getHealth(options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Patient History
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHistoryById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options?: any): AxiosPromise<RModelListRTrip> {
             return DefaultApiFp(configuration).getHistoryById(apiKey, secretKey, authToken, centerID, id, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHomeTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRHomeTrip> {
             return DefaultApiFp(configuration).getHomeTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHospitalById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRHospital> {
             return DefaultApiFp(configuration).getHospitalById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getHospitalTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRHospitalTrip> {
             return DefaultApiFp(configuration).getHospitalTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getLabById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRLab> {
             return DefaultApiFp(configuration).getLabById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getLabCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options?: any): AxiosPromise<RModelListRLabCollection> {
             return DefaultApiFp(configuration).getLabCollections(apiKey, secretKey, authToken, centerID, tripId, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getLabTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRLabTrip> {
             return DefaultApiFp(configuration).getLabTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getMainCenterById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRMainCenter> {
             return DefaultApiFp(configuration).getMainCenterById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Map
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Map Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getMap(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelMapPath> {
             return DefaultApiFp(configuration).getMap(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Menus
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getMenus(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListMenu> {
             return DefaultApiFp(configuration).getMenus(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getModule(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRTableRModule> {
             return DefaultApiFp(configuration).getModule(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Not Completed Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCovidOffsiteTrip> {
             return DefaultApiFp(configuration).getNotCompletedCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Not Completed Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCovidOnsiteTrip> {
             return DefaultApiFp(configuration).getNotCompletedCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Not Completed Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRHomeTrip> {
             return DefaultApiFp(configuration).getNotCompletedHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Not Completed Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRHospitalTrip> {
             return DefaultApiFp(configuration).getNotCompletedHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Not Completed Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getNotCompletedLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRLabTrip> {
             return DefaultApiFp(configuration).getNotCompletedLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelROrganisation> {
             return DefaultApiFp(configuration).getOrganisation(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRPatient> {
             return DefaultApiFp(configuration).getPatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Phlebotomist Features
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id Center Id
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPhlebotomistAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRTableRAccess> {
             return DefaultApiFp(configuration).getPhlebotomistAccess(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPouchById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRPouch> {
             return DefaultApiFp(configuration).getPouchById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Power Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getPowerDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelRPowerDashboard> {
             return DefaultApiFp(configuration).getPowerDashboard(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getRouteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRRoute> {
             return DefaultApiFp(configuration).getRouteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getSampleById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRSample> {
             return DefaultApiFp(configuration).getSampleById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getShipmentById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRShipment> {
             return DefaultApiFp(configuration).getShipmentById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableAdmins(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRUser> {
             return DefaultApiFp(configuration).getTableAdmins(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRBag> {
             return DefaultApiFp(configuration).getTableBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableBagsByType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelRTableRBag> {
             return DefaultApiFp(configuration).getTableBagsByType(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCarrier> {
             return DefaultApiFp(configuration).getTableCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type Module Type
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCarriersByType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelRTableRCarrier> {
             return DefaultApiFp(configuration).getTableCarriersByType(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCovidOffsiteTrip> {
             return DefaultApiFp(configuration).getTableCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidOffsites(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCovidOffsite> {
             return DefaultApiFp(configuration).getTableCovidOffsites(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCovidOnsiteTrip> {
             return DefaultApiFp(configuration).getTableCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelRTableRCovidPatient> {
             return DefaultApiFp(configuration).getTableCovidPatient(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Critical Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableCriticalBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRBag> {
             return DefaultApiFp(configuration).getTableCriticalBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRHomeTrip> {
             return DefaultApiFp(configuration).getTableHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRHospitalTrip> {
             return DefaultApiFp(configuration).getTableHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableHospitals(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRHospital> {
             return DefaultApiFp(configuration).getTableHospitals(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRLabTrip> {
             return DefaultApiFp(configuration).getTableLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableLabs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRLab> {
             return DefaultApiFp(configuration).getTableLabs(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRMainCenter> {
             return DefaultApiFp(configuration).getTableMainCenters(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Non Critical Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableNonCriticalBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRBag> {
             return DefaultApiFp(configuration).getTableNonCriticalBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableOrganisations(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableROrganisation> {
             return DefaultApiFp(configuration).getTableOrganisations(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePatient(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelRTableRPatient> {
             return DefaultApiFp(configuration).getTablePatient(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePowerAdmins(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRUser> {
             return DefaultApiFp(configuration).getTablePowerAdmins(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePowerMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options?: any): AxiosPromise<RModelRTableRMainCenter> {
             return DefaultApiFp(configuration).getTablePowerMainCenters(apiKey, secretKey, authToken, centerID, slug, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Admin Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTablePowerModules(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options?: any): AxiosPromise<RModelRTableRModule> {
             return DefaultApiFp(configuration).getTablePowerModules(apiKey, secretKey, authToken, centerID, slug, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableRoutes(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRRoute> {
             return DefaultApiFp(configuration).getTableRoutes(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableSamples(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRSample> {
             return DefaultApiFp(configuration).getTableSamples(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableShipments(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRShipment> {
             return DefaultApiFp(configuration).getTableShipments(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableSuperCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRCarrier> {
             return DefaultApiFp(configuration).getTableSuperCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelRTableRTestCollection> {
             return DefaultApiFp(configuration).getTableTestCollection(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelRTableRVaccineInjectData> {
             return DefaultApiFp(configuration).getTableVaccineInjectData(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Table Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTableVaccines(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelRTableRVaccine> {
             return DefaultApiFp(configuration).getTableVaccines(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTestCollectionById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRTestCollection> {
             return DefaultApiFp(configuration).getTestCollectionById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Sample Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTestGroups(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelListRSample> {
             return DefaultApiFp(configuration).getTestGroups(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Sample Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} module 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTestGroupsWithModule(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, module: string, options?: any): AxiosPromise<RModelListRSample> {
             return DefaultApiFp(configuration).getTestGroupsWithModule(apiKey, secretKey, authToken, centerID, type, module, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Trip TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTripTestCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options?: any): AxiosPromise<RModelListRTestCollection> {
             return DefaultApiFp(configuration).getTripTestCollections(apiKey, secretKey, authToken, centerID, type, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Trip VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getTripVaccineInjectDatas(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options?: any): AxiosPromise<RModelListRVaccineInjectData> {
             return DefaultApiFp(configuration).getTripVaccineInjectDatas(apiKey, secretKey, authToken, centerID, type, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Fetch All Carrier and Admin Accounts
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getUserCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelUserCollection> {
             return DefaultApiFp(configuration).getUserCollection(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getVaccineById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRVaccine> {
             return DefaultApiFp(configuration).getVaccineById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get All Vaccine Group Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} type 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getVaccineGroups(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any): AxiosPromise<RModelListRVaccine> {
             return DefaultApiFp(configuration).getVaccineGroups(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Get VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} id 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         getVaccineInjectDataById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any): AxiosPromise<RModelRVaccineInjectData> {
             return DefaultApiFp(configuration).getVaccineInjectDataById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Login to service
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {Login} login Account used to identify the user.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         login(apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options?: any): AxiosPromise<RModelRUser> {
             return DefaultApiFp(configuration).login(apiKey, secretKey, authToken, centerID, login, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Read Records
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} tripId 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         readRecords(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options?: any): AxiosPromise<RModelRTracker> {
             return DefaultApiFp(configuration).readRecords(apiKey, secretKey, authToken, centerID, tripId, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject2} [inlineObject2] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject2?: InlineObject2, options?: any): AxiosPromise<RModelListRCovidOffsiteTrip> {
             return DefaultApiFp(configuration).searchCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, inlineObject2, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the covidOnsite trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject3} [inlineObject3] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject3?: InlineObject3, options?: any): AxiosPromise<RModelListRCovidOnsiteTrip> {
             return DefaultApiFp(configuration).searchCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, inlineObject3, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the CovidPatient
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject4} [inlineObject4] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchCovidPatientTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject4?: InlineObject4, options?: any): AxiosPromise<RModelListRCovidPatient> {
             return DefaultApiFp(configuration).searchCovidPatientTrip(apiKey, secretKey, authToken, centerID, inlineObject4, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject} [inlineObject] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject?: InlineObject, options?: any): AxiosPromise<RModelListRBag> {
             return DefaultApiFp(configuration).searchHomeTrip(apiKey, secretKey, authToken, centerID, inlineObject, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject1} [inlineObject1] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip1(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject1?: InlineObject1, options?: any): AxiosPromise<RModelListRCarrier> {
             return DefaultApiFp(configuration).searchHomeTrip1(apiKey, secretKey, authToken, centerID, inlineObject1, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject6} [inlineObject6] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip2(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject6?: InlineObject6, options?: any): AxiosPromise<RModelListRHomeTrip> {
             return DefaultApiFp(configuration).searchHomeTrip2(apiKey, secretKey, authToken, centerID, inlineObject6, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the Lab
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject8} [inlineObject8] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip3(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject8?: InlineObject8, options?: any): AxiosPromise<RModelListRLab> {
             return DefaultApiFp(configuration).searchHomeTrip3(apiKey, secretKey, authToken, centerID, inlineObject8, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the LabTrip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject9} [inlineObject9] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip4(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject9?: InlineObject9, options?: any): AxiosPromise<RModelListRLabTrip> {
             return DefaultApiFp(configuration).searchHomeTrip4(apiKey, secretKey, authToken, centerID, inlineObject9, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the Patient
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject10} [inlineObject10] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip5(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject10?: InlineObject10, options?: any): AxiosPromise<RModelListRPatient> {
             return DefaultApiFp(configuration).searchHomeTrip5(apiKey, secretKey, authToken, centerID, inlineObject10, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the Bag
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject11} [inlineObject11] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip6(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject11?: InlineObject11, options?: any): AxiosPromise<RModelListRShipment> {
             return DefaultApiFp(configuration).searchHomeTrip6(apiKey, secretKey, authToken, centerID, inlineObject11, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the TestCollection
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject12} [inlineObject12] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHomeTrip7(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject12?: InlineObject12, options?: any): AxiosPromise<RModelListRTestCollection> {
             return DefaultApiFp(configuration).searchHomeTrip7(apiKey, secretKey, authToken, centerID, inlineObject12, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the home trip
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject7} [inlineObject7] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject7?: InlineObject7, options?: any): AxiosPromise<RModelListRHospitalTrip> {
             return DefaultApiFp(configuration).searchHospitalTrip(apiKey, secretKey, authToken, centerID, inlineObject7, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Search the VaccineInjectData
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {InlineObject13} [inlineObject13] 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         searchVaccineInjectDataTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject13?: InlineObject13, options?: any): AxiosPromise<RModelListRVaccineInjectData> {
             return DefaultApiFp(configuration).searchVaccineInjectDataTrip(apiKey, secretKey, authToken, centerID, inlineObject13, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Create Organisation Profile and Super Admin Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {ROrganisation} rOrganisation Organisation Profile with Super Admin Account.
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         signUp(apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).signUp(apiKey, secretKey, authToken, centerID, rOrganisation, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Access
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RAccess} rAccess Access that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, rAccess: RAccess, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateAccess(apiKey, secretKey, authToken, centerID, rAccess, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Bag Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RBag} rBag Bag Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateBag(apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateBag(apiKey, secretKey, authToken, centerID, rBag, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Carrier Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCarrier} rCarrier Carrier Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCarrier(apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateCarrier(apiKey, secretKey, authToken, centerID, rCarrier, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update CovidOffsite Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidOffsite(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateCovidOffsite(apiKey, secretKey, authToken, centerID, rCovidOffsite, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update CovidOffsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOffsiteTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update CovidOnsiteTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOnsiteTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update CovidPatient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RCovidPatient} rCovidPatient CovidPatient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateCovidPatient(apiKey, secretKey, authToken, centerID, rCovidPatient, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update HomeTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHomeTrip} rHomeTrip HomeTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateHomeTrip(apiKey, secretKey, authToken, centerID, rHomeTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Hospital Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospital} rHospital Hospital Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateHospital(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateHospital(apiKey, secretKey, authToken, centerID, rHospital, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update HospitalTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateHospitalTrip(apiKey, secretKey, authToken, centerID, rHospitalTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Lab Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLab} rLab Lab Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateLab(apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateLab(apiKey, secretKey, authToken, centerID, rLab, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update LabCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabCollection} rLabCollection LabCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateLabCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateLabCollection(apiKey, secretKey, authToken, centerID, rLabCollection, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update LabTrip Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RLabTrip} rLabTrip LabTrip Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateLabTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateLabTrip(apiKey, secretKey, authToken, centerID, rLabTrip, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateMainCenter(apiKey, secretKey, authToken, centerID, rMainCenter, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RModule} rModule Module that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateModule(apiKey: string, secretKey: string, authToken: string, centerID: string, rModule: RModule, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateModule(apiKey, secretKey, authToken, centerID, rModule, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Organisation Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {ROrganisation} rOrganisation Organisation Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateOrganisation(apiKey, secretKey, authToken, centerID, rOrganisation, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Carrier
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePatch(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any): AxiosPromise<RModelListRUser> {
             return DefaultApiFp(configuration).updatePatch(apiKey, secretKey, authToken, centerID, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Patient Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPatient} rPatient Patient Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updatePatient(apiKey, secretKey, authToken, centerID, rPatient, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Pouch Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RPouch} rPouch Pouch Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePouch(apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updatePouch(apiKey, secretKey, authToken, centerID, rPouch, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update MainCenter Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RMainCenter} rMainCenter MainCenter Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePowerMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updatePowerMainCenter(apiKey, secretKey, authToken, centerID, slug, rMainCenter, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Module
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {string} slug Organisation Slug
          * @param {RModule} rModule Module that need to be updated
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePowerModule(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rModule: RModule, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updatePowerModule(apiKey, secretKey, authToken, centerID, slug, rModule, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updatePowerUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updatePowerUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Route Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RRoute} rRoute Route Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateRoute(apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateRoute(apiKey, secretKey, authToken, centerID, rRoute, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Sample Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RSample} rSample Sample Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateSample(apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateSample(apiKey, secretKey, authToken, centerID, rSample, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Shipment Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RShipment} rShipment Shipment Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateShipment(apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateShipment(apiKey, secretKey, authToken, centerID, rShipment, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update TestCollection Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateTestCollection(apiKey, secretKey, authToken, centerID, rTestCollection, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Data Exchange API
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RTestCollection} rTestCollection TestCollection Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateTestInBooking(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any): AxiosPromise<RModelRTestCollection> {
             return DefaultApiFp(configuration).updateTestInBooking(apiKey, secretKey, authToken, centerID, rTestCollection, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update User Account
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RUser} rUser User account that need to be created
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update Vaccine Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccine} rVaccine Vaccine Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateVaccine(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateVaccine(apiKey, secretKey, authToken, centerID, rVaccine, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @summary Update VaccineInjectData Profile
          * @param {string} apiKey API Key to identification of service
          * @param {string} secretKey Secret Key to identification of service
          * @param {string} authToken Auth Token to identification of user
          * @param {string} centerID Identification of main center data
          * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         updateVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options?: any): AxiosPromise<RModelMessage> {
             return DefaultApiFp(configuration).updateVaccineInjectData(apiKey, secretKey, authToken, centerID, rVaccineInjectData, options).then((request) => request(axios, basePath));
         },
         /**
          * 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         uploadCertificate(options?: any): AxiosPromise<string> {
             return DefaultApiFp(configuration).uploadCertificate(options).then((request) => request(axios, basePath));
         },
     };
 };
 
 /**
  * DefaultApi - object-oriented interface
  * @export
  * @class DefaultApi
  * @extends {BaseAPI}
  */
 export class DefaultApi extends BaseAPI {
     /**
      * 
      * @summary Call Logs API
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} [org] 
      * @param {number} [time] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public callLogs(apiKey: string, secretKey: string, authToken: string, centerID: string, org?: string, time?: number, options?: any) {
         return DefaultApiFp(this.configuration).callLogs(apiKey, secretKey, authToken, centerID, org, time, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Change Password of the User Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {Login} login Account used to identify the user.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public changePassword(apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options?: any) {
         return DefaultApiFp(this.configuration).changePassword(apiKey, secretKey, authToken, centerID, login, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RBag} rBag Bag Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createBag(apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options?: any) {
         return DefaultApiFp(this.configuration).createBag(apiKey, secretKey, authToken, centerID, rBag, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCarrier} rCarrier Carrier Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createCarrier(apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options?: any) {
         return DefaultApiFp(this.configuration).createCarrier(apiKey, secretKey, authToken, centerID, rCarrier, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create CovidOffsite Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createCovidOffsite(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options?: any) {
         return DefaultApiFp(this.configuration).createCovidOffsite(apiKey, secretKey, authToken, centerID, rCovidOffsite, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options?: any) {
         return DefaultApiFp(this.configuration).createCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOffsiteTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options?: any) {
         return DefaultApiFp(this.configuration).createCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOnsiteTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create CovidPatient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidPatient} rCovidPatient CovidPatient Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options?: any) {
         return DefaultApiFp(this.configuration).createCovidPatient(apiKey, secretKey, authToken, centerID, rCovidPatient, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} [slug] 
      * @param {FormDataContentDisposition} [file] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createFile(apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, file?: FormDataContentDisposition, options?: any) {
         return DefaultApiFp(this.configuration).createFile(apiKey, secretKey, authToken, centerID, slug, file, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} [slug] 
      * @param {ContentDisposition} [contentDisposition] 
      * @param {object} [entity] 
      * @param {BodyPartMediaType} [mediaType] 
      * @param {object} [messageBodyWorkers] 
      * @param {MultiPart} [parent] 
      * @param {object} [providers] 
      * @param {Array<BodyPart>} [bodyParts] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createFiles(apiKey: string, secretKey: string, authToken: string, centerID: string, slug?: string, contentDisposition?: ContentDisposition, entity?: object, mediaType?: BodyPartMediaType, messageBodyWorkers?: object, parent?: MultiPart, providers?: object, bodyParts?: Array<BodyPart>, options?: any) {
         return DefaultApiFp(this.configuration).createFiles(apiKey, secretKey, authToken, centerID, slug, contentDisposition, entity, mediaType, messageBodyWorkers, parent, providers, bodyParts, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RHomeTrip} rHomeTrip HomeTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options?: any) {
         return DefaultApiFp(this.configuration).createHomeTrip(apiKey, secretKey, authToken, centerID, rHomeTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Hospital Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RHospital} rHospital Hospital Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createHospital(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options?: any) {
         return DefaultApiFp(this.configuration).createHospital(apiKey, secretKey, authToken, centerID, rHospital, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options?: any) {
         return DefaultApiFp(this.configuration).createHospitalTrip(apiKey, secretKey, authToken, centerID, rHospitalTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Lab Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RLab} rLab Lab Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createLab(apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options?: any) {
         return DefaultApiFp(this.configuration).createLab(apiKey, secretKey, authToken, centerID, rLab, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create LabCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RLabCollection} rLabCollection LabCollection Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createLabCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options?: any) {
         return DefaultApiFp(this.configuration).createLabCollection(apiKey, secretKey, authToken, centerID, rLabCollection, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RLabTrip} rLabTrip LabTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createLabTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options?: any) {
         return DefaultApiFp(this.configuration).createLabTrip(apiKey, secretKey, authToken, centerID, rLabTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RMainCenter} rMainCenter MainCenter Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options?: any) {
         return DefaultApiFp(this.configuration).createMainCenter(apiKey, secretKey, authToken, centerID, rMainCenter, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Patient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RPatient} rPatient Patient Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options?: any) {
         return DefaultApiFp(this.configuration).createPatient(apiKey, secretKey, authToken, centerID, rPatient, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Pouch Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RPouch} rPouch Pouch Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createPouch(apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options?: any) {
         return DefaultApiFp(this.configuration).createPouch(apiKey, secretKey, authToken, centerID, rPouch, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} slug Organisation Slug
      * @param {RMainCenter} rMainCenter MainCenter Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createPowerMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options?: any) {
         return DefaultApiFp(this.configuration).createPowerMainCenter(apiKey, secretKey, authToken, centerID, slug, rMainCenter, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create User Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RUser} rUser User account that need to be created
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createPowerUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any) {
         return DefaultApiFp(this.configuration).createPowerUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Record
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RTracker} rTracker Bag temperature logs
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createRecords(apiKey: string, secretKey: string, authToken: string, centerID: string, rTracker: RTracker, options?: any) {
         return DefaultApiFp(this.configuration).createRecords(apiKey, secretKey, authToken, centerID, rTracker, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Route Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RRoute} rRoute Route Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createRoute(apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options?: any) {
         return DefaultApiFp(this.configuration).createRoute(apiKey, secretKey, authToken, centerID, rRoute, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Sample Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RSample} rSample Sample Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createSample(apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options?: any) {
         return DefaultApiFp(this.configuration).createSample(apiKey, secretKey, authToken, centerID, rSample, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} tripId 
      * @param {string} tripType 
      * @param {string} version 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createShipment(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, version: string, options?: any) {
         return DefaultApiFp(this.configuration).createShipment(apiKey, secretKey, authToken, centerID, tripId, tripType, version, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RShipment} rShipment Shipment Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createShipment1(apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options?: any) {
         return DefaultApiFp(this.configuration).createShipment1(apiKey, secretKey, authToken, centerID, rShipment, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} tripId 
      * @param {string} tripType 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createShipment2(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, tripType: string, options?: any) {
         return DefaultApiFp(this.configuration).createShipment2(apiKey, secretKey, authToken, centerID, tripId, tripType, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create TestCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RTestCollection} rTestCollection TestCollection Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any) {
         return DefaultApiFp(this.configuration).createTestCollection(apiKey, secretKey, authToken, centerID, rTestCollection, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create User Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RUser} rUser User account that need to be created
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any) {
         return DefaultApiFp(this.configuration).createUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Vaccine Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RVaccine} rVaccine Vaccine Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createVaccine(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options?: any) {
         return DefaultApiFp(this.configuration).createVaccine(apiKey, secretKey, authToken, centerID, rVaccine, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create VaccineInjectData Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public createVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options?: any) {
         return DefaultApiFp(this.configuration).createVaccineInjectData(apiKey, secretKey, authToken, centerID, rVaccineInjectData, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteBagById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteBagById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteCarrierById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteCarrierById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete CovidOffsite Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteCovidOffsiteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteCovidOffsiteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteCovidOffsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteCovidOffsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteCovidOnsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteCovidOnsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete CovidPatient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteCovidPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteCovidPatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteHomeTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteHomeTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Hospital Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteHospitalById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteHospitalById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteHospitalTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteHospitalTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Lab Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteLabByd(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteLabByd(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteLabTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteLabTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteMainCenterByd(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteMainCenterByd(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Organisation Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteOrganisation(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Patient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deletePatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deletePatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Pouch Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deletePouchById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deletePouchById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Route Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteRouteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteRouteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Sample Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteSampleById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteSampleById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteShipmentById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteShipmentById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete TestCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteTestCollectionById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteTestCollectionById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete User Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id Identification of the user for delete.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteUserById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteUserById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete Vaccine Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteVaccineById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteVaccineById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Delete VaccineInjectData Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public deleteVaccineInjectDataById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).deleteVaccineInjectDataById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Data Exchange API
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} _function 
      * @param {string} type 
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public exchangeData(apiKey: string, secretKey: string, authToken: string, centerID: string, _function: string, type: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).exchangeData(apiKey, secretKey, authToken, centerID, _function, type, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Admin Features
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id Center Id
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAdminAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getAdminAccess(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All CovidOffsite Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllCovidOffsites(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllCovidOffsites(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All CovidPatient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllCovidPatients(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllCovidPatients(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Hospital Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllHospitals(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllHospitals(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All LabCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllLabCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllLabCollections(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Lab Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllLabs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllLabs(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllMainCenters(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Organisation Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllOrganisations(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllOrganisations(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Patient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllPatients(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllPatients(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Pouch Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllPouchs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllPouchs(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Route Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllRoutes(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllRoutes(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Sample Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllSamples(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllSamples(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllShipments(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllShipments(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All TestCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllTestCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllTestCollections(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Fetch All User Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllUsers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllUsers(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All VaccineInjectData Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllVaccineInjectDatas(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllVaccineInjectDatas(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Vaccine Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getAllVaccines(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getAllVaccines(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getBagById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getBagById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCarrierById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getCarrierById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Fetch All Carrier Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Completed Table CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCompletedTableCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getCompletedTableCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Completed Table CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCompletedTableCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getCompletedTableCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Completed Table HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCompletedTableHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getCompletedTableHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Completed Table HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCompletedTableHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getCompletedTableHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Completed Table LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCompletedTableLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getCompletedTableLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Dashboard Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {string} option 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCovidDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, option: string, options?: any) {
         return DefaultApiFp(this.configuration).getCovidDashboard(apiKey, secretKey, authToken, centerID, type, option, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get CovidPatient History
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCovidHistoryById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getCovidHistoryById(apiKey, secretKey, authToken, centerID, id, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get CovidOffsite Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCovidOffsiteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getCovidOffsiteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCovidOffsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getCovidOffsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCovidOnsiteTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getCovidOnsiteTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get CovidPatient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getCovidPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getCovidPatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Dashboard Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getDashboard(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Dynamic List
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id id of the item on edit
      * @param {DynamicList} dynamicList Dynamic List from the form
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getDynamicList(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, dynamicList: DynamicList, options?: any) {
         return DefaultApiFp(this.configuration).getDynamicList(apiKey, secretKey, authToken, centerID, id, dynamicList, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Field Executive Features
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id Center Id
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getFieldAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getFieldAccess(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} fileName 
      * @param {string} centerId 
      * @param {string} [org] 
      * @param {string} [download] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getFile(apiKey: string, secretKey: string, authToken: string, centerID: string, fileName: string, centerId: string, org?: string, download?: string, options?: any) {
         return DefaultApiFp(this.configuration).getFile(apiKey, secretKey, authToken, centerID, fileName, centerId, org, download, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Form
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type Form Name
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getFormType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getFormType(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getHealth(options?: any) {
         return DefaultApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Patient History
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getHistoryById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getHistoryById(apiKey, secretKey, authToken, centerID, id, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getHomeTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getHomeTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Hospital Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getHospitalById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getHospitalById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getHospitalTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getHospitalTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Lab Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getLabById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getLabById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All LabCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} tripId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getLabCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options?: any) {
         return DefaultApiFp(this.configuration).getLabCollections(apiKey, secretKey, authToken, centerID, tripId, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getLabTripById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getLabTripById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getMainCenterById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getMainCenterById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Map
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id Map Id
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getMap(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getMap(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Menus
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getMenus(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getMenus(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Module
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id Center Id
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getModule(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getModule(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Not Completed Table CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getNotCompletedCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getNotCompletedCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Not Completed Table CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getNotCompletedCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getNotCompletedCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Not Completed Table HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getNotCompletedHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getNotCompletedHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Not Completed Table HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getNotCompletedHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getNotCompletedHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Not Completed Table LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getNotCompletedLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getNotCompletedLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Organisation Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getOrganisation(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Patient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getPatientById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getPatientById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Phlebotomist Features
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id Center Id
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getPhlebotomistAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getPhlebotomistAccess(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Pouch Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getPouchById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getPouchById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Power Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getPowerDashboard(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getPowerDashboard(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Route Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getRouteById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getRouteById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Sample Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getSampleById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getSampleById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getShipmentById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getShipmentById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Admin Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableAdmins(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableAdmins(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableBagsByType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableBagsByType(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type Module Type
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableCarriersByType(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableCarriersByType(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableCovidOffsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableCovidOffsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table CovidOffsite Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableCovidOffsites(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableCovidOffsites(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableCovidOnsiteTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableCovidOnsiteTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table CovidPatient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableCovidPatient(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Critical Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableCriticalBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableCriticalBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableHomeTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableHomeTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableHospitalTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableHospitalTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Hospital Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableHospitals(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableHospitals(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableLabTrips(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableLabTrips(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Lab Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableLabs(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableLabs(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableMainCenters(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Non Critical Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableNonCriticalBags(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableNonCriticalBags(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Organisation Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableOrganisations(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableOrganisations(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Patient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTablePatient(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getTablePatient(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Admin Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTablePowerAdmins(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTablePowerAdmins(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} slug Organisation Slug
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTablePowerMainCenters(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options?: any) {
         return DefaultApiFp(this.configuration).getTablePowerMainCenters(apiKey, secretKey, authToken, centerID, slug, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Admin Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} slug Organisation Slug
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTablePowerModules(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, options?: any) {
         return DefaultApiFp(this.configuration).getTablePowerModules(apiKey, secretKey, authToken, centerID, slug, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Route Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableRoutes(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableRoutes(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Sample Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableSamples(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableSamples(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableShipments(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableShipments(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableSuperCarriers(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableSuperCarriers(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table TestCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableTestCollection(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table VaccineInjectData Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableVaccineInjectData(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Table Vaccine Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTableVaccines(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getTableVaccines(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get TestCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTestCollectionById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getTestCollectionById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Sample Group Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTestGroups(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getTestGroups(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Sample Group Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {string} module 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTestGroupsWithModule(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, module: string, options?: any) {
         return DefaultApiFp(this.configuration).getTestGroupsWithModule(apiKey, secretKey, authToken, centerID, type, module, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Trip TestCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTripTestCollections(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getTripTestCollections(apiKey, secretKey, authToken, centerID, type, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Trip VaccineInjectData Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getTripVaccineInjectDatas(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getTripVaccineInjectDatas(apiKey, secretKey, authToken, centerID, type, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Fetch All Carrier and Admin Accounts
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getUserCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).getUserCollection(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get Vaccine Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getVaccineById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getVaccineById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get All Vaccine Group Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} type 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getVaccineGroups(apiKey: string, secretKey: string, authToken: string, centerID: string, type: string, options?: any) {
         return DefaultApiFp(this.configuration).getVaccineGroups(apiKey, secretKey, authToken, centerID, type, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Get VaccineInjectData Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} id 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public getVaccineInjectDataById(apiKey: string, secretKey: string, authToken: string, centerID: string, id: string, options?: any) {
         return DefaultApiFp(this.configuration).getVaccineInjectDataById(apiKey, secretKey, authToken, centerID, id, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Login to service
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {Login} login Account used to identify the user.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public login(apiKey: string, secretKey: string, authToken: string, centerID: string, login: Login, options?: any) {
         return DefaultApiFp(this.configuration).login(apiKey, secretKey, authToken, centerID, login, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Read Records
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} tripId 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public readRecords(apiKey: string, secretKey: string, authToken: string, centerID: string, tripId: string, options?: any) {
         return DefaultApiFp(this.configuration).readRecords(apiKey, secretKey, authToken, centerID, tripId, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the home trip
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject2} [inlineObject2] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject2?: InlineObject2, options?: any) {
         return DefaultApiFp(this.configuration).searchCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, inlineObject2, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the covidOnsite trip
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject3} [inlineObject3] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject3?: InlineObject3, options?: any) {
         return DefaultApiFp(this.configuration).searchCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, inlineObject3, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the CovidPatient
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject4} [inlineObject4] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchCovidPatientTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject4?: InlineObject4, options?: any) {
         return DefaultApiFp(this.configuration).searchCovidPatientTrip(apiKey, secretKey, authToken, centerID, inlineObject4, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the Bag
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject} [inlineObject] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject?: InlineObject, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip(apiKey, secretKey, authToken, centerID, inlineObject, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the Bag
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject1} [inlineObject1] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip1(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject1?: InlineObject1, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip1(apiKey, secretKey, authToken, centerID, inlineObject1, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the home trip
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject6} [inlineObject6] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip2(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject6?: InlineObject6, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip2(apiKey, secretKey, authToken, centerID, inlineObject6, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the Lab
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject8} [inlineObject8] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip3(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject8?: InlineObject8, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip3(apiKey, secretKey, authToken, centerID, inlineObject8, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the LabTrip
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject9} [inlineObject9] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip4(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject9?: InlineObject9, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip4(apiKey, secretKey, authToken, centerID, inlineObject9, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the Patient
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject10} [inlineObject10] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip5(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject10?: InlineObject10, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip5(apiKey, secretKey, authToken, centerID, inlineObject10, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the Bag
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject11} [inlineObject11] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip6(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject11?: InlineObject11, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip6(apiKey, secretKey, authToken, centerID, inlineObject11, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the TestCollection
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject12} [inlineObject12] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHomeTrip7(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject12?: InlineObject12, options?: any) {
         return DefaultApiFp(this.configuration).searchHomeTrip7(apiKey, secretKey, authToken, centerID, inlineObject12, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the home trip
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject7} [inlineObject7] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject7?: InlineObject7, options?: any) {
         return DefaultApiFp(this.configuration).searchHospitalTrip(apiKey, secretKey, authToken, centerID, inlineObject7, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Search the VaccineInjectData
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {InlineObject13} [inlineObject13] 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public searchVaccineInjectDataTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, inlineObject13?: InlineObject13, options?: any) {
         return DefaultApiFp(this.configuration).searchVaccineInjectDataTrip(apiKey, secretKey, authToken, centerID, inlineObject13, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Create Organisation Profile and Super Admin Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {ROrganisation} rOrganisation Organisation Profile with Super Admin Account.
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public signUp(apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options?: any) {
         return DefaultApiFp(this.configuration).signUp(apiKey, secretKey, authToken, centerID, rOrganisation, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Access
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RAccess} rAccess Access that need to be updated
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateAccess(apiKey: string, secretKey: string, authToken: string, centerID: string, rAccess: RAccess, options?: any) {
         return DefaultApiFp(this.configuration).updateAccess(apiKey, secretKey, authToken, centerID, rAccess, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Bag Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RBag} rBag Bag Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateBag(apiKey: string, secretKey: string, authToken: string, centerID: string, rBag: RBag, options?: any) {
         return DefaultApiFp(this.configuration).updateBag(apiKey, secretKey, authToken, centerID, rBag, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Carrier Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCarrier} rCarrier Carrier Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateCarrier(apiKey: string, secretKey: string, authToken: string, centerID: string, rCarrier: RCarrier, options?: any) {
         return DefaultApiFp(this.configuration).updateCarrier(apiKey, secretKey, authToken, centerID, rCarrier, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update CovidOffsite Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidOffsite} rCovidOffsite CovidOffsite Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateCovidOffsite(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsite: RCovidOffsite, options?: any) {
         return DefaultApiFp(this.configuration).updateCovidOffsite(apiKey, secretKey, authToken, centerID, rCovidOffsite, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update CovidOffsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidOffsiteTrip} rCovidOffsiteTrip CovidOffsiteTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateCovidOffsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOffsiteTrip: RCovidOffsiteTrip, options?: any) {
         return DefaultApiFp(this.configuration).updateCovidOffsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOffsiteTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update CovidOnsiteTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidOnsiteTrip} rCovidOnsiteTrip CovidOnsiteTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateCovidOnsiteTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidOnsiteTrip: RCovidOnsiteTrip, options?: any) {
         return DefaultApiFp(this.configuration).updateCovidOnsiteTrip(apiKey, secretKey, authToken, centerID, rCovidOnsiteTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update CovidPatient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RCovidPatient} rCovidPatient CovidPatient Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateCovidPatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rCovidPatient: RCovidPatient, options?: any) {
         return DefaultApiFp(this.configuration).updateCovidPatient(apiKey, secretKey, authToken, centerID, rCovidPatient, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update HomeTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RHomeTrip} rHomeTrip HomeTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateHomeTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHomeTrip: RHomeTrip, options?: any) {
         return DefaultApiFp(this.configuration).updateHomeTrip(apiKey, secretKey, authToken, centerID, rHomeTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Hospital Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RHospital} rHospital Hospital Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateHospital(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospital: RHospital, options?: any) {
         return DefaultApiFp(this.configuration).updateHospital(apiKey, secretKey, authToken, centerID, rHospital, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update HospitalTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RHospitalTrip} rHospitalTrip HospitalTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateHospitalTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rHospitalTrip: RHospitalTrip, options?: any) {
         return DefaultApiFp(this.configuration).updateHospitalTrip(apiKey, secretKey, authToken, centerID, rHospitalTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Lab Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RLab} rLab Lab Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateLab(apiKey: string, secretKey: string, authToken: string, centerID: string, rLab: RLab, options?: any) {
         return DefaultApiFp(this.configuration).updateLab(apiKey, secretKey, authToken, centerID, rLab, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update LabCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RLabCollection} rLabCollection LabCollection Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateLabCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabCollection: RLabCollection, options?: any) {
         return DefaultApiFp(this.configuration).updateLabCollection(apiKey, secretKey, authToken, centerID, rLabCollection, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update LabTrip Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RLabTrip} rLabTrip LabTrip Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateLabTrip(apiKey: string, secretKey: string, authToken: string, centerID: string, rLabTrip: RLabTrip, options?: any) {
         return DefaultApiFp(this.configuration).updateLabTrip(apiKey, secretKey, authToken, centerID, rLabTrip, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RMainCenter} rMainCenter MainCenter Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, rMainCenter: RMainCenter, options?: any) {
         return DefaultApiFp(this.configuration).updateMainCenter(apiKey, secretKey, authToken, centerID, rMainCenter, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Module
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RModule} rModule Module that need to be updated
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateModule(apiKey: string, secretKey: string, authToken: string, centerID: string, rModule: RModule, options?: any) {
         return DefaultApiFp(this.configuration).updateModule(apiKey, secretKey, authToken, centerID, rModule, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Organisation Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {ROrganisation} rOrganisation Organisation Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateOrganisation(apiKey: string, secretKey: string, authToken: string, centerID: string, rOrganisation: ROrganisation, options?: any) {
         return DefaultApiFp(this.configuration).updateOrganisation(apiKey, secretKey, authToken, centerID, rOrganisation, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Carrier
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updatePatch(apiKey: string, secretKey: string, authToken: string, centerID: string, options?: any) {
         return DefaultApiFp(this.configuration).updatePatch(apiKey, secretKey, authToken, centerID, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Patient Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RPatient} rPatient Patient Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updatePatient(apiKey: string, secretKey: string, authToken: string, centerID: string, rPatient: RPatient, options?: any) {
         return DefaultApiFp(this.configuration).updatePatient(apiKey, secretKey, authToken, centerID, rPatient, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Pouch Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RPouch} rPouch Pouch Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updatePouch(apiKey: string, secretKey: string, authToken: string, centerID: string, rPouch: RPouch, options?: any) {
         return DefaultApiFp(this.configuration).updatePouch(apiKey, secretKey, authToken, centerID, rPouch, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update MainCenter Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} slug Organisation Slug
      * @param {RMainCenter} rMainCenter MainCenter Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updatePowerMainCenter(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rMainCenter: RMainCenter, options?: any) {
         return DefaultApiFp(this.configuration).updatePowerMainCenter(apiKey, secretKey, authToken, centerID, slug, rMainCenter, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Module
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {string} slug Organisation Slug
      * @param {RModule} rModule Module that need to be updated
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updatePowerModule(apiKey: string, secretKey: string, authToken: string, centerID: string, slug: string, rModule: RModule, options?: any) {
         return DefaultApiFp(this.configuration).updatePowerModule(apiKey, secretKey, authToken, centerID, slug, rModule, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update User Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RUser} rUser User account that need to be created
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updatePowerUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any) {
         return DefaultApiFp(this.configuration).updatePowerUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Route Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RRoute} rRoute Route Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateRoute(apiKey: string, secretKey: string, authToken: string, centerID: string, rRoute: RRoute, options?: any) {
         return DefaultApiFp(this.configuration).updateRoute(apiKey, secretKey, authToken, centerID, rRoute, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Sample Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RSample} rSample Sample Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateSample(apiKey: string, secretKey: string, authToken: string, centerID: string, rSample: RSample, options?: any) {
         return DefaultApiFp(this.configuration).updateSample(apiKey, secretKey, authToken, centerID, rSample, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Shipment Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RShipment} rShipment Shipment Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateShipment(apiKey: string, secretKey: string, authToken: string, centerID: string, rShipment: RShipment, options?: any) {
         return DefaultApiFp(this.configuration).updateShipment(apiKey, secretKey, authToken, centerID, rShipment, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update TestCollection Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RTestCollection} rTestCollection TestCollection Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateTestCollection(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any) {
         return DefaultApiFp(this.configuration).updateTestCollection(apiKey, secretKey, authToken, centerID, rTestCollection, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Data Exchange API
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RTestCollection} rTestCollection TestCollection Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateTestInBooking(apiKey: string, secretKey: string, authToken: string, centerID: string, rTestCollection: RTestCollection, options?: any) {
         return DefaultApiFp(this.configuration).updateTestInBooking(apiKey, secretKey, authToken, centerID, rTestCollection, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update User Account
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RUser} rUser User account that need to be created
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateUser(apiKey: string, secretKey: string, authToken: string, centerID: string, rUser: RUser, options?: any) {
         return DefaultApiFp(this.configuration).updateUser(apiKey, secretKey, authToken, centerID, rUser, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update Vaccine Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RVaccine} rVaccine Vaccine Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateVaccine(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccine: RVaccine, options?: any) {
         return DefaultApiFp(this.configuration).updateVaccine(apiKey, secretKey, authToken, centerID, rVaccine, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @summary Update VaccineInjectData Profile
      * @param {string} apiKey API Key to identification of service
      * @param {string} secretKey Secret Key to identification of service
      * @param {string} authToken Auth Token to identification of user
      * @param {string} centerID Identification of main center data
      * @param {RVaccineInjectData} rVaccineInjectData VaccineInjectData Profile
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public updateVaccineInjectData(apiKey: string, secretKey: string, authToken: string, centerID: string, rVaccineInjectData: RVaccineInjectData, options?: any) {
         return DefaultApiFp(this.configuration).updateVaccineInjectData(apiKey, secretKey, authToken, centerID, rVaccineInjectData, options).then((request) => request(this.axios, this.basePath));
     }
 
     /**
      * 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public uploadCertificate(options?: any) {
         return DefaultApiFp(this.configuration).uploadCertificate(options).then((request) => request(this.axios, this.basePath));
     }
 
 }
 
 
 