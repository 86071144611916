import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { 
  RVaccineInjectData, RCovidTrip,
  RVaccine, DefaultApi, RModelMessageCodeEnum, 
  RModelListRVaccine, RModelListRVaccineCodeEnum,
  RModelListRCovidTripCodeEnum,
  RModelListRCovidTrip,
  FileItem
} from '../../apis';
import { BASE_PATH } from '../../apis/base';
import { AxiosResponse } from 'axios';
import { Modal } from 'react-bootstrap';
import './HistoryView.css';

interface CovidHistoryViewProps extends RouteComponentProps {
  id: string;
  type: string;
  show: boolean;
  hide(): void;
}

interface CovidHistoryViewState {
  samples: Array<RVaccine>;
  trips: Array<RCovidTrip>;
}

export class CovidHistoryView extends React.Component<CovidHistoryViewProps, CovidHistoryViewState> {
  
  constructor(props: CovidHistoryViewProps) {
    super(props)
    this.state = {
      trips: [],
      samples: [],
    }
    this.update = this.update.bind(this);
  }

  update(obj: any) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    obj.patient = this.props.id || "";
    obj.tripType = this.props.type || "OP";
    const defaultApi = new DefaultApi()
    if( obj.id !== undefined ) {
      defaultApi.updateVaccineInjectData("","",token,centerId, obj as RVaccineInjectData).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          //window.alert("Created.");
          self.props.hide(); 
        }
      });
    }else {
      defaultApi.createVaccineInjectData("","",token,centerId, obj as RVaccineInjectData).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          //window.alert("Created."); 
          self.props.hide();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getAllVaccines("","",token,centerId).then((itemR: AxiosResponse<RModelListRVaccine>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRVaccineCodeEnum.Success ) {
        self.setState({
          samples: dataR.success || [] 
        });
      }
    });
    defaultApi.getCovidHistoryById("", "", token, centerId, this.props.id, this.props.type ).then((itemR: AxiosResponse<RModelListRCovidTrip>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRCovidTripCodeEnum.Success ) {
        self.setState({
          trips: dataR.success || [] 
        });
      }
    });
  }

  render() {
    const trips = this.state.trips;
    return(
      <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl" >
        <div className="window" style={{overflow: "scroll"}}>
          {
            trips.map((trip, index) => {
              const testProps: VaccineInjectDataProps = {
                trip: trip,
                samples: this.state.samples,
                update: this.update
              }
              return(<TestCovidHistoryView {...testProps} key={index}/>);
            })
          }
        </div>
      </Modal>
    )
  }

}

interface VaccineInjectDataProps {
  samples: Array<RVaccine>;
  trip: RCovidTrip;
  update(obj: any): void;
}

interface VaccineInjectDataState {
  show: boolean;
}

class TestCovidHistoryView extends React.Component<VaccineInjectDataProps, VaccineInjectDataState> {

  constructor(props: VaccineInjectDataProps) {
    super(props);
    this.state = {
      show: false
    };
    this.hide = this.hide.bind(this);
    this.selected = this.selected.bind(this);
  }

  selected(id: string) {
    let obj = this.props.trip.vaccineInjectData || {};
    obj.tripId = this.props.trip.id;
    obj.number = "";
    obj.vaccineId = id;
    this.props.update(obj);
    this.hide();
  }

  hide() {
    this.setState({show: false});
  }

  render() {
    const vaccineInjectData = this.props.trip.vaccineInjectData;
    const item  = ( vaccineInjectData?.status || false ) ? (<img src="/images/maps/lab_checked.png" alt="" width="20px" height="20px"/>) : (<button type="button" className="btn" onClick={()=>{this.setState({show: true})}} >Change Vaccine</button>)
    const vaccineId = vaccineInjectData?.vaccineId || "";
    const sample = this.props.samples.find(it => (it.id === vaccineId));
    const bar = vaccineInjectData?.batchNumber || vaccineInjectData?.barcode || "";
    const view = (sample !== undefined) ? <VaccineView sample={sample} bar={bar}/> : "";     
    const date = (vaccineInjectData?.status || false) ? new Date(vaccineInjectData?.updatedAt ||0).toLocaleString("en-US") : "";
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div>Trip ID #{this.props.trip.name || ""} (1)
          <span style={{paddingLeft: 10}}>{item}</span>
        </div>
        <div style={{padding: 10}}>
          <div className="row"><strong>Vaccine</strong>&nbsp;&nbsp;({date})</div>
          <div className="row">
            <div className="col-md-4">
              {view}
            </div>  
          </div>
          <div className="row">
            {
              vaccineInjectData?.files?.map((file, index) => {
                return(
                  <div key={index}>
                    <ThumbDocumentView file={file} />
                  </div>
                )
              })
            }
          </div>
        </div>
        <AddCovidHistoryView samples={this.props.samples} show={this.state.show} id={this.props.trip.vaccineInjectData?.vaccineId || ""}
          selected={this.selected} hide={this.hide} />
      </div>
    )
  }

}

export interface DocumentProps {
  file: FileItem;
}

export class ThumbDocumentView extends React.Component<DocumentProps> {
  render() {
    const file = this.props.file
    const name = file.name || "file.jpeg"
    const url = file.url || ""
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div style={{padding: 10}}>
          <div className="row">
            <div>
              <img src={BASE_PATH+url+"&download=false"} alt={name} width="200" height="auto" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

interface VaccineProps {
  sample: RVaccine;
  bar: string;
}

class VaccineView extends React.Component<VaccineProps> {

  render() {
    const color = "urine";
    const image = "/images/tubes/"+color+".png";
    const name = this.props.sample.name+" ("+this.props.sample.created+")";
    const bar = this.props.bar;
    if( bar === "" ) {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
            <div>
              <div className={"bar-code blue"}>
                {bar || ""}
              </div>
            </div> 
          </div>
        </div>
      )
    }
  }
}

interface VaccineSelectProps {
  sample: RVaccine;
  selected: boolean;
}

class VaccineSelectView extends React.Component<VaccineSelectProps> {

  render() {
    const image = "/images/tubes/urine.png"
    const checked = "/images/maps/"+((this.props.selected)? "lab_checked" : "lab_selected")+".png"
    const name = this.props.sample.name
    return(
      <div style={{padding: 10}}>
        <div className="test-tube">
          <div className="flex-row">
            <div><img src={image} alt="" style={{width:25, height:25}}/><span style={{paddingLeft: 10}}>{name}</span></div>
            <div><img src={checked} alt="" style={{width:25, height:25}}/></div>
          </div>             
        </div>
      </div>
    )
  }

}

interface AddCovidHistoryViewProps extends RouteComponentProps {
  samples: Array<RVaccine>;
  show: boolean;
  id: string;
  selected(id: string): void;
  hide(): void;
}

export class AddCovidHistoryView extends React.Component<AddCovidHistoryViewProps> {

  constructor(props: AddCovidHistoryViewProps) {
    super(props);
    this.update = this.update.bind(this);
  }

  update(id: string) {
    this.props.selected(id);
    this.setState({});
  }

  render() {
      return(
        <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl">
          <div className="window">
            <div className="row">
              {
                this.props.samples.map((sample, index) => {
                  return(
                    <div className="col-md-4" key={index} onClick={()=>{this.update(sample.id || "")}}>
                      <VaccineSelectView sample={sample} selected={this.props.id === sample.id} />
                    </div>
                  )
                })
              }
            </div>
            <div className="flex-action">
              <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Close</button></div>
              <div><button className="btn btn-confirm" onClick={()=>{this.props.selected(this.props.id)}}>Confirm</button></div>
            </div>
          </div>
        </Modal>
      )
  }

}