import React from 'react';
import {RouteComponentProps} from '@reach/router';

import {
  DefaultApi, 
  DashboardTypeEnum,
  CriticalBag,
  LeaderBoard,
  DynamicItem,
  RModelDashboardCodeEnum, RModelRMainCenterCodeEnum
} from '../../apis'
import LeaderBoardView from '../../components/leaderboard/LeaderBoard';
import {Chart, ChartType} from '../../components/chart/Chart';
import {options, dashData} from '../dashboard/DataFile';
import './SuperDashboard.css';
import '../base_view/BaseView.css';
import { Dropdown } from '../../components/dropdown/Dropdown';
import Weather from '../../components/weather/Weather';
import { CriticalBags } from '../../components/criticalbags/CriticalBags';
import ReSampleView from '../../components/resample/ReSample';
import Axios from 'axios';

interface SuperDashboardProps extends RouteComponentProps {

}

interface Lab {
  name: string;
  id: string;
}

interface SuperDashboardItem {
  type: DashboardTypeEnum;
  total: number;
  data: any;
  criticalBag: CriticalBag;
  carrier: LeaderBoard;
  lab: LeaderBoard;
  numberOfReSample: number;
  temperature: number;
}

interface SuperDashboardState extends RouteComponentProps {
  state: string;
  actions: Array<string>;
  list: Array<DynamicItem>;
  selected: string;
  dashboard: SuperDashboardItem;
}

export class SuperDashboardView extends React.Component<SuperDashboardProps, SuperDashboardState> {

  constructor(props: SuperDashboardProps) {
    super(props);
    this.selectList = this.selectList.bind(this);
    this.updateSuperDashboard = this.updateSuperDashboard.bind(this);
    this.typeUpdate = this.typeUpdate.bind(this);
    this.state = dashData;
  }

  typeUpdate(type: string) {
    this.setState({state: type});
    this.updateSuperDashboard(undefined, type);
  }

  updateSuperDashboard(cId?: string, type?: string) {
    const self = this;
    const state = this.state;
    const token = localStorage.getItem("token") || "";
    const centerId = cId || state.selected || "all";
    const tab = type || state.state;
    const defaultApi = new DefaultApi();
    defaultApi.getDashboard("","",token, centerId, tab).then((item) => {
      if( item.data.code === RModelDashboardCodeEnum.Success ) {
        let dashboard = item.data.success || {data: "{}"};
        dashboard.data = JSON.parse(dashboard.data || "{}");
        if( centerId !== "all" ) {
          defaultApi.getMainCenterById("","",token, centerId, centerId)
          .then((zItem) => {
            if( zItem.data.code === RModelRMainCenterCodeEnum.Success ) {
              const location = zItem.data.success?.address?.location;
              Axios.get("https://api.openweathermap.org/data/2.5/weather?lat="+location?.lat+"&lon="+location?.lng+"&units=metric&appid=b99cd2e7eeaafc3bc1f060f2e0e75230")
              .then((lItem) => {
                if(lItem.status === 200) {
                  dashboard.temperature = lItem.data.main.temp as number;
                  self.setState({
                    dashboard: dashboard as SuperDashboardItem
                  });
                }
              });
            }
          }).catch(() => {
            self.setState({
              dashboard: dashboard as SuperDashboardItem
            });
          });
        }else {
          self.setState({
            dashboard: dashboard as SuperDashboardItem
          });
        }
      }   
    })
  }

  componentDidMount() {
    const self = this;
    //const state = this.state;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const defaultApi = new DefaultApi();
    /*defaultApi.updatePatch("","", token, centerId).then((item) => {
      console.log("Migrate User Batch Updated ", item);
    })*/
    this.updateSuperDashboard();
    defaultApi.getDynamicList("","",token,centerId,"all",{
      name: "MainCenter",
      properties: ["id", "name"]
    }).then((item) => {
      let filters = item.data;
      filters.unshift({id: "all", name: "All"});
      self.setState({list: filters});
    })
  }

  selectList(list: DynamicItem) {
    this.setState({selected: list.id || "all"});
    this.updateSuperDashboard(list.id || "all", undefined);
  }

  render() {
    const list = this.state.list;
    const actions = this.state.actions;
    const state = this.state.state;
    const dashboard = this.state.dashboard;
    const style = (this.state.selected === "all") ? {display: "none"} : {};
    //console.log(dashboard.data)
    return(
      <div>
        <div>
          <div className="row">
            <div className="col-md-9">
              <div className="tab-container">
              {
                actions.map((name, index) => {
                  const isActive = (name === state);
                  const active = (isActive) ? "tab-active-container" : "";
                  return(
                    <div className={active} key={index} onClick={(e)=>{this.typeUpdate(name)}}>{name}</div>
                  );
                })
              }
              </div>                    
            </div>
            <div className="col-md-3">
              <Dropdown id="dashboard-lab" selected={this.state.selected} 
              placeholder="All" 
              items={list}
              invert={true}
              onSelected={(item) => {this.selectList(item)}}/>
            </div> 
          </div> 
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={{minHeight: "60px"}}>

            </div>
          </div>
          <div className="col-md-7">
            <Chart type={ChartType.BAR} data={dashboard.data} options={options} count={dashboard.total || 0}/>
            <div style={{minHeight: "60px"}} />
            <div className="row" style={style}> 
              <div className="col-md-8" style={{alignSelf: "flex-end"}} >
                <CriticalBags critialBags={dashboard.criticalBag} showAll={false}/>
              </div>
              <div className="col-md-4" style={{alignSelf: "flex-end"}} >
                <ReSampleView count={dashboard.numberOfReSample} />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <LeaderBoardView leaderBoard={dashboard.lab} isLab={true} showAll={false}/>
            <div style={{minHeight: "20px"}} />
            <LeaderBoardView leaderBoard={dashboard.carrier} isLab={false} showAll={false}/>
            <div style={{minHeight: "20px"}} />
            <div style={style}>
              <Weather temperature={dashboard.temperature}/>
            </div>            
          </div>
        </div>  
      </div>
    );
  }

}