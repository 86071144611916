import React from 'react';
import {RouteComponentProps} from '@reach/router';

import './OrganisationView.css';
import { DefaultApi, Form, TableModel, ROrganisation, RModelMessage, RModelMessageCodeEnum, RModelRTableROrganisation, RModelRTableROrganisationCodeEnum, RModelFormCodeEnum } from '../../../apis';
import Table from '../../../components/table/Table';
import { AxiosResponse } from 'axios';
import { SearchText } from '../../../components/search/SearchText';
import { FormModel } from '../../../components/form/Form';

interface OrganisationViewProps extends RouteComponentProps {

}

interface OrganisationViewState {
  form: Form;
  showForm: boolean;
  changeForm: Form;
  selected: any;
  edit: boolean;
  search?: string;
  table: TableModel
}

export class OrganisationView extends React.Component<OrganisationViewProps,OrganisationViewState> {

  constructor(props: OrganisationViewProps) {
    super(props);
    this.state = {
      form : {},
      showForm: false,
      changeForm: {},
      selected: {},
      edit: false,
      table: {}
    };
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    localStorage.setItem("custom_logo",obj?.slug || "medall-lab");
    this.setState(
      {
        form : state.form,
        showForm: true,
        selected: obj || {},
        edit: true,
        table: state.table
      }
    );
  }

  show() {
    const state = this.state;
    this.setState(
      {
        form: state.form,
        showForm: true,
        selected: state.selected,
        edit: state.edit,
        table: state.table
      }
    );
  }

  hide() {
    const state = this.state;
    this.setState(
      {
        form : state.form,
        showForm: false,
        selected: {},
        edit: false,
        table: state.table
      }
    );
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updateOrganisation("","",token,centerId, obj as ROrganisation).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          localStorage.removeItem("custom_logo");
          window.showAlert("Organisation updated successfully.","Manage Organisation", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }else {
      defaultApi.signUp("","","","", obj as ROrganisation).then((item: AxiosResponse<RModelMessage>) => {
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert("Organisation created successfully.","Manage Organisation", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const defaultApi = new DefaultApi();
    defaultApi.getTableOrganisations("","",token,centerId).then((itemR: AxiosResponse<RModelRTableROrganisation>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableROrganisationCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          form : state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          table: dataR.success as TableModel
        });
      }
    });
    defaultApi.getFormType("","",token,centerId,"CreateAccount").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const state = self.state;
        const lform = data.success || {};
        lform.name = "Organisation";
        self.setState(
          {
            form : lform,
            showForm: state.showForm,
            selected: state.selected,
            edit: state.edit,
            table: state.table
          }
        );
      }
    });
    defaultApi.getFormType("","",token,centerId,"Organisation").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const lform = data.success || {};
        lform.name = "Organisation";
        self.setState(
          {
            changeForm : lform,
          }
        );
      }
    });
  }

  onChange(text: string) {
    const state = this.state;
    this.setState({
      form : state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: text,
      table: state.table
    });
  }

  onClear() {
    const state = this.state;
    this.setState({
      form : state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: undefined,
      table: state.table
    });
  }

  render() {
    const form =  this.state.edit ? (this.state.changeForm || {}) : (this.state.form || {});
    return(
      <div>
        <div className="action-holder flex-row">
          <div><SearchText onChange={this.onChange} onClear={this.onClear}/></div>
          <div><button type="button" className="btn" onClick={this.show}>Add Organisation</button></div>
        </div>
        <Table table={this.state.table} openForm={this.openForm} search={this.state.search} />
        <FormModel form={form} edit={this.state.edit} 
          obj={this.state.selected} update={this.update} hide={this.hide} 
          show={this.state.showForm}/>
      </div>
    );  
  }
  
}