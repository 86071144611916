import React from 'react';
import { Bar, Line } from 'react-chartjs-2';

import './Chart.css'
import { Badge } from '../badges/Bagde';

export interface ChartProps {
  type: ChartType;
  data: Object;
  width?: number;
  height?: number;
  options: Object;
  count: number;
}

export enum ChartType {
  BAR,
  LINE
}

export interface ChartState {
  type: ChartType;
  width?: number;
  height?: number;
  options: Object;
}

export class Chart extends React.Component<ChartProps, ChartState> {

  constructor(props : ChartProps) {
    super(props);
    this.state = {
      type: props.type,
      width: props.width,
      height: props.height,
      options: props.options ? props.options : {
        maintainAspectRatio: false
      }
    }
  }

  render() {
    const chartData: ChartState = this.state;
    if( chartData.type === ChartType.BAR ) {
      return(
        <div className="chart">
          <Badge size={80} color="#fff" count={this.props.count} bgColor="var(--dark-green-color)" badgeClass={"chart-badge"} />
          <Bar data={this.props.data} width={chartData.width} height={chartData.height} options={chartData.options} />
        </div>
      )
    }else {
      return(
        <div className="">
          <Line data={this.props.data} width={chartData.width} height={chartData.height} options={chartData.options} />
        </div>
      )
    }
  }
}