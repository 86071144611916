import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';

import {LoginView} from './screens/login/Login';
import { SignUp } from './screens/signup/Signup';
import AlertUI, {AlertUIProps} from './components/alert/AlertUI';
import './App.css';

declare global {
  interface Window {
    showAlert: ((message: string, title?: string, confirmation?: boolean, callback?: ((confirmed: boolean)=> void)) => void);
  }
}

interface AppProps extends RouteComponentProps {

}

interface AppState {
  show: boolean;
  message: string;
  title: string;
  login: boolean;
  confirmation: boolean; 
  callback: ((confirmed: boolean)=> void);
  alertCallback?: ((confirmed: boolean)=> void);
}

export default class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.showAlert = this.showAlert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.state = {
      show: false,
      message: "",
      title: "Krizac",
      login: false,
      confirmation: false,
      callback: this.closeAlert
    };
  }

  showAlert(message: string, title?: string, confirmation?: boolean, callback?: ((confirmed: boolean)=>void)) {
    this.setState({
      show: true,
      message: message || "",
      title: title || "Krizac",
      confirmation: confirmation || false,
      alertCallback: callback
    });
  }

  closeAlert(confirmed: boolean) {
    this.state.alertCallback?.(confirmed);
    this.setState({
      show: false,
      message: "",
      title: "Krizac",
      confirmation: false,
      alertCallback: undefined
    });
  }

  componentDidMount() {
    window.showAlert = this.showAlert;
  }

  render() {
    const alertProps = this.state as AlertUIProps;
    return(
      <div>
        <Router>
          <SignUp path="/delete_signup" />
          <LoginView path="/:orgId" />
          <LoginView path="/*" />
        </Router>
        <AlertUI {...alertProps} />
      </div>     
    );
  }

}

