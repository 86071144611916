import {Header, Filter, RModelString, RModelListString, RUserRoleEnum} from './api';
import {BASE_PATH} from './base';
import axios, { AxiosResponse, AxiosPromise } from 'axios';

//http://13.232.239.113:8080/api

export class TableModel {
    name?: string;
    title?: string;
    titleBackground?: string;
    headerColor?: string;
    filters?: Array<Filter>;
    headers?: Array<Header>;
    data?: Array<Object>;
    access?: boolean;
}

/*
https://stackoverflow.com/questions/41878838/how-do-i-set-multipart-in-axios-with-react
*/
export class FileUpload {

    createFile(file: File, token: string) : AxiosPromise<AxiosResponse<RModelString>> {
        const centerId = localStorage.getItem("centerId") || "";
        const formData = new FormData();
        formData.append("file", file);
        const config = {
            headers: {
                'Api-Key': "",
                'Secret-Key': "",
                'Auth-Token': token,
                'Center-ID': centerId,
                'Content-Type': 'multipart/form-data'
            }
        }
        const role = localStorage.getItem("role") || RUserRoleEnum.Admin;
        const orgId = ( role === RUserRoleEnum.PowerAdmin ) ? "?slug="+localStorage.getItem("custom_logo"): "";
        const url = BASE_PATH + '/org/file/create/single'+orgId;
        return axios.post(url, formData, config)
    }

    createFiles(files: FileList, token: string) : AxiosPromise<AxiosResponse<RModelListString>> {
        const centerId = localStorage.getItem("centerId") || "";
        const formData = new FormData();
        for( let index in files ) {
            formData.append("file"+index, files[index]);
        }
        const config = {
            headers: {
                'Api-Key': "",
                'Secret-Key': "",
                'Auth-Token': token,
                'Center-ID': centerId,
                'Content-Type': 'multipart/form-data'
            }
        }
        const role = localStorage.getItem("role") || RUserRoleEnum.Admin;
        const orgId = ( role === RUserRoleEnum.PowerAdmin ) ? "?slug="+localStorage.getItem("custom_logo"): "";
        const url = BASE_PATH + '/org/file/create/multiple'+orgId;
        return axios.post(url,formData, config).then()
    }
}