import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import {ManageTrips} from './manage_trips/ManageTrips';
import {ManageRoutes} from './manage_routes/ManageRoutes';
import {ManageLabs} from './manage_labs/ManageLabs';

import './LabCollection.css';

interface LabCollectionProps extends RouteComponentProps {

}

export class LabCollection extends React.Component<LabCollectionProps> {

  render() {
    return(
      <div>
        <Router basepath="/lab_collection/">
          <ManageRoutes path="manage_routes" />
          <ManageTrips path="manage_trips" />
          <ManageTrips path="manage_trips/:id" />
          <ManageLabs path="manage_labs" />
        </Router>
      </div>
    );  
  }
  
}