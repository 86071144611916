import React from 'react';
import {Modal} from 'react-bootstrap';
import {navigate, RouteComponentProps} from '@reach/router';

import {DefaultApi, Form, RModelFormCodeEnum, RBag, RModelMessageCodeEnum, TableModel, RModelRTableRBagCodeEnum} from '../../../apis';
import {FormModel} from '../../../components/form/Form';
import {SearchText} from '../../../components/search/SearchText';
import Table from '../../../components/table/Table';
import {BagDetails} from '../bag_details/BagDetails';

import './ManageBags.css';

interface ManageBagsProps extends RouteComponentProps {
  id?: string
}

interface ManageBagsState {
  form?: Form;
  showForm: boolean;
  selected: any;
  edit: boolean;
  critialBags: TableModel;
  search?: string;
  ltable: TableModel;
  htable: TableModel;
  cttable: TableModel;
  type: string;
}

export class ManageBags extends React.Component<ManageBagsProps, ManageBagsState> {

  private reference = React.createRef<HTMLDivElement>();

  constructor(props: ManageBagsProps) {
    super(props);
    this.state = {
      form : {},
      showForm: false,
      selected: {},
      edit: false,
      critialBags: {},
      ltable: {},
      htable: {},
      cttable: {},
      type: "all"
    }
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    const type = obj?.type || "Home";
    this.setState({
      form: state.form,
      showForm: true,
      selected: obj || {},
      edit: true,
      critialBags: state.critialBags,
      type: type
    });
  }

  show() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: true,
      selected: state.selected,
      edit: state.edit,
      critialBags: state.critialBags,
      type: "all"
    });
  }

  hide() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: false,
      selected: {},
      edit: false,
      critialBags: state.critialBags,
      type: "all"
    });
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updateBag("","",token,centerId, obj as RBag).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Bags", false, (confirmed) => {
            self.hide(); 
          });          
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createBag("","",token,centerId, obj as RBag).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.", "Manage Bags", false, (confirmed) => {
            self.hide(); 
          });
        } else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi()
    defaultApi.getFormType("","",token,centerId,"Bag").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const state = self.state;
        self.setState({ 
          form: data.success,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          critialBags: state.critialBags,
        });
      }
    });
    defaultApi.getTableCriticalBags("","",token, centerId).then((item) =>{
      const data = item.data;
      if( data.code === RModelRTableRBagCodeEnum.Success ) {
        const state = self.state;
        self.setState({ 
          form: state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          critialBags: data.success as TableModel,
        });
      }
    });
    defaultApi.getTableBagsByType("","",token, centerId, "Lab").then((item) =>{
      const data = item.data;
      if( data.code === RModelRTableRBagCodeEnum.Success ) {
        let ltable = data.success as TableModel
        //ltable.access = true
        self.setState({ 
          ltable: ltable
        });
      }
    });
    defaultApi.getTableBagsByType("","",token, centerId, "Home").then((item) =>{
      const data = item.data;
      if( data.code === RModelRTableRBagCodeEnum.Success ) {
        let htable = data.success as TableModel
        //htable.access = true
        self.setState({ 
          htable: htable
        });
      }
    });
    defaultApi.getTableBagsByType("","",token, centerId, "CovidOnsite").then((item) =>{
      const data = item.data;
      if( data.code === RModelRTableRBagCodeEnum.Success ) {
        let cttable = data.success as TableModel
        //htable.access = true
        self.setState({ 
          cttable: cttable
        });
      }
    });
  }

  onChange(text: string) {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      critialBags: state.critialBags,
      search: text,
    });
  }

  onClear() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      critialBags: state.critialBags,
      search: undefined,
    });
  }

  render() {
    //console.log(this.props.id);
    const form = this.state.form || {};
    const module = this.state.type || "Home";
    const showBagDetails = this.props.id ? true : false; 
    const style = (this.state.ltable.access || this.state.htable.access) ? {} : {display: "none"};
    return(
      <div>
        <div ref={this.reference}>
          <Table table={this.state.critialBags} openForm={this.openForm}/>
          <div style={{minHeight: "20px"}}></div>
          <div className="action-holder flex-row">
            <div><SearchText onChange={this.onChange} onClear={this.onClear}/></div>
            <div style={style}><button type="button" className="btn" onClick={this.show}>Add a Bag</button></div>
          </div>
          <Table table={this.state.ltable} openForm={this.openForm} search={this.state.search} />
          <div style={{minHeight: 20}}></div>
          <Table table={this.state.htable} openForm={this.openForm} search={this.state.search} />
          <div style={{minHeight: 20}}></div>
          <Table table={this.state.cttable} openForm={this.openForm} search={this.state.search} />
          <FormModel form={form} edit={this.state.edit} 
            obj={this.state.selected} update={this.update} hide={this.hide} 
            show={this.state.showForm} module={module}/> 
        </div>
        <Modal show={showBagDetails} dialogClassName="modal-70w" onHide={()=>{navigate("/bags_carriers/manage_bags");}} size="xl" >
          <BagDetails id={this.props.id||""} />
        </Modal>
      </div>
    );
  }
  
}
