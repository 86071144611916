import React from 'react';
import {RouteComponentProps} from '@reach/router';

import './MainCenter.css';
import { DefaultApi, TableModel, DynamicItem, RModelFormCodeEnum, RModelMessageCodeEnum, RMainCenter, Form, RModelRTableRMainCenterCodeEnum } from '../../../apis';
import {FormModel} from '../../../components/form/Form';
import { Dropdown } from '../../../components/dropdown/Dropdown';
import Table from '../../../components/table/Table';

interface MainCenterProps extends RouteComponentProps {

}

interface MainCenterState {
  filter: string;
  filters: Array<DynamicItem>;
  form?: Form;
  showForm: boolean;
  selected: any;
  edit: boolean;
  search?: string;
  table: TableModel;
}

export class MainCenter extends React.Component<MainCenterProps, MainCenterState> {

  constructor(props: MainCenterProps) {
    super(props);
    this.state = {
      filter: "medall-lab", 
      filters: [],
      showForm: false,
      selected: {},
      edit: false,
      table: {}
    };
  
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.selectList = this.selectList.bind(this);
    this.showForFilter = this.showForFilter.bind(this); 
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: true,
      selected: obj || {},
      edit: true,
      table: state.table
    });
  }

  show() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: true,
      selected: state.selected,
      edit: state.edit,
      table: state.table
    });
  }

  hide() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: false,
      selected: {},
      edit: false,
      table: state.table
    });
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = obj.centerId || "all";
    const slug = this.state.filter || "medall-lab";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updatePowerMainCenter("","",token,centerId,slug, obj as RMainCenter).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert("Main center updated successfully.","Manage Main Center", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createPowerMainCenter("","",token,centerId,slug, obj as RMainCenter).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert("Main center created successfully.","Manage Main Center", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }
  }

  showForFilter(filter?: string) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const slug = filter || "medall-lab";
    const defaultApi = new DefaultApi()
    defaultApi.getTablePowerMainCenters("","",token, centerId, slug).then((item) =>{
      const data = item.data;
      if( data.code === RModelRTableRMainCenterCodeEnum.Success ) {
        self.setState({ 
          table: data.success as TableModel
        });
      }
    });
  }  

  selectList(list: DynamicItem) {
    this.setState({filter: list.id || "medall-lab"});
    this.showForFilter(list.id || "medall-lab");
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const defaultApi = new DefaultApi();
    defaultApi.getDynamicList("","",token,centerId,"all",{
      name: "Organisation",
      properties: ["id", "name"]
    }).then((item) => {
      let filters = item.data;
      self.setState({filters: filters, filter: filters[0].id || "medall-lab"});
      this.showForFilter(filters[0].id || "medall-lab");
    })
    defaultApi.getFormType("","",token,centerId,"MainCenter").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        self.setState({ 
          form: data.success,
        });
      }
    });
  }

  onChange(text: string) {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: text,
      table: state.table
    });
  }

  onClear() {
    const state = this.state;
    this.setState({
      form: state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: undefined,
      table: state.table
    });
  }

  render() {
    const form = this.state.form || {};
    const filter = this.state.filter
    return(
      <div>
        <div style={{minHeight: "20px"}}></div>
        <div className="action-holder flex-row">
          <div className="col-md-3"><Dropdown id="dashboard-lab" selected={filter} 
                placeholder="Select Organisation" 
                items={this.state.filters}
                invert={true}
                onSelected={(item) => {this.selectList(item)}}/></div>
          <div><button type="button" className="btn" onClick={this.show}>Add Main Center</button></div>
        </div>
        <Table table={this.state.table} openForm={this.openForm} search={this.state.search} />
        <FormModel form={form} edit={this.state.edit} 
            obj={this.state.selected} update={this.update} hide={this.hide} 
            show={this.state.showForm}/> 
      </div>
    );
  }
}