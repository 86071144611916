import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import {ManageTrips} from './manage_trips/ManageTrips';
import {ManageTestData} from './manage_test_data/ManageTestData';
import {ManagePatients} from './manage_patients/ManagePatients';
import './HospitalCollection.css';
import { ManageHospitals } from './manage_hospital/ManageHospitals';

interface HospitalCollectionProps extends RouteComponentProps {

}

export class HospitalCollection extends React.Component<HospitalCollectionProps> {

  render() {
    return(
      <div>
        <Router basepath="/hospital_collection/">
          <ManageTestData path="manage_test_data" />
          <ManageTrips path="manage_trips" />
          <ManageTrips path="manage_trips/:id" />
          <ManagePatients path="manage_patient_data" />
          <ManagePatients path="manage_patient_data/:id" />
          <ManageHospitals path="manage_hospital" />
        </Router>
      </div>
    );  
  }
  
}