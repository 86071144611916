import React from 'react';

import './ReSample.css';

interface ReSample {
  count: number;
}

interface ReSampleProps {
  count: number
}

export default class ReSampleView extends React.Component<ReSampleProps, ReSample> {

  constructor(props: ReSampleProps) {
    super(props);
    this.state = {
      count: props.count ? props.count : 0
    };
  }

  render() {
    const title = "Number of resamples collected";
    const count = this.state.count;
    return (
      <div className="resample-card">
        <div className="resample-content">
          <span>{title}</span>
        </div>
        <div className="resample-count">
          {count}
        </div>
      </div>
    );
  }
} 