import React from 'react';
import {Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import {BASE_PATH} from '../../apis/base';
import {Form, FileUpload, Group, Field, FieldTypeEnum, RModelString, RModelStringCodeEnum, DynamicItem, DefaultApi, Item} from '../../apis';
import {Dropdown} from '../dropdown/Dropdown';

import './Form.css';

export interface FormProps{
  form: Form;
  edit: boolean;
  obj: any;
  show: boolean;
  module?: string;
  update(obj: any, callback: Function) : void;
  hide(): void;
}

export interface FormState {
  obj: any;
  formGroups: Array<FormElement>;
  confirm: boolean;
}

export class FormModel extends React.Component<FormProps> {
  render() {
    const extra = this.props.edit ? "Update " : "Add ";
    return (
      <Modal show={this.props.show} onHide={() => this.props.hide()} centered>
        <Modal.Body >
          <div className="dialog-title">
            <span>{extra+this.props.form.name}</span>
          </div>
          <FormView form={this.props.form} edit={this.props.edit} obj={this.props.obj} update={this.props.update} show={this.props.show} hide={this.props.hide} module={this.props.module}/>
        </Modal.Body>
      </Modal>
    );  
  }

}

export class FormView extends React.Component<FormProps,FormState> {

  constructor(props: FormProps) {
    super(props);
    this.state = {
      obj: props.obj,
      formGroups: [],
      confirm: true
    }
    this.updateProp = this.updateProp.bind(this);
    this.validate = this.validate.bind(this);
    this.reference = this.reference.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getModule = this.getModule.bind(this);
    this.buttonClick = this.buttonClick.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.disableConfirm = this.disableConfirm.bind(this);
  }

  reference(ref: any) {
    let formGroups = this.state.formGroups;
    formGroups.push(ref);
    this.setState({formGroups: formGroups});
  }

  buttonClick(obj: any) {
    if( this.validate(obj) ) {
      this.disableConfirm();
      this.props.update(obj, this.showConfirm);
    }
  }

  validate(obj:  any): boolean {
    let result = true;
    this.state.formGroups.forEach((formGroup) => {
      if( !formGroup.validateAll() && result ) {
        result = false;
      }
    });
    return result;
  }

  showConfirm() {
    this.setState({confirm: true});
  }

  disableConfirm() {
    this.setState({confirm: false});
  }

  getModule(): string {
    return this.props.module || "Home";
  }

  getValue(keys: Array<string>) : any {
    let obj = this.state.obj;
    if( keys.length === 1 ) {
      return obj[keys[0]] || "";
    }else if( keys.length === 2 ) {
      let local = obj[keys[0]] || {};  
      return local[keys[1]] || "";
    }else {
      window.showAlert("Currently too many deep dependecy not done.");
      return "";
    }   
  }

  updateProp(keys: Array<string>, value: any) {
    let obj = this.state.obj;
    if( keys.length === 1 ) {
      obj[keys[0]] = value;
    }else if( keys.length === 2 ) {
      let local = obj[keys[0]] || {};  
      local[keys[1]] = value;
      obj[keys[0]] = local;
    }else {
      window.showAlert("Currently too many deep dependecy not done.");
      return;
    }   
    this.setState({obj: obj});  
  }

  render() {
    return (
      <div className="form-class">
        <form>
          {
            this.props.form.groups?.map((group, index) =>{
              return(<FormGroup group={group} update={this.updateProp} reference={this.reference} getValue={this.getValue} key={index} getModule={this.getModule}/>);
            })
          }
        </form>
        <div className="flex-action">
          <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Cancel</button></div>
          <div><button className="btn btn-confirm" onClick={()=>{this.buttonClick(this.state.obj)}} disabled={!this.state.confirm}>Confirm</button></div>
        </div>
      </div>
    );  
  }
}

interface FormGroupProps {
  group: Group;
  reference(ref: FormElement): void;
  update(keys: Array<string>, value: any): void;
  getValue(keys: Array<string>): any;
  getModule(): string;
}

export class FormGroup extends React.Component<FormGroupProps> {

  render() {
    return (
      <div>
        <div className="category-title">{ this.props.group.name||"" }</div>
        <div>
          {
            this.props.group.fields?.map((field, index) =>{
              return(<FormElement field={field} update={this.props.update} reference={this.props.reference} getValue={this.props.getValue} key={index} full={false} getModule={this.props.getModule}/>) 
            })  
          }
        </div>
      </div>
    )
  }

}

interface FormElementProps {
  field: Field,
  reference(ref: FormElement): void;
  update(keys: Array<string>, value: any): void;
  getValue(keys: Array<string>): any;
  getModule(): string;
  full: boolean;
}

interface FormElementState {
  items: Array<DynamicItem>;
  ids: Array<string>;
  selected: string;
  showError: boolean;
}

export class FormElement extends React.Component<FormElementProps,FormElementState> {

  private readonly fileOpen : React.RefObject<HTMLInputElement>

  constructor(props: FormElementProps) {
    super(props);
    this.props.reference(this);
    const keys = this.props.field.name?.split(".") || [""];
    const idsVar = this.props.getValue(keys);
    const ids = ( idsVar === undefined || idsVar === null || idsVar === "" ) ? [] : idsVar;
    this.state = {
      items : [],
      ids: ids || [],
      selected: this.props.field.placeholder || "",
      showError: false
    };
    this.validate = this.validate.bind(this);
    this.validateAll = this.validateAll.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.fetchDynamic = this.fetchDynamic.bind(this);
    this.addId = this.addId.bind(this);
    this.removeId = this.removeId.bind(this);
    this.dynamicUpdate = this.dynamicUpdate.bind(this);
    this.fileOpen = React.createRef();
    this.fetchDynamic();
  }

  validateAll() {
    const field = this.props.field;
    const keys = field.name?.includes(",") ? [field.name?.split(",")[0]] : field.name?.split(".") || [""];
    const value = this.props.getValue(keys);
    return this.validate(field, keys, value);
  }

  selected(item: Item, keys: Array<string>) {
    const state = this.state
    this.setState({
      items : state.items,
      ids: state.ids,
      selected: item.name || this.props.field.placeholder || ""
    });
    this.props.update(keys, item.id);
  }

  fetchDynamic() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const id = this.props.getValue(["id"]);
    let path = ( id !== undefined && id!== null && id!== "" ) ? id : "all";
    if( this.props.field.dynamicList?.name?.includes("PPatient") ) {
      path = "all";
    }
    if( this.props.field.dynamicList?.name?.includes("Module") ) {
      path = centerId;
    }
    if( this.props.field.dynamicList?.name?.includes("Carrier") ) {
      path = this.props.getModule();
    }
    const defaultApi = new DefaultApi();
    defaultApi.getDynamicList("","",token,centerId, path, this.props.field.dynamicList!).then((items) => {
      const data = items.data;
      const state = self.state;
      self.setState({
        items: data,
        ids: state.ids
      });
    })
  }

  dynamicUpdate(item: DynamicItem) {
    const field = this.props.field;
    const keys = field.name?.split(",") || [""];
    this.props.update([keys[0]], item.id);
    if( keys.length === 2 ){
      this.props.update([keys[1]], item.name);
    }
    const linkTo = field.linkTo || ""
    if( linkTo !== "" ) {
      this.props.update([linkTo], Object.assign([], item.ids));
    } 
    const state = this.state;
    this.setState({
      items : state.items,
      ids: item.ids || [],
      selected: item.name || this.props.field.placeholder || ""
    });
  }

  addId(item: Item, keys: Array<string>) {
    const state = this.state;
    let ids =  this.props.getValue(keys) || [];
    if( ids.indexOf(item.id||"") === -1 ) {
      ids.push(item.id||"");
    }
    this.setState({
      items: state.items,
      ids: ids
    });
    this.props.update(keys, ids);
    this.setState({
      items : state.items,
      ids: state.ids,
      selected: item.name || this.props.field.placeholder || ""
    });
  }

  removeId(item: Item, keys: Array<string>) {
    const state = this.state;
    let ids = this.props.getValue(keys) || [];
    const index = ids.indexOf(item.id||"");
    if( index > -1 ) {
      ids.splice(index, 1);
    }
    this.setState({
      items: state.items,
      ids: ids
    });
    this.props.update(keys, ids);
    this.setState({
      items : state.items,
      ids: state.ids,
      selected: item.name || this.props.field.placeholder || ""
    });
  }

  uploadFile(keys: Array<string>, files?: FileList) {
    if( files === undefined || files === null ) {
      return;
    }
    const self = this;
    const file = files![0];
    const token = localStorage.getItem("token") || "";
    const fileUpload = new FileUpload();
    fileUpload.createFile(file, token).then((item) =>{
      const data = item.data as RModelString
      if( data.code === RModelStringCodeEnum.Success ) {
        self.props.update(keys, data.success || "");
      }else {
        window.showAlert("Failed to file upload");
      }
    })
  }

  validate(field: Field, keys: Array<string>, value: any) : boolean {
    let fResult = true;
    if( keys[0] === "password1" && this.props.getValue(["password"]) !== this.props.getValue(keys) ) {
      this.setState({showError: true});
      fResult = false;
    }else if( field.required ) {
      if(value === null || value === undefined || value === "" || ((typeof value  === "string") && (value.trim() === "")) ) {
        this.setState({showError: true});
        fResult = false;
      }else if( field.validate ) {
        if( field.regex === "EmailPattern" ) {
          const email = new RegExp(/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/);
          const result = email.test(value as string)
          if( result ) {
            this.props.update(keys,value);
            this.setState({showError: false});
          }else {
            this.setState({showError:true});
            fResult = false;
          }
        } else if( field.regex === "MobilePattern" ) {
          const email = new RegExp(/^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/);
          const result = email.test(value as string)
          if( result ) {
            this.props.update(keys,value);
            this.setState({showError: false});
          }else {
            this.setState({showError:true});
            fResult = false;
          }
        } 
      } else {
        this.props.update(keys,value);
        this.setState({showError: false});
      }
    }else {
      if(value !== null && value !== undefined) {
        this.props.update(keys,value);
      }
    }
    return fResult;
  }

  render() {
    const field = this.props.field;
    const keys = field.name?.includes(",") ? [field.name?.split(",")[0]] : field.name?.split(".") || [""];
    const value = this.props.getValue(keys);
    const formControl = this.props.full? "form-control" : "form-control-75";
    const width = this.props.full ? "100%" : "75%";
    const star = field.required ? "*" : "";
    if( field.type === FieldTypeEnum.Hidden ) {
      return(<div></div>);
    } else if( field.type === FieldTypeEnum.Text ) {
      return(
        <div className="form-group">
          <div>{field.placeholder||""}<span className="form-star">{star}</span></div>
          <input type="text" className={formControl} value={value} placeholder={field.placeholder || ""} onChange={(e)=>{this.props.update(keys,e.target.value)}} onBlur={(e)=>{this.validate(field,keys,e.target.value)}}/>
          <div className="form-error" style={{display: (this.state.showError ? "block" : "none")}}>{field.errorMessage || ""}</div>
        </div>
      );
    }else if( field.type === FieldTypeEnum.StaticList ) {
      return(
        <div className="form-group">
          <div>{field.placeholder||""}<span className="form-star">{star}</span></div>
          <div style={{width:width}}>
            <Dropdown id={field.name||""} selected={value} 
              placeholder={field.placeholder || ""} 
              items={field.list as Array<DynamicItem>}
              onSelected={(item) => {this.selected(item as Item,keys)}}/>
          </div>
          <div className="form-error" style={{display: (this.state.showError ? "block" : "none")}}>{field.errorMessage || ""}</div>
        </div>
      );
    } else if( field.type === FieldTypeEnum.DynamicList ) {
      return(
        <div className="form-group">
          <div>{field.placeholder||""}<span className="form-star">{star}</span></div>
          <div style={{width:width}}>
            <Dropdown id={field.name||""} selected={value} 
              placeholder={field.placeholder || ""} 
              items={this.state.items}
              onSelected={(item) => {this.dynamicUpdate(item)}} />
          </div>
          <div className="form-error" style={{display: (this.state.showError ? "block" : "none")}}>{field.errorMessage || ""}</div>
        </div>
      );
    } else if( field.type === FieldTypeEnum.MultipleList ) {
      return(
        <div className="form-group">
          <div className="mlist">
            { 
              this.state.items.map((item, index) => {
                if( value.includes(item.id || "") ) {
                  return(
                    <div className="multiple-list-display flex-row" key={index}>
                      <div>{item.name || ""}</div>
                      <div>
                        <FontAwesomeIcon icon={faTimesCircle} color="red" className="align-right" 
                        onClick={(e)=>{this.removeId(item, keys)}}/>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            }            
          </div>
          <div>{field.placeholder||""}<span className="form-star">{star}</span></div>
          <div style={{width:width}}>
            <Dropdown id={field.name||""} selected={value} 
              placeholder={field.placeholder || ""} 
              items={this.state.items}
              onSelected={(item) => {this.addId(item, keys)}} />
          </div>
          <div className="form-error" style={{display: (this.state.showError ? "block" : "none")}}>{field.errorMessage || ""}</div>
        </div>
      );
    } else if( field.type === FieldTypeEnum.Attachment ) {
      return(
        <div className="form-group" onClick={()=>{this.fileOpen.current?.click()}}>
          <div className={formControl}>
            <input type="file" hidden className={formControl} ref={this.fileOpen} onChange={ (e) =>{ this.uploadFile(keys, e.target.files || undefined) }} />
            <div className="flex-row">
              <div>{ value || field.placeholder }</div>
              <div><FontAwesomeIcon icon={faPaperclip}/></div>
            </div>
          </div>
        </div>
      );
    } else if( field.type === FieldTypeEnum.Profile ) {
      let fileUrl = (value === "" || value === "/images/profile.png" ) ? "/images/profile.png" : (value === "/images/client_logo.png" ) ? "/images/client_logo.png" : BASE_PATH+value+"&download="
      return(
        <div className="form-group" onClick={(e)=>{this.fileOpen.current?.click()}}>
          <img src={ fileUrl } alt="" className="profile-image" />  
          <input type="file" hidden className={formControl} ref={this.fileOpen} onChange={ (e) =>{ this.uploadFile(keys, e.target.files || undefined) }} />
        </div>
      );
    } else if( field.type === FieldTypeEnum.Password ) {
      return(
        <div className="form-group">
          <div>{field.placeholder||""}<span className="form-star">{star}</span></div>
          <input type="password" className={formControl} value={value} placeholder={field.placeholder || ""} onChange={(e)=>{this.props.update(keys,e.target.value)}} onBlur={(e)=>{this.validate(field, keys, e.target.value)}}/>
          <div className="form-error" style={{display: (this.state.showError ? "block" : "none")}}>{field.errorMessage || ""}</div>
        </div>
      );
    } else {
      return(
        <div className="form-group">
          <div>{field.placeholder||""}<span className="form-star">{star}</span></div>
          <input type="text" className={formControl} value={value} placeholder={field.placeholder || ""} onChange={(e)=>{this.props.update(keys,e.target.value)}} onBlur={(e)=>{this.validate(field, keys, e.target.value)}}/>
          <div className="form-error" style={{display: (this.state.showError ? "block" : "none")}}>{field.errorMessage || ""}</div>
        </div>
      );
    }
  }

}

/*
//static list
<SplitButton id={field.name||""} className="dropdown-class" title={selected}>
              <Dropdown.Menu className="dropdown-menu">
                {
                  field.list?.map((item, index) => {
                    return(
                      <Dropdown.Item href="#" key={index} onClick={()=>{this.selected(item,keys)}} className="dropdown-menu-item">
                        {item.name}
                      </Dropdown.Item>
                    );
                  })
                }
              </Dropdown.Menu>
            </SplitButton>

            */

            /*
//dyanmic list
<SplitButton id={field.name||""} className="dropdown-class" title={selected}>
              <Dropdown.Menu className="dropdown-menu">
                {
                  this.state.items.map((item, index) => {
                    return(
                      <Dropdown.Item href="#" key={index} onClick={()=>{this.dynamicUpdate(item)}} className="dropdown-menu-item">
                        {item.name}
                      </Dropdown.Item>
                    );
                  })
                }
              </Dropdown.Menu>
            </SplitButton>

            */

            /*
//multiple list
<SplitButton id={field.name||""} className="dropdown-class" title={selected}>
              <Dropdown.Menu className="dropdown-menu">
                {
                  this.state.items.map((item, index) => {
                    return(
                      <Dropdown.Item href="#" key={index} onClick={()=>{this.addId(item, keys)}} className="dropdown-menu-item">
                        {item.name}
                      </Dropdown.Item>
                    );
                  })
                }
              </Dropdown.Menu>
            </SplitButton>

            */

            