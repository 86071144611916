import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import {ManageTrips} from './manage_trips/ManageTrips';
import {ManageTestData} from './manage_test_data/ManageTestData';
import {ManagePatients} from './manage_patients/ManagePatients';
import './HomeCollection.css';

interface HomeCollectionProps extends RouteComponentProps {

}

export class HomeCollection extends React.Component<HomeCollectionProps> {

  render() {
    return(
      <div>
        <Router basepath="/home_collection/">
          <ManageTestData path="manage_test_data" />
          <ManageTrips path="manage_trips" />
          <ManageTrips path="manage_trips/:id" />
          <ManagePatients path="manage_patient_data" />
          <ManagePatients path="manage_patient_data/:id" />
        </Router>
      </div>
    );  
  }
  
}