import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface SearchProps {
  onChange(text: string): void;
  onClear(): void;
}

interface SearchState {
  text: string;
}

export class SearchText extends React.Component<SearchProps, SearchState> {

  constructor(props: SearchProps) {
    super(props);
    this.state = {
      text: ""
    };
    this.search = this.search.bind(this);
  }

  search() {
    if( this.state.text.length > 0 ) {
      this.props.onChange(this.state.text);
    }else {
      this.props.onClear();
    }
  }

  render() {
    return(
      <div className="form-group" style={{maxWidth: '420px'}}>
        <div className="form-control invert flex-row"  style={{padding: 8}}>
          <div><input type="text" className="form-control-search" placeholder="Search" 
          onChange={event => {
            if( event.target.value.length === 0 ) {
              this.props.onClear();
            }
            this.setState({text: event.target.value});
          }}
          onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.search()
                }
              }}/></div>
          <div><FontAwesomeIcon icon={faSearch} color="black" className="align-right" onClick={(e)=>{this.search()}}/></div>
        </div>          
      </div>
    );
  }

}