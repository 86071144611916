import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { AxiosResponse } from 'axios';

import MapView, {MapType, MapProps, MarkerData, MapData, WayPoint} from '../../../components/map/Map';
import { 
  RModelRMainCenterCodeEnum,
  RModelRMainCenter,
  RBag,
  RModelRBag,
  RModelRBagCodeEnum,
  DefaultApi,
  RLabTripStatusEnum,
  RHomeTripStatusEnum, 
  RHomeTrip, 
  RPatient, 
  RLabTrip, 
  RLab, 
  RModelListRPatient, 
  RModelListRPatientCodeEnum, RModelListRLab, RModelListRLabCodeEnum
} from '../../../apis';

import './BagDetails.css';
import { Chart, ChartType } from '../../../components/chart/Chart';

interface BagDetailsProps extends RouteComponentProps {
  id: string
}

interface Chamber {
  count: number;
  num: number;
  data: Object;
  temp: number;
}


interface BagDetailsState {
  isUpdate: boolean;
  mapData: Array<MapData>;
  markers: Array<MarkerData>;
  mapType: MapType;
  center: MarkerData;
  user?: MarkerData;
  bag: RBag;
  data?: Array<Chamber>;
}

export class BagDetails extends React.Component<BagDetailsProps, BagDetailsState> {

  private timer?: NodeJS.Timeout;

  constructor(props: BagDetailsProps) {
    super(props);
    this.state = {
      isUpdate: false,
      mapData: [],
      mapType: MapType.BagDetail,
      markers: [],
      center: {
        id: "main-center",
        title: "Main Center",
        address: "",
        assign: "",
        state: "",
        location: {lat: 13.081739, lng: 80.202409}
      },
      bag: {}
    }
    this.getChartData = this.getChartData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getWayHomePoints = this.getWayHomePoints.bind(this);
    this.getPatientMarkers = this.getPatientMarkers.bind(this);
    this.getMapHomeTrip = this.getMapHomeTrip.bind(this);
    this.getWayLabPoints = this.getWayLabPoints.bind(this);
    this.getLabMarkers = this.getLabMarkers.bind(this);
    this.getMapLabTrip = this.getMapLabTrip.bind(this);
  }

  getWayHomePoints(patients: Array<RPatient>, ids?: Array<string>) : Array<WayPoint> {
    let data : Array<WayPoint> = [];
    ids?.map((id) =>{
      const lab = patients.find(rPatient => (id === rPatient.id))
      if(lab !== undefined || lab !== null ) {
        const waypoint : WayPoint = {
          id: lab?.id || "",
          stopover: true,
          location: lab?.address?.location || {}
        };
        data.push(waypoint);
      }
    });
    return data;
  }

  getPatientMarkers(trip: RHomeTrip, patients: Array<RPatient>) : Array<MarkerData> {
    let data : Array<MarkerData> = [];
    patients.forEach((patient) => {
      if( trip?.patientIds?.includes(patient.id||"") ) {
        let status = trip?.status || "";
        if( status === 'Active' ) {
          status = trip?.completedIds?.includes(patient.id||"") ? 'Completed' : 'Active';
        }
        let markerData: MarkerData = {
          id: patient.id || "",
          title: patient.name || "",
          location: patient.address?.location || {},
          address: patient.address?.area || "",
          assign: trip.id || "",
          state: status
        };
        data.push(markerData);
      }
    });
    return data;
  }

  getMapHomeTrip(trip: RHomeTrip, patients: Array<RPatient>) : Array<MapData> {
    let data : Array<MapData> = [];
    let stops = trip.patientIds?.length || 0;
    if( stops > 0 ) {
      let fPId = trip.patientIds![0] || "";
      let lPId = trip.patientIds![stops-1] || "";
      let first = patients.find((item) => ((item.id || "") === fPId));
      let last = patients.find((item) => ((item.id || "") === lPId));
      let mapData : MapData = {
        id: trip.id || "",
        startPlace: first?.address?.full || "",
        endPlace: last?.address?.full || "",
        waypoints: this.getWayHomePoints(patients, trip.patientIds),
        state: trip.status || 'InActive'
      };
      data.push(mapData)
    }
    return data;
  }

  getWayLabPoints(labs: Array<RLab>, ids?: Array<string>) : Array<WayPoint> {
    let data : Array<WayPoint> = [];
    ids?.map((id) =>{
      const lab = labs.find(rlab => (id === rlab.id))
      if(lab !== undefined || lab !== null ) {
        const waypoint : WayPoint = {
          id: lab?.id || "",
          stopover: true,
          location: lab?.address?.location || {}
        };
        data.push(waypoint);
      }
    });
    return data;
  }

  getMapLabTrip(trip: RLabTrip, labs: Array<RLab>) : Array<MapData> {
    let data : Array<MapData> = [];
    let stops = trip.labIds?.length || 0;
    if( stops > 0 ) {
      let fLId = trip.labIds![0] || "";
      let lLId = trip.labIds![stops-1] || "";
      let first = labs.find((item) => ((item.id || "") === fLId));
      let last = labs.find((item) => ((item.id || "") === lLId));
      let mapData : MapData = {
        id: trip.id || "",
        startPlace: first?.address?.full || "",
        endPlace: last?.address?.full || "",
        waypoints: this.getWayLabPoints(labs, trip.labIds),
        state: trip.status || 'InActive'
      };
      data.push(mapData)
    }
    return data;
  }

  getLabMarkers(trip: RLabTrip, labs: Array<RLab>) : Array<MarkerData> {
    let data : Array<MarkerData> = [];
    labs.forEach((lab) =>{
      if( trip?.labIds?.includes(lab.id || "") ) {
        let status = trip?.status || "";
        if( status === 'Active' ) {
          status = trip?.completedIds?.includes(lab.id||"") ? 'Completed' : 'Active';
        }
        let markerData: MarkerData = {
          id: lab.id || "",
          title: lab.name || "",
          address: lab.address?.area || "",
          location: lab.address?.location || {},
          assign: trip.id || "",
          state: status
        };
        data.push(markerData);
      }
    })
    return data;
  }


  refresh() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getBagById("","",token,centerId, this.props.id).then((item : AxiosResponse<RModelRBag>) => {
      const data = item.data;
      if( data.code! === RModelRBagCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          isUpdate: true,
          bag: data.success || {},
          center: state.center
        });          
      } 
    });
    defaultApi.getMainCenterById("","",token,centerId, centerId).then((item: AxiosResponse<RModelRMainCenter>) =>{
      const dataR= item.data;
      if( dataR.code! === RModelRMainCenterCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          isUpdate: true,
          bag: state.bag,
          center: {
            id: centerId,
            title: dataR.success?.name || "Main Center",
            address: dataR.success?.address?.area || "Anna Nagar",
            assign: "",
            state: "",
            location: dataR.success?.address?.location || {lat: 13.081739, lng: 80.202409}
          }
        });
      }
    })
    if( this.props.id.startsWith("L") ) {
      let tripId = "";
      let trip: RLabTrip = {};
      defaultApi.getAllLabTrips("","",token, centerId).then((item)=>{
        const completedTrips = item.data.success || [];
        let foundActive = false;
        for(var i = 0 ; i < completedTrips.length; i++ ) {
          trip = completedTrips[i];
          if( trip.bagId === this.props.id && trip.status === RLabTripStatusEnum.Active) {
            tripId = trip.id || ""
            foundActive = true;
          }
          else if( trip.bagId === this.props.id && !foundActive && trip.status === RLabTripStatusEnum.Completed) {
            tripId = trip.id || ""
          }
        }
        if( tripId === "" ) {
          self.setState({data: [{
            num: 1,
            count: 0,
            temp: 0,
            data: this.getChartData([],[])
          },{
            num: 2,
            count: 0,
            temp: 0,
            data: this.getChartData([],[])
          },{
            num: 3,
            count: 0,
            temp: 0,
            data: this.getChartData([],[])
          }]});
        }else {
          defaultApi.getAllLabs("","",token,centerId).then((labs : AxiosResponse<RModelListRLab>) => {
            const data = labs.data;
            if( data.code! === RModelListRLabCodeEnum.Success ) {
              let mapData = this.getMapLabTrip(trip, data.success || []);
              let markers = this.getLabMarkers(trip, data.success || []);
              self.setState({
                mapData: mapData,
                markers: markers,
                mapType: MapType.LabTrip
              }); 
            } 
          });
          defaultApi.getLabCollections("","", token, centerId, tripId).then((testCollections) =>{
            let totalC1 = 0;
            let totalC2 = 0;
            let totalC3 = 0;
            const collections = testCollections.data.success || [];
            for(var j = 0 ; j < collections.length; j++ ) {
              const c = collections[j];
              const c1 = c.chamber1 || 0;
              const c2 = c.chamber2 || 0;
              const c3 = c.chamber3 || 0;
              totalC1 = totalC1 + c1;
              totalC2 = totalC2 + c2;
              totalC3 = totalC3 + c3;
            }
            defaultApi.readRecords("","", token, centerId, tripId).then((records) =>{
              let temp1 = 0;
              let temp2 = 0;
              let temp3 = 0;
              let location = {lat: 13.081739, lng: 80.202409};
              const recs = records.data.success || {temperatures: [], locations: []};
              const locations = recs.locations || [];
              const temperatures = recs.temperatures || [];
              let time = 0;
              for( var k = 0; k < locations.length; k++ ) {
                const loc = locations[k] || {};
                if( (loc.time || 0) > time ) {
                  location = {lat: loc.location?.lat || 13.081739, lng: loc.location?.lng || 80.202409};
                }
              }
              let label: Array<string> = [];
              let chamber1: Array<number> = [];
              let chamber2: Array<number> = [];
              let chamber3: Array<number> = [];
              for( var m = 0; m < temperatures.length; m++ ) {
                label.push(m+"");
                const c = temperatures[m];
                temp1 = c.chamber1 || 0;
                temp2 = c.chamber2 || 0;
                temp3 = c.chamber3 || 0;
                chamber1.push(temp1);
                chamber2.push(temp2);
                chamber3.push(temp3);                
              }
              self.setState(
                {
                  data: [
                    {
                      num: 1,
                      count: totalC1,
                      temp: temp1,
                      data: this.getChartData(label, chamber1)
                    },
                    {
                      num: 2,
                      count: totalC2,
                      temp: temp2,
                      data: this.getChartData(label, chamber2)
                    },
                    {
                      num: 3,
                      count: totalC3,
                      temp: temp3,
                      data: this.getChartData(label, chamber3)
                    }
                  ],
                  isUpdate: true,
                  user: {
                    id: centerId,
                    title: "User Location",
                    address: "Anna Nagar",
                    assign: "",
                    state: "",
                    location: location
                  }
                }  
              );
            })
          })
        }        
      })
    } else {
      let tripId = "";
      let trip: RHomeTrip = {};
      defaultApi.getAllHomeTrips("","",token, centerId).then((item)=>{
        const completedTrips = item.data.success || [];
        let foundActive = false;
        for(var i = 0 ; i < completedTrips.length; i++ ) {
          trip = completedTrips[i];
          if( trip.bagId === this.props.id && trip.status === RHomeTripStatusEnum.Active) {
            tripId = trip.id || "";
            foundActive = true;
          }
          else if( trip.bagId === this.props.id && !foundActive && trip.status === RHomeTripStatusEnum.Completed) {
            tripId = trip.id || "";
          }
        }
        if( tripId === "" ) {
          self.setState({data: [{
            num: 1,
            count: 0,
            temp: 0,
            data: {data:[]}
          }]});
        }else {
          defaultApi.getAllPatients("","",token, centerId, "OP").then((patients : AxiosResponse<RModelListRPatient>) => {
            const data = patients.data;
            if( data.code! === RModelListRPatientCodeEnum.Success ) {
              let mapData = this.getMapHomeTrip(trip, data.success || []);
              let markers = this.getPatientMarkers(trip, data.success || []);
              self.setState({
                mapData: mapData,
                markers: markers,
                mapType: MapType.HomeTrip
              });          
            } 
          });
          defaultApi.getTripTestCollections("","", token, centerId, "OP", tripId).then((testCollections) =>{
            let total = 0;
            const collections = testCollections.data.success || [];
            for(var j = 0 ; j < collections.length; j++ ) {
              const c = collections[j].barCollection?.length || 0;
              total = total + c;
            }
            defaultApi.readRecords("","", token, centerId, tripId).then((records) =>{
              let temp = 0;
              let location = {lat: 13.081739, lng: 80.202409};
              const recs = records.data.success || {temperatures: [], locations: []};
              const locations = recs.locations || [];
              const temperatures = recs.temperatures || [];
              let time = 0;
              for( var k = 0; k < locations.length; k++ ) {
                const loc = locations[k] || {};
                if( (loc.time || 0) > time ) {
                  location = {lat: loc.location?.lat || 13.081739, lng: loc.location?.lng || 80.202409};
                }
              }
              let label: Array<string> = [];
              let chamber1: Array<number> = [];
              for( var m = 0; m < temperatures.length; m++ ) {
                label.push(m+"");
                temp = temperatures[m].chamber1 || 0;
                chamber1.push(temp);
              }
              self.setState(
                {
                  data: [
                    {
                      num: 1,
                      count: total,
                      temp: temp,
                      data: this.getChartData(label, chamber1)
                    }
                  ],
                  isUpdate: true,
                  user: {
                    id: centerId,
                    title: "User Location",
                    address: "Anna Nagar",
                    assign: "",
                    state: "",
                    location: location
                  }
                }  
              );
            })
          })
        }        
      })
    }
  }

  getChartData(label: Array<string>, data: Array<number>) : Object {
    return {
      labels: label,
      datasets: [{
        label: '',
        data: data,
        backgroundColor: "#3a419a"
      }]
    }
  }

  componentDidMount() {
    this.refresh();
    this.timer = setInterval(() => {
      this.refresh();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer!);
  }

  render() {
     const mapProps : MapProps = {
      type: this.state.mapType,
      isFull: false,
      center: this.state.center,
      data: this.state.mapData,
      markers: this.state.markers,
      bike: this.state.user || this.state.center,
      isUpdated: this.state.isUpdate,
      selectedRoute: "",
      selectedIds: []
    }
    const options = {
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true,
            fontSize: 16,
            fontStyle: 'Bold'
          },
          gridLines: {
            drawOnChartArea: false
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontSize: 16
          },
          gridLines: {
            drawOnChartArea: false
          } 
        }]
      },
      legend: {
        display: false,
        position: "top"
      }
    };
    let items = this.state.data || [];
    return(
      <div className="bag_detail">
        <div className="flex-row" style={{justifyContent: "space-around", backgroundColor: "transparent"}}>
          {
            items.map((item, index) =>{
              return (
                <div key={index} className="bag_detail_holder">
                  <div style={{textAlign: 'center', paddingBottom: '5px'}}>CHAMBER {item.num}</div>
                  <div>Specimen Count: {item.count}</div>
                  <div style={{paddingBottom: '10px'}}>Current Temperature: {item.temp}°C</div>
                  <Chart type={ChartType.LINE} data={item.data} options={options} height={320} count={0}/>
                </div>
              );
            })            
          }
        </div>
        <div style={{height:"40px"}}></div>
        <div>
          <MapView {...mapProps} />
        </div>
      </div>
    );  
  }
  
}