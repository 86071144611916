import React from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'react-bootstrap';
import { Link, RouteComponentProps, navigate } from '@reach/router';

import { BaseView } from '../base_view/BaseView';
import {
  DefaultApi,
  RModelRUser,
  RModelRUserCodeEnum,
  RModelROrganisation,
  RModelROrganisationCodeEnum,
  Login, RUserRoleEnum
} from '../../apis';
import './Login.css';

interface LoginViewProps extends RouteComponentProps {
  orgId?: string
}

interface LoginViewState {
  org: string;
  token: string;
  login: Login;
}

export class LoginView extends React.Component<LoginViewProps, LoginViewState> {

  constructor(props: LoginViewProps) {
    super(props);
    const org = props.orgId || localStorage.getItem('org') || "";
    const token = localStorage.getItem('token') || "";
    this.state = {
      org: org,
      token: token,
      login: {
        org: org,
        name: "",
        password: ""
      }
    };
    this.login = this.login.bind(this);
    this.setLocal = this.setLocal.bind(this);
    this.removeLocal = this.removeLocal.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
  }

  onDataChange(key: string, value: string) {
    const loginState = this.state;
    const org = (key === "org") ? value : loginState.org;
    const token = loginState.token;
    const name = (key === "name") ? value : loginState.login.name;
    const password = (key === "password") ? value : loginState.login.password;
    this.setState({
      org: org,
      token: token,
      login: {
        org: org,
        name: name,
        password: password
      }
    });
  }

  login() {
    const self = this;
    const login = this.state;
    const defaultAPI = new DefaultApi();
    defaultAPI.login("", "", "", "", login.login).then((item: AxiosResponse<RModelRUser>) => {
      const data = item.data
      if (data === undefined || data === null) {
        self.removeLocal();
      } else if (data.code === RModelRUserCodeEnum.Success) {
        const name = data.success?.firstName + " " + data.success?.lastName || "";
        const centerId = data.success?.centerId || ""
        self.setLocal(login.org || "", data.success?.token || "", name, centerId, data.success?.role || RUserRoleEnum.Admin);
        const dashboard_path = (data.success?.role === RUserRoleEnum.PowerAdmin) ? "/power_dashboard" : (data.success?.role === RUserRoleEnum.SuperAdmin) ? "/super_dashboard" : "/dashboard";
        navigate(dashboard_path);
      } else {
        self.removeLocal();
      }
    }).catch(() => {
      self.removeLocal();
    })
  }

  setLocal(org: string, token: string, name: string, centerId: string, role: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('org', org);
    localStorage.setItem('name', name);
    localStorage.setItem('centerId', centerId);
    localStorage.setItem('role', role);
    let loginState = this.state;
    this.setState({
      org: org,
      token: token,
      login: {
        org: org,
        name: loginState.login.name,
        password: loginState.login.password
      }
    });
  }

  removeLocal() {
    localStorage.removeItem('org');
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('centerId');
    localStorage.removeItem('role');
    localStorage.removeItem('logo');
    this.setState({
      org: this.props.orgId || "",
      token: "",
      login: {
        org: this.props.orgId || "",
        name: "",
        password: ""
      }
    });
  }

  componentDidMount() {
    const self = this;
    const token = this.state.token;
    const role = localStorage.getItem("role") || RUserRoleEnum.Admin;
    if (role === RUserRoleEnum.PowerAdmin) {
      const name = localStorage.getItem("name") || "";
      const centerId = localStorage.getItem("centerId") || "";
      self.setLocal("medall-lab", token, name, centerId, role);
    } else {
      const defaultAPI = new DefaultApi()
      defaultAPI.getOrganisation("", "", token, "").then((item: AxiosResponse<RModelROrganisation>) => {
        const data = item.data
        if (data === undefined || data === null) {
          self.removeLocal();
        } else if (data.code === RModelROrganisationCodeEnum.Success) {
          localStorage.setItem("logo", data.success?.icon || "/images/client_logo.png")
          const name = localStorage.getItem("name") || "";
          const centerId = localStorage.getItem("centerId") || "";
          self.setLocal(data.success?.slug || "", token, name, centerId, role);
        } else {
          self.removeLocal();
        }
      }).catch(() => {
        self.removeLocal();
      })
    }
  }

  getOrgTextField(org: string) {
    return (
      <div className="form-group">
        <input type="text" className="form-control" value={org} placeholder="Org Slug" onChange={(e) => { this.onDataChange('org', e.target.value) }} />
      </div>
    );
  }

  render() {
    const loginState = this.state
    let OrgTag = (loginState.org === "") ? this.getOrgTextField(loginState.org) : (<div></div>);
    if (loginState.token === "") {
      return (
        <div className="background" >
          <div className="login-container">
            <div className="login-title">SIGN IN TO <em>One-Click Collect</em> </div>
            <form>
              {OrgTag}
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Username" onChange={(e) => { this.onDataChange('name', e.target.value) }} />
              </div>
              <div className="form-group">
                <input type="password" className="form-control" placeholder="Password" onChange={(e) => { this.onDataChange('password', e.target.value) }} />
              </div>
              <div className="flex-row">
                <div><Link to="/forget" className="login-link" >Forget Password?</Link></div>
              </div>
              <Button type="button" className="btn btn-custom" onClick={() => { this.login() }}>Login</Button>
            </form>
          </div>
        </div>
      );
    } else {
      return (<BaseView />);
    }
  }

}
//<div><Link to="/signup" className="login-link" >Create Account</Link></div>