import React from 'react';
import {RouteComponentProps, navigate} from '@reach/router';
import { AxiosResponse } from 'axios';

import MapView, { MapType, MapProps, MapData, WayPoint, MarkerData } from '../../../components/map/Map';
import { 
  RLabTrip,
  RLab, 
  DefaultApi, 
  RModelListRLab,
  RModelListRLabCodeEnum,
  RModelMessage,
  RModelMessageCodeEnum,
  Form,
  TableModel,
  RModelRTableRLabTrip,
  RModelRTableRLabTripCodeEnum,
  RModelFormCodeEnum,
  RModelRMainCenter,
  RModelRMainCenterCodeEnum,
  DynamicItem
} from '../../../apis';
import Table from '../../../components/table/Table';
import {Dropdown} from '../../../components/dropdown/Dropdown';
import './ManageTrips.css';
import { FormModel } from '../../../components/form/Form';
import { MapLegend } from '../../../components/map/MapLegend';
import { LabCollectionView } from '../../../components/labcollection/LabCollectionView';

interface ManageTripsProps extends RouteComponentProps {
  id?: string
}

interface ManageTripsState {
  labs: Array<RLab>;
  trips: Array<RLabTrip>;
  isUpdate: boolean;
  selectedTrip: string;
  form?: Form;
  showForm: boolean;
  selected: any;
  edit: boolean;
  completedTrips: TableModel;
  tableTrips: TableModel;
  center: MarkerData;
  selectedIds: Array<string>;
  search?: string;
  regions: Array<DynamicItem>;
  rSelect: string;
}

export class ManageTrips extends React.Component<ManageTripsProps, ManageTripsState> {

  constructor(props: ManageTripsProps) {
    super(props);
    this.state = {
      labs: [],
      trips: [],
      isUpdate: false,
      selectedTrip: "",
      form : {},
      showForm: false,
      selected: {},
      edit: false,
      completedTrips: {},
      tableTrips: {},
      center: {
        id: "main-center",
        title: "Main Center",
        address: "",
        assign: "",
        state: "",
        location: {lat: 13.081739, lng: 80.202409}
      },
      selectedIds: [],
      regions: [],
      rSelect: "All"
    };
    this.removeLabs = this.removeLabs.bind(this);
    this.selectedLabs = this.selectedLabs.bind(this);
    this.selectedLabTrip = this.selectedLabTrip.bind(this);
    this.getMapTrip = this.getMapTrip.bind(this);
    this.getMarkers = this.getMarkers.bind(this);
    this.getWayPoints = this.getWayPoints.bind(this);
    this.isAssigned = this.isAssigned.bind(this);
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
    this.search = this.search.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    this.setState(
      {
        labs: state.labs,
        trips: state.trips,
        isUpdate: true,
        selectedTrip: state.selectedTrip,
        form : state.form,
        showForm: true,
        selected: obj || {},
        edit: true,
        completedTrips: state.completedTrips,
        tableTrips: state.tableTrips,
        center: state.center,
        selectedIds: []
      }
    );
  }

  show() {
    const state = this.state;
    this.setState(
      {
        labs: state.labs,
        trips: state.trips,
        isUpdate: state.isUpdate,
        selectedTrip: state.selectedTrip,
        form : state.form,
        showForm: true,
        selected: state.selected,
        edit: state.edit,
        completedTrips: state.completedTrips,
        tableTrips: state.tableTrips,
        center: state.center,
        selectedIds: state.selectedIds
      }
    );
  }

  hide() {
    const state = this.state;
    this.setState(
      {
        labs: state.labs,
        trips: state.trips,
        isUpdate: true,
        selectedTrip: state.selectedTrip,
        form : state.form,
        showForm: false,
        selected: {},
        edit: false,
        completedTrips: state.completedTrips,
        tableTrips: state.tableTrips,
        center: state.center,
        selectedIds: []
      }
    );
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updateLabTrip("","",token,centerId, obj as RLabTrip).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Trips", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createLabTrip("","",token,centerId, obj as RLabTrip).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Trips", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getAllLabs("","",token,centerId).then((item : AxiosResponse<RModelListRLab>) => {
      const data = item.data;
      if( data.code! === RModelListRLabCodeEnum.Success ) {
        const state = self.state;
        const regions = [{id: 'All', name: 'All'}];
        data.success?.map((lab, _) =>{
          state.labs.push(lab); 
          let area = lab?.address?.area || "";
          regions.push({id: area, name: area});
        });
        const unique = regions.unique();
        for( let item1 of unique ) {
          state.regions.push(item1);
        }
        self.setState(state);          
      } 
    });
    defaultApi.getMainCenterById("","",token,centerId, centerId).then((item: AxiosResponse<RModelRMainCenter>) =>{
      const dataR= item.data;
      if( dataR.code! === RModelRMainCenterCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          labs: state.labs,
          trips: state.trips,
          isUpdate: state.isUpdate,
          selectedTrip: state.selectedTrip,
          form : state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          completedTrips: state.completedTrips,
          tableTrips: state.tableTrips,
          center: {
            id: centerId,
            title: dataR.success?.name || "Main Center",
            address: dataR.success?.address?.area || "Anna Nagar",
            assign: "",
            state: "",
            location: dataR.success?.address?.location || {lat: 13.081739, lng: 80.202409}
          },
          selectedIds: state.selectedIds
        });
      }
    })
    defaultApi.getNotCompletedLabTrips("","",token,centerId).then((itemR: AxiosResponse<RModelRTableRLabTrip>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRLabTripCodeEnum.Success ) {
        const state = self.state;
        dataR.success?.data?.map((trip, _) =>{
          state.trips.push(trip);
        });
        self.setState({
          labs: state.labs,
          trips: state.trips,
          isUpdate: state.isUpdate,
          selectedTrip: state.selectedTrip,
          form : state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          completedTrips: state.completedTrips,
          tableTrips: dataR.success as TableModel,
          center: state.center,
          selectedIds: state.selectedIds 
        });
      }
    });
    defaultApi.getCompletedTableLabTrips("","",token,centerId).then((itemR: AxiosResponse<RModelRTableRLabTrip>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRLabTripCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          labs: state.labs,
          trips: state.trips,
          isUpdate: state.isUpdate,
          selectedTrip: state.selectedTrip,
          form : state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          completedTrips: dataR.success as TableModel,
          tableTrips: state.tableTrips,
          center: state.center,
          selectedIds: state.selectedIds
        });
      }
    });
    defaultApi.getFormType("","",token,centerId,"LabTrip").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const state = self.state;
        self.setState(
          {
            labs: state.labs,
            trips: state.trips,
            isUpdate: state.isUpdate,
            selectedTrip: state.selectedTrip,
            form : data.success,
            showForm: state.showForm,
            selected: state.selected,
            edit: state.edit,
            completedTrips: state.completedTrips,
            tableTrips: state.tableTrips,
            center: state.center,
            selectedIds: state.selectedIds
          }
        );
      }
    });
  }

  getWayPoints(labs: Array<RLab>, ids?: Array<string>) : Array<WayPoint> {
    let data : Array<WayPoint> = [];
    ids?.map((id) =>{
      const lab = labs.find(rlab => (id === rlab.id))
      if(lab !== undefined || lab !== null ) {
        const waypoint : WayPoint = {
          id: lab?.id || "",
          stopover: true,
          location: lab?.address?.location || {}
        };
        data.push(waypoint);
      }
    });
    return data;
  }

  getMapTrip(trips: Array<RLabTrip>, labs: Array<RLab>) : Array<MapData> {
    let data : Array<MapData> = [];
    trips.map((trip) => {
      let stops = trip.labIds?.length || 0;
      if( stops > 0 ) {
        let fLId = trip.labIds![0] || "";
        let lLId = trip.labIds![stops-1] || "";
        let first = labs.find((item) => ((item.id || "") === fLId));
        let last = labs.find((item) => ((item.id || "") === lLId));
        let mapData : MapData = {
          id: trip.id || "",
          startPlace: first?.address?.full || "",
          endPlace: last?.address?.full || "",
          waypoints: this.getWayPoints(labs, trip.labIds),
          state: trip.status || 'InActive'
        }
        data.push(mapData)
      }
      return trip;
    });
    return data;
  }

  isAssigned(trips: Array<RLabTrip>, id: string) : string {
    for( const trip of trips ) {
      const match = trip.labIds?.find(labId => (id === labId))
      if( match !== undefined && match !== null ) {
        return trip.id || "";
      }
    }
    return "";
  }

  getMarkers(trips: Array<RLabTrip>, labs: Array<RLab>) : Array<MarkerData>{
    let data : Array<MarkerData> = [];
    labs.map((lab) =>{
      const assigned = this.isAssigned(trips, lab.id!) || "";
      const trip = trips.find(trip => (trip.id === assigned))
      let status = trip?.status || "";
      if( status === 'Active' ) {
        status = trip?.completedIds?.includes(lab.id||"") ? 'Completed' : 'Active';
      }
      let markerData: MarkerData = {
        id: lab.id || "",
        title: lab.name || "",
        address: lab.address?.area || "",
        location: lab.address?.location || {},
        assign: assigned,
        state: status
      }
      data.push(markerData);
      return lab;
    })
    return data;
  }

  removeLabs(trip: MapData, waypoint: WayPoint, type: MapType) {
    const self = this;
    const trips = this.state.trips;
    const rtrip = trips.find((item => (item.id === trip.id)))
    if( rtrip !== undefined && rtrip !== null ) {
      const index = rtrip.labIds!.findIndex(item => (waypoint.id === item))
      if( index > -1 ) {
        rtrip.labIds!.splice(index, 1);
      }
      const token = localStorage.getItem("token") || "";
      const centerId = localStorage.getItem("centerId") || "";
      const defaultAPI = new DefaultApi();
      defaultAPI.updateLabTrip("","",token,centerId, rtrip).then((item : AxiosResponse<RModelMessage>) => {
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          self.hide();
        }        
      })
    }
  }

  selectedLabs(ids: Array<string>, type: MapType) {
    const selected = {
      labIds: ids
    };
    const state = {
      isUpdate: true,
      selectedTrip: this.state.selectedTrip,
      trips: this.state.trips,
      labs: this.state.labs,
      form: this.state.form,
      showForm: false,
      selected: selected,
      edit: false,
      completedTrips: this.state.completedTrips,
      tableTrips: this.state.tableTrips,
      center: this.state.center,
      selectedIds: ids
    };
    this.setState(state);
  }

  selectedLabTrip(trip: MapData, type: MapType) {
    const selected = this.state.trips.find((item => (item.id === trip.id)))
    const state = {
      isUpdate: true,
      selectedTrip: trip.id,
      trips: this.state.trips,
      labs: this.state.labs,
      form: this.state.form,
      showForm: false,
      selected: selected,
      edit: true,
      completedTrips: this.state.completedTrips,
      tableTrips: this.state.tableTrips,
      center: this.state.center,
      selectedIds: []
    };
    this.setState(state);
  }

  search(text: string) {
    if( text === 'All' || text === 'all' ) {
      this.setState({search: undefined, rSelect: text});
    }else {
      let items = this.state.labs.search(text);
      let keyword = '';
      if( items.length === 1 ) {
        keyword = items[0].id || "";
      }else {
        for( let item of items ) {
          keyword = keyword+item.id+",";
        }
      }
      this.setState({search: keyword, rSelect: text})
    }
  }

  render() {
    const trips = this.state.trips;
    const labs = this.state.labs;
    const mapProps : MapProps = {
      type: MapType.LabTrip,
      isFull: false,
      center: this.state.center,
      data: this.getMapTrip(trips, labs),
      markers: this.getMarkers(trips, labs),
      isUpdated: this.state.isUpdate,
      selectedRoute: this.state.selectedTrip,
      selectedIds: this.state.selectedIds,
      removed: this.removeLabs,
      selected: this.selectedLabTrip,
      selectedPin: this.selectedLabs
    }
    const form = this.state.form || {};
    const search = this.state.search ? ((this.state.search === "All") ? undefined : this.state.search) : undefined
    const showCollectionDetail = this.props.id ? true : false;
    const ctrips = this.state.completedTrips.data || []
    const completedTrips = ctrips as Array<RLabTrip>
    const trip = this.state.trips.find(item =>(item.id === this.props.id)) || completedTrips.find(item =>(item.id === this.props.id)) || {id: this.props.id}
    return(
      <div>
        <div className="action-holder flex-row">
          <div className="row">
            <div style={{minWidth: "240px"}}>
              <Dropdown id="map-trip-region" selected={(this.state.rSelect)}
                placeholder="All" items={this.state.regions}
                invert={true}
                onSelected={(item)=>{this.search(item.name||"All")}}/>  
            </div>
            <div style={{marginLeft: 35, marginTop: 10}}><MapLegend type={MapType.LabTrip} /></div>
          </div>
          <div>
            <button type="button" className="btn" onClick={this.show}>{(this.state.edit) ? "Update" : "Create"} Trip</button>
          </div>          
        </div>
        <div>
          <MapView {...mapProps} />
        </div>
        <div style={{minHeight: "20px"}}></div>
        <Table table={this.state.tableTrips} openForm={this.openForm} verticalScroll={true} search={search}/>
        <div style={{minHeight: "20px"}}></div>
        <Table table={this.state.completedTrips} openForm={this.openForm} verticalScroll={true} search={search}/>
        <FormModel form={form} edit={this.state.edit} 
          module="Lab"
          obj={this.state.selected} update={this.update} hide={this.hide} 
          show={this.state.showForm}/> 
        <LabCollectionView show={showCollectionDetail}  hide={()=>{navigate("/lab_collection/manage_trips")}} trip={trip}/>  
      </div>
    );  
  }
  
}