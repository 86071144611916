import React from 'react';
import {RouteComponentProps} from '@reach/router';

import './PowerAdmins.css';
import { DefaultApi, Form, TableModel, RModelMessageCodeEnum, RModelRTableRUser, RModelRTableRUserCodeEnum, RModelFormCodeEnum, RUser, RUserRoleEnum } from '../../apis';
import Table from '../../components/table/Table';
import { AxiosResponse } from 'axios';
import { SearchText } from '../../components/search/SearchText';
import { FormModel } from '../../components/form/Form';

interface PowerAdminsProps extends RouteComponentProps {

}

interface PowerAdminsState {
  form: Form;
  showForm: boolean;
  changePasswordForm: Form;
  showChangeForm: boolean;
  selected: any;
  edit: boolean;
  search?: string;
  table: TableModel
}

export class PowerAdmins extends React.Component<PowerAdminsProps,PowerAdminsState> {

  constructor(props: PowerAdminsProps) {
    super(props);
    this.state = {
      form : {},
      showForm: false,
      changePasswordForm: {},
      showChangeForm: false,
      selected: {},
      edit: false,
      table: {}
    };
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.hidePassword = this.hidePassword.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    if( form === "Change Password" ) {
      this.setState({
        showChangeForm: true, 
        selected: obj || {}
      });
    }else {
      this.setState(
        {
          form : state.form,
          showForm: true,
          selected: obj || {},
          edit: true,
          table: state.table
        }
      );
    }
  }

  show() {
    const state = this.state;
    this.setState(
      {
        form: state.form,
        showForm: true,
        selected: state.selected,
        edit: state.edit,
        table: state.table
      }
    );
  }

  hide() {
    const state = this.state;
    this.setState(
      {
        form : state.form,
        showForm: false,
        selected: {},
        edit: false,
        table: state.table
      }
    );
    window.location.reload();
  }

  hidePassword() {
    this.setState({
      showChangeForm: false,
      selected: {}
    });
  }

  updatePassword(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    delete obj.password1;
    const defaultApi = new DefaultApi()
    defaultApi.updatePowerUser("","",token,centerId, obj as RUser).then((item)=>{
      const data = item.data;
      if( data.code === RModelMessageCodeEnum.Success ) {
        window.showAlert("Password updated successfully.","Power Admin", false, (confirmed) => {
          self.hidePassword();  
        });
      }else {
        callback();
      }
    });
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = obj.centerId;
    delete obj.password1;
    obj.role = RUserRoleEnum.PowerAdmin;
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updatePowerUser("","",token,centerId, obj as RUser).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Power Admin", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createPowerUser("","",token,centerId, obj as RUser).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Power Admin", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const defaultApi = new DefaultApi();
    defaultApi.getTablePowerAdmins("","",token,centerId).then((itemR: AxiosResponse<RModelRTableRUser>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRUserCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          form : state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          table: dataR.success as TableModel
        });
      }
    });
    defaultApi.getFormType("","",token,centerId,"PowerAdmin").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const state = self.state;
        const lform = data.success || {};
        lform.name = "Admin";
        self.setState(
          {
            form : lform,
            showForm: state.showForm,
            selected: state.selected,
            edit: state.edit,
            table: state.table
          }
        );
      }
    });
    defaultApi.getFormType("","",token,centerId,"ChangePassword").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const lform = data.success || {};
        lform.name = "Password";
        self.setState(
          {
            changePasswordForm : lform,
          }
        );
      }
    });
  }

  onChange(text: string) {
    const state = this.state;
    this.setState({
      form : state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: text,
      table: state.table
    });
  }

  onClear() {
    const state = this.state;
    this.setState({
      form : state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: undefined,
      table: state.table
    });
  }

  render() {
    const form = this.state.form || {};
    return(
      <div>
        <div className="action-holder flex-row">
          <div><SearchText onChange={this.onChange} onClear={this.onClear}/></div>
          <div><button type="button" className="btn" onClick={this.show}>Add Admin</button></div>
        </div>
        <Table table={this.state.table} openForm={this.openForm} search={this.state.search} />
        <FormModel form={form} edit={this.state.edit} 
          obj={this.state.selected} update={this.update} hide={this.hide} 
          show={this.state.showForm}/>
        <FormModel form={this.state.changePasswordForm} edit={true} 
          obj={this.state.selected} update={this.updatePassword} hide={this.hidePassword} 
          show={this.state.showChangeForm}/>
      </div>
    );  
  }
  
}