import React from 'react';
import { Modal } from 'react-bootstrap';
import {CriticalBag} from '../../apis';

import './CriticalBags.css';
import { Link } from '@reach/router';
import { Badge } from '../badges/Bagde';

interface CriticalBagsProps {
  critialBags: CriticalBag;
  showAll: boolean;
}

interface CriticalBagsState {
  showDialog: boolean;
}

export class CriticalBags extends React.Component<CriticalBagsProps, CriticalBagsState> {
  
  constructor(props: CriticalBagsProps) {
    super(props);
    this.state = {
      showDialog: false
    };
  }

  render() {
    const criticalBags = this.props.critialBags;
    const showAll = this.props.showAll;
    const showViewAll = showAll ? false : ( (criticalBags.data?.length || 0) > 3 );
    const style = (showViewAll) ? {} : {display: "none"};
    let total = 0;
    return(
      <div>
        <table className="critial-bags">
          <thead style={{backgroundColor: "var(--light-red-color)"}}>
            <tr>
              <th className="critial-bags-border" style={{width: "10%"}}>
                S.No
              </th>  
              <th className="critial-bags-border" style={{width: "30%"}}>
                Bag ID
              </th>
              <th className="critial-bags-border" colSpan={3} style={{width: "60%"}}>
                No. Of Critical Situations
              </th>
            </tr>
          </thead>
          <tbody>
            {
              criticalBags.data?.map((bag, index) =>{
                if( showAll || index < 3 )  {
                  const noStyle= {width: "20%"};
                  const style = {color: "var(--dark-red-color)", width: "20%"};
                  const c1 = (bag.noOfCriticalSampleC1 || 0 );
                  const c2 = (bag.noOfCriticalSampleC2 || 0 );
                  const c3 = (bag.noOfCriticalSampleC3 || 0 );
                  total = total + (c1 + c2 + c3);
                  const bagC1 = ( c1 > 0 ) ? style : noStyle;
                  const bagC2 = ( c2 > 0 ) ? style : noStyle;
                  const bagC3 = ( c3 > 0 ) ? style : noStyle;
                  return(
                    <tr key={index}>
                      <td className="critial-bags-border" style={{width: "10%"}}>{index}</td>
                      <td className="critial-bags-border" style={{width: "30%"}}>{bag.bagId || "L0001"}</td>
                      <td style={bagC1} className="critial-bags-border">C1<br/>{ "(" + c1 + ")"}</td>
                      <td style={bagC2} className="critial-bags-border">C2<br/>{ "(" + c2 + ")"}</td>
                      <td style={bagC3} className="critial-bags-border">C3<br/>{ "(" + c3 + ")"}</td>
                    </tr>
                  );
                }
              })
            }
          </tbody>
        </table>
        <Badge size={40} color="#fff" count={total} bgColor="var(--dark-red-color)" badgeClass={"table-badge"} />
        <div className="align-right" style={style}>
          <span>
            <Link to="#" onClick={()=>{this.setState({showDialog: true})}}>
              view all
            </Link>
          </span>
        </div>
        <Modal show={this.state.showDialog} dialogClassName="modal-70w" onHide={()=>{this.setState({showDialog: false})}} size="xl" >
          <div className="window" style={{overflow: "scroll"}}>
            <CriticalBags critialBags={criticalBags} showAll={true} />
          </div>  
        </Modal>
      </div>
    );  
  }

}