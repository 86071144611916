import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import {ManageTrips} from './manage_trips/ManageTrips';
import {ManageTestData} from './manage_test_data/ManageTestData';
import {ManageCovidPatients} from './manage_patients/ManagePatients';
import './CovidOnsiteCollection.css';

interface CovidOnsiteCollectionProps extends RouteComponentProps {

}

export class CovidOnsiteCollection extends React.Component<CovidOnsiteCollectionProps> {

  render() {
    return(
      <div>
        <Router basepath="/covid_onsite_collection/">
          <ManageTestData path="manage_vaccine_data" />
          <ManageTrips path="manage_trips" />
          <ManageTrips path="manage_trips/:id" />
          <ManageCovidPatients path="manage_patient_data" />
          <ManageCovidPatients path="manage_patient_data/:id" />
        </Router>
      </div>
    );  
  }
  
}