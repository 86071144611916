import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import {ManageAdmins} from './manage_admins/ManageAdmins';
import {ManageCarriers} from './manage_carriers/ManageCarriers';

interface EmployeeViewProps extends RouteComponentProps {

}


export class EmployeeView extends React.Component<EmployeeViewProps> {
  render() {
    return(
      <div>
        <Router basepath="/employees/">
          <ManageAdmins path="manage_admins" />
          <ManageCarriers path="manage_carriers" />
        </Router>
      </div>
    );  
  }
}
