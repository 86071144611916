import React from 'react';
import {RouteComponentProps} from '@reach/router';

import { DefaultApi, Form, RModelMessageCodeEnum, TableModel, RModelFormCodeEnum, RModelRTableRVaccine, RModelRTableRVaccineCodeEnum, RVaccine } from '../../../apis';
import { FormModel } from '../../../components/form/Form';
import Table from '../../../components/table/Table';
import { AxiosResponse } from 'axios';
import './ManageTestData.css';

interface ManageTestDataProps extends RouteComponentProps {

}

interface ManageTestDataState {
  form: Form;
  showForm: boolean;
  selected: any;
  edit: boolean;
  table: TableModel;
}

export class ManageTestData extends React.Component<ManageTestDataProps,ManageTestDataState> {

  constructor(props: ManageTestDataProps) {
    super(props);
    this.state = {
      form : {},
      showForm: false,
      selected: {},
      edit: false,
      table: {}
    };
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    this.setState(
      {
        form : state.form,
        showForm: true,
        selected: obj || {},
        edit: true,
        table: state.table
      }
    );
  }

  show() {
    const state = this.state;
    this.setState(
      {
        form: state.form,
        showForm: true,
        selected: state.selected,
        edit: state.edit,
        table: state.table
      }
    );
  }

  hide() {
    const state = this.state;
    this.setState(
      {
        form : state.form,
        showForm: false,
        selected: {},
        edit: false,
        table: state.table
      }
    );
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updateVaccine("","",token,centerId, obj as RVaccine).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Test Data", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createVaccine("","",token,centerId, obj as RVaccine).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          window.showAlert(data.success?.message || "Created.","Manage Test Data", false, (confirmed) => {
            self.hide();  
          });
        }else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getTableVaccines("","",token,centerId).then((itemR: AxiosResponse<RModelRTableRVaccine>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRVaccineCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          form : state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          table: dataR.success as TableModel
        });
      }
    });
    defaultApi.getFormType("","",token,centerId,"Vaccine").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const state = self.state;
        self.setState(
          {
            form : data.success || {},
            showForm: state.showForm,
            selected: state.selected,
            edit: state.edit,
            table: state.table
          }
        );
      }
    });
  }

  render() {
    const form = this.state.form || {};
    const style = (this.state.table.access) ? {} : {display: "none"};
    return(
      <div>
        <div style={{minHeight: "20px"}}></div>
        <button type="button" className="btn" onClick={this.show} style={style}>Add Vaccine</button>
        <div style={{minHeight: "20px"}}></div>
        <Table table={this.state.table} openForm={this.openForm} />
        <FormModel form={form} edit={this.state.edit} 
          obj={this.state.selected} update={this.update} hide={this.hide} 
          show={this.state.showForm}/> 
      </div>
    );  
  }
  
}