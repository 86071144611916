import React from 'react';

import {FormElement, FormState, FormProps} from '../../../components/form/Form';
import '../../../components/form/Form.css';
import { Modal  } from 'react-bootstrap';
import { Group } from '../../../apis';

export class CarrierForm extends React.Component<FormProps> {

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.props.hide()} centered size="lg">
        <Modal.Body >
          <div className="dialog-title">
            <span>{this.props.form.name}</span>
          </div>
          <CarrierFormView form={this.props.form} edit={this.props.edit} obj={this.props.obj} update={this.props.update} show={this.props.show} hide={this.props.hide} />
        </Modal.Body>
      </Modal>
    );  
  }
}

class CarrierFormView extends React.Component<FormProps,FormState> {

  constructor(props: FormProps) {
    super(props);
    this.state = {
      obj: props.obj,
      formGroups: [],
      confirm: true
    }
    this.updateProp = this.updateProp.bind(this);
    this.reference = this.reference.bind(this);
    this.validate = this.validate.bind(this);
    this.getValue = this.getValue.bind(this);
    this.buttonClick = this.buttonClick.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.disableConfirm = this.disableConfirm.bind(this);
  }

  reference(ref: any) {
    let formGroups = this.state.formGroups;
    formGroups.push(ref);
    this.setState({formGroups: formGroups});
  }

  validate(obj:  any): boolean {
    let result = true;
    this.state.formGroups.forEach((formGroup) => {
      if( !formGroup.validateAll() && result ) {
        result = false;
      }
    });
    return result;
  }

  buttonClick(obj: any) {
    if( this.validate(obj) ) {
      this.disableConfirm();
      this.props.update(obj, this.showConfirm);
    }
  }

  showConfirm() {
    this.setState({confirm: true});
  }

  disableConfirm() {
    this.setState({confirm: false});
  }


  getValue(keys: Array<string>) : any {
    let obj = this.state.obj;
    if( keys.length === 1 ) {
      return obj[keys[0]] || "";
    }else if( keys.length === 2 ) {
      let local = obj[keys[0]] || {};  
      return local[keys[1]] || "";
    }else {
      window.showAlert("Currently too many deep dependecy not done.");
      return "";
    }   
  }

  updateProp(keys: Array<string>, value: any) {
    let obj = this.state.obj;
    console.log(keys, value);
    if( keys.length === 1 ) {
      obj[keys[0]] = value;
    }else if( keys.length === 2 ) {
      let local = obj[keys[0]] || {};  
      local[keys[1]] = value;
      obj[keys[0]] = local;
    }else {
      window.showAlert("Currently too many deep dependecy not done.");
      return;
    }   
    this.setState({obj: obj});  
  }

  render() {
    return (
      <div className="form-class">
        <form>
          {
            this.props.form.groups?.map((group, index) =>{
              return(<GridFormGroup group={group} update={this.updateProp} reference={this.reference} getValue={this.getValue} column={(index===0)? 3: 2} key={index}/>);
            })
          }
        </form>
        <div className="flex-action">
          <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Cancel</button></div>
          <div><button className="btn btn-confirm" onClick={()=>{this.buttonClick(this.state.obj)}} disabled={!this.state.confirm}>Confirm</button></div>
        </div>
      </div>
    );  
  }
}

interface GridFormGroupProps {
  group: Group;
  reference(ref: any): void;
  update(keys: Array<string>, value: any, check?: boolean): void;
  getValue(keys: Array<string>): any;
  column: number;
}

export class GridFormGroup extends React.Component<GridFormGroupProps> {

  constructor(props: GridFormGroupProps) {
    super(props);
    this.getModule = this.getModule.bind(this);
  }

  getModule() : string {
    return "Home"
  }

  render() {
    if( this.props.column === 2 ) {
      return (
        <div>
          <div className="category-title">{ this.props.group.name||"" }</div>
          <div className="row">
              {
                this.props.group.fields?.map((field, index) =>{
                  return(
                    <div className="col-md-6" key={index}>
                      <FormElement field={field} update={this.props.update} reference={this.props.reference} getValue={this.props.getValue} full={true} getModule={this.getModule} />
                    </div>
                  ) 
                })  
              }
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="category-title">{ this.props.group.name||"" }</div>
          <div className="row">
            <div className="col-md-4">
              {
                this.props.group.fields?.map((field, index ) =>{
                  if( index === 0 ) {
                    return(
                      <div className="col-md-4" key={index}>
                        <FormElement field={field} update={this.props.update} reference={this.props.reference} getValue={this.props.getValue} full={true} getModule={this.getModule} />
                      </div>  
                    );
                  }
                })  
              }
            </div>
            <div className="col-md-8">
              <div className="row">
                {
                  this.props.group.fields?.map((field, index ) =>{
                    if( index > 0 ) {
                      return(
                        <div className="col-md-6" key={index}>
                          <FormElement field={field} update={this.props.update} reference={this.props.reference} getValue={this.props.getValue} full={true} getModule={this.getModule}/>
                        </div>  
                      );
                    }
                  })  
                }
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

}
