import React from 'react';
import {RouteComponentProps, navigate} from '@reach/router';
import Axios from 'axios';

import {
  DefaultApi, 
  DashboardTypeEnum,
  CriticalBag,
  LeaderBoard,
  //DynamicItem,
  RModelRMainCenterCodeEnum,
  //RModelListRLabCodeEnum,
  //RModelListRPatientCodeEnum,
  RModelDashboardCodeEnum
} from '../../apis'
import { CriticalBags } from '../../components/criticalbags/CriticalBags';
import LeaderBoardView from '../../components/leaderboard/LeaderBoard';
import ReSampleView from '../../components/resample/ReSample';
import Weather from '../../components/weather/Weather';
import {Chart, ChartType} from '../../components/chart/Chart';
import {options, dashData} from '../dashboard/DataFile';
import './Dashboard.css';
import '../base_view/BaseView.css';
//import { Dropdown } from '../../components/dropdown/Dropdown';

interface DashboardProps extends RouteComponentProps {

}

interface Lab {
  name: string;
  id: string;
}

interface DashboardItem {
  type: DashboardTypeEnum;
  total: number;
  data: any;
  criticalBag: CriticalBag;
  carrier: LeaderBoard;
  lab: LeaderBoard;
  numberOfReSample: number;
  temperature: number;
}

interface DashboardState extends RouteComponentProps {
  state: string;
  actions: Array<string>;
  //list: Array<DynamicItem>;
  //selected?: string;
  dashboard: DashboardItem;
}

export class DashboardView extends React.Component<DashboardProps, DashboardState> {

  constructor(props: DashboardProps) {
    super(props);
    //this.selectList = this.selectList.bind(this);
    this.updateDashboard = this.updateDashboard.bind(this);
    this.typeUpdate = this.typeUpdate.bind(this);
    this.changeDashboard = this.changeDashboard.bind(this);
    this.state = dashData;
  }

  changeDashboard() {
    navigate("/covid_dashboard");
  }

  typeUpdate(type: string) {
    this.setState({state: type});
    this.updateDashboard(type);
  }

  updateDashboard(type?: string) {
    const self = this;
    const state = this.state;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getDashboard("","",token, centerId, type || state.state).then((item) => {
      if( item.data.code === RModelDashboardCodeEnum.Success ) {
        let dashboard = item.data.success || {data: "{}"};
        dashboard.data = JSON.parse(dashboard.data || "{}");
        self.setState({
          dashboard: dashboard as DashboardItem
        });
        defaultApi.getMainCenterById("","",token, centerId, centerId)
        .then((zItem) => {
          if( zItem.data.code === RModelRMainCenterCodeEnum.Success ) {
            const location = zItem.data.success?.address?.location;
            Axios.get("https://api.openweathermap.org/data/2.5/weather?lat="+location?.lat+"&lon="+location?.lng+"&units=metric&appid=b99cd2e7eeaafc3bc1f060f2e0e75230")
            .then((lItem) => {
              if(lItem.status === 200) {
                dashboard.temperature = lItem.data.main.temp as number;
                self.setState({
                  dashboard: dashboard as DashboardItem
                });
              }
            });
          }
        })
      }   
    })
  }

  componentDidMount() {
    /*const self = this;
    const state = this.state;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();*/
    /*defaultApi.updatePatch("","", token, centerId).then((item) => {
      console.log("Migrate User Batch Updated ", item);
    })*/
    this.updateDashboard()
    /*defaultApi.getAllLabs("","",token,centerId).then((item) => {
      if( item.data.code === RModelListRLabCodeEnum.Success ) {
        const list = item.data.success;
        list?.map((litem, _)  => {
          let area = litem.address?.area || "";
          state.list.push({id: area, name: area});
          self.setState({
            list: state.list.unique()
          });
        });
      }
    })
    defaultApi.getAllPatients("","",token,centerId, "OP").then((item) => {
      if( item.data.code === RModelListRPatientCodeEnum.Success ) {
        const list = item.data.success;
        list?.map((lItem, _)  => {
          let area = lItem.address?.area || "";
          state.list.push({id: area, name: area});
          self.setState({
            list: state.list.unique()
          });
        });
      }
    })*/
  }

  /*selectList(list: DynamicItem) {
    this.setState({selected: list.name});
  }*/

  render() {
    //const list = this.state.list;
    const actions = this.state.actions;
    const state = this.state.state;
    const dashboard = this.state.dashboard;
    //console.log(dashboard.data)
    return(
      <div>
        <div>
        <div className="row">
            <div className="col-md-6">
              <button type="button" className="btn" onClick={()=>this.changeDashboard()}>Covid Dashboard</button>
            </div>
            <div className="col-md-6">
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <div className="tab-container">
              {
                actions.map((name, index) => {
                  const isActive = (name === state);
                  const active = (isActive) ? "tab-active-container" : "";
                  return(
                    <div className={active} key={index} onClick={(e)=>{this.typeUpdate(name)}}>{name}</div>
                  );
                })
              }
              </div>                    
            </div> 
          </div> 
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={{minHeight: "60px"}}>

            </div>
          </div>
          <div className="col-md-7">
            <Chart type={ChartType.BAR} data={dashboard.data} options={options} count={dashboard.total || 0}/>
            <div style={{minHeight: "60px"}} />
            <div className="row"> 
              <div className="col-md-8" style={{alignSelf: "flex-end"}} >
                <CriticalBags critialBags={dashboard.criticalBag} showAll={false}/>
              </div>
              <div className="col-md-4" style={{alignSelf: "flex-end"}} >
                <ReSampleView count={dashboard.numberOfReSample} />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <LeaderBoardView leaderBoard={dashboard.lab} isLab={true} showAll={false}/>
            <div style={{minHeight: "20px"}} />
            <LeaderBoardView leaderBoard={dashboard.carrier} isLab={false} showAll={false}/>
            <div style={{minHeight: "20px"}} />
            <Weather temperature={dashboard.temperature} />
          </div>
        </div>  
      </div>
    );
  }

}

/*
<div className="col-md-3">
              <Dropdown id="dashboard-lab" selected={this.state.selected} 
              placeholder="All" 
              items={list}
              invert={true}
              onSelected={(item) => {this.selectList(item)}}/>
            </div>*/