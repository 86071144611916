import React from 'react';
//import { Button } from 'react-bootstrap';
import {Link} from '@reach/router';
//import {Scrollbars} from 'react-custom-scrollbars';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import {TableModel, HeaderTypeEnum, HeaderStyleEnum} from '../../apis';
import './Table.css';
import { BASE_PATH } from '../../apis/base';

interface TblProps{
  table?: TableModel;
  row?: number;
  col?: number;
  type?: boolean;
  verticalScroll?: boolean;
  search?: string;
  openForm(form: string, id: string, obj?: any): void;
}

interface TblState {
  row?: number;
  col?: number;
}

class TableHeader extends React.Component<TblProps,TblState> {

  constructor(props: TblProps) {
    super(props);
    this.state = {
    }
  }

  render() {
    const table = this.props.table
    if( table === undefined || table === null ) {
      return(<tr></tr>);
    }else {
      const type = this.props.type || false
      const tableModel: TableModel = table
      return(
        <tr style={{backgroundColor: tableModel.headerColor}} >
          <th style={{width: "5%", textAlign: "center", verticalAlign: "middle"}}> S.No. </th>
          {
            tableModel.headers?.map((header,index) => {
              const title = (type && (header.name === "Address")) ? "Hospital Address" : header.name;
              return(
                <th key={index} style={{width: header.width+"%", textAlign: "center", verticalAlign: "middle"}}>
                  {title}
                </th>
              )
            })
          }
        </tr>
      );
    }
  }

}

interface LooseObject {
  [key: string]: any
}

class TableData extends React.Component<TblProps,TblState> {

  constructor(props: TblProps) {
    super(props);
    this.state = {
      row: props.row,
      col: props.col
    }
    this.getText = this.getText.bind(this);
    this.getStyle = this.getStyle.bind(this);
  }

  getText(props: Array<string>, data: Map<string,any>, index: number) : string {
    if( props.length === (index+1) ) {
      return data.get(props[index]) || "";
    } else {
      const subObj = data.get(props[index]) || {};
      const subMap = new Map(Object.entries(subObj));
      return this.getText(props, subMap, index+1);
    }    
  }

  getStyle(style: HeaderStyleEnum) :  LooseObject {
    if( style === HeaderStyleEnum.Bold ) {
      return { fontWeight: "700" }
    }else if ( style === HeaderStyleEnum.Caps ) {
      return { textTransform: "uppercase" }
    }else if( style === HeaderStyleEnum.Italic ) {
      return { fontStyle: 'italic' }
    }else if( style === HeaderStyleEnum.Center ) {
      return { textAlign: 'center' }
    }else if( style === HeaderStyleEnum.CapsBold) {
      return { fontWeight: "700", textTransform: "uppercase" }
    }else if( style === HeaderStyleEnum.CenterCapsBold) {
      return { textAlign: 'center', fontWeight: "700", textTransform: "uppercase" }
    }
    return {};
  }

  render() {
    const table = this.props.table;
    if( table === undefined || table === null ) {
      return(<td></td>);
    }else {
      const tableModel: TableModel = table
      let filter = (this.props.search ? tableModel.data?.search(this.props.search) : tableModel.data) || [];
      const row = this.state.row || 0;
      const col = this.state.col || 0;
      const data = new Map(Object.entries(filter[row] || {}));
      const header = tableModel.headers![col];
      const type = header.type || HeaderTypeEnum.Text;
      const property = header.property || "";
      const status = "";
      const style = this.getStyle(header.style || HeaderStyleEnum.None);
      const linkBag = ( "Bag ID" === header.name?.trim() );
      const linkCollection = ( "Collection Info" === header.name?.trim() );
      if( type === HeaderTypeEnum.Edit ) {
        const text = data.get(property) || "";
        if( linkBag ) {
          return(
            <td className={status} style={style}>
              <div className="flex-row">
                <div><Link to={"/bags_carriers/manage_bags/"+text}>{text}</Link></div>
                <div>  
                  <img src="/images/table/edit.png" alt="edit" width="20px" onClick={()=>{this.props.openForm(header.link?.name|| "", data.get(header.link?.link || "id"), tableModel.data ? tableModel.data[row] : {})}} />
                </div>
              </div>
            </td>
          );
        }else {
          return(
            <td className={status} style={style}>
              <div className="flex-row">
                <div>{text}</div>
                <div>  
                  <img src="/images/table/edit.png" alt="edit" width="20px" onClick={()=>{this.props.openForm(header.link?.name|| "", data.get(header.link?.link || "id"), tableModel.data ? tableModel.data[row] : {})}} />
                </div>
              </div>
            </td>
          );     
        }   
      }else if( type === HeaderTypeEnum.Text ) {
        if( property.includes(".") ) {
          const properties = property.split(".");
          const text = this.getText(properties, data, 0)
          const colors = header.colors || {};
          const color = colors[text] || "var(--black-color)";
          style['color'] = color;
          return(
            <td className={status} style={style}>
              {text}
            </td>
          );
        }else {
          const text = data.get(property) || "";
          const colors = header.colors || {};
          const color = colors[text] || "var(--black-color)";
          style['color'] = color;
          if( linkBag ) {
            return(
              <td className={status} style={style}>
                <Link to={"/bags_carriers/manage_bags/"+text}>{text}</Link>
              </td>
            );
          }else {
            return(
              <td className={status} style={style}>
                {text}
              </td>
            );
          }
        }
      }else if( type === HeaderTypeEnum.Date ) {
        const text: number = data.get(property) || 0;
        const date = new Date(text)
        const name = date.getDate()+"."+(date.getMonth() + 1)+"."+date.getFullYear()
        return(
          <td className={status} style={style}>
            { name }
          </td>
        );
      }else if( type === HeaderTypeEnum.Number ) {
        const value : number = data.get(property) || 0;
        const min = header.min || 0;
        const max = header.max || 0;
        let color = "var(--black-color)";
        if( min !== 0 && max !== 0 ) {
          const colors = header.colors!
          if( value > min && value < max ) {
            color = colors["in"] || "var(--black-color)";
          } else {
            color = colors["out"] || "var(--black-color)";
          }
        }
        style['color'] = color;
        if( (property === "chamber2" || property === "chamber3") && data.get("type") === 'Home') {
          style['color'] = "var(--black-color)";
          return (
            <td className={status} style={style}>-</td>
          )
        }else {
          return (
            <td className={status} style={style}>{value+" "+(header.unit||"")}</td>
          )
        }
      }else if( type === HeaderTypeEnum.Link) {
        const link = header.link!;
        const linkTitle = ( link.variable || false) ? data.get(link.name || "") : link.name;
        let linkTo = (linkBag) ? "/bags_carriers/manage_bags/"+linkTitle : 
            (linkCollection) ? header.property+"/"+data.get(link.link || "") : header.property ||""
         if( link.name === "slug" ) {
           linkTo = "http://"+window.location.host+"/"+linkTitle
           return(
            <td className={status} style={style}>
              <a target="_blank" rel="noopener noreferrer" href={linkTo}>{linkTitle}</a>
            </td>
          );
         } else {    
          return(
            <td className={status} style={style}>
              <Link to={linkTo}>{linkTitle}</Link>
            </td>
          );
         }
      } else if( type === HeaderTypeEnum.Image) {
        const text = data.get(property) || "";
        const img = header.images?.[text] || "";
        //console.log(text, property, img)
        if( property === "numberOfSamples") {
          return(
            <td className={status} style={style} align="center">
              {text}
            </td>
          );
        }else {
          if( img === "" ) {
            const colors = header.colors || {};
            const color = colors[text] || "var(--black-color)";
            style['color'] = color;
            return(
              <td className={status} style={style} align="center">
                {text}
              </td>
            );
          }else {
            return(
              <td className={status} style={style} align="center">
                <img src={img} alt="" width="25" height="25" />
              </td>
            );
          }
        }
      } else if( type === HeaderTypeEnum.Button) {
        const button = header.button!;
        const url = (data.get("pdfURL") || "")+"&download=true";
        return (
          <td className={status} style={style}>
            <a style={{backgroundColor: "var(--dark-blue-color)", color: "white", padding: 5}} target="_blank" rel="noopener noreferrer" href={BASE_PATH+url}>{button.name || "Action"}</a>
          </td>
        )
      } else if( type === HeaderTypeEnum.CustomAction ){
        const text = (property === "Change Password") ? property : data.get(property) || "";
        //const colors = header.colors || {};
        //const cColor = "var(--dark-blue-color)"; //colors[text] || 
        return(
          <td className={status} style={style}>
            <span className={"custom-action"} onClick={()=>{this.props.openForm(header.property || "", data.get(header.link?.link || "id"), tableModel.data ? tableModel.data[row] : {})}}>{text}</span>  
          </td>
        );
      } else {
        if( property.includes(".") ) {
          const properties = property.split(".");
          const text = this.getText(properties, data, 0);
          return(
            <td className={status} style={style}>
              {text}
            </td>
          );
        }else {
          const text = data.get(property) || "";
          return(
            <td className={status} style={style}>
              {text}
            </td>
          );
        }
      }
    }
  }
}

class TableRow extends React.Component<TblProps,TblState> {

  constructor(props: TblProps) {
    super(props);
    this.state = {
      row: props.row || 0
    }
  }

  render() {
    const table = this.props.table;
    const row = this.state.row || 0;
    if( table === undefined || table === null ) {
      return(<tr></tr>);
    }else {
      const tableModel: TableModel = table
      return(
        <tr key={row}>
          <td>{row+1}</td>
          {
            tableModel.headers?.map((_,index) => {
              return(<TableData table={tableModel} row={row} col={index} key={index} openForm={this.props.openForm} search={this.props.search}/>)
            })
          }
        </tr>
      );
    }
  }
  
}


export default class Table extends React.Component<TblProps,TblState> {

  constructor(props: TblProps) {
    super(props);
    this.state = {
    }
    this.getTableTitleView = this.getTableTitleView.bind(this);
    this.getRowData = this.getRowData.bind(this);
  }

  getTableTitleView(tableModel: TableModel) {
    let title = <div></div>;
    if( tableModel.title === undefined || tableModel.title === "" ) {
      title = <div></div>
    }else {
      title = <div className="table-title-holder"><div className="table-title" style={{backgroundColor: tableModel.titleBackground || "var(--dark-blue-color)"}}>{tableModel.title}</div></div>
    }
    return title;
  }

  getRowData(tableModel: TableModel) {
    let filter = (this.props.search ? tableModel.data?.search(this.props.search) : tableModel.data) || [];
    const zeroRow = (filter.length === 0) || false;
    if( zeroRow ) {
      return(  
        <tr>
          <td style={{textAlign: 'center'}}>-</td>
          {
            tableModel.headers?.map((_,index) => {
              return(<td style={{textAlign: 'center'}} key={index}>-</td>)
            })
          }
        </tr>
      )
    } else {
      let data = filter.map((_value:any, index: number) => {
        return ( <TableRow table={tableModel} row={index} key={index} openForm={this.props.openForm} search={this.props.search}/>)
      });
      return data;
    }
  }

  render() {
    const table = this.props.table;
    //const verticalScroll = this.props.verticalScroll || false;
    if( table === undefined || table === null ) {
      return(<div></div>);
    }/*else if( verticalScroll){
      const tableModel: TableModel = table;
      const titleView = this.getTableTitleView(tableModel);
      return(
        <div className="table">
          {titleView}
          <Scrollbars autoHeight autoHeightMax="200" >
            <table className="table">
              <thead>
                <TableHeader table={tableModel} openForm={this.props.openForm} /> 
              </thead>
              <tbody> 
                {
                  this.getRowData(tableModel)
                }
              </tbody>
            </table>
          </Scrollbars>
        </div>
      );
    }*/else {
      const tableModel: TableModel = table;
      const titleView = this.getTableTitleView(tableModel);
      return(
        <div className="table">
          {titleView}
          <table className="table">
            <thead>
              <TableHeader table={tableModel} openForm={this.props.openForm} type={this.props.type} /> 
            </thead>
            <tbody> 
              {
                this.getRowData(tableModel)
              }
            </tbody>
          </table>
        </div>
      );
    }
  }

}
