import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export interface AlertUIProps {
  show: boolean;
  message: string;
  title: string;
  confirmation: boolean;  
  callback: ((confirmed: boolean)=> void);
}

export default class AlertUI extends React.Component<AlertUIProps> {

  render() {
    const props = this.props;
    const buttonName = (props.confirmation) ? "Confirm" : "Okay"
    return(
      <Modal show={props.show} variant="info" onHide={() => props.callback(false)} centered={true} >
        <Modal.Header className="border-0 p-0 px-3">
          <Modal.Title className="text-primary">{props.title}</Modal.Title> 
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="px-3">{props.message}</div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-3">
          <Button onClick={() => props.callback(false)} variant="danger" hidden={!props.confirmation} className="pe-3">
            Cancel
          </Button>
          <Button onClick={() => props.callback(true)} variant="primary">
            {buttonName}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
}