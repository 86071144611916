import React from 'react';
import {RouteComponentProps} from '@reach/router';

import {
  DefaultApi,
  RModelRPowerDashboardCodeEnum
} from '../../apis'
import {Chart, ChartType} from '../../components/chart/Chart';
import {options, optionStorage} from './DataFile';
import './PowerDashboard.css';
import '../base_view/BaseView.css';

interface PowerDashboardProps extends RouteComponentProps {

}

interface RPowerDashboardItem {
  storage: any,
  fileCounts: number,
  samples: any,
  sampleCounts: number
}

interface PowerDashboardState extends RouteComponentProps {
  state: string;
  actions: Array<string>;
  dashboard: RPowerDashboardItem;
}

export class PowerDashboardView extends React.Component<PowerDashboardProps, PowerDashboardState> {

  constructor(props: PowerDashboardProps) {
    super(props);
    this.updatePowerDashboard = this.updatePowerDashboard.bind(this);
    this.typeUpdate = this.typeUpdate.bind(this);
    this.state = {
      state: "Week",
      actions: [ "Day","Week","Month","Year" ],
      dashboard: {
        storage: {},
        fileCounts: 0,
        samples: {},
        sampleCounts: 0
      }
    };
  }

  typeUpdate(type: string) {
    this.setState({state: type});
    this.updatePowerDashboard(type);
  }

  updatePowerDashboard(type?: string) {
    const self = this;
    const state = this.state;
    const token = localStorage.getItem("token") || "";
    const centerId = "all";
    const tab = type || state.state;
    const defaultApi = new DefaultApi();
    defaultApi.getPowerDashboard("","",token, centerId, tab).then((item) => {
      if( item.data.code === RModelRPowerDashboardCodeEnum.Success ) {
        let dashboard = item.data.success || {};
        self.setState({
          dashboard: {
            samples: JSON.parse(dashboard.samples || "{}"),
            sampleCounts: dashboard.sampleCounts || 0,
            storage: JSON.parse(dashboard.storage || "{}"),
            fileCounts: dashboard.fileCounts || 0
          }
        });
      }   
    })
  }

  componentDidMount() {
    this.updatePowerDashboard();
  }

  render() {
    const state = this.state.state;
    const actions = this.state.actions;
    const dashboard = this.state.dashboard;
    return(
      <div>
        <div>
          <div className="row">
            <div className="col-md-9">
              <div className="tab-container">
              {
                actions.map((name, index) => {
                  const isActive = (name === state);
                  const active = (isActive) ? "tab-active-container" : "";
                  return(
                    <div className={active} key={index} onClick={(e)=>{this.typeUpdate(name)}}>{name}</div>
                  );
                })
              }
              </div>                    
            </div> 
          </div> 
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={{minHeight: "60px"}}>

            </div>
          </div>
          <div className="col-md-9">
            <Chart type={ChartType.BAR} data={dashboard.samples} options={options} count={dashboard.sampleCounts || 0}/>
            <div style={{minHeight: "60px"}} />
            <Chart type={ChartType.BAR} data={dashboard.storage} options={optionStorage} count={dashboard.fileCounts || 0}/>
          </div>
        </div>  
      </div>
    );
  }

}