import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { 
  RTestCollection, RTrip,
  RSample, DefaultApi, RModelMessageCodeEnum, 
  RModelListRSample, RModelListRSampleCodeEnum,
  RModelRTestCollectionCodeEnum,
  RModelListRTripCodeEnum,
  RModelListRTrip
} from '../../apis';
import { AxiosResponse } from 'axios';
import { Modal } from 'react-bootstrap';
import './HistoryView.css';

interface HistoryViewProps extends RouteComponentProps {
  id: string;
  home: boolean;
  type: string;
  show: boolean;
  hide(): void;
}

interface HistoryViewState {
  samples: Array<RSample>;
  sampleGroups: Array<RSample>;
  trips: Array<RTrip>;
}

export class HistoryView extends React.Component<HistoryViewProps, HistoryViewState> {
  
  constructor(props: HistoryViewProps) {
    super(props)
    this.state = {
      trips: [],
      samples: [],
      sampleGroups: []
    }
    this.update = this.update.bind(this);
  }

  update(obj: any) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    obj.patient = this.props.id || "";
    obj.tripType = this.props.type || "OP";
    const defaultApi = new DefaultApi()
    if( this.props.home ) {
      defaultApi.updateTestInBooking("","", token, centerId, obj as RTestCollection).then((item) => {
        const data = item.data;
        if( data.code === RModelRTestCollectionCodeEnum.Success ) {
          obj.testCollection = data.success?.testCollection || [];
          self.props.hide(); 
        }
      });
    }else {
      if( obj.id !== undefined ) {
        defaultApi.updateTestCollection("","",token,centerId, obj as RTestCollection).then((item)=>{
          const data = item.data;
          if( data.code === RModelMessageCodeEnum.Success ) {
            //window.alert("Created.");
            self.props.hide(); 
          }
        });
      }else {
        defaultApi.createTestCollection("","",token,centerId, obj as RTestCollection).then((item)=>{
          const data = item.data;
          if( data.code === RModelMessageCodeEnum.Success ) {
            //window.alert("Created."); 
            self.props.hide();
          }
        });
      }
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getTestGroups("","",token,centerId, "nonpro").then((itemR: AxiosResponse<RModelListRSample>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRSampleCodeEnum.Success ) {
        self.setState({
          sampleGroups: dataR.success || [] 
        });
      }
    });
    defaultApi.getAllSamples("","",token,centerId).then((itemR: AxiosResponse<RModelListRSample>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRSampleCodeEnum.Success ) {
        self.setState({
          samples: dataR.success || [] 
        });
      }
    });
    defaultApi.getHistoryById("", "", token, centerId, this.props.id, this.props.type ).then((itemR: AxiosResponse<RModelListRTrip>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRTripCodeEnum.Success ) {
        self.setState({
          trips: dataR.success || [] 
        });
      }
    });
  }

  render() {
    const trips = this.state.trips;
    return(
      <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl" >
        <div className="window" style={{overflow: "scroll"}}>
          {
            trips.map((trip, index) => {
              const testProps: TestCollectionProps = {
                home: this.props.home,
                trip: trip,
                samples: this.state.samples,
                sampleGroups: this.state.sampleGroups,
                update: this.update
              }
              return(<TestHistoryView {...testProps} key={index}/>);
            })
          }
        </div>
      </Modal>
    )
  }

}

interface TestCollectionProps {
  home: boolean;
  samples: Array<RSample>;
  sampleGroups: Array<RSample>;
  trip: RTrip;
  update(obj: any): void;
}

interface TestCollectionState {
  show: boolean;
}

class TestHistoryView extends React.Component<TestCollectionProps, TestCollectionState> {

  constructor(props: TestCollectionProps) {
    super(props);
    this.state = {
      show: false
    };
    this.hide = this.hide.bind(this);
    this.selected = this.selected.bind(this);
  }

  selected(ids: Array<string>) {
    let obj = this.props.trip.testCollection || {};
    obj.tripId = this.props.trip.id;
    obj.number = "";
    if( this.props.home ) {
      obj.group = ids.unique();
    }else {
      obj.testCollection = ids.unique();
    }
    this.props.update(obj);
    this.hide();
  }

  hide() {
    this.setState({show: false});
  }

  render() {
    const item  = ( this.props.trip.testCollection?.status || false ) ? (<img src="/images/maps/lab_checked.png" alt="" width="20px" height="20px"/>) : (<button type="button" className="btn" onClick={()=>{this.setState({show: true})}} >Add Test</button>)
    const groups = (this.props.home) ? this.props.sampleGroups : [];
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div>Trip ID #{this.props.trip.name || ""} ({this.props.trip.testCollection?.noOfTests || 0})
          <span style={{paddingLeft: 10}}>{item}</span>
        </div>
        <div style={{padding: 10}}>
          <div className="row"><strong>Test Group</strong></div>
          <div className="row">
            {
              this.props.trip.testCollection?.group?.map((testId, index) => {
                const sample = groups.find(item1 => (item1.id === testId));
                if( sample !== undefined ) {
                  return(
                    <div className="col-md-4" key={index}>
                      <SampleGroupView sample={sample}/>
                    </div>
                  )
                }else {
                  return(
                    <div className="col-md-4" key={index}>
                    </div>
                  )
                }
              })
            }
          </div>
          <div className="row"><strong>Test Collection</strong></div>
          <div className="row">
            {
              this.props.trip.testCollection?.testCollection?.map((testId, index) => {
                const sample = this.props.samples.find(it => (it.id === testId));
                if( sample !== undefined ) {
                  const sampleType =  this.props.trip.testCollection?.sampleType || [];
                  const barScans = this.props.trip.testCollection?.barCollection || [];
                  const barId = sampleType.indexOf((sample.type || "") as string);
                  //console.log( this.props.trip.testCollection, sampleType, barId, sample.type);
                  const bar = (barId >= 0 && barScans.length > barId) ? barScans[barId] : "";
                  return(
                    <div className="col-md-4" key={index}>
                      <SampleView sample={sample} bar={bar}/>
                    </div>
                  )
                }else {
                  return(
                    <div className="col-md-4" key={index}>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
        <AddHistoryView home={this.props.home} samples={this.props.samples} show={this.state.show} ids={this.props.trip.testCollection?.testCollection || []}
          selected={this.selected} hide={this.hide} group={this.props.trip.testCollection?.group|| []} sampleGroups={this.props.sampleGroups}/>
      </div>
    )
  }

}

interface SampleGroupProps {
  sample: RSample;
}

class SampleGroupView extends React.Component<SampleGroupProps> {
 
  render() {
    const name = this.props.sample.name;
    const count = this.props.sample.ids?.length || 1;
    const image = "/images/maps/lab_selected.png";
    return(
      <div style={{padding: 10}}>
        <div className="test-tube">
          <div>
            <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name + " ("+count+")"}</span>  
          </div>
        </div>
      </div>
    )
  }
}


interface SampleProps {
  sample: RSample;
  bar: string;
}

class SampleView extends React.Component<SampleProps> {

  render() {
    const color = this.props.sample.type?.toLowerCase() || "blue";
    const image = "/images/tubes/"+color+".png";
    const name = this.props.sample.name;
    const bar = this.props.bar;
    if( bar === "" ) {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
            <div>
              <div className={"bar-code "+color}>
                {bar || ""}
              </div>
            </div> 
          </div>
        </div>
      )
    }
  }
}

interface SampleSelectProps {
  sample: RSample;
  selected: boolean;
}

class SampleSelectView extends React.Component<SampleSelectProps> {

  render() {
    const image = "/images/tubes/"+this.props.sample.type?.toLowerCase()+".png"
    const checked = "/images/maps/"+((this.props.selected)? "lab_checked" : "lab_selected")+".png"
    const name = this.props.sample.name
    return(
      <div style={{padding: 10}}>
        <div className="test-tube">
          <div className="flex-row">
            <div><img src={image} alt="" style={{width:25, height:25}}/><span style={{paddingLeft: 10}}>{name}</span></div>
            <div><img src={checked} alt="" style={{width:25, height:25}}/></div>
          </div>             
        </div>
      </div>
    )
  }

}

interface AddHistoryViewProps extends RouteComponentProps {
  home: boolean;
  sampleGroups: Array<RSample>;
  samples: Array<RSample>;
  show: boolean;
  ids: Array<string>;
  group: Array<string>;
  selected(ids: Array<string>): void;
  hide(): void;
}

export class AddHistoryView extends React.Component<AddHistoryViewProps> {

  constructor(props: AddHistoryViewProps) {
    super(props);
    this.update = this.update.bind(this);
  }

  update(id: string) {
    if( !this.props.home ) {
      if( this.props.ids.includes(id) ) {
        const index = this.props.ids.indexOf(id);
        if( index > -1 ) {
          this.props.ids.splice(index, 1);
        }      
      }else {
        this.props.ids.push(id);
      }
    }else {
      if( this.props.group.includes(id) ) {
        const index = this.props.group.indexOf(id);
        if( index > -1 ) {
          this.props.group.splice(index, 1);
        }      
      }else {
        this.props.group.push(id);
      }
    }
    this.setState({});
  }

  render() {
    if( this.props.home ) {
      return(
        <Modal show={this.props.show} dialogClassName="modal-70w overflow-auto" onHide={()=>{this.props.hide()}} size="xl">
          <div className="window overflow-auto">
            <div className="row">
              {
                this.props.sampleGroups.map((sample, index) => {
                  return(
                    <div className="col-md-4" key={index} onClick={()=>{this.update(sample.id || "")}}>
                      <SampleSelectView sample={sample} selected={this.props.group.includes(sample.id || "")} />
                    </div>
                  )
                })
              }
            </div>
            <div className="flex-action">
              <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Close</button></div>
              <div><button className="btn btn-confirm" onClick={()=>{this.props.selected(this.props.group)}}>Confirm</button></div>
            </div>
          </div>
        </Modal>
      )
    }else {
      return(
        <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl">
          <div className="window">
            <div className="row">
              {
                this.props.samples.map((sample, index) => {
                  return(
                    <div className="col-md-4" key={index} onClick={()=>{this.update(sample.id || "")}}>
                      <SampleSelectView sample={sample} selected={this.props.ids.includes(sample.id || "")} />
                    </div>
                  )
                })
              }
            </div>
            <div className="flex-action">
              <div><button className="btn btn-close" onClick={()=>{this.props.hide()}}>Close</button></div>
              <div><button className="btn btn-confirm" onClick={()=>{this.props.selected(this.props.ids)}}>Confirm</button></div>
            </div>
          </div>
        </Modal>
      )
    }
  }

}