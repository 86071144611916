export{}
declare global {
  interface Array<T> {
    search(keyword: string) : Array<T>;
    contains(item: T): boolean;
    unique(): Array<T>;
  }
}
if( !Array.prototype.search ) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.search = function<T>(keyword: string): T[] {
    if( keyword.includes(",") ) {
      let arr: Array<T> = [];
      let keywords = keyword.split(",");
      for (var i = 0; i < keywords.length; i++) {
        let items = this.search(keywords[i].trim());
        for( let item of items ) {
          arr.push(item);
        }
      }
      //console.log(keyword);
      return arr.unique();  
    }else {
      return this.filter(item => JSON.stringify(item).toLowerCase().includes(keyword.toLowerCase()) );
    }
  };

  // eslint-disable-next-line no-extend-native
  Array.prototype.contains = function<T>(item: T): boolean {
    for (var i = 0; i < this.length; i++) {
      if (this[i] === item) return true;
    }
    return false;
  };
  
  // eslint-disable-next-line no-extend-native
  Array.prototype.unique = function<T>() : Array<T> {
    let arr: Array<T> = [];
    for (var i = 0; i < this.length; i++) {
      if (!arr.contains(this[i])) {
        arr.push(this[i]);
      }
    }
    return arr;
  }
}
