import React from 'react';
import {RouteComponentProps} from '@reach/router';

import './ShipmentHistory.css';
import { DefaultApi, TableModel, RModelRTableRShipmentCodeEnum } from '../../apis';
import Table from '../../components/table/Table';

interface ShipmentHistoryProps extends RouteComponentProps {

}

interface ShipmentHistoryState {
  table: TableModel
}

export class ShipmentHistory extends React.Component<ShipmentHistoryProps, ShipmentHistoryState> {

  constructor(props: ShipmentHistoryProps) {
    super(props);
    this.state = {
      table: {}
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi()
    defaultApi.getTableShipments("","",token,centerId).then((item) =>{
      const data = item.data;
      if( data.code === RModelRTableRShipmentCodeEnum.Success ) {
        self.setState({ 
          table: data.success as TableModel
        });
      }
    });
  }

  render() {
    return(
      <div>
        <div style={{minHeight: "20px"}}></div>
        <Table table={this.state.table} openForm={()=>{}} />
      </div>
    );  
  }
  
}