import React from 'react';
import {RouteComponentProps} from '@reach/router';
import { 
  RLabCollection, RLabTrip, RLab, RModelListRLab, FileItem, DefaultApi, RModelListRLabCodeEnum, RModelListRLabCollection, RModelListRLabCollectionCodeEnum
} from '../../apis';
import { BASE_PATH } from '../../apis/base';
import { AxiosResponse } from 'axios';
import { Modal } from 'react-bootstrap';
import './LabCollectionView.css';

interface LabCollectionViewProps extends RouteComponentProps {
  trip: RLabTrip;
  show: boolean;
  hide(): void;
}

interface LabCollectionViewState {
  labs: Array<RLab>;
  labCollections: Array<RLabCollection>;
}

export class LabCollectionView extends React.Component<LabCollectionViewProps, LabCollectionViewState> {
  
  constructor(props: LabCollectionViewProps) {
    super(props)
    this.state = {
      labCollections: [],
      labs: []
    }
  }


  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getAllLabs("","",token,centerId).then((itemR: AxiosResponse<RModelListRLab>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRLabCodeEnum.Success ) {
        self.setState({
          labs: dataR.success || [] 
        });
      }
    });
    defaultApi.getLabCollections("","",token,centerId, this.props.trip.id || "").then((itemR: AxiosResponse<RModelListRLabCollection>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelListRLabCollectionCodeEnum.Success ) {
        self.setState({
          labCollections: dataR.success || [] 
        });
      }
    });
  }

  render() {
    const trip = this.props.trip
    return(
      <Modal show={this.props.show} dialogClassName="modal-70w" onHide={()=>{this.props.hide()}} size="xl" >
        <div className="window" style={{overflow: "scroll"}}>
          {
            trip.labIds?.map((labId, index) => {
              const test = this.state.labCollections.find( item => (item.labId === labId));
              const lab = this.state.labs.find( item => (item.id === labId) ) || {}; 
              const testProps: TestCollectionProps = {
                labCollection: test,
                lab: lab
              }
              return(<TestLabCollectionView {...testProps} key={index}/>);
            })
          }
        </div>
      </Modal>
    )
  }

}

interface TestCollectionProps {
  labCollection?: RLabCollection;
  lab: RLab;
}

interface TestCollectionState {
  show: boolean;
}

class TestLabCollectionView extends React.Component<TestCollectionProps, TestCollectionState> {

  constructor(props: TestCollectionProps) {
    super(props);
    this.state = {
      show: false
    };
    this.hide = this.hide.bind(this);
  }

  
  hide() {
    this.setState({show: false});
  }

  render() {
    const labCollection = this.props.labCollection
    const chamber1 = labCollection?.chamber1 || 0
    const chamber2 = labCollection?.chamber2 || 0
    const chamber3 = labCollection?.chamber3 || 0
    const total = chamber1 + chamber2 + chamber3
    const data = [{
        num: 1,
        count: chamber1,
        bar: labCollection?.chamberBar1 || ""
      },
      {
        num: 2,
        count: chamber2,
        bar: labCollection?.chamberBar2 || ""
      },
      {
        num: 3,
        count: chamber3,
        bar: labCollection?.chamberBar3 || ""
      }
    ]
    const item  = (total > 0) ? (<img src="/images/maps/lab_checked.png" alt="" width="20px" height="20px"/>) : ""
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div>Lab ID #{this.props.lab.id?.substr(this.props.lab.id?.length-6).toUpperCase() || ""} ({total})
          <span style={{paddingLeft: 10}}>{item}</span>
        </div>
        <div style={{padding: 10}}>
          <div className="row">
            {
              data.map((item, index) => {
                const props: SampleProps = item
                return(
                  <div className="col-md-4" key={index}>
                    <SampleView {...props} />
                  </div>
                )
              })
            }
          </div>
          <div className="row">
            {
              labCollection?.files?.map((file, index) => {
                return(
                  <div key={index}>
                    <ThumbDocumentView file={file} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

}

interface SampleProps {
  count: number;
  num: number;
  bar: string;
}

class SampleView extends React.Component<SampleProps> {

  render() {
    const color = ( this.props.num === 1 ) ? "red" : ( this.props.num === 2 ) ? "green" : "blue" 
    const image = "/images/tubes/"+color+".png";
    const name = "Chamber "+this.props.num + " ("+ this.props.count + ")";
    const bar = this.props.bar;
    if( bar === "" ) {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
          </div>
        </div>
      )
    } else {
      return(
        <div style={{padding: 10}}>
          <div className="test-tube">
            <div>
              <img src={image} alt=""/><span style={{paddingLeft: 10}}>{name}</span>  
            </div>
            <div>
              <div className={"bar-code "+color}>
                {bar || ""}
              </div>
            </div> 
          </div>
        </div>
      )
    }
  }
}

export interface DocumentProps {
  file: FileItem;
}

export class ThumbDocumentView extends React.Component<DocumentProps> {
  render() {
    const file = this.props.file
    const name = file.name || "file.jpeg"
    const url = file.url || ""
    return(
      <div style={{padding: 20, minHeight: 100}}>
        <div style={{padding: 10}}>
          <div className="row">
            <div>
              <img src={BASE_PATH+url+"&download=false"} alt={name} width="200" height="auto" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
