import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import {MainCenter} from './center/MainCenter';
import {OrganisationView} from './org/OrganisationView';

interface ManageOrgProps extends RouteComponentProps {

}


export class ManageOrg extends React.Component<ManageOrgProps> {
  render() {
    return(
      <div>
        <Router basepath="/power_organisation/">
          <MainCenter path="manage_centers" />
          <OrganisationView path="manage_orgs" />
        </Router>
      </div>
    );  
  }
}