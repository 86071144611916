import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import './BagsCarriers.css';
import { ManageBags } from './manage_bags/ManageBags';
import { ManageCarriers } from './manage_carriers/ManageCarriers';

interface BagsCarriersProps extends RouteComponentProps {

}

export class BagsCarriers extends React.Component<BagsCarriersProps> {

  render() {
    return(
      <div>
        <Router basepath="/bags_carriers/">
          <ManageBags path="manage_bags" />
          <ManageBags path="manage_bags/:id" />
          <ManageCarriers path="manage_carriers" />
        </Router>
      </div>
    );  
  }

}