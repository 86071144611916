import React from 'react';

import './Weather.css';


interface TemperatureProps {
  temperature: number
}

export default class Weather extends React.Component<TemperatureProps> {

  render() {
    const title = "Today's Temperature"
    const info = this.props.temperature+"°C";
    return (
      <div className="weather-card">
        <div className="weather-card-title">
          {title}
        </div>
        <div className="weather-card-info">
          {info}
        </div>
      </div>
    );
  }
} 