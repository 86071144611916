import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';

import {ManageTrips} from './manage_trips/ManageTrips';
import {ManageTestData} from './manage_test_data/ManageTestData';
import {ManageCovidPatients} from './manage_patients/ManagePatients';
import { ManageCovidOffsites } from './manage_covid_offsite/ManageCovidOffsites';
import './CovidOffsiteCollection.css';

interface CovidOffsiteCollectionProps extends RouteComponentProps {

}

export class CovidOffsiteCollection extends React.Component<CovidOffsiteCollectionProps> {

  render() {
    return(
      <div>
        <Router basepath="/covid_offsite_collection/">
          <ManageTestData path="manage_vaccine_data" />
          <ManageTrips path="manage_trips" />
          <ManageTrips path="manage_trips/:id" />
          <ManageCovidPatients path="manage_patient_data" />
          <ManageCovidPatients path="manage_patient_data/:id" />
          <ManageCovidOffsites path="manage_covid_offsite" />
        </Router>
      </div>
    );  
  }
  
}