import React from 'react';
import {RouteComponentProps} from '@reach/router';

import './ManageCovidOffsites.css';
import { DefaultApi, Form, RCovidOffsite, RModelMessageCodeEnum, RModelRTableRCovidOffsite, RModelRTableRCovidOffsiteCodeEnum, TableModel, RModelFormCodeEnum } from '../../../apis';
import { FormModel } from '../../../components/form/Form';
import Table from '../../../components/table/Table';
import { AxiosResponse } from 'axios';
import { SearchText } from '../../../components/search/SearchText';

interface ManageCovidOffsitesProps extends RouteComponentProps {

}

interface ManageCovidOffsitesState {
  form: Form;
  showForm: boolean;
  selected: any;
  edit: boolean;
  search?: string; 
  table: TableModel
}

export class ManageCovidOffsites extends React.Component<ManageCovidOffsitesProps, ManageCovidOffsitesState> {

  constructor(props: ManageCovidOffsitesProps) {
    super(props);
    this.state = {
      form : {},
      showForm: false,
      selected: {},
      edit: false,
      table: {}
    };
    this.update = this.update.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.openForm = this.openForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  openForm(form: string, id: string, obj?: any) {
    const state = this.state;
    this.setState(
      {
        form : state.form,
        showForm: true,
        selected: obj || {},
        edit: true,
        table: state.table
      }
    );
  }

  show() {
    const state = this.state;
    this.setState(
      {
        form: state.form,
        showForm: true,
        selected: state.selected,
        edit: state.edit,
        table: state.table
      }
    );
  }

  hide() {
    const state = this.state;
    this.setState(
      {
        form : state.form,
        showForm: false,
        selected: {},
        edit: false,
        table: state.table
      }
    );
    window.location.reload();
  }

  update(obj: any, callback: Function) {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi()
    if( this.state.edit ) {
      defaultApi.updateCovidOffsite("","",token,centerId, obj as RCovidOffsite).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          //window.alert(data.success?.message || "Created.");
          self.hide(); 
        }else {
          callback();
        }
      });
    }else {
      defaultApi.createCovidOffsite("","",token,centerId, obj as RCovidOffsite).then((item)=>{
        const data = item.data;
        if( data.code === RModelMessageCodeEnum.Success ) {
          //window.alert(data.success?.message || "Created."); 
          self.hide();
        }else {
          callback();
        }
      });
    }
  }

  componentDidMount() {
    const self = this;
    const token = localStorage.getItem("token") || "";
    const centerId = localStorage.getItem("centerId") || "";
    const defaultApi = new DefaultApi();
    defaultApi.getTableCovidOffsites("","",token,centerId).then((itemR: AxiosResponse<RModelRTableRCovidOffsite>) => {
      const dataR = itemR.data;
      if( dataR.code! === RModelRTableRCovidOffsiteCodeEnum.Success ) {
        const state = self.state;
        self.setState({
          form : state.form,
          showForm: state.showForm,
          selected: state.selected,
          edit: state.edit,
          table: dataR.success as TableModel
        });
      }
    });
    defaultApi.getFormType("","",token,centerId,"CovidOffsite").then((item) =>{
      const data = item.data;
      if( data.code === RModelFormCodeEnum.Success ) {
        const state = self.state;
        self.setState(
          {
            form : data.success || {},
            showForm: state.showForm,
            selected: state.selected,
            edit: state.edit,
            table: state.table
          }
        );
      }
    });
  }

  onChange(text: string) {
    const state = this.state;
    this.setState({
      form : state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: text,
      table: state.table
    });
  }

  onClear() {
    const state = this.state;
    this.setState({
      form : state.form,
      showForm: state.showForm,
      selected: state.selected,
      edit: state.edit,
      search: undefined,
      table: state.table
    });
  }

  render() {
    const form = this.state.form || {};
    const style = (this.state.table.access) ? {} : {display: "none"};
    return(
      <div>
        <div className="action-holder flex-row">
          <div><SearchText onChange={this.onChange} onClear={this.onClear}/></div>
          <div style={style}><button type="button" className="btn" onClick={this.show}>Add CovidOffsite</button></div>
        </div>
        <Table table={this.state.table} openForm={this.openForm} search={this.state.search}/>
        <FormModel form={form} edit={this.state.edit} 
          obj={this.state.selected} update={this.update} hide={this.hide} 
          show={this.state.showForm}/> 
      </div>
    );  
  }
  
}