import React from 'react';
import { AxiosResponse } from 'axios';
import { Button, Fade } from 'react-bootstrap';
import { navigate, RouteComponentProps } from '@reach/router';

import { 
  DefaultApi,
  RUserStatusEnum,
  ROrganisation,
  RModelMessage,
  RModelMessageCodeEnum
} from '../../apis';
import '../login/Login.css';

interface SignUpState {
  org: ROrganisation,
  error?: string
}


export class SignUp extends React.Component<RouteComponentProps, SignUpState> {

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      org: {
        name: "",
        icon: "/images/client_logo.png",
        slug: "",
        services: ['HomeCollection', 'LabCollection', 'Organisation', 'RealTime', 'Report'],
        user: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          status: RUserStatusEnum.Active
        }
      }
    };
    this.signup = this.signup.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.showError = this.showError.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
  }

  showError(message: string) {
    const rorg = this.state.org;
    this.setState({
      org: rorg,
      error: message
    });
  }

  goToLogin( ) {
    const rorg = this.state.org;
    const slug = rorg.slug || "";
    navigate("/"+slug);  
  }

  onDataChange(key: string, value: string) {
    const rorg = this.state.org;
    const slug = (key === "slug") ? value : rorg.slug;
    const name = (key === "name") ? value : rorg.name;
    const firstName = (key === "firstName") ? value : rorg.user?.firstName;
    const lastName = (key === "lastName") ? value : rorg.user?.lastName;
    const password = (key === "password") ? value : rorg.user?.password;
    const phone = (key === "phone") ? value : rorg.user?.phone;
    const email = (key === "email") ? value : rorg.user?.email;
    this.setState({
      org: {
        name: name,
        icon: rorg.icon,
        slug: slug,
        services: rorg.services,
        user: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          password: password,
          status: rorg.user?.status
        }
      }
    });
  }

  signup() {
    const self = this;
    const org = this.state.org;
    const defaultAPI = new DefaultApi();
    defaultAPI.signUp("","","","",org).then((item: AxiosResponse<RModelMessage>) => {
      const data = item.data
      if( data === undefined || data === null ) {
        self.showError("Network Error");
      } else if( data.code === RModelMessageCodeEnum.Success ) {
        self.goToLogin();
      }else {
        self.showError(data.failure?.message || "Network Error");
      }
    }).catch(() => {
      self.showError("Network Error");
    })  
  }



  render() {
    const message = this.state.error || "";
    const open = (message === "");
    return (
      <div className="background" >
        <div className="login-container">
          <div className="login-title">SIGN UP TO <em>KRIZAC</em> </div>
          <form>
            <div className="category-title">Organisation Profile</div>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Name" onChange={(e)=>{this.onDataChange('name',e.target.value)}} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Slug" onChange={(e)=>{this.onDataChange('slug',e.target.value)}} />
            </div>
            <div className="category-title">Super Admin User Profile</div>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="First Name" onChange={(e)=>{this.onDataChange('firstName',e.target.value)}} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Last Name" onChange={(e)=>{this.onDataChange('lastName',e.target.value)}} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Email" onChange={(e)=>{this.onDataChange('email',e.target.value)}} />
            </div>
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Phone" onChange={(e)=>{this.onDataChange('phone',e.target.value)}} />
            </div>
            <div className="form-group">
              <input type="password" className="form-control" placeholder="Password" onChange={(e)=>{this.onDataChange('password',e.target.value)}} />
            </div>         
            <Button type="button" className="btn btn-custom" onClick={()=>{this.signup()}}>Create Account</Button>
          </form>
          <Fade in={open}>
            <div className="form-error">
              {message}
            </div>
          </Fade>
        </div>
      </div>
    );   
  }

}